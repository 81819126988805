(function () {
    'use strict';

    angular
        .module('elogbooks.admin.users')
        .controller('UserSiteAccessController', UserSiteAccessController);

    function UserSiteAccessController($state, apiClient, messenger, lodash, userResponse, membershipsResponse, validationService) {
        var vm = this;
        vm.delete = deleteMembership;
        vm.search = searchMemberships;
        vm.user = userResponse;
        vm.memberships = membershipsResponse;
        vm.searchQuery = $state.params.name || '';
        vm.searchResults = vm.memberships;
        vm.isFiltered = vm.searchQuery.length > 0;
        vm.validationService = validationService;

        function searchMemberships() {
            $state.go('.', { name: vm.searchQuery, sitesPage: 1 })
        }

        function deleteMembership(membership) {
            apiClient.delete(membership._links.delete.href).then(function (response) {
                if (response) {
                    $state.go('.', {}, {reload: true});
                } else {
                    messenger.error('Unable to delete, please try again later');
                }
            });
        }
    }
})();
