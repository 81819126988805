;(function() {
    'use strict';

    angular.module('elogbooksServices').service('stonlyService', function() {
        var self = this;

        self.env = null;
        self.tag = null;

        self.init = init;
        self.checkStonly = checkStonly;

        function init() {
            var origin = window.location.origin;

            self.env = !!origin.match('sandpit|prerelease') ?
                'staging' : !!origin.match('testing') ?
                'testing' : !!origin.match('local') ? 'development': 'production';

            if (!!self.env.match('production|staging|prerelease|development')) {
                self.tag = document.createElement("script");
                self.tag.src = "/stonly.js";
                document.getElementsByTagName("head")[0].appendChild(self.tag);
            }
        }

        function checkStonly() {
            if (!self.tag) {
                this.init();
            }
        }
    });
})();