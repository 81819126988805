angular.module('elogbooksDirectives').directive('hexagonNominalCodes', function () {
    return {
        restrict: 'E',
        templateUrl: '/modules/directives/hexagon/nominal-codes.html',
        scope: {
            url: '=',
            propertyReference: '=',
            model: '=ngModel',
            form: '=',
            setFn: '&'
        },
        controller: function($scope, apiClient, messenger) {
            var mappings = [
                {analysisLevelName: 'Category', label: 'P2P_HEXAGON_NOMINAL_ANALYSIS_CATEGORY', requestProperty: 'nominal', responseProperty: 'reference', titleProperty: 'nominalTitle'},
                {analysisLevelName: 'Fund', label: 'P2P_HEXAGON_NOMINAL_ANALYSIS_FUND', requestProperty: 'fundId', responseProperty: 'id', titleProperty: 'fundTitle'},
                {analysisLevelName: 'Period', label: 'P2P_HEXAGON_NOMINAL_ANALYSIS_PERIOD', requestProperty: 'periodId', responseProperty: 'id', titleProperty: 'periodTitle'},
                {analysisLevelName: 'Schedule', label: 'P2P_HEXAGON_NOMINAL_ANALYSIS_SCHEDULE', requestProperty: 'scheduleId', responseProperty: 'id', titleProperty: 'scheduleTitle'},
                {analysisLevelName: 'ExpenseType', label: 'P2P_HEXAGON_NOMINAL_ANALYSIS_EXPENSE_TYPE', requestProperty: 'expenseTypeId', responseProperty: 'id', titleProperty: 'expenseTypeTitle'}
            ];

            $scope.addLine = addLine;
            $scope.ratioChange = ratioChange;
            $scope.removeLine = removeLine;

            $scope.$watch('propertyReference', function() {
                reset();
            });
            $scope.setFn({validateRatios: ratioChange});

            function removeLine(index) {
                $scope.model.splice(index, 1);
                $scope.selectModelsData.splice(index, 1);
                reindex();
                ratioChange();
            }

            function addLine() {
                fetchCodes($scope.selectModelsData.length);
            }

            function reindex() {
                for(var i = 0; i < $scope.selectModelsData.length; i++) {
                    for(var j = 0; j < $scope.selectModelsData[i].length; j++) {
                        $scope.selectModelsData[i][j].index = i;
                    }
                }
            }

            function ratioChange(exact) {
                exact = exact == undefined ? false : exact;
                var totalValue = 0;
                angular.forEach($scope.model, function (element) {
                    totalValue += element.ratio;
                });

                if (totalValue > 100 || (totalValue !== 100 && exact)) {
                    messenger.clearAll();
                    messenger.error('P2P_ERROR_RATIOS_INVALID');
                    $scope.model.ratioError = true;

                    return;
                }

                messenger.clearAll();
                $scope.model.ratioError = false;
            }

            function fetchCodes(index) {
                var level = 0;
                $scope.loading = true;
                $scope.$emit('hexagonNominalCodes', {loading: true});

                if ($scope.selectModelsData[index] === undefined) {
                    $scope.selectModelsData[index] = [];
                    $scope.model[index] = {propertyReference: $scope.propertyReference, ratio: null};
                } else {
                    level = $scope.selectModelsData[index].length;
                }

                apiClient.get($scope.url, $scope.model[index]).then(function (response) {
                    if (response) {
                        var selectModel = buildSelectModel(response, index, level);
                        $scope.selectModelsData[index].push(selectModel);

                        if (response.analysisCodes.length === 1) {
                            var object = response.analysisCodes[0];
                            var mapping = mappings[$scope.selectModelsData[index].length - 1];
                            selectModel.selected = object;
                            $scope.model[index][mapping.requestProperty] = object[mapping.responseProperty];
                            $scope.model[index][mapping.titleProperty] = object['title'];
                            fetchCodes(index);
                        }
                    } else {
                        messenger.error('REQUEST_ERROR');
                    }

                    $scope.$emit('hexagonNominalCodes', {loading: false});
                    $scope.loading = false;
                });
            }

            function buildSelectModel(response, index, level) {
                return {
                    index: index,
                    level: level,
                    label: mappings[level].label,
                    response: response,
                    required: true,
                    responseKeyPath: 'analysisCodes',
                    itemValuePath: 'title',
                    itemHrefPath: mappings[level].responseProperty,
                    searchKey: 'title',
                    onSelect: handleSelect,
                    onRemove: handleRemove
                };
            }

            function handleSelect(model) {
                var object = model.selected.object;
                var mapping = mappings[model.level];
                if ($scope.model[model.index][mapping.requestProperty] != object[mapping.responseProperty]) {
                    removeHigherLevels(model.index, model.level);
                    $scope.model[model.index][mapping.requestProperty] = object[mapping.responseProperty];
                    $scope.model[model.index][mapping.titleProperty] = object['title'];
                    if (object.nextAnalysisLevel) {
                        fetchCodes(model.index);
                    }
                }
            }

            function handleRemove(model) {
                delete($scope.model[model.index][mappings[model.level].requestProperty]);
                $scope.selectModelsData[model.index][model.level].selected = undefined;
                removeHigherLevels(model.index, model.level);
            }

            function removeHigherLevels(index, level) {
                var i = $scope.selectModelsData[index].length - 1;
                while (i > level) {
                    var removedModel = $scope.selectModelsData[index].pop();
                    delete($scope.model[index][mappings[removedModel.level].requestProperty]);
                    i--;
                }
            }

            function reset() {
                $scope.selectModels = [];
                $scope.selectModelsData = [];
                $scope.model = [];
                fetchCodes(0);
            }
        }
    }
});
