(function () {
    'use strict';

    angular
        .module('elogbooks.admin.sites')
        .controller('AssociatesEditController', AssociatesEditController);

    AssociatesEditController.$inject = ['$state', 'apiClient', 'messenger', 'membershipsResponse', 'associatesResponse', 'requestDataFactory', 'lodash', 'sortableOptions', '$scope'];

    function AssociatesEditController ($state, apiClient, messenger, membershipsResponse, associatesResponse, requestDataFactory, lodash, sortableOptions, $scope) {
        var vm = this;
        vm.memberships = membershipsResponse.getData().memberships;
        vm.update = updateAction;
        vm.addAssociate = addAssociate;
        vm.removeAssociate = removeAssociate;

        vm.sortableOptions = sortableOptions.addProperty({
            accept: function (sourceItemHandleScope, destSortableScope) {
                return sourceItemHandleScope.itemScope.sortableScope.$id === destSortableScope.$id;
            }
        });
        vm.getOptionsProxy = function (value) {
            return sortableOptions.addProperty({containment: '.draglist-'+value}, vm.sortableOptions);
        };

        if ($state.current.name.indexOf('admin') > 0) {
            vm.associatesCollection = $scope.$parent.$parent.$parent.vm.associatesCollection;
        } else {
            vm.associatesCollection = $scope.$parent.vm.associatesCollection;
        }

        vm.associateSelect = [];

        angular.forEach(vm.associatesCollection, function(associateCollection, key) {
            vm.associateSelect[key] = {
                response: membershipsResponse,
                link: membershipsResponse.getLink('self'),
                responseKeyPath: 'memberships',
                itemValuePath: '_links.user.title',
                linkParameters: {
                    permission: ['site_permission_can_be_associate']
                },
                onSelect: function() {},
                onRemove: function() {},
                appendToBody: true
            };
        });

        // deep watch for changes to associatescollection
        $scope.$watch('vm.associatesCollection', function(newValue, oldValue) {
            if (newValue !== oldValue) {
                updateAction();
            }
        }, true);

        function removeAssociate(associateType, associateKey) {
            associateType.members.splice(associateKey, 1);
        }

        function addAssociate(associateType, membership, key) {
            // don't allow adding existing memberships to this type
            var findExisting = lodash.find(associateType.members, function(element) { return element.href === membership._links.self.href; });
            if (findExisting === undefined) {

                var addition = {
                    href: membership._links.self.href,
                    title: membership._links.user.title,
                    userHref: membership._links.user.href
                };
                associateType.members.push(addition);
            }
            vm.associateSelect[key].selected = null;
        }

        function updateAction() {
            var associates = [];

            angular.forEach(vm.associatesCollection, function(associateType, key) {

                var typeHref = associateType.href;

                angular.forEach(associateType.members, function(member, key) {

                    var associate = {
                        'associate' : {
                            'siteassociatetype': {
                                'href': typeHref
                            },
                            'sitemembership': {
                                'href': member.href
                            }
                        }
                    };
                    associates.push(associate);
                });
            });

            var associatesData = {};
            associatesData.associates = associates;
            associatesData = requestDataFactory.createRequest(associatesData);

            apiClient.update(associatesResponse.getLink('self'), associatesData).then(function (response) {
                if (response) {
                    messenger.success('ASSOCIATES_UPDATED');
                } else {
                    messenger.error('REQUEST_ERROR');
                }
            });
        }
    }
})();
