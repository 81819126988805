(function () {
    'use strict';

    angular
        .module('elogbooks.user.reports')
        .controller('UserReportsSummaryDetailsController', UserReportsSummaryDetailsController);

    UserReportsSummaryDetailsController.$inject = ['$scope', '$filter', '$state', '$window', '$stateParams', '$translate', 'apiClient', 'siteCollectionResponse', 'lodash', 'coreClientCollectionResponse', 'siteGroupCollectionResponse', 'siteAssociateTypeCollectionResponse', 'siteAssociateCollectionResponse', 'serviceProviderGroupCollectionResponse', 'serviceProviderCollectionResponse', 'regionCollectionResponse', 'locationCollectionResponse', 'modulesService'];

    function UserReportsSummaryDetailsController($scope, $filter, $state, $window, $stateParams, $translate, apiClient, siteCollectionResponse, lodash, coreClientCollectionResponse, siteGroupCollectionResponse, siteAssociateTypeCollectionResponse, siteAssociateCollectionResponse, serviceProviderGroupCollectionResponse, serviceProviderCollectionResponse, regionCollectionResponse, locationCollectionResponse, modulesService) {
        var vm = this;
        vm.stateParams = $stateParams;
        vm.orderDirection = 1;
        vm.offset = 0;
        vm.snakeToCamel = snakeToCamel;
        vm.readablePath = readablePath;
        vm.search = search;
        vm.order = order;
        vm.toggleAll = toggleAll;
        vm.openModal = openModal;
        vm.changePage = changePage;
        vm.timesheetsEnabled = modulesService.isEnabled('timesheets');
        vm.floatTheadOptions = {
            top: 80,
            position: 'absolute'
        };
        // Filters
        vm.siteSelect = {
            response : siteCollectionResponse,
            link : siteCollectionResponse && siteCollectionResponse.count > 0 ? siteCollectionResponse.getLink('self') : null,
            responseKeyPath: 'sites',
            itemValuePath: 'id',
            itemTitlePath: 'name',
        };

        vm.locationSelect = {
            response : locationCollectionResponse,
            link : locationCollectionResponse && locationCollectionResponse.count > 0 ? locationCollectionResponse.getLink('self') : null,
            responseKeyPath: 'locations',
            itemValuePath: 'id',
            itemTitlePath: 'name',
            disabled: true,
            selected: null,
        };

        vm.serviceProviderSelect = {
            response : serviceProviderCollectionResponse,
            link : serviceProviderCollectionResponse ? serviceProviderCollectionResponse.getLink('self') : null,
            responseKeyPath: 'serviceproviders',
            itemValuePath: 'id',
            itemTitlePath: 'name'
        };
        vm.siteGroupSelect = {
            response : siteGroupCollectionResponse,
            link : siteGroupCollectionResponse ? siteGroupCollectionResponse.getLink('self') : null,
            responseKeyPath: 'sitegroups',
            itemValuePath: 'id',
            itemTitlePath: 'name'
        };
        vm.serviceProviderGroupSelect = {
            response : serviceProviderGroupCollectionResponse,
            link : serviceProviderGroupCollectionResponse ? serviceProviderGroupCollectionResponse.getLink('self') : null,
            responseKeyPath: 'groups',
            itemValuePath: 'id',
            itemTitlePath: 'name'
        };
        vm.siteAssociateTypeSelect = {
            response : siteAssociateTypeCollectionResponse,
            link : siteAssociateTypeCollectionResponse ? siteAssociateTypeCollectionResponse.getLink('self') : null,
            responseKeyPath: 'siteAssociateTypes',
            itemValuePath: 'id',
            itemTitlePath: 'name'
        };
        vm.siteRegionSelect = {
            response : regionCollectionResponse,
            link : regionCollectionResponse ? regionCollectionResponse.getLink('self') : null,
            responseKeyPath: 'regions',
            itemValuePath: 'id',
            itemTitlePath: 'name'
        };
        // Tables
        vm.tables = [
            {
                tab: 'region',
                title: 'JOBS_BY_REGION',
                columns: ['REGION', 'TOTAL_JOBS', 'OPEN_JOBS', 'COMPLETED_JOBS', 'MISSED_JOBS', 'ATTENDED_ON_TIME', 'COMPLETED_ON_TIME'],
                statutoryBreakdown: true,
                route: '/reports/jobs',
                params: {
                    groupBy: 'region',
                    'excludedStatuses[]' : 'cancelled'
                },
                response: null,
                loading: true,
                rowTemplate: '/modules/user/reports/summary/rows/jobs.html',
                groups: ['all', 'planned', 'reactive'],
                showHoursWorked: true
            },
            {
                tab: 'priority',
                title: 'JOBS_BY_PRIORITY',
                columns: ['PRIORITY', 'TOTAL_JOBS', 'OPEN_JOBS', 'COMPLETED_JOBS', 'MISSED_JOBS', 'ATTENDED_ON_TIME', 'COMPLETED_ON_TIME'],
                statutoryBreakdown: true,
                route: '/reports/jobs',
                params: {
                    groupBy: 'priority',
                    'excludedStatuses[]' : 'cancelled'
                },
                response: null,
                loading: true,
                rowTemplate: '/modules/user/reports/summary/rows/jobs.html',
                groups: ['all', 'planned', 'reactive'],
                showHoursWorked: true
            },
            {
                tab: 'job_group',
                title: 'JOBS_BY_JOB_GROUP',
                columns: ['JOB_GROUP', 'TOTAL_JOBS', 'OPEN_JOBS', 'COMPLETED_JOBS', 'MISSED_JOBS', 'ATTENDED_ON_TIME', 'COMPLETED_ON_TIME'],
                statutoryBreakdown: true,
                route: '/reports/jobs',
                params: {
                    groupBy: 'jobGroup',
                    'excludedStatuses[]' : 'cancelled'
                },
                response: null,
                loading: true,
                rowTemplate: '/modules/user/reports/summary/rows/jobs.html',
                groups: ['all', 'planned', 'reactive'],
                showHoursWorked: true
            },
            {
                tab: 'serviceprovider',
                title: 'JOBS_BY_SERVICEPROVIDER',
                columns: ['SERVICEPROVIDER', 'TOTAL_JOBS', 'OPEN_JOBS', 'COMPLETED_JOBS', 'MISSED_JOBS', 'ATTENDED_ON_TIME', 'COMPLETED_ON_TIME'],
                statutoryBreakdown: true,
                route: '/reports/jobs',
                params: {
                    groupBy: 'serviceProvider',
                    'excludedStatuses[]' : 'cancelled'
                },
                response: null,
                loading: true,
                rowTemplate: '/modules/user/reports/summary/rows/jobs.html',
                groups: ['all', 'planned', 'reactive'],
                showHoursWorked: true
            },
            {
                tab: 'site_group',
                title: 'JOBS_BY_SITE_GROUP',
                columns: ['SITE_GROUP', 'TOTAL_JOBS', 'OPEN_JOBS', 'COMPLETED_JOBS', 'MISSED_JOBS', 'ATTENDED_ON_TIME', 'COMPLETED_ON_TIME'],
                statutoryBreakdown: true,
                route: '/reports/jobs',
                params: {
                    groupBy: 'siteGroup',
                    'excludedStatuses[]' : 'cancelled'
                },
                response: null,
                loading: true,
                rowTemplate: '/modules/user/reports/summary/rows/jobs.html',
                groups: ['all', 'planned', 'reactive'],
                showHoursWorked: true
            },
            {
                tab: 'site',
                title: 'JOBS_BY_SITE',
                columns: ['SITE', 'TOTAL_JOBS', 'OPEN_JOBS', 'COMPLETED_JOBS', 'MISSED_JOBS', 'ATTENDED_ON_TIME', 'COMPLETED_ON_TIME'],
                statutoryBreakdown: true,
                route: '/reports/jobs',
                params: {
                    groupBy: 'site',
                    'excludedStatuses[]' : 'cancelled'
                },
                response: null,
                loading: true,
                rowTemplate: '/modules/user/reports/summary/rows/jobs.html',
                groups: ['all', 'planned', 'reactive'],
                showHoursWorked: true,
                jobTypeBreakDown: true,
            },
            {
                tab: 'service_provider_group',
                title: 'SERVICE_PROVIDER_GROUP',
                columns: ['SERVICE_PROVIDER_GROUP', 'TOTAL_JOBS', 'OPEN_JOBS', 'COMPLETED_JOBS', 'MISSED_JOBS', 'ATTENDED_ON_TIME', 'COMPLETED_ON_TIME'],
                statutoryBreakdown: true,
                route: '/reports/jobs',
                params: {
                    groupBy: 'serviceProviderGroup',
                    'excludedStatuses[]' : 'cancelled'
                },
                response: null,
                loading: true,
                rowTemplate: '/modules/user/reports/summary/rows/jobs.html',
                groups: ['all', 'planned', 'reactive'],
                hideTotals: true,
                showHoursWorked: true
            },
            {
                tab: 'quotes_by_site',
                title: 'QUOTES_BY_SITE',
                columns: ['SITE', 'TOTAL_QUOTES', 'ACCEPTED_QUOTES', 'DECLINED_QUOTES', 'EXPIRED_QUOTES', 'REJECTED_QUOTES'],
                route: '/reports/quotes',
                params: {
                    groupBy: 'site'
                },
                response: null,
                loading: true,
                rowTemplate: '/modules/user/reports/summary/rows/quotes.html',
                groups: ['all', 'quotes']
            },
            {
                tab: 'quotes_by_sp',
                title: 'QUOTES_BY_SERVICEPROVIDER',
                columns: ['SERVICEPROVIDER', 'TOTAL_QUOTES', 'ACCEPTED_QUOTES', 'DECLINED_QUOTES', 'EXPIRED_QUOTES', 'REJECTED_QUOTES'],
                route: '/reports/quotes',
                params: {
                    groupBy: 'serviceProvider'
                },
                response: null,
                loading: true,
                rowTemplate: '/modules/user/reports/summary/rows/quotes.html',
                groups: ['all', 'quotes']
            },
            {
                tab: 'kpi',
                title: 'KPI',
                columns: ['SERVICEPROVIDER', 'TOTAL_KPI', 'AVERAGE_SCORE'],
                route: '/reports/kpi',
                params: {
                    groupBy: 'serviceProvider'
                },
                response: null,
                loading: true,
                rowTemplate: '/modules/user/reports/summary/rows/kpi.html',
                groups: ['all', 'kpi']
            },
            {
                tab: 'kpi_groups',
                title: 'KPI_GROUPS',
                columns: ['SERVICEPROVIDER_GROUP', 'TOTAL_KPI', 'AVERAGE_SCORE'],
                route: '/reports/kpi',
                params: {
                    groupBy: 'serviceProviderGroup'
                },
                response: null,
                loading: true,
                rowTemplate: '/modules/user/reports/summary/rows/kpi.html',
                groups: ['all', 'kpi'],
                hideTotals: true
            }
        ];

        if (vm.timesheetsEnabled) {
            lodash.forEach(vm.tables, function(table) {
                if (table.showHoursWorked) {
                    table.columns.splice(2, 0, 'TIME_WORKED');
                }
            });
        }

        // Validate the initial date
        if ($stateParams.dateRange !== null && typeof $stateParams.dateRange === 'string'
            && moment(new Date($stateParams.dateRange.split(',')[1])) > moment().subtract(1, 'day')
        ) {
            $stateParams.dateRange = moment(new Date($stateParams.dateRange.split(',')[0])).format()+','+moment().subtract(1, 'day').endOf('day').format();
        }

        function loadData() {
            lodash.forEach(vm.tables, function(table) {
                if (!table.response && table.route && vm.stateParams.tab === table.tab.toLowerCase()) {
                    if (table.groups.indexOf($stateParams.reportOn) !== -1) {
                        table.true = false;
                        table.params.order = $stateParams.srOrder;
                        table.params = angular.extend(table.params, vm.cleanParams);
                        table.totals = {};
                        apiClient.get(table.route, table.params).then(function (response) {
                            table.response = response;
                            table.loading = false;

                            lodash.forEach(response.data, function(item) {
                                for (var property in item) {
                                    if (lodash.isNumber(item[property])) {
                                        if (typeof table.totals[property] === 'undefined') {
                                            table.totals[property] = 0;
                                        }

                                        table.totals[property] += item[property];
                                    }

                                    // Calculate all data
                                    item.displayName = item.path ? vm.readablePath(item.path) : item.name || null;

                                    if (property === 'groupIsActive') {
                                        if (item.name === '') {
                                            item.isActive = true;
                                        } else {
                                            item.isActive = item.groupIsActive;
                                        }
                                    }
                                }
                            });

                            order($stateParams.srOrder);
                        });
                    }
                }
            });
        }

        vm.searchCriteria = {
            dateRange: {
                type: 'date-range',
                title: 'BETWEEN_DATES',
                value: {
                    startDate: ($stateParams.dateRange !== null && typeof $stateParams.dateRange === 'string') ? moment(new Date($stateParams.dateRange.split(',')[0])) : null,
                    endDate: ($stateParams.dateRange !== null && typeof $stateParams.dateRange === 'string') ? moment(new Date($stateParams.dateRange.split(',')[1])) : null,
                    endMaxDate: moment().subtract(1, 'day')
                },
                clearValue: {
                    startDate: null,
                    endDate: null
                }
            },
            reportOn: {
                type: 'options',
                title: 'REPORT_ON',
                value: $stateParams.reportOn,
                options: [
                    {
                        title: $filter('translate')('ALL'),
                        value: 'all'
                    },
                    {
                        title: $filter('translate')('PLANNED_JOBS'),
                        value: 'planned'
                    },
                    {
                        title: $filter('translate')('REACTIVE_JOBS'),
                        value: 'reactive'
                    },
                    {
                        title: $filter('translate')('QUOTES'),
                        value: 'quotes'
                    },
                    {
                        title: $filter('translate')('KPI_SURVEYS'),
                        value: 'kpi'
                    }
                ]
            },
            site: { type: 'jsonselectwidget', title: 'SITE', value: JSON.parse($stateParams.site), options: vm.siteSelect },
            location: { type: 'jsonselectwidget', title: 'LOCATION', value: JSON.parse($stateParams.location), options: vm.locationSelect},
            siteGroup: { type: 'jsonselectwidget', title: 'SITE_GROUP', value: JSON.parse($stateParams.siteGroup), options: vm.siteGroupSelect },
            serviceProviderGroup: { type: 'jsonselectwidget', title: 'SERVICE_PROVIDER_GROUP', value: JSON.parse($stateParams.serviceProviderGroup), options: vm.serviceProviderGroupSelect },
            serviceProvider: { type: 'jsonselectwidget', title: 'SERVICEPROVIDER', value: JSON.parse($stateParams.serviceProvider), options: vm.serviceProviderSelect },
            siteRegionId: { type: 'jsonselectwidget', title: 'REGION', value: JSON.parse($stateParams.siteRegionId), options: vm.siteRegionSelect },
            siteAssociateType: { type: 'jsonselectwidget', title: 'SITE_ASSOCIATE_TYPE', value: JSON.parse($stateParams.siteAssociateType), options: vm.siteAssociateTypeSelect }
        };

        if (vm.searchCriteria.site.value !== null) {
            vm.searchCriteria.location.options.disabled = false;
        } else {
            vm.searchCriteria.location.value = null;
            $stateParams.location = null;
        }

        // Clean params for export and table data loading
        vm.cleanParams = {};
        vm.cleanParams.reportOn = $stateParams.reportOn;
        vm.cleanParams.dateRange = $stateParams.dateRange;
        vm.cleanParams.coreClient = $stateParams.coreClient ? JSON.parse($stateParams.coreClient).v : null;
        vm.cleanParams.site = $stateParams.site ? JSON.parse($stateParams.site).v : null;
        vm.cleanParams.siteGroup = $stateParams.siteGroup ? JSON.parse($stateParams.siteGroup).v : null;
        vm.cleanParams.siteRegionId = $stateParams.siteRegionId ? JSON.parse($stateParams.siteRegionId).v : null;
        vm.cleanParams.siteAssociateType = $stateParams.siteAssociateType ? JSON.parse($stateParams.siteAssociateType).v : null;
        vm.cleanParams.siteAssociateId = $stateParams.siteAssociateId ? JSON.parse($stateParams.siteAssociateId).v : null;
        vm.cleanParams.serviceProviderGroup = $stateParams.serviceProviderGroup ? JSON.parse($stateParams.serviceProviderGroup).v : null;
        vm.cleanParams.serviceProvider = $stateParams.serviceProvider ? JSON.parse($stateParams.serviceProvider).v : null;
        vm.cleanParams.location = $stateParams.location ? JSON.parse($stateParams.location).v : null;

        if ($stateParams.siteAssociateType) {
            vm.siteAssociateSelect = {
                response : siteAssociateCollectionResponse,
                link : siteAssociateCollectionResponse ? siteAssociateCollectionResponse.getLink('self') : null,
                responseKeyPath: 'users',
                itemValuePath: 'id',
                itemTitlePath: 'name'
            };

            vm.searchCriteria.siteAssociateId = { type: 'jsonselectwidget', title: 'SITE_ASSOCIATE', value: JSON.parse($stateParams.siteAssociateId), options: vm.siteAssociateSelect };
        }

        if (coreClientCollectionResponse.count) {
            vm.coreClientSelect = {
                response : coreClientCollectionResponse,
                link : coreClientCollectionResponse ? coreClientCollectionResponse.getLink('self') : null,
                responseKeyPath: 'coreclients',
                itemValuePath: 'id',
                itemTitlePath: 'name'
            };

            vm.searchCriteria.coreClient = { type: 'jsonselectwidget', title: 'CORE_CLIENT', value: JSON.parse($stateParams.coreClient), options: vm.coreClientSelect };
        }

        function toggleAll(index, flag) {
            if (lodash.has(vm.tables[index], 'response.data')) {
                lodash.forEach(vm.tables[index].response.data, function(item) {
                    item.expanded = flag;
                });
                vm.tables[index].totals.expanded = flag;
            }
        }

        // Parse job group hierarchy to a readable string
        function readablePath(path) {
            var paths = [];

            var parsed = JSON.parse(path);

            for (var prop in parsed) {
                paths.push(parsed[prop].name);
            }

            return paths.join(' / ');
        }

        function snakeToCamel(s){
            return s.toLowerCase().replace(/(\_\w)/g, function(m){return m[1].toUpperCase();});
        }

        function search(params) {
            var override = {
                page: 1
            };

            switch (params.reportOn) {
                case 'quotes':
                    if (['quotes_by_site', 'quotes_by_sp'].indexOf(params.tab) === -1) {
                        override.tab = 'quotes_by_site';
                    }
                    break;
                case 'planned':
                case 'reactive':
                    if (['region', 'priority', 'job_group', 'site', 'site_group', 'serviceprovider'].indexOf(params.tab) === -1) {
                        override.tab = 'region';
                    }
                    break;
                case 'kpi':
                    if ('kpi' !== params.tab) {
                        override.tab = 'kpi';
                    }
                    break;
            }

            $state.go('.', angular.extend($stateParams, params, override), {reload: $state.current.name});
        }

        function order(key) {
            $stateParams.srOrder = key;
            // Make sure to reset page and offset after ordering
            $stateParams.summaryPage = 1;
            vm.offset = 0;
            $state.go('.', $stateParams, {reload:$state.current.name, notify: false});

            var regex = /^([ \+-])?(\w+)$/;
            var match = regex.exec(key);

            if (match) {
                var orderDirection = match[1] === '-' ? 'desc': 'asc';
                var orderColumn = match[2];

                if ($stateParams.tab === 'job_group'
                    && match[2] === 'name') {
                    orderColumn = 'displayName';
                }

                lodash.forEach(vm.tables, function(table) {
                    if (table.response) {
                        table.response.data = lodash.orderBy(table.response.data, orderColumn, orderDirection)
                    }
                });

                $window.scrollTo(0, 0);
            }
        }

        function openModal(item, type, column, statutory, jobType) {
            var route = '';

            switch (type) {
                case 'jobs':
                    route = 'dashboard.user.reports.list.view.summary.details.modal.jobs';
                    break;
                case 'quotes':
                    route = 'dashboard.user.reports.list.view.summary.details.modal.quotes';
                    break;
                case 'kpi':
                    route = 'dashboard.user.reports.list.view.summary.details.modal.kpi';
                    break;
            }

            var params = {
                titlePrefix: item.displayName,
                id: item.id || -1,
                columnName: column,
                itemId: item.id
            };

            if (typeof statutory !== 'undefined') {
                params.statutory = statutory;
            }

            if (typeof jobType !== 'undefined') {
                params.type = jobType;
            }

            if ($stateParams.location !== null) {
                params.location = JSON.parse($stateParams.location).v;
            }

            $state.go(route, angular.extend({}, $stateParams, params), {reload:false, notify: false});
        }

        function changePage(page) {
            // Make sure to set page and adjust the offset after ordering
            vm.offset = (page - 1) * $stateParams.summaryLimit;
            vm.stateParams.summaryPage = page;
            $window.scrollTo(0, 0);
            $state.go('.', angular.extend({}, $stateParams, {summaryPage: page}), { reload: $state.current.name, notify: false });
        }

        $scope.$watch('vm.stateParams.tab', function (newValue, oldValue) {
            if (newValue !== oldValue) {
                // Make sure to reset page and offset after changing tab
                $stateParams.summaryPage = 1;
                vm.offset = 0;

                //Silent update of state
                $state.go('.', $stateParams, {reload: $state.current.name, notify: false}).then(function () {
                    loadData();
                    // Trigger resize event so fixed able headers can get valid top margin
                    $window.dispatchEvent(new Event('resize'));
                });
            }
        });

        loadData();
    }
})();


