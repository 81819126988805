(function () {
    'use strict';

    angular
        .module('elogbooks.user.sites')
        .controller('UserSitesAssociatesController', UserSitesAssociatesController);

    UserSitesAssociatesController.$inject = ['$scope', 'approversResponse', 'associatesResponse', 'lodash', 'userManager', 'quoteThresholdsCollectionResponse', 'thresholdService'];

    function UserSitesAssociatesController($scope, approversResponse, associatesResponse, lodash, userManager, quoteThresholdsCollectionResponse, thresholdService) {
        var vm = this;
        vm.approvers = approversResponse;
        vm.associatesCollection = $scope.$parent.vm.associatesCollection;
        vm.canEditAssociates = userManager.hasPermission('SITE_PERMISSION_EDIT_ASSOCIATES');
        vm.canEditApprovers = userManager.hasPermission('SITE_PERMISSION_EDIT_APPROVERS');

        vm.quoteThresholds = [];

        if (lodash.has(quoteThresholdsCollectionResponse, 'quoteThresholds') ) {
            vm.quoteThresholds = quoteThresholdsCollectionResponse.quoteThresholds;
            thresholdService.initThresholds(vm.quoteThresholds);
            vm.getQuoteApproverThreshold = thresholdService.getQuoteApproverThreshold;
        }
    }
})();
