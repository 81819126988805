(function () {
    'use strict';

    angular.module('elogbooks.admin.sso-users').controller('SsoUsersController', SsoUsersController);

    SsoUsersController.$inject = ['$state', '$stateParams', 'ssoUsersCollectionResponse', 'apiClient', 'messenger', 'userManager', '$filter'];

    function SsoUsersController($state, $stateParams, ssoUsersCollectionResponse, apiClient, messenger, userManager, $filter) {
        var vm = this;
        vm.ssoUsersCollectionResponse = ssoUsersCollectionResponse;
        vm.deleteSelected = deleteSelected;
        vm.disableCheckbox = !userManager.hasPermission('user_permission_manage_users');
        var ssoTypes = [
            { title: $filter('translate')('NONE_SELECTED'), value: null },
            { title: $filter('translate')('AZURE'), value: $filter('translate')('AZURE') },
            { title: $filter('translate')('OKTA'), value: $filter('translate')('OKTA') }
        ];
        vm.criteria = {
            name: {type: 'text', title: 'NAME', value: $stateParams.name},
            type: {type: 'options', title: 'TYPE', value: $stateParams.type, options: ssoTypes},
        };

        vm.search = search;
        vm.order = order;

        function deleteSelected(ssoUserResource) {
            return apiClient.delete(ssoUserResource, {}, $stateParams).then(function (response) {
                if (response) {
                    $state.go('.', {}, {reload: $state.current.name}).then(function () {
                        messenger.success('DELETED');
                    });
                } else {
                    messenger.error('REQUEST_ERROR');
                }
            });
        }

        function search(params) {
            var override = {
                page: 1
            };

            $state.go('.', angular.extend({}, params, override), {reload: 'dashboard.admin.sso-users'});
        }

        function order(key) {
            $stateParams.order = key;
            var override = {
                page: 1
            };

            $state.go('.', angular.extend({}, $stateParams, override), {reload: 'dashboard.admin.sso-users'});
        }
    }
})();
