(function() {
    'use strict';

    angular
        .module('elogbooks.common.jobs.add')
        .controller('CommonJobAddDetailsController', CommonJobAddDetailsController);


    CommonJobAddDetailsController.$inject = [
        '$rootScope',
        '$scope',
        '$state',
        'apiClient',
        'confirmationModal',
        'jobGroupCollectionResponse',
        'partnershipCollectionResponse',
        'settingCollectionResponse',
        'statutoryTypeCollectionResponse',
        '$q',
        '$translate',
        'lodash',
        'messenger',
        'priorityService',
        'siteResourceResponse',
        'userManager',
        'siteResponse',
        'locationCollectionResponse',
        'chargeTypesCollectionResponse',
        'assetsCollectionResponse',
        'userIsReactiveJobApprover',
        '$stateParams',
        'actionResponse',
        'actionFilesCollection',
        'config',
        'documentTypesCollectionResponse',
        'documentTypeService',
        'elbSettings',
        'serviceProviderService'
    ];

    function CommonJobAddDetailsController (
        $rootScope,
        $scope,
        $state,
        apiClient,
        confirmationModal,
        jobGroupCollectionResponse,
        partnershipCollectionResponse,
        settingCollectionResponse,
        statutoryTypeCollectionResponse,
        $q,
        $translate,
        lodash,
        messenger,
        priorityService,
        siteResourceResponse,
        userManager,
        siteResponse,
        locationCollectionResponse,
        chargeTypesCollectionResponse,
        assetsCollectionResponse,
        userIsReactiveJobApprover,
        $stateParams,
        actionResponse,
        actionFilesCollection,
        config,
        documentTypesCollectionResponse,
        documentTypeService,
        elbSettings,
        serviceProviderService
    ) {
        $scope.form.$setPristine();

        var vm = $scope.vm;
        vm.canNext = false;
        vm.canPrevious = true;
        $rootScope.forceDirtyConfirm = vm.canPrevious;

        vm.canSubmit = true;
        vm.isStatutory = false;
        vm.unsetStatutory = unsetStatutory;
        vm.title = 'JOB_ADD_DETAILS';
        vm.poRequired = false;
        vm.hasChargeType = false;
        vm.locationCollectionResponse = locationCollectionResponse;
        vm.assets = assetsCollectionResponse;
        vm.noteConfig = config;
        vm.selectedJobRequisites = [];
        vm.isTenant = vm.config.isTenant;
        vm.isTenantPlus  = vm.config.isTenantPlus;
        vm.requiredDocumentType = documentTypesCollectionResponse;

        vm.removeJobRequisite = removeJobRequisite;

        vm.fromAction = actionResponse;

        vm.actionFilesCollection = actionFilesCollection ? actionFilesCollection : null;
        vm.actionFiles = [];
        vm.showActionFiles = false;
        vm.disableActionFilesButton = false;

        // If all files are expired do not show the button.
        vm.allFilesExpired = actionFilesCollection
            ? actionFilesCollection.files.every(file => file.expiresAt !== null && new Date(file.expiresAt) < new Date())
            : false;

        vm.fromActionClosed = true;
        vm.selectedRequiredDocumentTypes = [];
        vm.certificateRequiredDocType = null;

        var stateName = $scope.$root.currentState,
            priorityLinkParameters = {'active': true, 'entityTypes': 'entity_type_all,entity_type_job'};

        var reactiveJobApprovalRequired = siteResourceResponse.reactiveJobApprovalRequired;
        var reactivejobapprover = siteResourceResponse.getLink('reactivejobapprover');
        var reactiveJobApproverBuddy = siteResourceResponse.getLink('buddy');
        var reporter = lodash.has(vm, 'data.reporter._links.self') ? vm.data.reporter.getLink('self') : null;

        // Check if reporter is the same as the approver and check if 'Allow secondary onward approvers to approve their own reactive jobs' = true
        vm.reporterIsReactiveJobApprover = false;
        if (lodash.has(siteResourceResponse, '_embedded.approvers.reactiveJobApprovers.approvers.memberships')
        && siteResourceResponse._embedded.approvers.reactiveJobApprovers.approvers.memberships.length > 0) {
            var approvers = siteResourceResponse._embedded.approvers.reactiveJobApprovers.approvers.memberships;
            if (settingCollectionResponse.reactiveSelfApproval) {
                lodash.forEach(approvers, function(approver) {
                    if (approver._links.user.href === reporter || (approver._links.active_buddy && approver._links.active_buddy.href === reporter)) {
                        vm.reporterIsReactiveJobApprover = true;
                        return false;
                    }
                });
            } else {
                if (approvers[0]._links.user.href === reporter || (approvers[0]._links.active_buddy && approvers[0]._links.active_buddy.href === reporter)) {
                    vm.reporterIsReactiveJobApprover = true;
                }
            }
        }

        // This logic will be controlling the "Skip approval notification" checkbox default value and behaviour.
        vm.data.skipRequestApprovalNotification = vm.reporterIsReactiveJobApprover;

        vm.serviceProviderRequired = false;
        vm.selectedChargeTypeRequired = false;
        vm.locationRequired = siteResourceResponse.locationRequired;

        vm.prosureEnabled = elbSettings.getSetting('prosure_enabled').value;

        // If reactiveSelfApproval is enabled AND this user is a reactive job approver for this site OR:
        // Reactive jobs are not required to be approved OR:
        // (user or the selected job reporter is a job approver) THEN:
        // SP is required for non-tenant users
        if (
            settingCollectionResponse.reactiveSelfApproval && userIsReactiveJobApprover === true ||
            !reactiveJobApprovalRequired ||
            siteResourceResponse.sharingEnabled ||
            (
                !vm.config.isTenant &&
                reactivejobapprover !== null &&
                (
                    userManager.user.getLink('self') === reactivejobapprover ||
                    userManager.user.getLink('self') === reactiveJobApproverBuddy ||
                    vm.reporterIsReactiveJobApprover
                )
            )
        ) {
            if (siteResourceResponse.poRequired) {
                vm.poRequired = true;
            }
            vm.serviceProviderRequired = true;
        }

        if (!vm.config.isHelpdesk) {
            vm.data.notifyOnCreate = (userManager.getPreference('notificationOnCreateJob').value === 'on' ? true : false );
            vm.showNotifyOnCreate = !vm.data.notifyOnCreate;
            vm.data.notifyOnComplete = (userManager.getPreference('notificationOnCompleteReactiveJob').value === 'on' ? true : false );
            vm.showNotifyOnComplete = !vm.data.notifyOnComplete;
        }
        else {
            vm.showNotifyOnCreate = false;
            vm.notifyOnComplete = false;
        }

        if (vm.data.siteContactAvailableOnSite === 'true') {
            vm.data.siteContactAvailableOnSite = true;
        } else {
            vm.data.siteContactAvailableOnSite = false;
        }

        vm.jobGroups = jobGroupCollectionResponse;
        vm.statutoryTypes = statutoryTypeCollectionResponse;
        vm.priorities = [];
        vm.partnerships = partnershipCollectionResponse;
        vm.selectedRequiredDocumentTypes = [];
        vm.certificateRequiredDocType = null;
        vm.enforceConfirmationOfEmptyJobRequisites = settingCollectionResponse.enforceConfirmationOfEmptyJobRequisites;
        vm.noRequisiteRequired = true;

        if (vm.enforceConfirmationOfEmptyJobRequisites) {
            vm.noRequisiteRequired = false;
        }
        vm.showJobRequisitesSelect = false;

        vm.copyFromActionDetails = copyFromActionDetails;
        vm.includeFilesFromAction = includeFilesFromAction;
        vm.removeActionFile = removeActionFile;
        vm.changeJobGroup = changeJobGroup;
        vm.changeServiceProvider = changeServiceProvider;
        vm.changeStatutoryType = changeStatutoryType;
        vm.changeOperative = changeOperative;
        vm.changeAsset = changeAsset;
        vm.changeChargeType = changeChargeType;
        vm.previous = previousAction;
        vm.submit = submitAction;
        vm.changeRequiredDocumentType = changeRequiredDocumentType;
        vm.removeDocumentType = removeDocumentType;
        vm.changeRequisiteRequiredCheckbox = changeRequisiteRequiredCheckbox;
        vm.changeRequiredDocumentType = changeRequiredDocumentType;
        vm.removeDocumentType = removeDocumentType;

        vm.jobGroupRequired = true;

        vm.jobGroupChildSelectMandatory = settingCollectionResponse.jobGroupChildSelectMandatory;

        vm.data.isByPlannedDate = vm.data.isOnNextPlannedVisit = false;

        vm.data.assets = {
            asset: null,
            subasset: null
        }

        vm.data.requiredDocumentTypes = {
            links: null
        }

        vm.selectedStatutoryTypeModel = {
            response: vm.statutoryTypes,
            link: vm.statutoryTypes.getLink('self'),
            required: true,
            responseKeyPath: 'statutorytypes',
            itemHrefPath: '_links.self.href',
            itemValuePath: 'name',
            onSelect: vm.changeStatutoryType,
            onRemove: vm.changeStatutoryType
        };

        vm.selectedJobGroupModel = {
            response: vm.jobGroups,
            link: vm.jobGroups.getLink('self'),
            required: vm.jobGroupRequired,
            responseKeyPath: 'jobgroups',
            itemHrefPath: '_links.self.href',
            itemValuePath: 'name',
            onSelect: vm.changeJobGroup,
            onRemove: vm.changeJobGroup,
        };

        vm.selectedAssetModel = {
            response: vm.assets,
            link: vm.assets ? vm.assets.getLink('self') : null,
            required: false,
            responseKeyPath: 'assets',
            itemHrefPath: '_links.self.href',
            itemValuePath: 'name',
            onSelect: changeAsset,
            onRemove: changeAsset,
            refreshOnLinkChange: false
        };

        if (vm.fromAction && vm.fromAction._links.asset) {
            vm.data.assets.asset = vm.fromAction._links.asset;
        }

        if (vm.fromAction && vm.fromAction._links.subasset) {
            vm.data.assets.subasset = vm.fromAction._links.subasset;
        }

        if ($stateParams.sourceJobAsset) {
            vm.data.assets.asset = {href: $stateParams.sourceJobAsset.decode()};

            if ($stateParams.sourceJobSubasset) {
                vm.data.assets.subasset = {href: $stateParams.sourceJobSubasset.decode()};
            }
        }

        if (vm.fromAction && vm.fromAction._links.statutorytype) {
            vm.isStatutory = true;
            vm.selectedStatutoryTypeModel.selected = vm.fromAction._links.statutorytype;
            vm.data._links.statutorytype = vm.fromAction._links.statutorytype;
        }

        vm.selectedServiceProviderModel = {
            response: vm.partnerships,
            link: vm.partnerships ? vm.partnerships.getLink('self') : null,
            linkParameters: {active: 1},
            required: vm.serviceProviderRequired,
            responseKeyPath: 'partnerships',
            itemHrefPath: '_links.serviceprovider.href',
            itemValuePath: '_links.serviceprovider.title',
            itemDisabledKey: '_links.serviceprovider.contractorApproved',
            itemDisabledKeyValue: false,
            itemDisabledHint: 'NOT_APPROVED',
            onSelect: vm.changeServiceProvider,
            onRemove: vm.changeServiceProvider,
            formatValue: serviceProviderService.formatServiceProviderName,
            headers: serviceProviderService.isProsureEnabled ? serviceProviderService.prosureStatusHeaders : null,
            reorder: serviceProviderService.prosureGroupReorder
        };

        if (vm.prosureEnabled) {
            vm.selectedServiceProviderModel.loadmoreEnabled = true;
        }

        vm.selectedPriorityModel = {
            response: null,
            link: null,
            linkParameters: priorityLinkParameters,
            required: !vm.config.isTenantPlus,
            responseKeyPath: 'priorities',
            itemHrefPath: '_links.self.href',
            itemValuePath: 'name',
            onSelect: changePriority,
            onRemove: changePriority,
            refreshOnLinkChange: false
        };

        vm.selectedOperativeModel = {
            response: null,
            link: null,
            required: false,
            responseKeyPath: 'operatives',
            itemHrefPath: '_links.self.href',
            itemValuePath: '_links.user.title',
            onSelect: changeOperative,
            onRemove: changeOperative,
            includeEmbeddedData: 'serviceTypes',
            linkParameters: { permission: 'operative_permission_receive_jobs', includeServiceTypes: true }
        };

        vm.selectedJobRequisiteModel = {
            linkParameters: {active:1},
            required: false,
            responseKeyPath: 'jobRequisites',
            itemHrefPath: '_links.self.href',
            itemValuePath: 'name',
            disabled: vm.enforceConfirmationOfEmptyJobRequisites && vm.noRequisiteRequired,
            onSelect: changeJobRequisite,
            onRemove: changeJobRequisite
        };

        if(chargeTypesCollectionResponse.chargetypes.length > 0) {
            vm.hasChargeType = true;
        }

        vm.selectedChargeTypeModel = {
            response: chargeTypesCollectionResponse,
            required: vm.selectedChargeTypeRequired,
            responseKeyPath: 'chargetypes',
            itemHrefPath: '_links.self.href',
            itemValuePath: 'name',
            onSelect: vm.changeChargeType,
            onRemove: vm.changeChargeType
        };

        if (userManager.user.getLink('self') === reactivejobapprover ||
            userManager.user.getLink('self') === reactiveJobApproverBuddy ||
            vm.reporterIsReactiveJobApprover) {
            vm.selectedChargeTypeRequired = true;
        }

        if (siteResourceResponse.getLink('priorities')) {
            priorityService.setupPriorities(null, null, siteResourceResponse.getLink('priorities'), priorityLinkParameters).then(function (response) {
                vm.selectedPriorityModel.response = priorityService.addExtraPriorities(response);
            });
        }

        if (! vm.siteNotesConfirmed) {
            $state.go(vm.getRoute('site'), {}, { reload: false });
        }

        if (typeof settingCollectionResponse.requireJobGroup !== 'undefined') {
            vm.selectedJobGroupModel.required = settingCollectionResponse.requireJobGroup;
        }

        vm.locationNotesConfirmed = [];

        vm.selectedRequiredDocumentTypeModel = {
            response: vm.requiredDocumentType,
            required: false,
            responseKeyPath: 'documentTypes',
            itemHrefPath: '_links.self.href',
            itemValuePath: 'name',
            linkParameters: {status: 'active'},
            onSelect: changeRequiredDocumentType,
            onRemove: changeRequiredDocumentType
        };

        angular.forEach(vm.requiredDocumentType.documentTypes, function (value) {
            if (value.name === 'Certificate') {
                vm.certificateRequiredDocType = value;
            }
        });

        if (vm.fromAction && vm.certificateRequiredDocType && vm.fromAction.hasOwnProperty('meridianDocType')) {
            vm.selectedRequiredDocumentTypes.push(vm.certificateRequiredDocType);
        }

        function changeRequiredDocumentType() {
            documentTypeService.changeRequiredDocumentType(vm);
        }

        function removeDocumentType(documentType) {
            documentTypeService.removeDocumentType(vm, documentType);
        }

        $scope.$on('location:select:click', function(event) {
            if (vm.noteConfig.showSiteNotes) {
                var selectedIndex = null;
                var selected = null;

                lodash.find(event.targetScope.locationFormFields, function(item, index) {
                    if (item.selected._links.self.href === event.targetScope.locationFormField.selected) {
                        selectedIndex = index;
                        selected = item.selected;
                    }
                });

                //if location is different prompt notes again
                if (vm.data._links.location && (vm.data._links.location.href !== selected._links.self.href)) {
                    vm.locationNotesConfirmed[selectedIndex] = false;
                }

                if (!vm.locationNotesConfirmed[selectedIndex] && selected) {
                    apiClient.get(selected.getLink('notes'), {allowHelpdesk: true}).then(function (noteResponse) {
                        if (noteResponse && noteResponse.locationNotes.length > 0) {
                            var config = {
                                primaryClass: 'btn-primary',
                                primaryText: 'OK',
                                secondaryText: 'CANCEL',
                                titleMessage: 'LOCATION_NOTES',
                                template: '/modules/common/site/notes.html',
                                data: noteResponse.locationNotes
                            };

                            return confirmationModal.open(config).result.then(function() {
                                vm.locationNotesConfirmed[selectedIndex] = true;
                            }, function() {
                                //clear the location selection
                                event.targetScope.locationFormFields[selectedIndex].selected = null;
                                delete event.targetScope.locationFormFields[selectedIndex+1];

                                return;
                            });
                        }
                    });
                }
            }
        });

        $scope.$on('location:select:clear', function(event, index) {
            delete vm.data._links.location;
            vm.locationNotesConfirmed[index] = false;
        });

        function copyFromActionDetails() {
            vm.data.summary = vm.fromAction.summary;
            vm.data.description = vm.fromAction.description;
        }

        function includeFilesFromAction() {
            if (actionFilesCollection && actionFilesCollection.count > 0) {
                angular.forEach(actionFilesCollection.files, function(file) {
                    const isFileExpired = file.expiresAt !== null && new Date(file.expiresAt) < new Date();
                    if (!vm.actionFiles.some(element => element.id === file.id) && !isFileExpired) {
                        const resource = file._links.resource;
                        if (resource) {
                            vm.actionFiles.push({
                                id: file.id,
                                submitted: false,
                                title: resource.title,
                                type: resource.type,
                                href: resource.href
                            });
                            vm.showActionFiles = true;
                            vm.disableActionFilesButton = true;
                        }
                    }
                });
            }
        }

        function removeActionFile(file) {
            vm.actionFiles = vm.actionFiles.filter(element => element.id !== file.id);
            vm.disableActionFilesButton = false;
        }

        function submitAction() {
            const action = () => {
                if (vm.fromAction && vm.certificateRequiredDocType && vm.fromAction.hasOwnProperty('meridianDocType')) {
                    let $formElement = jQuery('.required-document-type');

                    if (!vm.selectedRequiredDocumentTypes.length) {
                        $formElement.addClass('has-error')
                        return;
                    } else {
                        $formElement.removeClass('has-error')
                    }
                }

                vm.data.requiredDocumentTypes.links = [];
                angular.forEach(vm.selectedRequiredDocumentTypes, function(value) {
                    vm.data.requiredDocumentTypes.links.push({
                        href: value._links.self.href
                    });
                });

                vm.data.requiredDocumentTypes.links = [];
                angular.forEach(vm.selectedRequiredDocumentTypes, function(value) {
                    vm.data.requiredDocumentTypes.links.push({
                        href: value._links.self.href
                    });
                });

                if (vm.data._links.hasOwnProperty('statutorytype') && vm.data._links.statutorytype.href === null) {
                    delete vm.data._links.statutorytype;
                }

                angular.forEach(vm.selectedJobRequisites, function(jobRequisite) {
                    vm.data.optedInJobRequisites.links.push({
                        href: jobRequisite._links.self.href
                    });
                });

                if ($stateParams.fromJob !== null) {
                    vm.data._links.fromJob = {
                        href:$stateParams.fromJob.decode()
                    };
                }

                if ($stateParams.isRemedial !== null) {
                    vm.data.isRemedial = $stateParams.isRemedial;
                }

                if ($stateParams.sourceJob !== null) {
                    vm.data._links.sourceJob = { href: $stateParams.sourceJob.decode() };
                }

                if (vm.data.assets) {
                    if (vm.data.assets.asset) {
                        vm.data._links.asset = vm.data.assets.asset;
                    }

                    if (vm.data.assets.subasset) {
                        vm.data._links.subasset = vm.data.assets.subasset;
                    }
                }

                vm.data.noRequisiteRequired = vm.noRequisiteRequired;

                if (vm.data.reporter !== undefined && vm.data.reporter.id !== undefined) {
                    delete vm.data.reporter.id;
                }

                if (vm.data.siteContact !== undefined && vm.data.siteContact !== null && vm.data.siteContact.id !== undefined) {
                    delete vm.data.siteContact.id;
                }

                if (vm.data.reporterTenant !== undefined && vm.data.reporterTenant !== null && vm.data.reporterTenant.id !== undefined) {
                    delete vm.data.reporterTenant.id;
                }

                if (vm.data.contactTenant !== undefined && vm.data.contactTenant !== null && vm.data.contactTenant.id !== undefined) {
                    delete vm.data.contactTenant.id;
                }

                if (vm.actionFiles.length > 0) {
                    angular.forEach(vm.actionFiles, function(file) {
                        if (!file.submitted) {
                            file.submitted = true;
                            vm.data.files.links.push({
                                title: file.title,
                                type: file.type,
                                href: file.href
                            });
                        }
                    })
                }

                return apiClient.create(vm.config.link, vm.data).then(function(response) {
                    if (response) {
                        var reloadRoute = $state.current.name.substring(0, $state.current.name.lastIndexOf('.list')),
                            successRoute = '',
                            params = {};

                        if ($stateParams.isRemedial) {
                            if ($state.current.name.indexOf('dashboard.user.sites.list') > -1) {
                                successRoute = 'dashboard.user.sites.list.details.jobs.list.details.remedials';
                                params.resource = siteResponse.getLink('self').encode();
                                params.jobResource = $stateParams.sourceJob;
                            } else {
                                successRoute = 'dashboard.user.jobs.list.details.remedials';
                                params.resource = $stateParams.sourceJob;
                            }
                        } else {
                            successRoute = $state.current.name.indexOf('.sites') > -1
                                ? 'dashboard.user.sites.list.details.jobs.list.details.status'
                                : 'dashboard.user.jobs.details.status'

                            if (successRoute.indexOf('dashboard.user.sites') > -1) {
                                params.resource = siteResponse.getLink('self').encode();
                                params.jobResource = response.getLink('self').encode();
                            } else {
                                params.resource = response.getLink('self').encode();
                            }
                        }

                        $state.go(successRoute, params, {reload: reloadRoute}).then(function() {
                            messenger.success('JOB_CREATED');
                        });
                    }
                });
            };

            if (vm.prosureEnabled && vm.prosureWarningMessage) {
                confirmationModal.open({
                    bodyMessage: vm.prosureWarningMessage,
                    titleMessage: 'ARE_YOU_SURE_HEADER',
                    primaryText: 'CONTINUE',
                    secondaryText: 'CANCEL'
                }).result.then(() => {
                  action();
                });
            } else {
                action();
            }
        }

        function changeStatutoryType() {
            if (vm.selectedStatutoryTypeModel.selected && vm.selectedStatutoryTypeModel.selected.object) {
                vm.data._links.statutorytype = {href: vm.selectedStatutoryTypeModel.selected.href};
                changeRequiredDocumentType();
            } else {
                delete vm.data._links.statutorytype;
                vm.selectedRequiredDocumentTypeModel.selected = null;
            }
        }

        function changeJobGroup() {
            if (vm.selectedJobGroupModel.selected.object) {
                vm.data._links.jobgroup = {href: vm.selectedJobGroupModel.selected.href};
            } else {
                vm.data._links.jobgroup = null;
            }
        }

        function changeServiceProvider() {
            vm.prosureWarningMessage = null;

            if (vm.selectedServiceProviderModel.selected && vm.selectedServiceProviderModel.selected.object) {
                vm.submitDisabled = true;

                if (vm.prosureEnabled) {
                    vm.prosureWarningMessage = serviceProviderService.setProsureWarningMessage(vm.selectedServiceProviderModel.selected);
                }

                apiClient.get(userManager.user.getLink('job-requisites'), {
                    active: 1,
                    optIn: true,
                    optOut: true,
                    'option[]': ['reactiveJobOption'],
                    site: siteResourceResponse.id,
                    serviceProvider: vm.selectedServiceProviderModel.selected.object.getLinkAttribute('serviceprovider', 'id')
                }).then(function(response) {
                    if (!reactiveJobApprovalRequired || !reactivejobapprover) {
                        response.jobRequisites = lodash.filter(response.jobRequisites, function(jobRequisite) {
                            return !lodash.includes([1, 2], jobRequisite.approvalStrategy) && !(jobRequisite.approvalStrategy === 3 && jobRequisite.allowEscalation);
                        });
                    }
                    vm.selectedJobRequisites = [];
                    vm.selectedJobRequisiteModel.response = response;
                    vm.showJobRequisitesSelect = response.jobRequisites.length > 0 && userManager.hasPermission('site_permission_create_jobs');
                    response.jobRequisites.forEach(function (jr) {
                        if (jr.reactiveJobOption === 3) { //opt_out
                            vm.selectedJobRequisites.push(jr);
                            vm.noRequisiteRequired = false;
                        }
                    });
                });

                apiClient.get(vm.selectedServiceProviderModel.selected.href).then(function(response) {
                    if (response) {
                        vm.selectedServiceProvider = response;
                        vm.selectedOperativeModel.loading = true;
                        vm.selectedPriorityModel.loading = true;
                        if (!vm.config.isTenantPlus) {
                            vm.selectedAssetModel.required = vm.selectedServiceProvider.requiresAssetsWhenLoggingReactiveJob
                                && vm.assets.count > 0;
                        }
                        vm.data._links.serviceprovider = {href: vm.selectedServiceProvider.getLink('self')};
                        vm.selectedOperativeModel.selected = null;
                        vm.selectedPriorityModel.selected = null;
                        changePriority();

                        // Update priority options
                        priorityService.setupPriorities(vm.selectedServiceProviderModel.selected.object.getLink('priorities'),
                            vm.selectedServiceProvider.getLink('priorities'),
                            siteResourceResponse.getLink('priorities'), priorityLinkParameters).then(function(response) {
                            vm.selectedPriorityModel.response = priorityService.addExtraPriorities(response);
                        });

                        var requests = {};

                        if (vm.selectedServiceProvider.getLink('joboperatives')) {
                            var params = {
                                permission: 'operative_permission_receive_jobs',
                                includeServiceTypes: true
                            };

                            if (siteResourceResponse) {
                                params.siteId = siteResourceResponse.id;
                            }

                            requests.operatives = apiClient.get(vm.selectedServiceProvider.getLink('joboperatives'), params);
                        }

                        $q.all(requests).then(function (promises) {
                            if (vm.selectedServiceProvider.getLink('joboperatives') === null && ! promises.operatives) {
                                vm.submitDisabled = false;
                                return;
                            }

                            if (promises.operatives && promises.operatives.operatives.length) {
                                vm.operativeCollectionResponse = promises.operatives;
                                vm.selectedOperativeModel.response = promises.operatives;
                                vm.selectedOperativeModel.link = vm.selectedServiceProvider.getLink('joboperatives');
                            } else {
                                vm.selectedOperativeModel.response = null;
                            }

                            vm.submitDisabled = false;
                            vm.selectedOperativeModel.loading = false;
                            vm.selectedPriorityModel.loading = false;
                        });

                        return;
                    }

                    return apiClient.noResourceFound();
                });
            } else {
                vm.selectedServiceProvider = null;
                vm.data._links.serviceprovider = null;
                vm.selectedPriorityModel.selected = null;
                vm.selectedJobRequisites = [];
                changePriority();

                // Update priority options
                priorityService.setupPriorities(null, null, siteResourceResponse.getLink('priorities'), priorityLinkParameters).then(function(response) {
                    vm.selectedPriorityModel.response = priorityService.addExtraPriorities(response);
                });

                vm.selectedAssetModel.response = null;
                vm.selectedAssetModel.link = null;
                vm.selectedAssetModel.selected = null;
                vm.selectedAssetModel.required = false;
            }
        }

        function changeOperative() {
            if (vm.selectedOperativeModel.selected && vm.selectedOperativeModel.selected.object) {
                vm.data._links.operative = {href: vm.selectedOperativeModel.selected.href};
            } else {
                delete vm.data._links.operative;
            }
        }

        function changeAsset() {
            if (vm.selectedAssetModel.selected.object) {
                vm.data._links.asset = {href: vm.selectedAssetModel.selected.href};
            } else {
                vm.data._links.asset = null;
            }
        }

        function changePriority() {
            if (vm.selectedPriorityModel.selected && vm.selectedPriorityModel.selected.object) {
                vm.data._links.priority = {href: vm.selectedPriorityModel.selected.href};
                vm.data.isByPlannedDate = vm.selectedPriorityModel.selected.value === $translate.instant('BY_PLANNED_DATE');
                vm.data.isOnNextPlannedVisit = vm.selectedPriorityModel.selected.value === $translate.instant('JOB_NEXT_PLANNED_VISIT');

                if (!vm.config.isHelpdesk && vm.selectedPriorityModel.selected.object.alertMessageEnabled) {
                    confirmationModal.open({
                        titleMessage: 'PRIORITY_MESSAGE',
                        bodyMessage: vm.selectedPriorityModel.selected.object.alertMessage,
                        primaryText: 'OK',
                        primaryClass: 'btn-primary',
                        secondaryText: ''
                    });
                }

                if (vm.data.isOnNextPlannedVisit || vm.data.isByPlannedDate) {
                    delete vm.data._links.priority;
                }

                if (!vm.data.isByPlannedDate && !vm.data.isOnNextPlannedVisit) {
                    delete vm.data.attendanceDueAt;
                    delete vm.data.completionDueAt;
                }
            } else {
                delete vm.data._links.priority;
                vm.data.isByPlannedDate = vm.data.isOnNextPlannedVisit = false;
            }
        }

        function changeChargeType() {
            if (vm.selectedChargeTypeModel.selected) {
                vm.data._links.chargetype = {href: vm.selectedChargeTypeModel.selected.href};
            } else {
                vm.data._links.chargetype = null;
            }
        }

        function previousAction() {
            $rootScope.skipDirtyCheck = true;
            $state.go(vm.getRoute('contact'), {}, {reload: false}).then(function() {
                $rootScope.skipDirtyCheck = false;
            });
        }

        function unsetStatutory() {
            delete vm.data._links.statutorytype;

            if (vm.certificateRequiredDocType) {
                removeDocumentType(vm.certificateRequiredDocType);
            }
        }

        function changeJobRequisite() {
            if (vm.selectedJobRequisiteModel.selected && vm.selectedJobRequisiteModel.selected.object) {
                var isAlreadySelected = false;
                vm.noRequisiteRequired = false;

                angular.forEach(vm.selectedJobRequisites, function(value, index) {
                    if (value.getLink('self') === vm.selectedJobRequisiteModel.selected.object.getLink('self')) {
                        isAlreadySelected = true;
                    }
                });

                if (!isAlreadySelected) {
                    vm.selectedJobRequisites.push(vm.selectedJobRequisiteModel.selected.object);
                }

                vm.selectedJobRequisiteModel.selected = null;
            }
        }

        function removeJobRequisite(jobRequisite) {
            angular.forEach(vm.selectedJobRequisites, function(value, index) {
                if (value.getLink('self') === jobRequisite.getLink('self')) {
                    vm.selectedJobRequisites.splice(index, 1);
                }
            });
        }

        function changeRequisiteRequiredCheckbox(value) {
            if (value) {
                vm.selectedJobRequisiteModel.disabled = true;
            } else {
                vm.selectedJobRequisiteModel.disabled = false;
            }
        }
    }
})();
