app.config(function ($stateProvider) {

    // Now set up the states
    $stateProvider
        .state('redirect', {
            url: '/redirect?notification&link&jobResource&file&open-close&expiry&token&thumbUp&reporterName&reporterEmail&reporterPhone&unsubscribe',
            templateUrl: 'shared-templates/loading.html',
            controller: 'RedirectController'
        })
});
