(function(){
    'use strict';

    angular
        .module('elogbooks.admin.settings')
        .config(registerRoutes)
        .config(registerGeneralSettingRoutes)
        .config(registerJobSettingRoutes)
        .config(registerQuoteSettingRoutes)
        .config(registerAppSettingRoutes)
        .config(registerPerformanceSettingRoutes)
        .config(registerEmailSettingRoutes)
        .config(registerFileSettingRoutes)
        .config(registerSurveySettingRoutes)
        .config(registerModulePasswordRoutes)
        .config(registerPublicHolidaysRoutes)
        .config(registerEscalationStagesRoutes)
        .config(registerTaskSettingRoutes)
        .config(registerContractsSettingRoutes)
        .config(registerSpillageSettingRoutes)
        .config(registerMeterSettingRoutes)
        .config(registerManageOperativesSettingRoutes)
        .config(registerDashboardsSettingRoutes)
        .config(registerWebhookSettingRoutes)
        .config(registerAssetSettingRoutes)
        .config(registerAbsenceSettingRoutes)
        .config(registerClientAdminSettingRoutes)
        .config(registerServiceProviderFeedbackSettingRoutes)
    ;

    function registerRoutes($stateProvider) {
        $stateProvider
            .state('dashboard.admin.settings', {
                url: '/settings',
                abstract: true,
                templateUrl: '/modules/admin/settings/settings.html',
                resolve: {
                    settingsCollection : function (elbSettings) {
                        return elbSettings.getSettings(true);
                    },
                    siteAssociateTypesCollection: function() {
                        // include empty by default, set in general settings
                        return {};
                    },
                    assetScoreTypesCollection: function () {
                        return {};
                    },
                    elogsUserCollection: function () {
                        return {};
                    },
                    contractManager: function () {
                        return {};
                    },
                    module: function () {
                        return null;
                    },
                    meridianVerifyConfiguration: function () {
                        return null;
                    },
                    meridianConfigurationCollectionResponse: function () {
                        return null;
                    }
                },
                ncyBreadcrumb: {
                    label: '{{ ::"SETTINGS" | translate }}'
                },
                data: {
                    permissions: ['user_permission_list_settings']
                }
            });
    }

    function registerGeneralSettingRoutes($stateProvider) {
        $stateProvider
            .state('dashboard.admin.settings.general-settings', {
                parent: 'dashboard.admin.settings',
                url: '/general',
                resolve: {
                    settingsFields: function () {
                        return [
                            'general_logo',
                            'general_opening_time',
                            'general_closing_time',
                            'general_tasks_generate_ahead',
                            'general_audits_generate_ahead',
                            'general_actions_paperwork_note_required',
                            'general_actionsiteassociatetype',
                            'general_auditcompletionemailassociatetype',
                            'general_helpdesk_number',
                            'general_global_helpdesk_number',
                            'general_helpdesk_email',
                            'general_tenant_emailer_reply_email',
                            'general_training_link',
                            'general_default_locale',
                            'general_non_priority_colour',
                            'general_p2p_started_at_date',
                            'general_contract_manager',
                            'general_inactivate_users_enabled',
                            'general_inactivate_users_days',
                            'general_meridian_workflow'
                        ];
                    },
                    siteAssociateTypesCollection: function (rootResourceResponse) {
                        return rootResourceResponse.getResource('siteassociatetypes');
                    },
                    elogsUserCollection: function (apiClient, rootResourceResponse) {
                        return apiClient.get(rootResourceResponse.getLink('users'), { "category[]": 0, "status": "active" });
                    },
                    contractManager: function (rootResourceResponse, settingsCollection, apiClient) {
                        const contractManagerId = settingsCollection.settings.find(setting => setting.name === 'general_contract_manager').value;

                        if (contractManagerId && typeof contractManagerId === 'number' && contractManagerId > 0) {
                            return apiClient.get(rootResourceResponse.getLink('users') + '/' + contractManagerId, {});
                        }

                        return {};
                    },
                    meridianConfigurationCollectionResponse: function ( rootResourceResponse, apiClient) {
                        if (rootResourceResponse.getLink('meridian-configuration')) {
                            return apiClient.get(rootResourceResponse.getLink('meridian-configuration')).then(function(response) {
                                return response || apiClient.noResourceFound();
                            });
                        }
                    }
                },
                ncyBreadcrumb: {
                    label: '{{ ::"GENERAL_SETTINGS" | translate }}'
                },
                views: {
                    '@dashboard.admin.settings': {
                        templateUrl: '/modules/admin/settings/display-settings/general-settings.html',
                        controller: 'AdminDisplaySettingsController',
                        controllerAs: 'vm'
                    }
                },
                data: {
                    permissions: ['user_permission_list_settings']
                }
            })
            .state('dashboard.admin.settings.general-settings.edit', {
                parent: 'dashboard.admin.settings.general-settings',
                url: '/edit',
                views: {
                    '@dashboard.admin.settings': {
                        templateUrl: '/modules/admin/settings/edit-settings/edit-settings.html',
                        controller: 'AdminEditSettingsController',
                        controllerAs: 'vm'
                    }
                },
                ncyBreadcrumb: {
                    label: '{{ ::"EDIT" | translate }}'
                },
                data: {
                    permissions: ['user_permission_manage_settings']
                }
            });
    }

    function registerJobSettingRoutes($stateProvider) {
        $stateProvider
            .state('dashboard.admin.settings.job-settings', {
                url: '/job',
                parent: 'dashboard.admin.settings',
                abstract: true,
                templateUrl: '/modules/admin/settings/job-settings/job-settings.html',
                ncyBreadcrumb: {
                    label: '{{ ::"JOB" | translate }}'
                },
                data: {
                    permissions: ['user_permission_list_settings']
                }
            })
            .state('dashboard.admin.settings.job-settings.details', {
                parent: 'dashboard.admin.settings.job-settings',
                url: '',
                resolve: {
                    settingsFields : function () {
                        return [
                            'job_acceptance_terms_file',
                            'job_acceptance_terms_text',
                            'job_satisfaction_survey_frequency',
                            'job_group_child_select_mandatory',
                            'allow_secondary_approvers_to_reopen_jobs',
                            'job_reporter_feedback',
                            'job_complete_email_text'
                        ]
                    }
                },
                templateUrl: '/modules/admin/settings/job-settings/details/job-settings-details.html',
                controller: 'AdminDisplaySettingsController',
                controllerAs: 'vm',
                ncyBreadcrumb: {
                    skip: true
                },
                data: {
                    permissions: ['user_permission_list_settings']
                }
            })
            .state('dashboard.admin.settings.job-settings.details.edit', {
                parent: 'dashboard.admin.settings.job-settings.details',
                url: '/edit',
                views: {
                    '@dashboard.admin.settings': {
                        templateUrl: '/modules/admin/settings/edit-settings/edit-settings.html',
                        controller: 'AdminEditSettingsController',
                        controllerAs: 'vm'
                    }
                },
                ncyBreadcrumb: {
                    skip: true
                },
                data: {
                    permissions: ['user_permission_manage_settings']
                }
            })
            .state('dashboard.admin.settings.job-settings.details.chase-settings', {
                parent: 'dashboard.admin.settings.job-settings',
                url: '/chase-settings',
                resolve: {
                    settingsFields: function (){
                        return ['reactive', 'reactiveStatutory', 'planned', 'plannedStatutory'];
                    },
                    chaseSettingsResponse: function (rootResourceResponse) {
                        return rootResourceResponse.getResource('chasesettings');
                    },
                    siteAssociateTypesResponse: function(rootResourceResponse) {
                        return rootResourceResponse.getResource('siteassociatetypes');
                    }
                },
                templateUrl: '/modules/admin/settings/chase-settings/chase-settings.html',
                controller: 'AdminSettingsDisplayChaseSettingsController',
                controllerAs: 'vm',
                ncyBreadcrumb: {
                    skip: true
                },
                data: {
                    permissions: ['user_permission_list_settings']
                }
            })
            .state('dashboard.admin.settings.job-settings.details.chase-settings.edit', {
                parent: 'dashboard.admin.settings.job-settings.details.chase-settings',
                url: '/edit/{chaseSettingResource}',
                resolve: {
                    chaseSettingResponse: function (apiClient, $stateParams){
                        return apiClient.get($stateParams.chaseSettingResource.decode());
                    }
                },
                views: {
                    '@dashboard.admin.settings': {
                        templateUrl: '/modules/admin/settings/chase-settings/add-edit/chase-settings-add-edit.html',
                        controller: 'AdminSettingsAddEditChaseSettingsController',
                        controllerAs: 'vm'
                    }
                },
                ncyBreadcrumb: {
                    skip: true
                },
                data: {
                    permissions: ['user_permission_manage_settings']
                }
            })
            .state('dashboard.admin.settings.job-settings.details.chase-settings.edit-entity-type', {
                parent: 'dashboard.admin.settings.job-settings.details.chase-settings',
                url: '/{chaseType}/edit-chase-entity-type',
                resolve: {
                    chaseSettingResponse: function(chaseSettingsResponse, $stateParams, lodash) {
                        var settings = lodash.find(chaseSettingsResponse, function(chaseSetting) {
                            if (!chaseSetting.hasOwnProperty('settings')) {
                                return false;
                            }

                            return chaseSetting['settings'].type === parseInt($stateParams.chaseType);
                        }).settings;

                        settings.id = true; // Because of panel entity. Sigh.

                        return settings;
                    }
                },
                views: {
                    '@dashboard.admin.settings': {
                        templateUrl: '/modules/admin/settings/chase-settings/add-edit/chase-entity-type-settings-add-edit.html',
                        controller: 'AdminSettingsAddEditChaseEntityTypeSettingsController',
                        controllerAs: 'vm'
                    }
                },
                ncyBreadcrumb: {
                    skip: true
                },
                data: {
                    permissions: ['user_permission_manage_settings']
                }
            })
            .state('dashboard.admin.settings.job-settings.details.chase-settings.add', {
                parent: 'dashboard.admin.settings.job-settings.details.chase-settings',
                url: '/add/{chaseType}',
                resolve: {
                    chaseSettingResponse: function ($stateParams){
                        return {
                            type : $stateParams.chaseType
                        };
                    }
                },
                views: {
                    '@dashboard.admin.settings': {
                        templateUrl: '/modules/admin/settings/chase-settings/add-edit/chase-settings-add-edit.html',
                        controller: 'AdminSettingsAddEditChaseSettingsController',
                        controllerAs: 'vm'
                    }
                },
                ncyBreadcrumb: {
                    skip: true
                },
                data: {
                    permissions: ['user_permission_manage_settings']
                }
            })
            .state('dashboard.admin.settings.job-settings.details.extra', {
                parent: 'dashboard.admin.settings.job-settings',
                url: '/extra',
                resolve: {
                    jobExtraSettings : function (apiClient, rootResourceResponse) {
                        return apiClient.get(rootResourceResponse.getLink('jobsettings')).then(function (response) {
                            switch (response.jobCompletionDueSlaExtension) {
                                case 0:
                                    response.jobCompletionDueSlaExtension = 'off';
                                break;
                                case -1:
                                    response.jobCompletionDueSlaExtension = 'on';
                                break;
                                default:
                                    response.timeDelay = (response.jobCompletionDueSlaExtension);
                                    response.jobCompletionDueSlaExtension = 'autoApprove';
                            }
                            return response;
                        });
                    }
                },
                templateUrl: '/modules/admin/settings/job-settings/extra/job-extra-details.html',
                controller: 'AdminJobExtraSettingsController',
                controllerAs: 'vm',
                ncyBreadcrumb: {
                    skip: true
                },
                data: {
                    permissions: ['user_permission_list_settings']
                }
            })
            .state('dashboard.admin.settings.job-settings.details.extra.edit', {
                parent: 'dashboard.admin.settings.job-settings.details.extra',
                url: '/edit',
                resolve: {
                    siteAssociateTypesResponse: function(rootResourceResponse) {
                        return rootResourceResponse.getResource('siteassociatetypes');
                    }
                },
                views: {
                    '@dashboard.admin.settings': {
                        templateUrl: '/modules/admin/settings/job-settings/extra/edit/job-extra-edit.html',
                        controller: 'AdminEditJobExtraSettingsController',
                        controllerAs: 'vm',
                    }
                },
                ncyBreadcrumb: {
                    skip: true
                },
                data: {
                    permissions: ['user_permission_manage_settings']
                }
            });
    }

    function registerQuoteSettingRoutes($stateProvider) {
        $stateProvider
            .state('dashboard.admin.settings.quote-settings', {
                url: '/quote',
                parent: 'dashboard.admin.settings',
                abstract: true,
                templateUrl: '/modules/admin/settings/quote-settings/quote-settings.html',
                ncyBreadcrumb: {
                    label: '{{ ::"QUOTE" | translate }}'
                },
                data: {
                    permissions: ['user_permission_list_settings']
                }
            })
            .state('dashboard.admin.settings.quote-settings.details', {
                parent: 'dashboard.admin.settings.quote-settings',
                url: '/',
                resolve: {
                    settingsFields : function () {
                        return [
                            'quote_timeout',
                            'quote_terms_text',
                            'quote_terms_file',
                            'quote_require_labour_and_materials',
                            'budget_quotes'
                        ];
                    }
                },
                templateUrl: '/modules/admin/settings/quote-settings/details/quote-settings-details.html',
                controller: 'AdminDisplaySettingsController',
                controllerAs: 'vm',
                ncyBreadcrumb: {
                    skip: true
                },
                data: {
                    permissions: ['user_permission_list_settings']
                }
            })
            .state('dashboard.admin.settings.quote-settings.details.edit', {
                parent: 'dashboard.admin.settings.quote-settings.details',
                url: '/edit',
                views: {
                    '@dashboard.admin.settings': {
                        templateUrl: '/modules/admin/settings/edit-settings/edit-settings.html',
                        controller: 'AdminEditSettingsController',
                        controllerAs: 'vm'
                    }
                },
                ncyBreadcrumb: {
                    skip: true
                },
                data: {
                    permissions: ['user_permission_manage_settings']
                }
            })
            .state('dashboard.admin.settings.quote-settings.details.chase-settings', {
                parent: 'dashboard.admin.settings.quote-settings',
                data: {
                    permissions: ['user_permission_list_settings']
                },
                url: '/chase-settings',
                resolve: {
                    settingsFields: function (){
                        return ['quote'];
                    },
                    chaseSettingsResponse: function (rootResourceResponse) {
                        return rootResourceResponse.getResource('chasesettings');
                    },
                    siteAssociateTypesResponse: function(rootResourceResponse) {
                        return rootResourceResponse.getResource('siteassociatetypes');
                    }
                },
                templateUrl: '/modules/admin/settings/chase-settings/chase-settings.html',
                controller: 'AdminSettingsDisplayChaseSettingsController',
                controllerAs: 'vm',
                ncyBreadcrumb: {
                    skip: true
                }
            })
            .state('dashboard.admin.settings.quote-settings.details.chase-settings.edit-entity-type', {
                parent: 'dashboard.admin.settings.quote-settings.details.chase-settings',
                data: {
                    permissions: ['user_permission_manage_settings']
                },
                url: '/{chaseType}/edit-chase-entity-type',
                resolve: {
                    chaseSettingResponse: function(chaseSettingsResponse, $stateParams, lodash) {
                        var settings = lodash.find(chaseSettingsResponse, function(chaseSetting) {
                            if (!chaseSetting.hasOwnProperty('settings')) {
                                return false;
                            }

                            return chaseSetting['settings'].type === parseInt($stateParams.chaseType);
                        }).settings;

                        settings.id = true; // Because of panel entity. Sigh.

                        return settings;
                    }
                },
                views: {
                    '@dashboard.admin.settings': {
                        templateUrl: '/modules/admin/settings/chase-settings/add-edit/chase-entity-type-settings-add-edit.html',
                        controller: 'AdminSettingsAddEditChaseEntityTypeSettingsController',
                        controllerAs: 'vm'
                    }
                },
                ncyBreadcrumb: {
                    skip: true
                }
            })

            .state('dashboard.admin.settings.quote-settings.details.chase-settings.edit', {
                parent: 'dashboard.admin.settings.quote-settings.details.chase-settings',
                data: {
                    permissions: ['user_permission_manage_settings']
                },
                url: '/edit/{chaseSettingResource}',
                resolve: {
                    chaseSettingResponse: function (apiClient, $stateParams){
                        return apiClient.get($stateParams.chaseSettingResource.decode());
                    }
                },
                views: {
                    '@dashboard.admin.settings': {
                        templateUrl: '/modules/admin/settings/chase-settings/add-edit/chase-settings-add-edit.html',
                        controller: 'AdminSettingsAddEditChaseSettingsController',
                        controllerAs: 'vm'
                    }
                },
                ncyBreadcrumb: {
                    skip: true
                }
            })
            .state('dashboard.admin.settings.quote-settings.details.chase-settings.add', {
                parent: 'dashboard.admin.settings.quote-settings.details.chase-settings',
                data: {
                    permissions: ['user_permission_manage_settings']
                },
                url: '/add/{chaseType}',
                resolve: {
                    chaseSettingResponse: function ($stateParams){
                        return {
                            type : $stateParams.chaseType
                        };
                    }
                },
                views: {
                    '@dashboard.admin.settings': {
                        templateUrl: '/modules/admin/settings/chase-settings/add-edit/chase-settings-add-edit.html',
                        controller: 'AdminSettingsAddEditChaseSettingsController',
                        controllerAs: 'vm'
                    }
                },
                ncyBreadcrumb: {
                    skip: true
                }
            })
            .state('dashboard.admin.settings.quote-settings.details.extra', {
                parent: 'dashboard.admin.settings.quote-settings',
                data: {
                    permissions: ['user_permission_list_settings']
                },
                url: '/extra',
                resolve: {
                    quoteExtraSettings : function (apiClient, rootResourceResponse, lodash) {
                        return apiClient.get(rootResourceResponse.getLink('settings'), {type: 'Quote'}).then(function (response) {
                            // Filter by 'extra' quote settings
                            var filterBy = [
                                'quote_extension_approval',
                                'quote_reminder_at',
                                'quote_disable_p2p_on_acceptance',
                                'quote_allow_approvers_ignore_thresholds'
                            ];

                            var settings = {
                                count: 0,
                                settings: []
                            };

                            lodash.forEach(response.settings, function(setting) {
                                if(filterBy.indexOf(setting.name) > -1) {
                                    if(setting.name === 'quote_disable_p2p_on_acceptance' || setting.name ===  'quote_allow_approvers_ignore_thresholds') {
                                        setting.type = 'checkbox';
                                        setting.required = false;
                                    } else {
                                        setting.type = 'number';
                                        setting.required = true;
                                    }
                                    settings.settings.push(setting);

                                    settings.count++;
                                }
                            });

                            response.count = settings.count;
                            response.settings = settings.settings;

                            return response;
                        });
                    }
                },
                templateUrl: '/modules/admin/settings/quote-settings/extra/quote-extra-details.html',
                controller: 'AdminQuoteExtraSettingsController',
                controllerAs: 'vm',
                ncyBreadcrumb: {
                    skip: true
                }
            })
            .state('dashboard.admin.settings.quote-settings.details.extra.edit', {
                parent: 'dashboard.admin.settings.quote-settings.details.extra',
                data: {
                    permissions: ['user_permission_manage_settings']
                },
                url: '/edit',
                views: {
                    '@dashboard.admin.settings': {
                        templateUrl: '/modules/admin/settings/quote-settings/extra/edit/quote-extra-edit.html',
                        controller: 'AdminEditQuoteExtraSettingsController',
                        controllerAs: 'vm',
                    }
                },
                ncyBreadcrumb: {
                    skip: true
                }
            });
    }

    function registerAppSettingRoutes($stateProvider) {
        $stateProvider
            .state('dashboard.admin.settings.app-settings', {
                url: '/app',
                resolve: {
                    settingsFields : function () {
                        return [
                            'app_timeout'
                        ]
                    }
                },
                templateUrl: '/modules/admin/settings/display-settings/app-settings.html',
                controller: 'AdminDisplaySettingsController',
                controllerAs: 'vm',
                ncyBreadcrumb: {
                    label: '{{ ::"APP_SETTINGS" | translate }}'
                },
                data: {
                    permissions: ['user_permission_list_settings']
                }
            })
            .state('dashboard.admin.settings.app-settings.edit', {
                parent: 'dashboard.admin.settings.app-settings',
                data: {
                    permissions: ['user_permission_manage_settings']
                },
                url: '/edit',
                views: {
                    '@dashboard.admin.settings': {
                        templateUrl: '/modules/admin/settings/edit-settings/edit-settings.html',
                        controller: 'AdminEditSettingsController',
                        controllerAs: 'vm'
                    }
                },
                ncyBreadcrumb: {
                    label: '{{ ::"EDIT" | translate }}'
                }
            });
    }

    function registerEmailSettingRoutes($stateProvider) {
        $stateProvider
            .state('dashboard.admin.settings.email-settings', {
                url: '/email',
                resolve: {
                    settingsFields : function () {
                        return [
                            'email_html_signature',
                            'email_text_signature',
                            'email_tenant_logo'
                        ];
                    }
                },
                templateUrl: '/modules/admin/settings/display-settings/email-settings.html',
                controller: 'AdminDisplaySettingsController',
                controllerAs: 'vm',
                ncyBreadcrumb: {
                    label: '{{ ::"EMAIL" | translate }}'
                },
                data: {
                    permissions: ['user_permission_list_settings']
                }
            })
            .state('dashboard.admin.settings.email-settings.edit', {
                parent: 'dashboard.admin.settings.email-settings',
                data: {
                    permissions: ['user_permission_manage_settings']
                },
                url: '/edit',
                views: {
                    '@dashboard.admin.settings': {
                        templateUrl: '/modules/admin/settings/edit-settings/edit-settings.html',
                        controller: 'AdminEditSettingsController',
                        controllerAs: 'vm'
                    }
                },
                ncyBreadcrumb: {
                    label: '{{ ::"EDIT" | translate }}'
                }
            });
    }

    function registerFileSettingRoutes($stateProvider) {
        $stateProvider
            .state('dashboard.admin.settings.file-settings', {
                url: '/file',
                resolve: {
                    settingsFields : function () {
                        return [
                            'file_restrict',
                            'file_allowed_types',
                            'file_expiry_notification_time'
                        ];
                    }
                },
                templateUrl: '/modules/admin/settings/display-settings/file-settings.html',
                controller: 'AdminDisplaySettingsController',
                controllerAs: 'vm',
                ncyBreadcrumb: {
                    label: '{{ ::"FILES" | translate }}'
                },
                data: {
                    permissions: ['user_permission_list_settings']
                }
            })
            .state('dashboard.admin.settings.file-settings.edit', {
                parent: 'dashboard.admin.settings.file-settings',
                data: {
                    permissions: ['user_permission_manage_settings']
                },
                url: '/edit',
                views: {
                    '@dashboard.admin.settings': {
                        templateUrl: '/modules/admin/settings/edit-settings/edit-settings.html',
                        controller: 'AdminEditSettingsController',
                        controllerAs: 'vm'
                    }
                },
                ncyBreadcrumb: {
                    label: '{{ ::"EDIT" | translate }}'
                }
            });
    }

    function registerSurveySettingRoutes($stateProvider) {
        $stateProvider
            .state('dashboard.admin.settings.survey-settings', {
                url: '/survey',
                resolve: {
                    settingsFields : function () {
                        return [
                            'fm_survey_submission_type',
                            'fm_submit_kpi_by_date',
                            'survey_timeout',
                            'survey_automated_stats_enabled',
                            'sp_auto_submit_kpi',
                            'sp_submit_kpi_by_date'
                        ];
                    }
                },
                templateUrl: '/modules/admin/settings/display-settings/survey-settings.html',
                controller: 'AdminDisplaySettingsController',
                controllerAs: 'vm',
                ncyBreadcrumb: {
                     label: '{{ ::"SURVEYS" | translate }}'
                },
                data: {
                    permissions: ['user_permission_list_settings']
                }
            })
            .state('dashboard.admin.settings.survey-settings.edit', {
                parent: 'dashboard.admin.settings.survey-settings',
                data: {
                    permissions: ['user_permission_manage_settings']
                },
                url: '/edit',
                views: {
                    '@dashboard.admin.settings': {
                        templateUrl: '/modules/admin/settings/edit-settings/edit-settings.html',
                        controller: 'AdminEditSettingsController',
                        controllerAs: 'vm'
                    }
                },
                ncyBreadcrumb: {
                    label: '{{ ::"EDIT" | translate }}'
                }
            });
    }

    function registerPerformanceSettingRoutes($stateProvider) {
        $stateProvider
            .state('dashboard.admin.settings.performance-settings', {
                url: '/performance',
                resolve: {
                    settingsFields : function () {
                        return [
                            'performance_system_score_weight',
                            'performance_quote_score_weight',
                            'performance_include_survey_score',
                            'performance_survey_score_weight',
                            'performance_include_patrol_score',
                            'performance_patrol_score_weight',
                        ]
                    }
                },
                templateUrl: '/modules/admin/settings/display-settings/performance-settings.html',
                controller: 'AdminDisplaySettingsController',
                controllerAs: 'vm',
                ncyBreadcrumb: {
                    label: '{{ ::"PERFORMANCE_REPORTING_SETTINGS" | translate }}'
                },
                data: {
                    permissions: ['user_permission_list_settings']
                }
            })
            .state('dashboard.admin.settings.performance-settings.edit', {
                parent: 'dashboard.admin.settings.performance-settings',
                data: {
                    permissions: ['user_permission_manage_settings']
                },
                url: '/edit',
                views: {
                    '@dashboard.admin.settings': {
                        templateUrl: '/modules/admin/settings/edit-settings/edit-settings.html',
                        controller: 'AdminEditSettingsController',
                        controllerAs: 'vm'
                    }
                },
                ncyBreadcrumb: {
                    label: '{{ ::"EDIT" | translate }}'
                }
            });
    }

    function registerModulePasswordRoutes($stateProvider) {
        $stateProvider
            .state('dashboard.admin.settings.password-settings', {
                url: '/password',
                resolve: {
                    settingsFields : function () {
                        return [
                            'password_days_till_change_notification',
                            'password_disallow_previous_passwords',
                            'password_force_change',
                            'password_min_characters',
                            'password_require_number',
                            'password_require_punctuation',
                            'password_require_uppercase'
                        ]
                    }
                },
                templateUrl: '/modules/admin/settings/display-settings/password-settings.html',
                controller: 'AdminDisplaySettingsController',
                controllerAs: 'vm',
                ncyBreadcrumb: {
                    label: '{{ ::"PASSWORD" | translate }}'
                },
                data: {
                    permissions: ['user_permission_list_settings']
                }
            })
            .state('dashboard.admin.settings.password-settings.edit', {
                parent: 'dashboard.admin.settings.password-settings',
                data: {
                    permissions: ['user_permission_manage_settings']
                },
                url: '/edit',
                views: {
                    '@dashboard.admin.settings': {
                        templateUrl: '/modules/admin/settings/edit-settings/password-settings.html',
                        controller: 'AdminEditSettingsController',
                        controllerAs: 'vm'
                    }
                },
                ncyBreadcrumb: {
                    label: '{{ ::"EDIT" | translate }}'
                }
            });
    }

    function registerPublicHolidaysRoutes($stateProvider) {
        $stateProvider
            .state('dashboard.admin.settings.public-holidays', {
                url: '/public-holidays?date&id&name&order&page&limit',
                resolve: {
                    publicHolidaysCollectionResponse: function(apiClient, rootResourceResponse, $stateParams) {
                        return apiClient.get(rootResourceResponse.getLink('publicholidays'), $stateParams).then(function(response) {
                            return response;
                        });
                    }
                },
                views: {
                    '@dashboard.admin.settings': {
                        templateUrl: '/modules/admin/settings/public-holidays/public-holidays.html',
                        controller: 'AdminPublicHolidaysController',
                        controllerAs: 'vm'
                    }
                },
                params: {
                    'id':null,
                    'name':null,
                    'date':null,
                    'order':'date',
                    'page': '1',
                    'limit': '30'
                },
                ncyBreadcrumb: {
                    label: '{{ ::"PUBLIC_HOLIDAYS" | translate }}'
                },
                data: {
                    permissions: ['user_permission_list_settings']
                }
            })
            .state('dashboard.admin.settings.public-holidays.add', {
                url: '/add',
                parent: 'dashboard.admin.settings.public-holidays',
                data: {
                    permissions: ['user_permission_manage_settings']
                },
                views: {
                    '@dashboard.admin.settings': {
                        templateUrl: '/modules/admin/settings/public-holidays/add-edit/public-holiday-form.html',
                        controller: 'AdminPublicHolidayAddEditController',
                        controllerAs: 'vm'
                    }
                },
                resolve: {
                    publicHolidayResponse: function()
                    {
                        return {};
                    }
                },
                ncyBreadcrumb: {
                    skip: true
                }
            })
            .state('dashboard.admin.settings.public-holidays.edit', {
                url: '/edit/{resource}',
                parent: 'dashboard.admin.settings.public-holidays',
                data: {
                    permissions: ['user_permission_manage_settings']
                },
                views: {
                    '@dashboard.admin.settings': {
                        templateUrl: '/modules/admin/settings/public-holidays/add-edit/public-holiday-form.html',
                        controller: 'AdminPublicHolidayAddEditController',
                        controllerAs: 'vm'
                    }
                },
                resolve: {
                    publicHolidayResponse: function(apiClient, $stateParams) {
                        return apiClient.get($stateParams.resource.decode()).then(function(response) {
                            return response;
                        });
                    }
                },
                ncyBreadcrumb: {
                    skip: true
                }
            })
    }

    function registerEscalationStagesRoutes($stateProvider) {
        $stateProvider
            .state('dashboard.admin.settings.escalation-stages', {
                url: '/escalation-stages?page&limit&orderstage&name',
                resolve: {
                    escalationStagesCollectionResponse: function(apiClient, rootResourceResponse, $stateParams) {
                        return apiClient.get(rootResourceResponse.getLink('escalationstages'), $stateParams).then(function(response) {
                            return response || apiClient.noResourceFound();
                        });
                    }
                },
                views: {
                    '@dashboard.admin.settings': {
                        templateUrl: '/modules/admin/settings/escalation-stages/escalation-stages.html',
                        controller: 'AdminEscalationStagesController',
                        controllerAs: 'vm'
                    }
                },
                params: {
                    page: '1',
                    limit: '30',
                    order: 'stage',

                    stage: null,
                    name: null
                },
                ncyBreadcrumb: {
                    label: '{{ ::"ESCALATION_STAGES" | translate }}'
                },
                data: {
                    permissions: ['user_permission_list_settings']
                }
            })
            .state('dashboard.admin.settings.escalation-stages.add', {
                url: '/add',
                parent: 'dashboard.admin.settings.escalation-stages',
                data: {
                    permissions: ['user_permission_manage_settings']
                },
                views: {
                    '@dashboard.admin.settings': {
                        templateUrl: '/modules/admin/settings/escalation-stages/add-edit/escalation-stage-form.html',
                        controller: 'AdminEscalationStageAddEditController',
                        controllerAs: 'vm'
                    }
                },
                resolve: {
                    escalationStageResponse: function() {
                        return {};
                    },
                    siteAssociateTypesCollectionResponse: function(apiClient, rootResourceResponse) {
                        return apiClient.get(rootResourceResponse.getLink('siteassociatetypes')).then(function(response) {
                            return response || apiClient.noResourceFound();
                        });
                    },
                    userCollectionResponse: function (apiClient, rootResourceResponse) {
                        return apiClient.get(rootResourceResponse.getLink('users')).then(function(response) {
                            return response || apiClient.noResourceFound();
                        });
                    }
                },
                ncyBreadcrumb: {
                    label: '{{ ::"ESCALATION_STAGE_ADD" | translate }}'
                }
            })
            .state('dashboard.admin.settings.escalation-stages.edit', {
                url: '/edit/{resource}',
                parent: 'dashboard.admin.settings.escalation-stages',
                data: {
                    permissions: ['user_permission_manage_settings']
                },
                views: {
                    '@dashboard.admin.settings': {
                        templateUrl: '/modules/admin/settings/escalation-stages/add-edit/escalation-stage-form.html',
                        controller: 'AdminEscalationStageAddEditController',
                        controllerAs: 'vm'
                    }
                },
                resolve: {
                    escalationStageResponse: function(apiClient, $stateParams) {
                        return apiClient.get($stateParams.resource.decode()).then(function(response) {
                            return response || apiClient.noResourceFound();
                        });
                    },
                    siteAssociateTypesCollectionResponse: function(apiClient, rootResourceResponse) {
                        return apiClient.get(rootResourceResponse.getLink('siteassociatetypes')).then(function(response) {
                            return response || apiClient.noResourceFound();
                        });
                    },
                    userCollectionResponse: function (apiClient, rootResourceResponse) {
                        return apiClient.get(rootResourceResponse.getLink('users')).then(function(response) {
                            return response || apiClient.noResourceFound();
                        });
                    }
                },
                ncyBreadcrumb: {
                    label: '{{ ::"ESCALATION_STAGE_EDIT" | translate }}'
                }
            });
    }

    function registerTaskSettingRoutes($stateProvider) {
        $stateProvider
            .state('dashboard.admin.settings.task-settings', {
                url: '/tasks',
                resolve: {
                    settingsFields : function () {
                        return [
                            'task_force_service_routine'
                        ]
                    }
                },
                templateUrl: '/modules/admin/settings/display-settings/task-settings.html',
                controller: 'AdminDisplaySettingsController',
                controllerAs: 'vm',
                ncyBreadcrumb: {
                    label: '{{ ::"TASK_SETTINGS_VIEW" | translate }}'
                },
                data: {
                    permissions: ['user_permission_list_settings']
                }
            })
            .state('dashboard.admin.settings.task-settings.edit', {
                parent: 'dashboard.admin.settings.task-settings',
                data: {
                    permissions: ['user_permission_manage_settings']
                },
                url: '/edit',
                views: {
                    '@dashboard.admin.settings': {
                        templateUrl: '/modules/admin/settings/edit-settings/edit-settings.html',
                        controller: 'AdminEditSettingsController',
                        controllerAs: 'vm'
                    }
                },
                ncyBreadcrumb: {
                    label: '{{ ::"EDIT" | translate }}'
                }
            });
    }

    function registerContractsSettingRoutes($stateProvider) {
        $stateProvider
            .state('dashboard.admin.settings.contract-settings', {
                url: '/contracts',
                parent: 'dashboard.admin.settings',
                abstract: true,
                templateUrl: '/modules/admin/settings/contract-settings/contract-settings.html',
                ncyBreadcrumb: {
                    label: '{{ ::"CONTRACT" | translate }}'
                },
                data: {
                    permissions: ['user_permission_list_settings']
                }
            })
            .state('dashboard.admin.settings.contract-settings.review', {
                parent: 'dashboard.admin.settings.contract-settings',
                data: {
                    permissions: ['user_permission_list_settings']
                },
                url: '/review-settings?page&limit&type',
                resolve: {
                    settingsResponse: function (apiClient, rootResourceResponse, $stateParams) {
                        var params = angular.extend({}, $stateParams, {
                            type: 'review'
                        });

                        return rootResourceResponse.getResource('contractsettings', params);
                    },
                    siteAssociateTypesResponse: function(rootResourceResponse) {
                        return rootResourceResponse.getResource('siteassociatetypes');
                    },
                    config: function (){
                        return {entity: 'REVIEW'};
                    }
                },
                params: {
                    page: '1',
                    limit: '30',
                    type: null
                },
                templateUrl: '/modules/admin/settings/contract-settings/review-expiry-settings.html',
                controller: 'AdminSettingsDisplayReviewExpiryController',
                controllerAs: 'vm',
                ncyBreadcrumb: {
                    skip: true
                }
            })
            .state('dashboard.admin.settings.contract-settings.review.edit', {
                parent: 'dashboard.admin.settings.contract-settings.review',
                data: {
                    permissions: ['user_permission_manage_settings']
                },
                url: '/edit/{settingResource}',
                resolve: {
                    settingsResponse: function (apiClient, $stateParams){
                        return apiClient.get($stateParams.settingResource.decode());
                    }
                },
                views: {
                    '@dashboard.admin.settings': {
                        templateUrl: '/modules/admin/settings/contract-settings/add-edit/review-expiry-settings-add-edit.html',
                        controller: 'AdminSettingsAddEditReviewExpiryController',
                        controllerAs: 'vm'
                    }
                },
                ncyBreadcrumb: {
                    label: '{{ ::"EDIT" | translate }}'
                }
            })
            .state('dashboard.admin.settings.contract-settings.review.add', {
                parent: 'dashboard.admin.settings.contract-settings.review',
                data: {
                    permissions: ['user_permission_manage_settings']
                },
                url: '/add',
                views: {
                    '@dashboard.admin.settings': {
                        templateUrl: '/modules/admin/settings/contract-settings/add-edit/review-expiry-settings-add-edit.html',
                        controller: 'AdminSettingsAddEditReviewExpiryController',
                        controllerAs: 'vm'
                    }
                },
                ncyBreadcrumb: {
                    label: '{{ ::"ADD" | translate }}'
                }
            })
            .state('dashboard.admin.settings.contract-settings.expiry', {
                parent: 'dashboard.admin.settings.contract-settings',
                data: {
                    permissions: ['user_permission_list_settings']
                },
                url: '/expiry-settings',
                resolve: {
                    settingsResponse: function (rootResourceResponse, $stateParams) {
                        var params = angular.extend({}, $stateParams, {
                            type: 'expiry'
                        });

                        return rootResourceResponse.getResource('contractsettings', params);
                    },
                    siteAssociateTypesResponse: function(rootResourceResponse) {
                        return rootResourceResponse.getResource('siteassociatetypes');
                    },
                    config: function (){
                        return {entity: 'EXPIRY'};
                    }
                },
                templateUrl: '/modules/admin/settings/contract-settings/review-expiry-settings.html',
                controller: 'AdminSettingsDisplayReviewExpiryController',
                controllerAs: 'vm',
                ncyBreadcrumb: {
                    skip: true
                }
            })
            .state('dashboard.admin.settings.contract-settings.expiry.edit', {
                parent: 'dashboard.admin.settings.contract-settings.expiry',
                url: '/edit/{settingResource}',
                resolve: {
                    settingsResponse: function (apiClient, $stateParams){
                        return apiClient.get($stateParams.settingResource.decode());
                    }
                },
                views: {
                    '@dashboard.admin.settings': {
                        templateUrl: '/modules/admin/settings/contract-settings/add-edit/review-expiry-settings-add-edit.html',
                        controller: 'AdminSettingsAddEditReviewExpiryController',
                        controllerAs: 'vm'
                    }
                },
                ncyBreadcrumb: {
                    label: '{{ ::"EDIT" | translate }}'
                },
                data: {
                    permissions: ['user_permission_manage_settings']
                }
            })
            .state('dashboard.admin.settings.contract-settings.expiry.add', {
                parent: 'dashboard.admin.settings.contract-settings.expiry',
                data: {
                    permissions: ['user_permission_manage_settings']
                },
                url: '/add',
                views: {
                    '@dashboard.admin.settings': {
                        templateUrl: '/modules/admin/settings/contract-settings/add-edit/review-expiry-settings-add-edit.html',
                        controller: 'AdminSettingsAddEditReviewExpiryController',
                        controllerAs: 'vm'
                    }
                },
                ncyBreadcrumb: {
                    label: '{{ ::"ADD" | translate }}'
                }
            })
            .state('dashboard.admin.settings.contract-settings.chase-settings', {
                parent: 'dashboard.admin.settings.contract-settings',
                url: '/chase-settings',
                resolve: {
                    settingsFields: function () {
                        return ['contractApproval', 'contractExpired'];
                    },
                    chaseExpirySettingsResponse: function (rootResourceResponse, $stateParams) {
                        var params = angular.extend({}, $stateParams, {
                            type: 'chase_expiry'
                        });

                        return rootResourceResponse.getResource('contractsettings', params);
                    },
                    chaseApprovalSettingsResponse: function (rootResourceResponse, $stateParams) {
                        var params = angular.extend({}, $stateParams, {
                            type: 'chase_approval'
                        });

                        return rootResourceResponse.getResource('contractsettings', params);
                    },
                    chaseSettingsResponse: function(chaseExpirySettingsResponse, chaseApprovalSettingsResponse) {
                        return {
                            contractApproval: chaseApprovalSettingsResponse.contractSettings,
                            contractExpired: chaseExpirySettingsResponse.contractSettings,
                            _links: chaseApprovalSettingsResponse._links
                        };
                    },
                    siteAssociateTypesResponse: function(rootResourceResponse) {
                        return rootResourceResponse.getResource('siteassociatetypes');
                    }
                },
                templateUrl: '/modules/admin/settings/contract-settings/chase-settings/chase-settings.html',
                controller: 'AdminSettingsDisplayChaseSettingsController',
                controllerAs: 'vm',
                ncyBreadcrumb: {
                    skip: true
                },
                data: {
                    permissions: ['user_permission_list_settings']
                }
            })
            .state('dashboard.admin.settings.contract-settings.chase-settings.edit', {
                parent: 'dashboard.admin.settings.contract-settings.chase-settings',
                data: {
                    permissions: ['user_permission_manage_settings']
                },
                url: '/edit/{chaseSettingResource}',
                resolve: {
                    chaseSettingResponse: function (apiClient, $stateParams){
                        return apiClient.get($stateParams.chaseSettingResource.decode());
                    }
                },
                views: {
                    '@dashboard.admin.settings': {
                        templateUrl: '/modules/admin/settings/contract-settings/chase-settings/chase-settings-add-edit.html',
                        controller: 'AdminSettingsAddEditChaseSettingsController',
                        controllerAs: 'vm'
                    }
                },
                ncyBreadcrumb: {
                    skip: true
                }
            })
            .state('dashboard.admin.settings.contract-settings.chase-settings.add', {
                parent: 'dashboard.admin.settings.contract-settings.chase-settings',
                data: {
                    permissions: ['user_permission_manage_settings']
                },
                url: '/add/{chaseType}',
                resolve: {
                    chaseSettingResponse: function ($stateParams){
                        return {
                            type : $stateParams.chaseType
                        };
                    }
                },
                views: {
                    '@dashboard.admin.settings': {
                        templateUrl: '/modules/admin/settings/contract-settings/chase-settings/chase-settings-add-edit.html',
                        controller: 'AdminSettingsAddEditChaseSettingsController',
                        controllerAs: 'vm'
                    }
                },
                ncyBreadcrumb: {
                    skip: true
                }
            });
    }

    function registerMeterSettingRoutes($stateProvider) {
        $stateProvider
            .state('dashboard.admin.settings.meter-settings', {
                url: '/meters',
                resolve: {
                    settingsFields : function () {
                        return [
                            'meter_force_stick_to_date',
                            'meter_grace_period_week_before',
                            'meter_grace_period_week_after',
                            'meter_grace_period_month_before',
                            'meter_grace_period_month_after',
                            'meter_grace_period_year_before',
                            'meter_grace_period_year_after',
                            'meter_generate_week_day',
                            'meter_generate_month_day',
                            'meter_generate_year_day',
                            'meter_generate_year_month'
                        ];
                    }
                },
                templateUrl: '/modules/admin/settings/display-settings/meter-settings.html',
                controller: 'AdminDisplaySettingsController',
                controllerAs: 'vm',
                ncyBreadcrumb: {
                    label: '{{ ::"METER_SETTINGS_VIEW" | translate }}'
                },
                data: {
                    permissions: ['user_permission_list_settings']
                }
            })
            .state('dashboard.admin.settings.meter-settings.edit', {
                parent: 'dashboard.admin.settings.meter-settings',
                data: {
                    permissions: ['user_permission_manage_settings']
                },
                url: '/edit',
                views: {
                    '@dashboard.admin.settings': {
                        templateUrl: '/modules/admin/settings/edit-settings/edit-settings.html',
                        controller: 'AdminEditSettingsController',
                        controllerAs: 'vm'
                    }
                },
                ncyBreadcrumb: {
                    skip: true
                }
            });
    }

    function registerSpillageSettingRoutes($stateProvider) {
        $stateProvider
            .state('dashboard.admin.settings.spillage-settings', {
                url: '/spillages',
                resolve: {
                    siteAssociateTypesCollection: function(rootResourceResponse) {
                        return rootResourceResponse.getResource('siteassociatetypes');
                    },
                    settingsFields : function () {
                        return [
                            'spillage_open_chase_freq_before',
                            'spillage_open_chase_freq_after',
                            'spillage_accepted_chase_freq_before',
                            'spillage_accepted_chase_freq_after',
                            'spillage_in_progress_chase_freq_before',
                            'spillage_in_progress_chase_freq_after',
                            'spillage_escalation_interval_repeat',
                            'spillage_escalation_interval',
                            'spillage_escalation_siteassociate_type'
                        ];
                    }
                },
                templateUrl: '/modules/admin/settings/display-settings/spillage-settings.html',
                controller: 'AdminDisplaySettingsController',
                controllerAs: 'vm',
                ncyBreadcrumb: {
                    label: '{{ ::"SPILLAGE_SETTINGS_VIEW" | translate }}'
                },
                data: {
                    permissions: ['user_permission_list_settings']
                }
            })
            .state('dashboard.admin.settings.spillage-settings.edit', {
                parent: 'dashboard.admin.settings.spillage-settings',
                data: {
                    permissions: ['user_permission_manage_settings']
                },
                url: '/edit',
                views: {
                    '@dashboard.admin.settings': {
                        templateUrl: '/modules/admin/settings/edit-settings/edit-settings.html',
                        controller: 'AdminEditSettingsController',
                        controllerAs: 'vm'
                    }
                },
                ncyBreadcrumb: {
                    label: '{{ ::"EDIT" | translate }}'
                }
            });
    }

    function registerManageOperativesSettingRoutes($stateProvider) {
        $stateProvider
            .state('dashboard.admin.settings.manage-operatives', {
                url: '/operatives',
                resolve: {
                    permissionCollection: function(apiClient, rootResourceResponse) {
                        return apiClient.get(rootResourceResponse.getLink('permission-setting')).then(function(response) {
                            return response || apiClient.noResourceFound();
                        });
                    }
                },
                views: {
                    '@dashboard.admin.settings': {
                        templateUrl: '/modules/admin/settings/manage-operatives/permissions.html',
                        controller: 'AdminManagePermissionsController',
                        controllerAs: 'vm'
                    }
                },
                ncyBreadcrumb: {
                    label: '{{ ::"MANAGE_OPERATIVES" | translate }}'
                },
                data: {
                    permissions: ['user_permission_manage_settings']
                }
            });
    }

    function registerDashboardsSettingRoutes($stateProvider) {
        $stateProvider.state('dashboard.admin.settings.dashboards', {
            url: '/dashboards',
            resolve: {
                dashboardWidgetsCollection: function(apiClient, rootResourceResponse) {
                    return apiClient.get(rootResourceResponse.getLink('dashboard-widgets-setting')).then(function(response) {
                        return response || apiClient.noResourceFound();
                    });
                }
            },
            views: {
                '@dashboard.admin.settings': {
                    templateUrl: '/modules/admin/settings/dashboards/dashboards.html',
                    controller: 'AdminManageDashboardsController',
                    controllerAs: 'vm'
                }
            },
            ncyBreadcrumb: {
                label: '{{ ::"DASHBOARD" | translate }}'
            },
            data: {
                permissions: ['user_permission_list_settings']
            }
        });
    }

    function registerWebhookSettingRoutes($stateProvider) {
        $stateProvider
            .state('dashboard.admin.settings.webhook-settings', {
                url: '/webhook',
                resolve: {
                    settingsFields : function () {
                        return [
                            'webhook_job',
                            'webhook_quote'
                        ]
                    }
                },
                templateUrl: '/modules/admin/settings/display-settings/webhook-settings.html',
                controller: 'AdminDisplaySettingsController',
                controllerAs: 'vm',
                ncyBreadcrumb: {
                    label: '{{ ::"WEBHOOK_SETTINGS" | translate }}'
                },
                data: {
                    permissions: ['user_permission_list_settings']
                }
            })
            .state('dashboard.admin.settings.webhook-settings.edit', {
                parent: 'dashboard.admin.settings.webhook-settings',
                data: {
                    permissions: ['user_permission_manage_settings']
                },
                url: '/edit',
                views: {
                    '@dashboard.admin.settings': {
                        templateUrl: '/modules/admin/settings/edit-settings/edit-settings.html',
                        controller: 'AdminEditSettingsController',
                        controllerAs: 'vm'
                    }
                },
                ncyBreadcrumb: {
                    label: '{{ ::"EDIT" | translate }}'
                }
            });
    }

    function registerAssetSettingRoutes($stateProvider) {
        $stateProvider
            .state('dashboard.admin.settings.asset-settings', {
                url: '/asset',
                resolve: {
                    settingsFields: function () {
                        return [
                            'default_asset_score_types'
                        ]
                    },
                    assetScoreTypesCollection: function(apiClient, rootResourceResponse) {
                        return apiClient.get(rootResourceResponse.getLink('assetscoretypes'), {active:1}).then(function(response) {
                            return response || apiClient.noResourceFound();
                        });
                    }
                },
                templateUrl: '/modules/admin/settings/assets/asset-settings.html',
                controller: 'AdminDisplaySettingsController',
                controllerAs: 'vm',
                ncyBreadcrumb: {
                    label: '{{ ::"ASSET_SETTINGS" | translate }}'
                },
                data: {
                    permissions: ['user_permission_list_settings']
                }
            })
            .state('dashboard.admin.settings.asset-settings.edit', {
                parent: 'dashboard.admin.settings.asset-settings',
                url: '/edit',
                views: {
                    '@dashboard.admin.settings': {
                        templateUrl: '/modules/admin/settings/assets/asset-edit.html',
                        controller: 'AdminAssetEditController',
                        controllerAs: 'vm'
                    }
                },
                ncyBreadcrumb: {
                    label: '{{ ::"EDIT" | translate }}'
                },
                data: {
                    permissions: ['user_permission_manage_settings']
                }
            });
    }

    function registerAbsenceSettingRoutes($stateProvider) {
        $stateProvider
            .state('dashboard.admin.settings.absence-settings', {
                url: '/absence',
                resolve: {
                    settingsFields : function () {
                        return [
                            'default_yearly_entitlement',
                            'default_holiday_year_start_day',
                            'default_holiday_year_start_month',
                        ];
                    },
                    module: function () {
                        return 'absence_planner_enabled';
                    }
                },
                templateUrl: '/modules/admin/settings/display-settings/absence-settings.html',
                controller: 'AdminDisplaySettingsController',
                controllerAs: 'vm',
                ncyBreadcrumb: {
                    label: '{{ ::"ABSENCE_SETTINGS" | translate }}'
                },
                data: {
                    permissions: ['user_permission_list_settings']
                }
            })
            .state('dashboard.admin.settings.absence-settings.edit', {
                parent: 'dashboard.admin.settings.absence-settings',
                url: '/edit',
                views: {
                    '@dashboard.admin.settings': {
                        templateUrl: '/modules/admin/settings/edit-settings/edit-settings.html',
                        controller: 'AdminEditSettingsController',
                        controllerAs: 'vm'
                    }
                },
                ncyBreadcrumb: {
                    label: '{{ ::"EDIT" | translate }}'
                },
                data: {
                    permissions: ['user_permission_manage_settings']
                }
            });
    }

    function registerServiceProviderFeedbackSettingRoutes($stateProvider) {
        $stateProvider
            .state('dashboard.admin.settings.sp-feedback-settings', {
                parent: 'dashboard.admin.settings',
                url: '/sp-feedback',
                views: {
                    '@dashboard.admin.settings': {
                        templateUrl: '/modules/admin/settings/display-settings/sp-feedback-settings.html',
                        controller: 'AdminDisplaySettingsController',
                        controllerAs: 'vm'
                    },
                    'feed@dashboard.admin.settings': {
                        templateUrl: '/modules/admin/settings/events/event-feed.html',
                        controller: 'FeedController',
                        controllerAs: 'vm'
                    }
                },
                resolve: {
                    settingsFields : function () {
                        return [
                            'sp_feedback_active',
                            'sp_feedback_modal_title',
                            'sp_feedback_modal_body',
                            'sp_feedback_display_job_statistics',
                            'sp_feedback_allow_comments',
                            'sp_feedback_allowed_popups_per_week'
                        ]
                    },
                    eventsCollection: function (apiClient, settingsCollection) {
                        return apiClient.get(settingsCollection.getLink('events'), {type: 'service_provider_feedback_setting_updated'}).then(function(response) {
                            return response || apiClient.noResourceFound();
                        });
                    },
                    entityResponse: function (settingsCollection) {
                        return settingsCollection;
                    },
                    module: function () {
                        return 'sp_feedback_enabled';
                    }
                },
                ncyBreadcrumb: {
                    label: '{{ ::"SP_FEEDBACK_SETTINGS_VIEW" | translate }}'
                },
                data: {
                    permissions: ['user_permission_list_settings']
                }

            }).state('dashboard.admin.settings.sp-feedback-settings.edit', {
            parent: 'dashboard.admin.settings.sp-feedback-settings',
            url: '/edit',
            views: {
                '@dashboard.admin.settings': {
                    templateUrl: '/modules/admin/settings/sp-feedback/edit/sp-feedback-edit.html',
                    controller: 'AdminServiceProviderFeedbackEditController',
                    controllerAs: 'vm'
                }
            },
            ncyBreadcrumb: {
                label: '{{ ::"EDIT" | translate }}'
            },
            data: {
                permissions: ['user_permission_manage_settings']
            }
        });
    }

    function registerClientAdminSettingRoutes($stateProvider) {
        $stateProvider.state('dashboard.admin.settings.client-admin', {
            url: '/client-admin-domains',
            resolve: {
                settingsFields: function () {
                    return [
                        'client_admin_domains'
                    ];
                },
                module: function () {
                    return 'client_admin_user_enabled';
                }
            },
            templateUrl: '/modules/admin/settings/client-admin/client-admin.html',
            controller: 'AdminDisplaySettingsController',
            controllerAs: 'vm',
            ncyBreadcrumb: {
                label: '{{ ::"CLIENT_ADMIN_SETTINGS" | translate }}'
            },
            data: {
                permissions: ['user_permission_list_settings']
            }
        }).state('dashboard.admin.settings.client-admin.edit', {
            parent: 'dashboard.admin.settings.client-admin',
            url: '/edit',
            views: {
                '@dashboard.admin.settings': {
                    templateUrl: '/modules/admin/settings/edit-settings/edit-settings.html',
                    controller: 'AdminEditSettingsController',
                    controllerAs: 'vm'
                }
            },
            ncyBreadcrumb: {
                label: '{{ ::"EDIT" | translate }}'
            },
            data: {
                permissions: ['user_permission_manage_settings']
            }
        });
    }
})();
