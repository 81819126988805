app.run(function($rootScope, $state, messenger, $window, $timeout, $translate, userManager, $uibModalStack, $location, lodash, analyticsService) {

    $rootScope.previousState;
    $rootScope.previousStateSref;
    $rootScope.currentState;
    $rootScope.loadStartTime;
    $rootScope.analyticsService = analyticsService;

    if(analyticsService.isAllowed()) {
        $rootScope.$on('analytics.pageLoaded', function(event, args) {
            $rootScope.analyticsService.trackEvent({
                pageLoadSpeed: args.time - $rootScope.loadStartTime
            });
        });
    }

    $rootScope.$on('$stateChangeSuccess', function(ev, to, toParams, from, fromParams) {
        $rootScope.previousState = from.name;
        $rootScope.previousStateParams = fromParams;
        $rootScope.previousStateSref = from.name + "(" + JSON.stringify(fromParams) + ")";
        $rootScope.previousStateHref = from.ncyBreadcrumbLink;
        $rootScope.currentState = to.name;
        $rootScope.skipDirtyCheck = false;

        messenger.clearAll();

        if ($rootScope.scrollToTop === true || ($rootScope.scrollToTop === null && to.scroll !== false)) {
            $window.scroll(0, 0);
        }

        $rootScope.scrollToTop = null;

        if(analyticsService.isAllowed()) {
            $timeout(function() {
                if ($rootScope.hasOwnProperty('analyticsService')) {
                    $rootScope.analyticsService.trackEvent();
                }
            }, 1000);
        }
    });

    $rootScope.$on('$stateChangeError', function(event, toState, toParams, fromState, fromParams, error) {
        if(error === 'NOT_AUTHENTICATED') {
            event.preventDefault();
            $state.go('login', { destination: $location.url().encode() });
        }
    });

    var onBeforeUnload = function(event, toState, toStateParams) {

        var dirtyForm = userManager.checkIsDirty();
        var submittedForm = userManager.checkIsFormSubmitted();

        //Trigger notification if dirty form detected and not a submission.
        if(dirtyForm && !submittedForm) {
            var answer = confirm($translate.instant('NAVIGATION_CONFIRMATION'));
            if(!answer) {
                event.preventDefault();

                $timeout(function() {
                    $rootScope.$broadcast('$stateChangeAborted');
                }, 0);

                return false;
            } else {
                $rootScope.forceDirtyConfirm = false; //If we navigate away from the page we need to reset this.
            }
        } else {
            $rootScope.forceDirtyConfirm = false;
        }

        // If the state has a permission variable, check the user has at least one of the specified permissions
        if (typeof toState !== 'undefined' && lodash.has(toState, 'data.permissions')) {
            // If the user is first arriving on the site or reloading the page, the user isn't always loaded yet.
            // This checks the variable and gets the user first if needed.
            // This does mean on first load the user request is sometimes called twice, but not for every state change.
            if (!userManager.user) {
                userManager.getUser().then(function() {
                    checkAccess(event, toState.data.permissions);
                });
            } else {
                checkAccess(event, toState.data.permissions);
            }
        }

        if (
            typeof toState !== 'undefined' &&
            toState.name !== 'dashboard.user.dashboard' &&
            $rootScope.feedbackRequired
        ) {

            event.preventDefault();

            return $state.go('dashboard.user.dashboard', {}, {reload: true});
        }
    };

    /**
     * @param  StateChangeEvent event
     * @param  array permissions
     */
    var checkAccess = function(event, permissions) {
        // Check the user has permission to access this page
        var hasPermission = false;

        angular.forEach(permissions, function (permission) {
            if (!hasPermission && userManager.hasPermission(permission)) {
                hasPermission = true;
            }
        });

        // Cancel the state change if permission is not granted
        if (!hasPermission) {
            event.preventDefault();

            $timeout(function() {
                $rootScope.$broadcast('$stateChangeAborted');
            }, 0);

            return $state.go('dashboard.user.dashboard', {}, {reload: true});
        }
    };

    $rootScope.$on('$stateChangeStart', onBeforeUnload);
    $window.onbeforeunload = onBeforeUnload;

    var checkSessionExists = function(event) {
        if(event.key === 'token' || event.key === 'credentials') {
            if(!event.newValue) {
                $uibModalStack.dismissAll();
                $state.go('login');
            }
        }
    };

    window.addEventListener('storage', checkSessionExists);
});


app.config(function($provide) {
    $provide.decorator('$state', function($delegate, $rootScope) {
        var navigatingTo = null;

        // Rework $state.go to allow a new parameter
        $delegate.baseGo = $delegate.go;

        var go = function (to, params, options, scroll) {
            if (typeof scroll === 'boolean') {
                $rootScope.scrollToTop = scroll;
            }

            if (navigatingTo && navigatingTo.name !== to) {
                $rootScope.$broadcast('$stateChangeAborted', [navigatingTo, params]);
            }

            return this.baseGo(to, params, options);
        };

        $delegate.go = go;

        // Set variables on state change
        $rootScope.$on('$stateChangeStart', function(event, state, params) {
            $delegate.next = state;
            $delegate.toParams = params;
            $rootScope.loadStartTime = $rootScope.analyticsService.getTime();

            navigatingTo = state;
        });

        var clearNavigateTo = function() {
            navigatingTo = null;
        };

        $rootScope.$on('$stateChangeSuccess', clearNavigateTo);
        $rootScope.$on('$stateChangeAborted', clearNavigateTo);
        $rootScope.$on('$stateChangeError', clearNavigateTo);

        return $delegate;
    });
});

