angular.module('elogbooksDirectives').directive('elogbooksSubassetSelect', ['$rootScope', 'messenger', '$q', function ($rootScope, messenger, $q) {
    return {
        restrict: 'AE',
        require: ['ngModel'],
        scope: {
            model: '=ngModel',
            selectedAsset: '=',
            broadcast: '@',
            assets: '=',
            returnObject: '@',
            isRequired: '=',
            advancedCriteriaSearch: '='
        },
        templateUrl: '/modules/directives/form-elements/subasset-select/subasset-select.html',
        controller: function ($scope, apiClient) {
            $scope.loading = false;
            $scope.loadingAsset = true;
            $scope.loadPath = false;

            $scope.model = {
                asset: $scope.model.asset,
                subasset: $scope.model.subasset,
                clear: onClear
            };

            $scope.onSelect = onSelect;
            $scope.onSearch = onSearch;
            $scope.onLoadMore = onLoadMore;
            $scope.onClear = onClear;
            $scope.generateSelectedAssetRoute = generateSelectedAssetRoute;

            generateSelectedAssetRoute();

            if ($scope.asset && typeof $scope.asset.path === 'undefined') {
                apiClient.get($scope.asset.href).then(function (response) {
                    $scope.asset = response;
                    //make asset path directive to wait for value
                    $scope.loadPath = true;
                });
            }

            function onLoadMore($event, index) {
                if ($scope.loading) {
                    return;
                }

                $scope.assetFormFields[index].noMore = false;

                if ($scope.assetFormFields[index].page === $scope.assetFormFields[index].pages) {
                    messenger.error('NO_MORE_RESULTS');
                }

                if ($scope.assetFormFields[index].page < $scope.assetFormFields[index].pages) {
                    $event.stopPropagation();
                    $event.preventDefault();

                    $scope.loading = true;
                    var pageLink = $scope.assetFormFields[index]._links.next || $scope.assetFormFields[index]._links.last;

                    apiClient.get(pageLink.href, this.linkParameters).then(function (response) {
                        $scope.assetFormFields[index].assets = $scope.assetFormFields[index].assets.concat(response[$scope.assetFormFields[index].dataKey]);
                        $scope.assetFormFields[index].response = response;
                        $scope.assetFormFields[index].page = response.page;
                        $scope.assetFormFields[index].pages = response.pages;
                        $scope.assetFormFields[index]._links = response._links;
                        $scope.assetFormFields[index].noMore = $scope.assetFormFields[index].page === $scope.assetFormFields[index].pages;
                        $scope.loading = false;
                    });
                }
            }

            function onSelect($item, $index, $child) {
                if (typeof $scope.model !== "object") {
                    $scope.model = {};
                }

                $scope.assetFormFields.splice($index + 1, $scope.assetFormFields.length);

                if ($item._links.self.href.indexOf('subasset') > -1) {
                    $scope.model.subasset = { href: $item._links.self.href, id: $item.id};
                } else {
                    $scope.model.asset = { href: $item._links.self.href, id: $item.id};
                    $scope.model.subasset = null;
                }

                if ($item._links.self !== undefined) {
                    apiClient.get($item._links.self.href).then(function (response) {
                        if (response.getLink('subassets') && response.hasSubAssets) {
                            apiClient.get(response.getLink('subassets')).then(function (response){
                                if (response.subassets.length > 0) {
                                    $scope.assetFormFields.push(createFormField(response, $child, 'subassets'));
                                }
                                // don't broadcast to the complete controller
                                if ($scope.broadcast !== 'false') {
                                    $rootScope.$broadcast('assetSelected', response);
                                }
                            });

                        } else {
                            if ($scope.broadcast !== 'false') {
                                $rootScope.$broadcast('assetSelected', response);
                            }
                        }
                    });
                }
                if ($scope.raiseEvents) {
                    $scope.$emit('asset:select:click', $scope.model);
                }
            }

            function onSearch(keyword, index) {
                // hide the load-more button whilst searching
                $scope.assetFormFields[index].noMore = true;

                if ($scope.assetFormFields[index].response
                    && $scope.assetFormFields[index].response.getLink('self')
                    && !$scope.loading
                ) {
                    $scope.loading = true;

                    // Async function needs to access object properties from global scope
                    apiClient.get($scope.assetFormFields[index].response.getLink('self'), {name: keyword, page:1}).then(function (response) {
                        $scope.loading = false;
                        $scope.keyword = keyword;
                        $scope.assetFormFields[index].assets = response[$scope.assetFormFields[index].dataKey];
                        $scope.assetFormFields[index].response = response;
                        $scope.assetFormFields[index].page = response.page;
                        $scope.assetFormFields[index].pages = response.pages;
                        $scope.assetFormFields[index].response = response;
                        $scope.assetFormFields[index].noMore = $scope.assetFormFields[index].page === $scope.assetFormFields[index].pages;
                    });
                } else {
                    $scope.loading = false;
                }
            }

            function onClear($index) {
                $scope.assetFormFields.splice($index + 1, $scope.assetFormFields.length);
                $scope.assetFormFields[$index].selected = null;
                if ($scope.assetFormFields[$index - 1]) {
                    $item = $scope.assetFormFields[$index - 1];
                    $scope.model.subasset = undefined;

                    if ($scope.returnObject) {
                        $scope.model.href = $item.selected;
                    } else {
                        $scope.model.href = $item.selected._links.self.href;
                    }

                    if ($scope.model.href) {
                        apiClient.get($scope.model.href).then(function (response) {
                            if (response.getLink('subassets') && response.hasSubAssets) {
                                apiClient.get(response.getLink('subassets')).then(function (response){
                                    if ($scope.broadcast !== 'false') {
                                        $rootScope.$broadcast('assetSelected', response);
                                    }
                                });

                            } else {
                                if ($scope.broadcast !== 'false') {
                                    $rootScope.$broadcast('assetSelected', response);
                                }
                            }
                        });
                    } else {
                        if ($scope.broadcast !== 'false') {
                            $rootScope.$broadcast('assetSelected', null);
                        }
                    }
                } else {
                    if ($scope.raiseEvents) {
                        if ($scope.broadcast !== 'false') {
                            $scope.$emit('asset:select:clear', $index);
                        }
                    }
                    if ($scope.broadcast !== 'false') {
                        $rootScope.$broadcast('assetSelected', null);
                    }

                    if ($scope.advancedCriteriaSearch) {
                        $scope.model.asset = null;
                        $scope.model.subasset = null;
                    } else {
                        delete $scope.model;
                    }
                }
            }

            function createFormField($assets, $asset, $key) {
                if ($asset && typeof $asset.title !== 'undefined') {
                    $asset.name = $asset.title;
                }

                var noMore = true;

                if (typeof $assets !== 'undefined' && $assets.hasOwnProperty($key)) {

                    if ($assets.page !== $assets.pages) {
                        noMore = false;
                    }

                    return {
                        selected: $asset,
                        dataKey: $key,
                        assets: $assets[$key],
                        limit: 30,
                        page: $assets.page,
                        pages: $assets.pages,
                        _links: $assets._links,
                        response: $assets,
                        noMore: noMore
                    };
                }

                return {
                    selected: $asset,
                    assets: $assets,
                    noMore: noMore
                };
            }

            /**
             * Generate the slash delimited route to display above the selected asset
             */
            function generateSelectedAssetRoute() {
                $scope.assetFormFields = [];
                $scope.assetFormFields.push(createFormField($scope.assets, $scope.selectedAsset, 'assets'));

                var requests = [];
                if ($scope.model.asset) {
                    requests.push(apiClient.get($scope.model.asset.href).then(function (response) {
                        $scope.assetFormFields[0].selected = response;
                        $scope.assetFormFields[0].assets = [];
                    }));

                    if ($scope.model.asset.href) {
                        apiClient.get($scope.model.asset.href).then(function (response) {
                            if (response.getLink('subassets')) {
                                apiClient.get(response.getLink('subassets')).then(function (response){
                                    if (response.subassets.length > 0) {
                                        $scope.assetFormFields.push(createFormField(response,  $scope.model.subasset, 'subassets'));
                                        requests.push(apiClient.get($scope.model.subasset.href).then(function (response) {
                                            $scope.assetFormFields[1].selected = response;
                                        }));
                                    }
                                });
                            }
                        });
                    }
                }

                $q.all(requests).then(function() {
                    $scope.loadingAsset = false;
                });
            }
        }
    };
}]);
