(function () {
    'use strict';

    angular
        .module('elogbooksControllers')
        .controller('advancedJobFiltersModalController', advancedJobFiltersModalController);

    advancedJobFiltersModalController.$inject = ['$state', '$stateParams', 'config', 'assetCollectionResponse', 'chargeTypesCollectionResponse',
        'jobGroupCollectionResponse', 'serviceProviderResponse', 'regionCollectionResponse', 'operativeCollectionResponse', 'jobCollectionResponse',
        'approversCollectionResponse', 'creatorsCollectionResponse', 'assetTypeCollectionResponse', 'siteCollection',
        'statutoryTypeCollectionResponse', 'priorityCollectionResponse', 'siteGroupCollection', '$uibModalInstance', '$rootScope', '$scope',
        '$translate', 'lodash', 'moment', 'hierarchyService', 'paramConverterService', 'jobStatuses', 'apiClient', 'rootResource', 'jobRequisiteCollectionResponse',
        'sectorCollectionResponse', 'coreClientsCollectionResponse', 'serviceProviderGroupsResponse', 'filterCriteriaService', 'userManager', 'siteResource', 'elbSettings'];

    function advancedJobFiltersModalController($state, $stateParams, config, assetCollectionResponse, chargeTypesCollectionResponse, jobGroupCollectionResponse,
        serviceProviderResponse, regionCollectionResponse, operativeCollectionResponse, jobCollectionResponse, approversCollectionResponse, creatorsCollectionResponse,
        assetTypeCollectionResponse, siteCollection, statutoryTypeCollectionResponse, priorityCollectionResponse, siteGroupCollection, $uibModalInstance,
        $rootScope, $scope, $translate, lodash, moment, hierarchyService, paramConverterService, jobStatuses, apiClient, rootResource, jobRequisiteCollectionResponse,
        sectorCollectionResponse, coreClientsCollectionResponse, serviceProviderGroupsResponse, filterCriteriaService, userManager, siteResource, elbSettings) {

        var vm = this;
        vm.config = config;
        vm.close = close;
        vm.update = update;
        vm.getJobGroups = getJobGroups;
        vm.getApprovers = getApprovers;
        vm.getRegions = getRegions;
        vm.getServiceProviders = getServiceProviders;
        vm.getPriorities = getPriorities;
        vm.getStatTypes = getStatTypes;
        vm.saveParams = saveParams;
        vm.getPrioritiesBySite = getPrioritiesBySite;
        vm.getDueBy = getDueBy;
        vm.clear = clear;

        vm.p2p = $stateParams.hasOwnProperty('p2p') && ($state.params.p2p === '1' || $state.params.p2pAwaitingPoNumber === '1' || $state.params.p2pAwaitingValue === '1');
        vm.prosureEnabled = elbSettings.getSetting('prosure_enabled').value;

        var selectDisabled = false;

        if (siteCollection) {
            if (siteCollection.selected) {
                selectDisabled = true;
            }
        }

        vm.data = {};
        vm.advancedCriteria = {};

        var remedialOptions = [
            {
                title: $translate.instant('NONE_SELECTED'),
                value: null
            },
            {
                title: $translate.instant('YES'),
                value: 'true'
            },
            {
                title: $translate.instant('NO'),
                value: 'false'
            }
        ];

        var prosureStatusesOptions = [
            {
                title: $translate.instant('PROSURE_REQUIREMENTS_SUCCESS'),
                value: '0'
            },
            {
                title: $translate.instant('PROSURE_REQUIREMENTS_EXPIRING'),
                value: '1'
            },
            {
                title: $translate.instant('PROSURE_REQUIREMENTS_BELOW'),
                value: '2'
            },
            {
                title: $translate.instant('PROSURE_SUSPENDED'),
                value: '3'
            },
            {
                title: $translate.instant('N/A'),
                value: '-1'
            }
        ];

        var overdueOptions = [
            {
                title: $translate.instant('NONE_SELECTED'),
                value: null
            },
            {
                title: $translate.instant('OVERDUE_YES'),
                value: 'true'
            },
            {
                title: $translate.instant('OVERDUE_NO'),
                value: 'false'
            }
        ];

        var typeOptions = [
            {
                title: $translate.instant('NONE_SELECTED'),
                value: null
            },
            {
                title: $translate.instant('JOB_TYPE_REACTIVE'),
                value: 'reactive'
            },
            {
                title: $translate.instant('JOB_TYPE_PLANNED'),
                value: 'planned'
            }
        ];

        var approvalOptions = [
            {
                title: $translate.instant('NONE_SELECTED'),
                value: null
            },
            {
                title: $translate.instant('JOB_PRIMARY_OPERATIVE_APPROVAL'),
                value: "2"
            },
            {
                title: $translate.instant('JOB_APPROVER_APPROVAL'),
                value: "1"
            },
            {
                title: $translate.instant('JOB_PENDING_WITH_ME'),
                value: "0"
            }
        ];

        function getJobGroups() {
            return paramConverterService.formatResponse(hierarchyService.getJobGroupHierarchy(jobGroupCollectionResponse.jobgroups, true));
        }

        function getApprovers() {
            return paramConverterService.formatResponse(approversCollectionResponse.users);
        }

        function getRegions() {
            return paramConverterService.formatResponse(regionCollectionResponse.regions);
        }

        function getServiceProviders() {
            return paramConverterService.formatResponse(serviceProviderResponse.serviceproviders);
        }

        function getAssetTypes() {
            return paramConverterService.formatResponse(hierarchyService.getJobGroupHierarchy(assetTypeCollectionResponse.assettypes));
        }

        function getPriorities() {
            return paramConverterService.formatResponse(priorityCollectionResponse.priorities);
        }

        function getStatTypes() {
            return paramConverterService.formatResponse(statutoryTypeCollectionResponse.statutorytypes);
        }

        vm.advancedCriteria = {
            jobId: { type: 'number', title: 'ID', value: $stateParams.jobId ? parseInt($stateParams.jobId, 10) : null},
            alarmedAtStart: {
                type: 'date',
                title: 'ALARMED_AT_START',
                value: (typeof $stateParams.alarmedAtStart !== 'undefined' && $stateParams.alarmedAtStart !== null) ? moment(new Date($stateParams.alarmedAtStart)) : null
            },
            alarmedAtEnd: {
                type: 'date',
                title: 'ALARMED_AT_END',
                end: true,
                value: (typeof $stateParams.alarmedAtEnd !== 'undefined' && $stateParams.alarmedAtEnd !== null) ? moment(new Date($stateParams.alarmedAtEnd)) : null
            },
            createdAtStart: {
                type: 'date',
                title: 'CREATED_AT_START',
                value: (typeof $stateParams.createdAtStart !== 'undefined' && $stateParams.createdAtStart !== null) ? moment(new Date($stateParams.createdAtStart)) : null
            },
            createdAtEnd: {
                type: 'date',
                title: 'CREATED_AT_END',
                end: true,
                value: (typeof $stateParams.createdAtEnd !== 'undefined' && $stateParams.createdAtEnd !== null) ? moment(new Date($stateParams.createdAtEnd)) : null
            },
            completionDueStart: {
                type: 'date',
                title: 'COMPLETION_DUE_AT_START',
                enableDisableJobsDueBy: true,
                value: (typeof $stateParams.completionDueStart !== 'undefined' && $stateParams.completionDueStart !== null) ? moment(new Date($stateParams.completionDueStart)) : null
            },
            completionDueEnd: {
                type: 'date',
                title: 'COMPLETION_DUE_AT_END',
                end: true,
                enableDisableJobsDueBy: true,
                value: (typeof $stateParams.completionDueEnd !== 'undefined' && $stateParams.completionDueEnd !== null) ? moment(new Date($stateParams.completionDueEnd)) : null
            },
            jobsReminderAtStart: {
                type: 'date',
                title: 'REMINDER_AT_START',
                value: (typeof $stateParams.jobsReminderAtStart !== 'undefined' && $stateParams.jobsReminderAtStart !== null) ? moment(new Date($stateParams.jobsReminderAtStart)) : null
            },
            jobsReminderAtEnd: {
                type: 'date',
                title: 'REMINDER_AT_END',
                end: true,
                value: (typeof $stateParams.jobsReminderAtEnd !== 'undefined' && $stateParams.jobsReminderAtEnd !== null) ? moment(new Date($stateParams.jobsReminderAtEnd)) : null
            },
            jobsAttendanceDueStart: {
                type: 'date',
                title: 'ATTENDANCE_DUE_AT_START',
                enableDisableJobsDueBy: true,
                value: (typeof $stateParams.jobsAttendanceDueStart !== 'undefined' && $stateParams.jobsAttendanceDueStart !== null) ? moment(new Date($stateParams.jobsAttendanceDueStart)) : null
            },
            jobsAttendanceDueEnd: {
                type: 'date',
                title: 'ATTENDANCE_DUE_AT_END',
                end: true,
                enableDisableJobsDueBy: true,
                value: (typeof $stateParams.jobsAttendanceDueEnd !== 'undefined' && $stateParams.jobsAttendanceDueEnd !== null) ? moment(new Date($stateParams.jobsAttendanceDueEnd)) : null
            },
            completedAtStart: {
                type: 'date',
                title: 'COMPLETED_AT_START',
                value: (typeof $stateParams.completedAtStart !== 'undefined' && $stateParams.completedAtStart !== null) ? moment(new Date($stateParams.completedAtStart)) : null
            },
            completedAtEnd: {
                type: 'date',
                title: 'COMPLETED_AT_END',
                end: true,
                value: (typeof $stateParams.completedAtEnd !== 'undefined' && $stateParams.completedAtEnd !== null) ? moment(new Date($stateParams.completedAtEnd)) : null
            },
            jobsOverdueCompletion: {
                type: 'options',
                title: 'OVERDUE_COMPLETION',
                value: $stateParams.jobsOverdueCompletion,
                options: overdueOptions
            },
            jobsOverdueAttendance: {
                type: 'options',
                title: 'OVERDUE_ATTENDANCE',
                value: $stateParams.jobsOverdueAttendance,
                options: overdueOptions
            },
            jobRequisitePendingApproval: {
                type: 'options',
                title: 'JOB_REQUISITE_PENDING_APPROVAL',
                value: $stateParams.jobRequisitePendingApproval,
                options: approvalOptions
            },
            jobSummary: {
                type: 'text',
                title: 'SUMMARY',
                value: $stateParams.jobSummary,
                class: 'full-width'
            },
            description: {
                type: 'text',
                title: 'DESCRIPTION',
                value: $stateParams.description,
                class: 'full-width'
            },
            location: { type: 'number', title: 'LOCATION_ID', value: $stateParams.location ? parseInt($stateParams.location, 10) : null }
        };

        if (jobRequisiteCollectionResponse) {
            var selected = $stateParams.requisite ? {
                title: JSON.parse($stateParams.requisite).n,
                value: JSON.parse($stateParams.requisite).v
            } : null;

            vm.advancedCriteria.requisite = {
                type: 'jsonselectwidget',
                title: 'HAS_JOB_REQUISITE',
                value: $stateParams.requisite,
                options: {
                    mapValue: 'serviceProviderName',
                    onRemove: vm.saveParams,
                    onSelect: vm.saveParams,
                    response: jobRequisiteCollectionResponse,
                    responseKeyPath: 'jobRequisites',
                    itemValuePath: 'id',
                    itemTitlePath: 'name',
                    selected: selected
                }
            };
        }

        if (!vm.p2p) {
            vm.advancedCriteria.jobsType = {
                type: 'options',
                title: 'JOB_TYPE',
                value: $stateParams.jobsType,
                options: typeOptions
            };
            vm.advancedCriteria.jobStatus = {
                type: 'options',
                title: 'STATUS',
                multiple: true,
                value: $stateParams.jobStatus,
                options: jobStatuses.getAllTranslated()
            };
        }

        if (chargeTypesCollectionResponse && chargeTypesCollectionResponse.chargetypes && chargeTypesCollectionResponse.chargetypes.length !== 0) {
            vm.advancedCriteria.chargeType = {
                type: 'data_select',
                title: 'CHARGE_TYPES',
                options: {
                    mapValue: 'chargeType',
                    onRemove: vm.saveParams,
                    onSelect: vm.saveParams,
                    itemValuePath: 'id',
                    itemTitlePath: 'name',
                    response: chargeTypesCollectionResponse,
                    responseKeyPath: 'chargetypes',
                    selected : paramConverterService.getSelectedParam(chargeTypesCollectionResponse.chargetypes, $stateParams.chargeType)
                }
            };
        }

        if (jobGroupCollectionResponse && jobGroupCollectionResponse.jobgroups && jobGroupCollectionResponse.jobgroups.length !== 0) {
            vm.advancedCriteria.jobGroups = {
                type: 'options',
                multiple: true,
                title: 'JOB_GROUP',
                value: paramConverterService.checkStateParams($stateParams.jobGroups),
                options: getJobGroups(),
                nestedList: true
            };
        }

        if (statutoryTypeCollectionResponse) {
            vm.advancedCriteria.statType = { type: 'options', title: 'JOB_STAT_TYPE', multiple: true, value: paramConverterService.checkStateParams($stateParams.statType), options: getStatTypes()}
        }

        if (rootResource.getLink('documenttypes')) {
            vm.documentTypeSelect = {
                link : rootResource.getLink('documenttypes'),
                linkParameters: { status: 'active', order: 'name' },
                responseKeyPath: 'documentTypes',
                onRemove: vm.saveParams,
                onSelect: vm.saveParams,
                mapValue: 'documentTypes',
                autocompletemulti: true,
                advanced: true,
                itemValuePath: 'name',
                itemHrefPath: 'id'
            };

            vm.advancedCriteria.documentTypes = {
                type: 'autocompletemulti',
                multiple: true,
                title: 'DOCUMENT_TYPE',
                options: vm.documentTypeSelect,
                param: 'documentTypes',
                value: $stateParams.documentTypes,
                max: 50
            };
        }

        vm.dueByModel = getDueBy();

        if ($stateParams.hasOwnProperty('jobsDueBy')) {
            vm.advancedCriteria.jobsDueBy = {
                type: 'jobsDueBy',
                title: 'JOBS_DUE_BY',
                value: JSON.parse($stateParams.jobsDueBy),
                options: vm.dueByModel,
                heightAuto: true,
            };
        }

        if (operativeCollectionResponse && operativeCollectionResponse.count > 0) {

            var itemValuePath = '_links.user.id';
            var itemTitlePath = '_links.user.name';

            vm.advancedCriteria.operative = {
                type: 'data_select',
                title: 'OPERATIVE',
                options: {
                    itemHrefPath: '_links.user.href',
                    itemValuePath: itemValuePath,
                    itemTitlePath: itemTitlePath,
                    mapValue: 'operative',
                    onRemove: vm.saveParams,
                    onSelect: vm.saveParams,
                    response: operativeCollectionResponse,
                    responseKeyPath: 'operatives',
                    selected: paramConverterService.getSelectedParam(
                        operativeCollectionResponse.operatives,
                        $stateParams.operative,
                        {
                            'itemValuePath': itemTitlePath,
                            'itemTitlePath': itemTitlePath,
                            'searchObject': {
                                '_links': {
                                    'user': {
                                        'id': parseInt($stateParams.operative)
                                    }
                                }
                            }
                        })
                }
            };
        }

        if (approversCollectionResponse && approversCollectionResponse.users && approversCollectionResponse.users.length !== 0) {
            vm.advancedCriteria.approver = {
                type: 'data_select',
                title: 'APPROVER',
                options: {
                    mapValue: 'approver',
                    onRemove: vm.saveParams,
                    onSelect: vm.saveParams,
                    itemValuePath: 'id',
                    itemTitlePath: 'name',
                    response: approversCollectionResponse,
                    responseKeyPath: 'users',
                    selected: paramConverterService.getSelectedParam(approversCollectionResponse.users, $stateParams.approver)
                }
            };
        }

        if (serviceProviderResponse && serviceProviderResponse.serviceproviders && serviceProviderResponse.serviceproviders.length !== 0) {
            var selected = $stateParams.serviceProvider ? {
                title: JSON.parse($stateParams.serviceProvider).n,
                value: JSON.parse($stateParams.serviceProvider).v
            } : null;

            vm.advancedCriteria.serviceProvider = {
                type: 'jsonselectwidget',
                title: 'SERVICE_PROVIDER',
                value: $stateParams.serviceProvider,
                options: {
                    mapValue: 'serviceProviderName',
                    onRemove: vm.saveParams,
                    onSelect: vm.saveParams,
                    response: serviceProviderResponse,
                    responseKeyPath: 'serviceproviders',
                    itemValuePath: 'id',
                    itemTitlePath: 'name',
                    selected: selected
                }
            };
        }

        if (regionCollectionResponse && regionCollectionResponse.regions && regionCollectionResponse.regions.length !== 0) {
            vm.advancedCriteria.jobSiteRegion = {
                type: 'data_select',
                title: 'REGION',
                isDisabled: selectDisabled,
                options: {
                    mapValue: 'jobSiteRegion',
                    onRemove: vm.saveParams,
                    onSelect: vm.saveParams,
                    itemValuePath: 'id',
                    itemTitlePath: 'name',
                    response: regionCollectionResponse,
                    responseKeyPath: 'regions',
                    selected: paramConverterService.getSelectedParam(regionCollectionResponse.regions, !$stateParams.jobSiteRegion ? $stateParams.siteRegionId : $stateParams.jobSiteRegion)
                }
            };
        }

        if (userManager.user.getLink('sites') && $state.$current.self.name != 'dashboard.user.sites.list.details.jobs.list') {
            vm.siteSelect = {
                link : userManager.user.getLink('sites'),
                responseKeyPath: 'sites',
                itemValuePath: 'name',
                itemHrefPath: 'id',
                onRemove: vm.saveParams,
                onSelect: vm.saveParams,
                mapValue: 'siteIds',
                autocompletemulti: true,
                advanced: true,
                disabled: config.siteLevel || siteResource
            };

            if (siteCollection) {
                vm.siteSelect.cacheItems = lodash.map(siteCollection.sites, function(site) {
                    return {value: site.name, href: site.id, object:site};
                });
            }

            if (siteResource) {
                vm.siteSelect.cacheItems = [{value:siteResource.name, href:siteResource.id, object:siteResource}];
                $stateParams.siteIds = [siteResource.id];
            }

            vm.advancedCriteria.sites = { type: 'autocompletemulti', multiple: true, title: 'SITE', value: $stateParams.siteIds, options: vm.siteSelect, param: 'siteIds', max: 50 };
        }

        if (siteGroupCollection) {
            vm.advancedCriteria.jobSiteGroups = {
                type: 'data_select',
                title: 'SITE_GROUP',
                isDisabled: selectDisabled,
                options: {
                    mapValue: 'jobSiteGroups',
                    onRemove: vm.saveParams,
                    onSelect: vm.saveParams,
                    itemValuePath: 'id',
                    itemTitlePath: 'name',
                    response: siteGroupCollection,
                    responseKeyPath: 'sitegroups',
                    selected: paramConverterService.getSelectedParam(siteGroupCollection.sitegroups, $stateParams.jobSiteGroups)
                }
            };
        }

        if (priorityCollectionResponse) {
            vm.advancedCriteria.jobPriority = {
                type: 'options',
                title: 'JOB_PRIORITY',
                filterTitle: 'SELECT_PRIORITY',
                multiple: true,
                inputName: true,
                valueFilterByName: $stateParams.jobSpecificSpPriority,
                tempValueFilterByName: $stateParams.jobSpecificSpPriority,
                valueToShow: $stateParams.jobSpecificSpPriority,
                value: paramConverterService.checkStateParams($stateParams.jobPriority),
                options: getPriorities()
            };
        }

        if (creatorsCollectionResponse && creatorsCollectionResponse.count > 0) {
            vm.advancedCriteria.createdBy = {
                type: 'data_select',
                title: 'CREATED_BY',
                options: {
                    mapValue: 'createdBy',
                    onRemove: vm.saveParams,
                    onSelect: vm.saveParams,
                    itemValuePath: 'id',
                    itemTitlePath: 'name',
                    response: creatorsCollectionResponse,
                    responseKeyPath: 'users',
                    selected: paramConverterService.getSelectedParam(creatorsCollectionResponse.users, $stateParams.createdBy),
                }
            };
        }

        var advancedCriteria = {}

        apiClient.get(jobCollectionResponse.getLink('reporters'), { datagroup: 'list' }).then(function(response) {
            if (response && response.count > 0) {
                var reporter = lodash.find(response.reporters, { name: $stateParams.reporter });
                var selectedReporter = reporter ? { name: reporter.name, title: reporter.name } : null;

                // To get it in the right place otherwise it causes issues
                for (var key in vm.advancedCriteria) {
                    advancedCriteria[key] = vm.advancedCriteria[key];
                    if (key === 'createdBy') {
                        advancedCriteria.reporter = {
                            type: 'autocomplete',
                            title: 'REPORTER',
                            options: {
                                mapValue: 'reporter',
                                link: response.getLink('self'),
                                onRemove: function() {
                                    params.reporter = null;
                                },
                                onSelect: vm.saveParams,
                                itemValuePath: 'name',
                                itemTitlePath: 'name',
                                response: response,
                                responseKeyPath: 'reporters',
                                selected: selectedReporter,
                                searchKey: 'reporterName',
                                itemHrefPath: 'id',
                                itemIdPath: 'name'
                            }
                        }
                    }
                }
                vm.advancedCriteria = advancedCriteria;
            }
        });

        if (sectorCollectionResponse) {
            vm.advancedCriteria.sectors = {
                type: 'data_select',
                title: 'SECTORS',
                options: {
                    mapValue: 'sectors',
                    onRemove: vm.saveParams,
                    onSelect: vm.saveParams,
                    itemValuePath: 'id',
                    itemTitlePath: 'name',
                    response: sectorCollectionResponse,
                    responseKeyPath: 'sectors',
                    selected: paramConverterService.getSelectedParam(sectorCollectionResponse.sectors, $stateParams.sectors)
                }
            };
        }

        if (coreClientsCollectionResponse.coreclients) {
            vm.advancedCriteria.coreClient = {
                type: 'data_select',
                title: 'CORE_CLIENT',
                options: {
                    link: coreClientsCollectionResponse ? coreClientsCollectionResponse.getLink('self') : null,
                    mapValue: 'coreClient',
                    onRemove: vm.saveParams,
                    onSelect: vm.saveParams,
                    itemValuePath: 'id',
                    itemTitlePath: 'name',
                    response: coreClientsCollectionResponse,
                    responseKeyPath: 'coreclients',
                    selected: paramConverterService.getSelectedParam(coreClientsCollectionResponse.coreclients, $stateParams.coreClient)
                }
            };
        }

        if (serviceProviderGroupsResponse) {
            vm.advancedCriteria.serviceProviderGroup = {
                type: 'data_select',
                title: 'SERVICE_PROVIDER_GROUP',
                value: $stateParams.serviceProviderGroup,
                options: {
                    link: serviceProviderGroupsResponse ? serviceProviderGroupsResponse.getLink('self') : null,
                    mapValue: 'serviceProviderGroup',
                    onRemove: vm.saveParams,
                    onSelect: vm.saveParams,
                    itemValuePath: 'id',
                    itemTitlePath: 'name',
                    response: serviceProviderGroupsResponse,
                    responseKeyPath: 'groups',
                    selected: paramConverterService.getSelectedParam(serviceProviderGroupsResponse.groups, $stateParams.serviceProviderGroup)
                }
            };
        }

        if (assetTypeCollectionResponse && assetTypeCollectionResponse.assettypes && assetTypeCollectionResponse.assettypes.length !== 0) {
            vm.advancedCriteria.assetTypes = {
                type: 'data_select',
                title: 'ASSET_TYPES',
                options: {
                    mapValue: 'assetTypes',
                    onRemove: vm.saveParams,
                    onSelect: vm.saveParams,
                    itemValuePath: 'id',
                    itemTitlePath: 'name',
                    response: assetTypeCollectionResponse,
                    responseKeyPath: 'assettypes',
                    selected : paramConverterService.getSelectedParam(assetTypeCollectionResponse.assettypes, $stateParams.assetTypes)
                }
            };
        }

        if (assetCollectionResponse && assetCollectionResponse.count > 0) {
            vm.assetModel = {
                assets: {
                    asset: $stateParams.asset ? JSON.parse($stateParams.asset) : null,
                    subasset: $stateParams.subAsset ? JSON.parse($stateParams.subAsset) : null,
                    clear: vm.clear
                },
                response: assetCollectionResponse,
            };

            vm.advancedCriteria.asset = {
                type: 'asset',
                title: 'ASSET',
                options: vm.assetModel
            };
        }

        vm.advancedCriteria.jobsRemedial = {
            type: 'options',
            title: 'REMEDIALS',
            value: $stateParams.jobsRemedial,
            options: remedialOptions
        };

        if (vm.prosureEnabled) {
            vm.advancedCriteria.prosureStatusAtApproval = {
                type: 'options',
                multiple: true,
                title: 'PROSURE360_STATUS_AT_APPROVAL',
                value: $stateParams.prosureStatusAtApproval,
                options: prosureStatusesOptions
            };

            vm.advancedCriteria.prosureStatusAtCompletion = {
                type: 'options',
                multiple: true,
                title: 'PROSURE360_STATUS_AT_COMPLETION',
                value: $stateParams.prosureStatusAtCompletion,
                options: prosureStatusesOptions
            };
        }

        var params = {};

        function getPrioritiesBySite(selectedSites) {
            params.sites = selectedSites;
            var priorityLinkParameters = {'active': true, 'entityTypes': 'entity_type_all, entity_type_job', 'sites[]':selectedSites};

            apiClient.get(rootResource.getLink('priorities'), priorityLinkParameters).then(function(response) {
                vm.advancedCriteria.jobPriority = {
                    type: 'options',
                    title: 'JOB_PRIORITY',
                    multiple: true,
                    inputName: true,
                    value: null,
                    options: paramConverterService.formatResponse(response.priorities)
                };
            });
        }

        function getDueBy() {
            var options = {};
            if ($stateParams.jobsDueBy) {
                options = {
                    type: JSON.parse($stateParams.jobsDueBy).t,
                    frequency: JSON.parse($stateParams.jobsDueBy).f,
                    interval: JSON.parse($stateParams.jobsDueBy).i
                };
            }
            options.clear = vm.clear;

            if ($stateParams.completionDueStart !== null ||
                $stateParams.completionDueEnd !== null ||
                $stateParams.jobsAttendanceDueStart !== null ||
                $stateParams.jobsAttendanceDueEnd !== null
            ){
                options.isDisabled = true;
            } else {
                options.isDisabled = false;
            }

            return options;
        }

        function saveParams (selectModel) {
            if (selectModel && selectModel.callback) {
                selectModel.callback(selectModel.selected ? selectModel.selected.object.id : null);
            }

            // mapValue is for data select widget selections
            if (selectModel.mapValue) {
                if (typeof selectModel.autocompletemulti !== 'undefined'
                    && selectModel.autocompletemulti) {
                    params[selectModel.mapValue] = selectModel.selectedItems.map(
                        function (item) {
                            return item.href;
                        }
                    );
                } else {
                    if (selectModel.selected === undefined) {
                        params[selectModel.mapValue] = null;
                    } else {
                        if (selectModel.itemHrefPath === '_links.self.href') {
                            var itemIdPath = selectModel.itemIdPath ? selectModel.itemIdPath : 'id';
                            params[selectModel.mapValue] = selectModel.selected.object[itemIdPath];
                        } else {
                            params[selectModel.mapValue] = lodash.get(selectModel.selected.object, selectModel.itemIdPath);
                        }
                    }
                }
            } else if (selectModel) {
                params = selectModel;
            }

            if (selectModel.response && selectModel.response.count == 1 && selectModel.response.selected == null) {
                var url = selectModel.response.getLink('self').replace(/&?id=[0-9]+/g, '');
                selectModel.link = url;

                apiClient.get(url).then(function(response) {
                    selectModel.response = response;
                });
            }
        }

        function close() {
            if (!$scope.form.$pristine) {
                if (!confirm($translate.instant('NAVIGATION_CONFIRMATION'))) {
                    return;
                }
            }
            $uibModalInstance.dismiss();
        }

        // Validate and update params with dueByModel
        function updateDueBy() {
            if (vm.dueByModel.type !== null && vm.dueByModel.selected !== null &&
                (
                    vm.dueByModel.frequency === null  ||
                    vm.dueByModel.frequency === undefined ||
                    vm.dueByModel.interval === undefined
                )
            ) {
                return false;
            }

            if (vm.dueByModel.selected === null) {
                params.jobsDueBy = null;
            } else {
                params.jobsDueBy = JSON.stringify({
                    t: vm.dueByModel.type,
                    f: vm.dueByModel.frequency,
                    i: vm.dueByModel.interval
                });
            }

            return true;
        }

        function updateAssetSubasset() {
            if (vm.assetModel && vm.assetModel.assets) {
                params.asset = vm.assetModel.assets.asset ? JSON.stringify(vm.assetModel.assets.asset) : null;
                params.subAsset = vm.assetModel.assets.subasset ? JSON.stringify(vm.assetModel.assets.subasset) : null;
            } else {
                params.asset = null;
                params.subAsset = null;
            }
        }

        function clear() {
            var clearValues = {};
            lodash.each($stateParams, function(value, key) {
                if (value) {
                   clearValues[key] = null;
                }
            });
            $state.go('.', angular.extend({}, clearValues), { reload: true });
            $uibModalInstance.dismiss();
        }

        function update() {
            if (Object.keys(params).length > 0) {
                params.jobsPage = '1';
            }

            // Job priority filter has like 2 filters in one, only one can be sent to the request
            if (params.jobPriority && params.jobSpecificSpPriority) {
                delete params.jobPriority;
            }

            // When clear or update clicked update params with dueByModel
            if (vm.dueByModel.type !== undefined) {
                var updateDueByHasError = !(updateDueBy());

                if (updateDueByHasError) {
                    return false;
                }
            }

            updateAssetSubasset();

            $rootScope.skipDirtyCheck = true;

            // Clear save filter if there are changes
            if (!lodash.isEmpty(params) && filterCriteriaService.checkFilterChanges(params)) {
                filterCriteriaService.setSelectedFilter(null);
            }

            $state.go('.', angular.extend({}, params), { reload: true });
            $uibModalInstance.dismiss();
        }
    }
})();
