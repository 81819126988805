(function () {
    'use strict'

    angular
        .module('elogbooks.admin.job-requisites')
        .config(registerRoutes)
        .config(registerRoutesSites)
        .config(registerRoutesPartnerships)
        .config(registerRoutesServiceProviders);

    function registerRoutes($stateProvider) {
        $stateProvider
            .state('dashboard.admin.job-requisites', {
                abstract: true,
                templateUrl: 'shared-templates/view.html',
                ncyBreadcrumb: {
                    skip: true
                },
                data: {
                    permissions: ['user_permission_view_job_requisites', 'user_permission_client_admin_manage_job_requisites']
                }
            })
            .state('dashboard.admin.job-requisites.list', {
                url: '/job-requisites?page&limit&order&id&name&description',
                parent: 'dashboard.admin.job-requisites',
                data: {
                    permissions: ['user_permission_view_job_requisites', 'user_permission_client_admin_manage_job_requisites']
                },
                views: {
                    '@dashboard.admin.job-requisites': {
                        templateUrl: '/modules/admin/job-requisites/job-requisites-list.html',
                        controller: 'JobRequisitesController',
                        controllerAs: 'vm'
                    }
                },
                resolve: {
                    jobRequisiteCollectionResponse: function ($stateParams, apiClient, rootResourceResponse) {
                        return apiClient.get(rootResourceResponse.getLink('jobrequisites'), $stateParams);
                    }
                },
                params: {
                    page: '1',
                    limit: '30',
                    order: 'id',
                    id: null,
                    name: null,
                    active: null,
                    description: null,
                    excludeId: null
                },
                ncyBreadcrumb: {
                    label: '{{ ::"JOB_REQUISITES" | translate }}'
                }
            })
            .state('dashboard.admin.job-requisites.list.add', {
                url: '/add',
                parent: 'dashboard.admin.job-requisites.list',
                data: {
                    permissions: ['user_permission_manage_job_requisites', 'user_permission_client_admin_manage_job_requisites']
                },
                views: {
                    '@dashboard.admin.job-requisites': {
                        templateUrl: '/modules/admin/job-requisites/add-edit/job-requisite-form.html',
                        controller: 'JobRequisitesAddEditController',
                        controllerAs: 'vm'
                    }
                },
                resolve: {
                    jobRequisiteResponse: function () {
                        return {};
                    },
                    formCollectionResponse: function(rootResourceResponse, apiClient) {
                        if (rootResourceResponse.getLink('forms')) {
                            return apiClient.get(rootResourceResponse.getLink('forms'), {type: 'job', active: true, fvStatus: 1}).then(function(response) {
                                return response;
                            });
                        }
                    }
                },
                ncyBreadcrumb: {
                    label: '{{ ::"ADD" | translate }}'
                }
            })
            .state('dashboard.admin.job-requisites.list.details', {
                url: '/details/{resource}',
                abstract: true,
                parent: 'dashboard.admin.job-requisites.list',
                data: {
                    permissions: ['user_permission_view_job_requisites', 'user_permission_client_admin_manage_job_requisites']
                },
                views: {
                    '@dashboard.admin.job-requisites': {
                        templateUrl: '/modules/admin/job-requisites/details/job-requisite-details.html',
                        controller: 'JobRequisitesDetailsController',
                        controllerAs: 'vm'
                    }
                },
                resolve: {
                    jobRequisiteResponse: function (apiClient, $stateParams) {
                        return apiClient.get($stateParams.resource.decode());
                    }
                },
                ncyBreadcrumb: {
                    label: '{{ ::$resolves().jobRequisiteResponse.name }}'
                }
            })
            .state('dashboard.admin.job-requisites.list.details.info', {
                url: '/info',
                parent: 'dashboard.admin.job-requisites.list.details',
                data: {
                    permissions: ['user_permission_view_job_requisites', 'user_permission_client_admin_manage_job_requisites']
                },
                views: {
                    '@dashboard.admin.job-requisites.list.details': {
                        templateUrl: '/modules/admin/job-requisites/details/info/job-requisite-info.html',
                        controller: 'JobRequisitesInfoController',
                        controllerAs: 'vm'
                    }
                },
                resolve: {
                    formCollectionResponse: function(rootResourceResponse, apiClient) {
                        if (rootResourceResponse.getLink('forms')) {
                            return apiClient.get(rootResourceResponse.getLink('forms'), {type: 'job', active: true, fvStatus: 1}).then(function(response) {
                                return response;
                            });
                        }
                    }
                },
                ncyBreadcrumb: {
                    label: '{{ ::"INFO" | translate }}'
                }
            })
            .state('dashboard.admin.job-requisites.list.details.info.edit', {
                url: '/edit',
                parent: 'dashboard.admin.job-requisites.list.details.info',
                data: {
                    permissions: ['user_permission_manage_job_requisites', 'user_permission_client_admin_manage_job_requisites']
                },
                views: {
                    '@dashboard.admin.job-requisites.list.details': {
                        templateUrl: '/modules/admin/job-requisites/add-edit/job-requisite-form.html',
                        controller: 'JobRequisitesAddEditController',
                        controllerAs: 'vm'
                    }
                },
                ncyBreadcrumb: {
                    label: '{{ ::"EDIT" | translate }}'
                }
            });
    }

    function registerRoutesSites($stateProvider) {
        $stateProvider
            .state('dashboard.admin.job-requisites.list.details.sites', {
                url: '/sites?sitePage&siteLimit&siteOrder&siteId&siteName',
                parent: 'dashboard.admin.job-requisites.list.details',
                data: {
                    permissions: ['user_permission_view_job_requisites', 'user_permission_client_admin_manage_job_requisites']
                },
                views: {
                    '@dashboard.admin.job-requisites.list.details': {
                        templateUrl: '/modules/admin/job-requisites/details/sites/job-requisite-sites.html',
                        controller: 'JobRequisiteSitesController',
                        controllerAs: 'vm'
                    }
                },
                resolve: {
                    selectedSiteCollectionResponse: function ($stateParams, apiClient, jobRequisiteResponse) {
                        return apiClient.get(jobRequisiteResponse.getLink('sites'), angular.extend({}, $stateParams, {
                            page: $stateParams.sitePage,
                            limit: $stateParams.siteLimit,
                            order: $stateParams.siteOrder,
                            id: $stateParams.siteId,
                            name: $stateParams.siteName
                        }));
                    }
                },
                params: {
                    sitePage: '1',
                    siteLimit: '30',
                    siteOrder: 'id',
                    siteId: null,
                    siteName: null
                },
                ncyBreadcrumb: {
                    label: '{{ ::"SITES" | translate }}'
                }
            })
            .state('dashboard.admin.job-requisites.list.details.sites.edit', {
                url: '/edit',
                parent: 'dashboard.admin.job-requisites.list.details.sites',
                data: {
                    permissions: ['user_permission_manage_job_requisites', 'user_permission_client_admin_manage_job_requisites']
                },
                views: {
                    '@dashboard.admin.job-requisites.list.details': {
                        templateUrl: '/modules/admin/job-requisites/job-requisites-assign-form.html',
                        controller: 'JobRequisitesSitesEditController',
                        controllerAs: 'vm'
                    }
                },
                resolve: {
                    siteCollectionResponse: function ($stateParams, apiClient, rootResourceResponse) {
                        var params = {
                            status: 'active',
                            limit: 100
                        };

                        return apiClient.get(rootResourceResponse.getLink('sites'), params);
                    }
                },
                ncyBreadcrumb: {
                    label: '{{ ::"EDIT" | translate }}'
                }
            });
    }

    function registerRoutesPartnerships($stateProvider) {
        $stateProvider
            .state('dashboard.admin.job-requisites.list.details.partnerships', {
                url: '/partnerships?partnershipPage&partnershipLimit&partnershipOrder&partnershipSite&partnershipService',
                parent: 'dashboard.admin.job-requisites.list.details',
                data: {
                    permissions: ['user_permission_view_job_requisites', 'user_permission_client_admin_manage_job_requisites']
                },
                views: {
                    '@dashboard.admin.job-requisites.list.details': {
                        templateUrl: '/modules/admin/job-requisites/details/partnerships/job-requisite-partnerships.html',
                        controller: 'JobRequisitesPartnershipsController',
                        controllerAs: 'vm',
                    }
                },
                resolve: {
                    selectedPartnershipCollectionResponse: function ($stateParams, apiClient, jobRequisiteResponse) {
                        return apiClient.get(jobRequisiteResponse.getLink('partnerships'), angular.extend({}, {
                            page: $stateParams.partnershipPage,
                            limit: $stateParams.partnershipLimit,
                            order: $stateParams.partnershipOrder,
                            site: $stateParams.partnershipSite,
                            serviceProviderName: $stateParams.partnershipService
                        }));
                    }
                },
                params: {
                    partnershipPage: '1',
                    partnershipLimit: '30',
                    partnershipOrder: 'site',
                    partnershipSite: null,
                    partnershipService: null
                },
                ncyBreadcrumb: {
                    label: '{{ ::"PARTNERSHIPS" | translate }}'
                }
            })
            .state('dashboard.admin.job-requisites.list.details.partnerships.edit', {
                url: '/edit',
                parent: 'dashboard.admin.job-requisites.list.details.partnerships',
                data: {
                    permissions: ['user_permission_manage_job_requisites', 'user_permission_client_admin_manage_job_requisites']
                },
                views: {
                    '@dashboard.admin.job-requisites.list.details': {
                        templateUrl: '/modules/admin/job-requisites/details/partnerships/job-requisite-partnerships-edit.html',
                        controller: 'JobRequisitesPartnershipsEditController',
                        controllerAs: 'vm'
                    }
                },
                resolve: {
                    siteCollectionResponse: function ($stateParams, apiClient, rootResourceResponse) {
                        var params = {
                            status: 'active',
                            limit: 100,
                            datagroup: 'details'
                        };

                        return apiClient.get(rootResourceResponse.getLink('sites'), params);
                    }
                },
                ncyBreadcrumb: {
                    label: '{{ ::"EDIT" | translate }}'
                }
            });
    }

    function registerRoutesServiceProviders($stateProvider) {
        $stateProvider
            .state('dashboard.admin.job-requisites.list.details.serviceproviders', {
                url: '/serviceproviders?serviceProviderPage&serviceProviderLimit&serviceProviderOrder&serviceProviderId&{serviceProviderName:any}',
                parent: 'dashboard.admin.job-requisites.list.details',
                data: {
                    permissions: ['user_permission_manage_job_requisites', 'user_permission_client_admin_manage_job_requisites'] // BE requires manage permission for the cgetAction endpoint
                },
                views: {
                    '@dashboard.admin.job-requisites.list.details': {
                        templateUrl: '/modules/admin/job-requisites/details/service-providers/job-requisite-service-providers.html',
                        controller: 'JobRequisitesServiceProvidersController',
                        controllerAs: 'vm'
                    }
                },
                resolve: {
                    selectedServiceProvidersCollectionResponse: function ($stateParams, apiClient, jobRequisiteResponse) {
                        return apiClient.get(jobRequisiteResponse.getLink('serviceproviders'), angular.extend({}, $stateParams, {
                            page: $stateParams.serviceProviderPage,
                            limit: $stateParams.serviceProviderLimit,
                            order: $stateParams.serviceProviderOrder,
                            id: $stateParams.serviceProviderId,
                            name: $stateParams.serviceProviderName
                        }));
                    }
                },
                params: {
                    serviceProviderPage: '1',
                    serviceProviderLimit: '30',
                    serviceProviderOrder: 'id',
                    serviceProviderId: null,
                    serviceProviderName: null
                },
                ncyBreadcrumb: {
                    label: '{{ ::"SERVICE_PROVIDERS" | translate }}'
                }
            })
            .state('dashboard.admin.job-requisites.list.details.serviceproviders.edit', {
                url: '/edit',
                parent: 'dashboard.admin.job-requisites.list.details.serviceproviders',
                data: {
                    permissions: ['user_permission_manage_job_requisites', 'user_permission_client_admin_manage_job_requisites']
                },
                views: {
                    '@dashboard.admin.job-requisites.list.details': {
                        templateUrl: '/modules/admin/job-requisites/job-requisites-assign-form.html',
                        controller: 'JobRequisitesServiceProvidersEditController',
                        controllerAs: 'vm',
                    }
                },
                resolve: {
                    serviceProvidersCollectionResponse: function ($stateParams, apiClient, rootResourceResponse) {
                        var params = {
                            status: 'active'
                        };

                        return apiClient.get(rootResourceResponse.getLink('serviceproviders'), params);
                    }
                },
                ncyBreadcrumb: {
                    label: '{{ ::"EDIT" | translate }}'
                }
            });
    }

})();
