(function () {
    'use strict';

    angular
        .module('elogbooks.user.preferences')
        .controller('UserPreferencesGeneralEditController', UserPreferencesGeneralEditController);

    function UserPreferencesGeneralEditController($state, apiClient, messenger, userManager, userResponse, userPreferencesCollection, localeManager) {
        var vm = this;
        vm.user = userResponse;
        vm.userPreferences = userPreferencesCollection.userPreferences;
        vm.update = updateAction;
        vm.getPreferenceValue = userManager.getPreferenceValue;
        vm.preferenceOptions = userManager.getPreferenceOptions();
        vm.preferences = [
            'emailContentType',
            'notificationMethod',
            'locale',
            'notificationOnCreateJob',
            'notificationOnCompletePlannedJob',
            'notificationOnCompleteReactiveJob',
            'notificationOnCompleteStatutoryJob',
            'notificationOnCreateQuote',
            'notificationOnAcceptQuote',
            'emailTextSignature',
            'weeklyDashboardReport'
            ];
        vm.localeOptions = localeManager.getDropDownOptions();

        for (var key in vm.preferences) {
            var prefName = vm.preferences[key];

            vm.user[prefName] = vm.getPreferenceValue(prefName);

            // reduces hard-coding all cases in the bellow 'if(){}' assertion
            var notificationOnCompleteJob = prefName.indexOf('notificationOnComplete') != -1
                && prefName.indexOf('Job') != -1 ? true : false;

            // convert 'on'/'off' to true/false
            if (prefName === 'notificationOnCreateJob' || notificationOnCompleteJob ||
                prefName === 'notificationOnCreateQuote' || prefName === 'notificationOnAcceptQuote' ||
                prefName === 'weeklyDashboardReport'
            ) {
                switch (vm.user[prefName]) {
                    case 'on':
                        vm.user[prefName] = true;
                        break;
                    default:
                        vm.user[prefName] = false;
                        break;
                }
            }
        }

        function updateAction() {
            var requestData = {preferences: []};

            for (var key in vm.preferences) {
                var prefName = vm.preferences[key];
                var prefValue = vm.user[prefName];

                // reduces hard-coding all cases in the bellow 'if(){}' assertion
                var notificationOnCompleteJob = prefName.indexOf('notificationOnComplete') != -1
                    && prefName.indexOf('Job') != -1 ? true : false;

                // convert true/false to 'on'/'off'
                if(prefName === 'notificationOnCreateJob' || notificationOnCompleteJob ||
                    prefName === 'notificationOnCreateQuote' || prefName === 'notificationOnAcceptQuote' ||
                    prefName === 'weeklyDashboardReport'
                ) {
                    switch (vm.user[prefName]) {
                        case true:
                            prefValue = 'on';
                            break;
                        default:
                            prefValue = 'off';
                            break;
                    }
                }

                if (prefName === 'emailTextSignature') {
                    if (prefValue === null) {
                        prefValue = "";
                    }
                }

                requestData.preferences.push({name: prefName, value: prefValue});

            }

            apiClient.update(userPreferencesCollection.getLink('edit'), requestData, true).then(function (response) {
                if (response) {
                    angular.forEach(requestData.preferences, function(object) {
                        if(object.name == 'locale')
                        {
                            localeManager.set(object.value);
                            $state.go('^', {}, {reload:'dashboard'}).then(function() {
                                messenger.success('PREFERENCES_UPDATED');
                            });
                        }
                    })
                } else {
                    messenger.error('REQUEST_ERROR');
                }
            });
        }
    }
})();
