(function () {
    'use strict';

    angular
        .module('elogbooks.common.jobs.workflow')
        .controller('JobOperationCompleteController', JobOperationCompleteController);

    JobOperationCompleteController.$inject = [
        'apiClient',
        '$state',
        'messenger',
        'jobResponse',
        'showLabourLines',
        'siteResponse',
        'serviceProviderResponse',
        'partnershipResponse',
        'assetResponse',
        'scopeData',
        'priorityService',
        'user',
        '$translate',
        'elbVisibilityService',
        'tagCollectionResponse',
        'lodash',
        'config',
        '$scope',
        'locationCollectionResponse',
        'labourLinesCollectionResponse',
        'assetCollectionResponse',
        'userManager',
        'formVersionInstanceCollectionResponse',
        '$stateParams',
        'jobRemedialsCollection',
        'rootResourceResponse',
        'jobOperativeResponse',
        '$q',
        'documentTypeCollectionResponse',
        'fileDocumentTypeService',
        'dateTimeService',
        'postFormVersionInstanceCollectionResponse',
        'associatedEntities',
        'jobRequisitesResponse',
        'jobFilesCollectionResponse',
        'integrationsService',
        'elbSettings',
        'modulesService'
    ];

    function JobOperationCompleteController(
        apiClient,
        $state,
        messenger,
        jobResponse,
        showLabourLines,
        siteResponse,
        serviceProviderResponse,
        partnershipResponse,
        assetResponse,
        scopeData,
        priorityService,
        user,
        $translate,
        elbVisibilityService,
        tagCollectionResponse,
        lodash,
        config,
        $scope,
        locationCollectionResponse,
        labourLinesCollectionResponse,
        assetCollectionResponse,
        userManager,
        formVersionInstanceCollectionResponse,
        $stateParams,
        jobRemedialsCollection,
        rootResourceResponse,
        jobOperativeResponse,
        $q,
        documentTypeCollectionResponse,
        fileDocumentTypeService,
        dateTimeService,
        postFormVersionInstanceCollectionResponse,
        associatedEntities,
        jobRequisitesResponse,
        jobFilesCollectionResponse,
        integrationsService,
        elbSettings,
        modulesService
    ) {
        var vm = this;
        vm.job = jobResponse;
        vm.isJobComplete = true;
        vm.action = 'complete';
        vm.translation = 'JOB_WORKFLOW_COMPLETE_OPERATION';
        vm.noteRequired = false;
        vm.closed = true;
        vm.data = {_links: {}};
        vm.data.escalationFiles = { links: [] };
        vm.labourLinesCollectionResponse = labourLinesCollectionResponse;
        vm.siteAssetCollectionResponse = assetCollectionResponse;
        vm.associatedEntities = associatedEntities;
        vm.isReactiveJob = vm.job.type === 'reactive' ? true : false;
        vm.submitDisabled = false;
        vm.form = null;
        var createJobs = false;
        var createQuotes = false;
        var canScoreAssets = false;
        var meridianWorkflowEnabled = vm.meridianWorkflowEnabled = elbSettings.getSetting('general_meridian_workflow').value;
        var meridianEnabled = vm.meridianEnabled = modulesService.isEnabled('meridian');

        vm.integrationDocCat = {};
        vm.meridianDocType = {};
        vm.skipIntegrationFile = {};

        remainingRequiredDocTypes();

        if (jobOperativeResponse &&
            (
                jobOperativeResponse.permissions.indexOf('operative_permission_can_score_assets') !== -1 ||
                jobOperativeResponse.permissionsInherited.indexOf('operative_permission_can_score_assets') !== -1
            )
        ) {
            canScoreAssets = true;
        }

        if (!lodash.isEmpty(jobOperativeResponse)) {
            var allPermissions = [].concat(
                jobOperativeResponse.permissions,
                jobOperativeResponse.permissionDependencies,
                jobOperativeResponse.permissionsInherited,
                jobOperativeResponse.permissionsInheritedDependencies
            );

            // check can show the raise job button
            if (allPermissions.indexOf('operative_permission_create_reactive_jobs') >= 0
                || userManager.hasPermission('site_permission_create_jobs')
                || userManager.hasPermission('operative_permission_create_reactive_jobs')
            ) {
                createJobs = true;
            }
            // check can show the raise Qutoe button
            if (allPermissions.indexOf('operative_permission_create_quotes') >= 0
                || userManager.hasPermission('site_permission_create_quotes')
                || userManager.hasPermission('operative_permission_create_quotes')
            ) {
                createQuotes = true;
            }
        }

        vm.raiseJobModel = {
            createJobs: createJobs,
            rootResource: rootResourceResponse,
            user: user,
            siteResponse: siteResponse,
            sourceJob: jobResponse,
            config: config,
            sourceJobAsset: {
                asset: jobResponse._links.asset ? jobResponse._links.asset.href.encode() : null,
                subasset: jobResponse._links.subasset ? jobResponse._links.subasset.href.encode() : null
            }
        };

        vm.raiseQuoteModel = {
            createQuotes: createQuotes,
            rootResource: rootResourceResponse,
            user: user,
            siteResponse: siteResponse,
            sourceJob: jobResponse,
            serviceProviderResponse : serviceProviderResponse,
            partnershipResponse: partnershipResponse,
            priorityService : priorityService,
            locationCollectionResponse : locationCollectionResponse,
            config: config
        };

        vm.postFormVersionInstances = postFormVersionInstanceCollectionResponse.formVersionInstances;
        vm.forms = {};

        var jobRequisites = jobRequisitesResponse.jobRequisites;

        if (postFormVersionInstanceCollectionResponse && vm.postFormVersionInstances.length > 0) {
            angular.forEach(vm.postFormVersionInstances, function(postFormVersionInstance, formIndex) {
                vm.forms[formIndex] = angular.extend({}, postFormVersionInstance._embedded.formVersion.data);
                vm.forms[formIndex].postForm = true;
                vm.forms[formIndex].id = postFormVersionInstance.id;

               // Find matching requisite and remove so it is not selected again
               var matchIndex = lodash.findIndex(jobRequisites, function(jobRequisite){
                   return jobRequisite.getLink('postForm') && jobRequisite.getLink('postForm') === postFormVersionInstance.getLink('form');

               });
               if (matchIndex > -1) {
                   vm.forms[formIndex].requisiteName = jobRequisites.splice(matchIndex, 1)[0].name
               }

                angular.forEach(postFormVersionInstance.data, function(formData, dataIndex) {
                    var data = lodash.find(vm.forms[formIndex].components, {id: formData.id});

                    if (data) {
                        data.answer = formData.answer;
                        data.notApplicable = formData.notApplicable;
                    }
                });
            });
        }

        if (canScoreAssets) {
            vm.scoreAssets = true;
        }

        vm.assetResponse = assetResponse;

        if (assetCollectionResponse) {
            vm.selectedAssetModel = {
                response: vm.siteAssetCollectionResponse,
                link: vm.siteAssetCollectionResponse.getLink('self'),
                responseKeyPath: 'assets',
                itemHrefPath: '_links.self.href',
                itemValuePath: 'name',
                refreshOnLinkChange: false,
            };

            if (jobResponse.getLink('subasset')) {
                vm.selectedAssetModel.selected = jobResponse._links.subasset;
                vm.selectedAssetModel.selected.createdFromSubasset = true;
            } else if (jobResponse.getLink('asset')) {
                vm.selectedAssetModel.selected = jobResponse._links.asset;
            }

            $scope.$on('assetSelected', function(event, asset) {
                vm.assetResponse = asset;
                vm.selectedAssetModel.selected = asset;
            }
            );
            vm.selectedAssetModel.required = isAssetRequired();
        }

        vm.data.assets = {
            asset: vm.job._links.asset,
            subasset: vm.job._links.subasset
        };

        vm.partial = vm.action;
        vm.visibilityService = elbVisibilityService;
        vm.tags = tagCollectionResponse;
        vm.visibilityServiceOptions = [
            { title: $translate.instant('NONE_SELECTED'), value: null },
        ].concat(vm.visibilityService.getVisibilitiesAsObject());
        vm.config = config;
        vm.showFile = true;
        vm.isStatutory = jobResponse._links.statutorytype;
        vm.integrations = [];
        vm.serviceProvidersResponse = serviceProviderResponse;
        var priorityLinkParameters = {'active': true, 'entityTypes': 'entity_type_all,entity_type_quote'};
        var priorityLink;

        vm.canSendToQuote = (siteResponse.getLink('create-quoterequest') && partnershipResponse && partnershipResponse.active);

        vm.sendToQuote = false;
        vm.hasIntegrations = false;
        vm.labourLineRequired = vm.serviceProvidersResponse.requireLabourLineOnJobComplete &&
            vm.labourLinesCollectionResponse && vm.labourLinesCollectionResponse.count === 0;

        vm.setForm = (form) => vm.form = form;

        vm.locationCollectionResponse = locationCollectionResponse;
        vm.locationRequired = siteResponse.locationRequired;

        vm.datePickerOptions = {
            maxDate: new Date()
        };

        vm.submit = submitAction;
        vm.removeFile = removeFile;
        vm.transformTag = transformTag;
        vm.changeStatutory = changeStatutory;
        vm.selectItem = selectItem;
        vm.removeItem = removeItem;
        vm.addFile = addFile;
        vm.addFormFile = addFormFile;
        vm.resetIntegrationDoc = resetIntegrationDoc;
        vm.changePriority = changePriority;
        vm.changeSendToQuote = changeSendToQuote;
        vm.checkIfNew = checkIfNew;
        vm.inputChange = inputChange;
        vm.isAssetRequired = isAssetRequired;
        vm.searchTags = searchTags;
        vm.getJobRemedials = getJobRemedials;

        angular.extend(vm, scopeData);

        vm.remedialsCount = jobResponse.jobRemedialsCount;
        vm.jobRemedialsCollection = jobRemedialsCollection;
        vm.remedialsOpen = $stateParams.changed;

        vm.selectedPriorityModel = {
            response: null,
            link: null,
            linkParameters: priorityLinkParameters,
            required: true,
            responseKeyPath: 'priorities',
            itemHrefPath: '_links.self.href',
            itemValuePath: 'name',
            onSelect: changePriority,
            onRemove: changePriority
        };

        if (documentTypeCollectionResponse.count > 0) {
            vm.documentTypeModel = {
                response: documentTypeCollectionResponse,
                link: documentTypeCollectionResponse ? documentTypeCollectionResponse.getLink('self') : null,
                linkParameters: { status: 'active', order: 'name', job: vm.job.id },
                responseKeyPath: 'documentTypes'
            };
        }

        if (formVersionInstanceCollectionResponse.count > 0) {
            vm.formInstanceSheetOptions = [];
            lodash.each(formVersionInstanceCollectionResponse.formVersionInstances, function (formVersionInstance) {
                if (formVersionInstance.isCompleted) {
                    vm.formInstanceSheetOptions.push({
                        value: formVersionInstance.getLink('form-instance-sheet'),
                        title: formVersionInstance._embedded.form.summary
                    })
                }
            });
            sortFormInstanceSheetOptions();
            vm.originalformInstanceSheetOptions = angular.copy(vm.formInstanceSheetOptions);
        }

        if (
            showLabourLines.hasPermission &&
            vm.job.getLink('job-labours') &&
            !userManager.hasPermission('site_permission_helpdesk')
        ) {
            vm.showLabourLines = true;
        }

        let mandatoryLabourLineOptions = lodash.get(vm.serviceProvidersResponse, 'mandatoryLabourLineOptions', []);
        vm.isMandatoryLabourLineOption = (field) => vm.showLabourLines && vm.labourLineRequired && mandatoryLabourLineOptions.indexOf(field) >= 0;

        // start date needs be always mandatory
        (mandatoryLabourLineOptions.indexOf('start_date') < 0) && mandatoryLabourLineOptions.push('start_date');

        if (vm.canSendToQuote) {
            priorityService.setupPriorities(partnershipResponse.getLink('priorities'), serviceProviderResponse.getLink('priorities'), jobResponse.getLink('priorities'), priorityLinkParameters).then(function(response) {
                vm.selectedPriorityModel.response = priorityService.addExtraPriorities(response, ['byPlannedDate']);
            });
        }

        function sortFormInstanceSheetOptions() {
            vm.formInstanceSheetOptions.sort(function(a,b) {
                return (a.title < b.title ? -1 : (a.title > b.title ? 1 : 0))
            })
        }

        function inputChange() {
            if (vm.minutesWorkedMM < 0) {
                vm.minutesWorkedMM = 0;
            }

            if (vm.minutesWorkedMM > 59) {
                vm.minutesWorkedMM = 59;
            }

            if (vm.timeTravelledMM < 0) {
                vm.timeTravelledMM = 0;
            }

            if (vm.timeTravelledMM > 59) {
                vm.timeTravelledMM = 59;
            }
        }

        function changeSendToQuote () {
            if (!vm.data.sendToQuote) {
                vm.data.quoteSummary = null;
                vm.data.quoteDescription = null;
                if (vm.selectedPriorityModel) {
                    vm.selectedPriorityModel.selected = null;
                }
                delete vm.data.priority;
            }
        }

        function changePriority () {
            if (vm.selectedPriorityModel.selected.object) {
                priorityLink = {href: vm.selectedPriorityModel.selected.href};
                vm.data.isByPlannedDate = vm.selectedPriorityModel.selected.value === $translate.instant('BY_PLANNED_DATE');
                vm.data.isOnNextPlannedVisit = vm.selectedPriorityModel.selected.value === $translate.instant('JOB_NEXT_PLANNED_VISIT');
                if (vm.data.isByPlannedDate || vm.data.isOnNextPlannedVisit) {
                    priorityLink = null;
                } else {
                    vm.data.completionDueAt = null;
                }
            } else {
                priorityLink = null;
                vm.data.isByPlannedDate = vm.data.isOnNextPlannedVisit = false;
            }
        }

        function submitAction() {
            vm.data.files = angular.copy(vm.files);

            // remove empty records
            lodash.remove(vm.data.files, {file: null});

            // refactor file link, tidy data
            lodash.each(vm.data.files, function (file) {
                file._links.file = angular.copy(file.file._links.file);
                if (file.documentTypeModel && file.documentTypeModel.selected) {
                    file._links.documentType = { href: file.documentTypeModel.selected.href };
                    delete file.documentTypeModel.selected;
                }

                if (file.expiresAt) {
                    file.expiresAt = dateTimeService.setEndOfDay(file.expiresAt);
                }

                delete file.file;
                delete file.tagsSelected;
                delete file.integrationDocCat;
                delete file.skipIntegrationFile;
                delete file.disableExpiresAt;
            });

            // timetracking
            vm.data.minutesWorked = (vm.minutesWorkedHH || 0) * 60 + (vm.minutesWorkedMM || 0);
            vm.data.minutesTravelled = (vm.timeTravelledHH || 0) * 60 + (vm.timeTravelledMM || 0);

            // when assets are changed
            if (vm.data.assets && vm.data.assets.asset) {
                vm.data._links.asset = vm.data.assets.asset;
            }

            if (vm.data.assets && vm.data.assets.subasset) {
                vm.data._links.subasset = vm.data.assets.subasset;
            }

            if (!vm.data.assets) {
                vm.data._links.asset = null;
            }

            var requests = {};

            if (vm.postFormVersionInstances.length > 0) {
                angular.forEach(vm.forms, function(form, index) {
                    var instance = lodash.find(vm.postFormVersionInstances, { id: form.id });
                    var data = {
                        components: form.components,
                        settings: form.settings
                    };

                    if (instance) {
                        requests[index] = apiClient.create(instance.getLink('submit'), data);
                    }
                });
            }

            $q.all(requests).then(function (responses) {
                var success = true;

                angular.forEach(responses, function(response, index) {
                    if (!response) {
                        success = false;
                    }
                });

                if (success) {
                    apiClient.create(vm.job.getLink(vm.action), vm.data).then(function (response) {
                        if (response) {
                            if (vm.canSendToQuote && vm.sendToQuote) {
                                var params = {
                                    summary: vm.data.quoteSummary,
                                    description: vm.data.quoteDescription,
                                    completionDueAt: vm.data.completionDueAt ? vm.data.completionDueAt : null,
                                    _links: {
                                        priority: priorityLink,
                                        jobFrom: {href: jobResponse.getLink('self')},
                                        location: vm.selectedLocationModel,
                                        sourceJob: {href: jobResponse.getLink('self')}
                                    },
                                    serviceProviders: {
                                        links: [{href: jobResponse.getLink('serviceprovider')}]
                                    },
                                    isRemedial: true
                                };
                                if (jobResponse.getLink('action')) {
                                    params._links['action'] = {href: jobResponse.getLink('action')};
                                }

                                apiClient.create(siteResponse.getLink('create-quoterequest'), params).then(function (createQuoteRequestResponse) {
                                    $state.go('^.^', {}, {reload: $state.get('^.^.^')}).then(function () {
                                        messenger.success(vm.translation + '_SUCCESS');
                                    });
                                });
                            } else {
                                $state.go('^.^', {}, {reload: $state.get('^.^.^')}).then(function () {
                                    messenger.success(vm.translation + '_SUCCESS');
                                });
                            }
                        }
                    }, function () {
                        messenger.error('REQUEST_ERROR');
                    });
                } else  {
                    messenger.error('REQUEST_ERROR');
                }
            });
        }

        // To avoid add time to documentDate in uib-datepicker
        var d = new Date();
        d.setHours(0);
        d.setMinutes(0);
        d.setSeconds(0);
        d.setMilliseconds(0);

        var emptyFile = {
            file: null,
            formFile: null,
            tags: {
                tags: [],
            },
            id: null,
            title: null,
            visibility: null,
            documentDate: meridianEnabled ? null : d,
            expiresAt: null,
            notifyExpiry: false,
            isStatutory: false,
            userTags: [],
            tagsSelected: [],
            tagsToSelect: angular.copy(vm.tags.tags),
            documentTypeModel: vm.documentTypeModel,
            disableExpiresAt: false,
            advancedClosed: false,
            _links: {}
        };


        vm.yesNo = [
            {
                value: false,
                label: $translate.instant('NO')
            },
            {
                value: true,
                label: $translate.instant('YES')
            }
        ];

        // files array
        vm.files = [];
        addFile();

        $scope.$watch('vm.files', function(newVal, oldVal) {
            if (!lodash.isEqual(newVal, oldVal)) {
                if (vm.files[vm.files.length-1].formFile !== null) {
                    addFormFile(vm.files.length-1);
                }

                if (vm.files[vm.files.length-1].file !== null) {
                    addFile();
                } else if (vm.files.length > 1) {
                    var maxDate = new Date();
                    if (vm.files[vm.files.length-2].documentDate && vm.files[vm.files.length-2].documentDate > maxDate) {
                        vm.files[vm.files.length-2].documentDate = maxDate;
                    }
                }

                for (var i = 0, len = newVal.length -1; i < len; i++) {
                    remainingRequiredDocTypes();

                    if (newVal[i]) {
                        if (newVal[i].tagsSelected.length !== oldVal[i].tagsSelected.length) {
                            vm.files[i].tagsToSelect = emptyFile.tagsToSelect;
                        }

                        if (vm.files[i].title === null) {
                            vm.files[i].title = newVal[i].file.filename;
                        }

                        if (newVal[i].documentDate !== oldVal[i].documentDate ||
                            newVal[i].documentTypeModel !== oldVal[i].documentTypeModel
                        ) {
                            fileDocumentTypeService.calculateExpiresAt(newVal[i].documentTypeModel, vm.files[i]);
                        }

                        var precedentStatutoryName = null;

                        if (oldVal[i] && oldVal[i].documentTypeModel.selected && oldVal[i].documentTypeModel.selected.object._links.statutorytype) {
                            precedentStatutoryName = oldVal[i].documentTypeModel.selected.object._links.statutorytype.title;
                        }

                        if (newVal[i].documentTypeModel.selected && newVal[i].documentTypeModel.selected.href) {

                            if (!oldVal[i].documentTypeModel.selected ||
                                (
                                    oldVal[i].documentTypeModel.selected &&
                                    oldVal[i].documentTypeModel.selected.href !== newVal[i].documentTypeModel.selected.href
                                )
                            ) {
                                oldVal = oldVal[i];
                                newVal = newVal[i];

                                if (newVal && newVal.documentTypeModel.selected && newVal.documentTypeModel.selected.object._links.statutorytype) {
                                    vm.files[i].isStatutory = true;
                                    changeStatutory(i, newVal.documentTypeModel.selected.object._links.statutorytype, precedentStatutoryName);
                                }
                            }
                        } else if (newVal[i].documentTypeModel.selected === undefined && precedentStatutoryName) {
                            changeStatutory(i, null, precedentStatutoryName);
                        }
                    }
                }
            }
        }, true);

        function addFile() {
            var newFile = angular.copy(emptyFile);
            if (vm.files.length) {
                vm.skipIntegrationFile[vm.files.length] = false;
            }
            vm.files.push(newFile);
        }

        function addFormFile(index) {
            var formInstanceSheetLink = vm.files[index].formFile;
            apiClient.get(formInstanceSheetLink, {'macroValue[]': []})
                .then(function (response) {
                    if (response) {
                        //remove selected form from options
                        vm.formInstanceSheetOptions = vm.formInstanceSheetOptions.filter(function(item){
                            return item.value !== formInstanceSheetLink
                        });

                        vm.files[index].file = {
                            _links: {
                                file: response._links.self,
                                resource: response._links.resource
                            },
                            title: response.title,
                            filename: response.filename,
                            type: response.type
                        };
                        vm.files[index].isStatutory = true
                        changeStatutory(index)
                    } else {
                        messenger.error('REQUEST_ERROR');
                    }
                })
        }

        function removeFile($index) {
            //add selected form back to options
            if (vm.files[$index].formFile) {
                vm.formInstanceSheetOptions.push(lodash.find(vm.originalformInstanceSheetOptions,
                    {value: vm.files[$index].formFile })
                );
                sortFormInstanceSheetOptions();
            }
            vm.files.splice($index, 1);
            remainingRequiredDocTypes();
        }

        function remainingRequiredDocTypes() {
            vm.mandatoryDocTypes = [];
            vm.toRemove = [];
            vm.addedDocTypes = [];

            //Files with document type added to job before complete paperwork step
            lodash.each(jobFilesCollectionResponse.files, function (jobFile) {
                vm.addedDocTypes.push(jobFile.getLink('documentType'));
            });

            //Files with document type added on complete paperwork step
            lodash.each(vm.files, function (file) {
                if (file.documentTypeModel.selected) {
                    vm.addedDocTypes.push(file.documentTypeModel.selected.href);
                }
            });

            //Job's required document types
            lodash.each(vm.job.requiredDocumentTypes.documentTypes, function (jobDocType) {
                vm.mandatoryDocTypes.push({name: jobDocType.name, href: jobDocType._links.self.href});
            });

            vm.jobDocTypes = vm.mandatoryDocTypes;
            vm.mandatoryDocTypes = lodash.sortBy(vm.mandatoryDocTypes.filter(function(jobDocType) {
                return vm.addedDocTypes.indexOf(jobDocType.href) === -1;
            }), 'name');
        }

        function transformTag (tag) {
            var item = {
                type: 'user',
                name: tag
            };

            return item;
        }

        function selectItem (item, $index, fileObject) {
            if (item._links) {
                lodash.remove(fileObject.tagsToSelect, {name: item.name});
                lodash.remove(vm.files[$index].tags.tags, {href: item._links.self.href});
                vm.files[$index].tags.tags.push({href: item._links.self.href});
            } else {
                if (!lodash.find(vm.tags.tags, {name: item.name})) {
                    vm.tags.tags.push(item);
                }
                //remove if tag is selected again
                if ((lodash.filter(vm.files[$index].tagsSelected, {name: item.name})).length > 1) {
                    if (lodash.find(vm.files[$index].tagsSelected, {name: item.name})) {
                        lodash.remove(vm.files[$index].tagsSelected, {name: item.name});
                        vm.files[$index].tagsSelected.push(item);
                    }
                }

                lodash.remove(vm.files[$index].userTags, {name: item.name});
                vm.files[$index].userTags.push({name: item.name});
            }
        }

        function removeItem (item, $index, fileObject) {
            if (item._links) {
                fileObject.tagsToSelect.push(item);
                lodash.remove(vm.files[$index].tags.tags, {href: item._links.self.href});
            } else {
                lodash.remove(fileObject.tagsToSelect, {name: item.name});
                lodash.remove(vm.files[$index].userTags, {name: item.name});
            }
        }

        function checkIfNew($tag, $index, fileObject) {
            if (!lodash.find(fileObject.tagsToSelect, {name: $tag}) &&
                !lodash.find(vm.files[$index].tagsSelected, {name: $tag})) {
                return $tag + $translate.instant('NEW_TAG');
            } else {
                return $tag;
            }
        }

        // on changing statutory option add/remove statutory user tag
        function changeStatutory($index, statutoryType, precedentStatutoryName) {
            var statutoryName = statutoryType ? statutoryType.title : null;

            if (precedentStatutoryName) {
                // visible selected tag dom elements
                lodash.remove(vm.files[$index].tagsSelected, {name: precedentStatutoryName});

                // newly entered tags
                lodash.remove(vm.files[$index].userTags, {name: precedentStatutoryName});

                // loaded tags
                lodash.remove(vm.files[$index].tags.tags, {name: precedentStatutoryName});

                delete vm.files[$index]._links.statutoryType;
            }

            if (vm.files[$index].isStatutory === true) {
                vm.files[$index].index =  $index;
                initializeIntegrations(vm.files[$index]);
            }

            var tag = lodash.find(vm.files[$index].tagsSelected, function (selectedTag) {
                return selectedTag.name === statutoryName;
            });

            if (tag === undefined && statutoryName) {
                var statutoryTag = transformTag(statutoryName);
                vm.files[$index].tagsSelected.push(statutoryTag);
                vm.selectItem(statutoryTag, $index);
            }

            statutoryType ? vm.files[$index]._links.statutoryType = statutoryType : false;
        }

        function resetIntegrationDoc($integration, $index) {
            if (vm.data.integration !== undefined) {
                vm.data.integration[$integration] = null;
                vm.integrationDocCat[$index] = null;
            }
            delete vm.files[$index].integration[$integration];
        }

        $scope.$watch('vm.data.escalationSummary', function($escalationSummary) {
            if ($escalationSummary && $escalationSummary.length === 100) {
                messenger.error($translate.instant('FIELD_WITH_MAXIMUM_LENGTH', {fieldName: $translate.instant('SUMMARY'), limit:100}));
            }
        });

        function initializeIntegrations(file) {
            var preselectCallback;

            if (!meridianWorkflowEnabled) {
                preselectCallback = function(integration) {
                    return integrationsService.preSelectDocumentType(integration, vm.integrationDocCat, file);
                }
            }

            if (elbSettings.getSetting("general_meridian_workflow").value === false && vm.integrations.length === 0 && jobResponse.getLink('integration-doc-types')) {
                return apiClient.get(jobResponse.getLink('integration-doc-types')).then(function(integrationDocTypesResponse) {
                    vm.integrations = integrationDocTypesResponse ? integrationDocTypesResponse.data : [];
                    vm.hasIntegrations = integrationsService.checkIntegrations(vm.integrations, jobResponse, preselectCallback);
                });
            } else if (vm.integrations.length > 0) {
                vm.hasIntegrations = integrationsService.checkIntegrations(vm.integrations, jobResponse, preselectCallback);
            }
        }

        function isAssetRequired() {
            return serviceProviderResponse.requiresAssetsWhenLoggingReactiveJob
                && assetCollectionResponse.count > 0
                && vm.isReactiveJob;
        }

        function searchTags(keyword, fileObject) {
            vm.loading = true;
            if (keyword.length >= 1 && tagCollectionResponse.getLink('self')) {
                apiClient.get(tagCollectionResponse.getLink('self'), {name: keyword})
                    .then(function (response) {
                        vm.loading = false;
                        if (response.tags.length !== 0 ){
                            fileObject.tagsToSelect =  angular.copy(response.tags);
                        }
                    });
            } else {
                vm.loading = false;
            }
        }

        function getJobRemedials(stayClosed, fromRaiseJobService) {
            var def = $q.defer();

            var params = {
                page: $stateParams.remedialsPage,
                limit: $stateParams.remedialsLimit
            };

            if (
                vm.job.getLink('remedials') &&
                !vm.remedialsOpen || fromRaiseJobService
            ) {
                if (!vm.jobRemedialsCollection.remedials.length || fromRaiseJobService) {
                    vm.loading = true;
                    return apiClient.get(vm.job.getLink('remedials'), params).then(
                        function(response) {
                            vm.remedialsCount = response.count;
                            vm.jobRemedialsCollection = response;
                            vm.remedialsOpen = stayClosed ? false : true;
                            vm.loading = false;

                            def.resolve();
                        },
                        function (error) {
                            def.reject();
                        });
                } else {
                    vm.remedialsOpen = stayClosed ? false : true;
                }
            } else {
                vm.remedialsOpen = false;
            }

            return def.promise;
        }
    }
})();
