(function() {
    'use strict';

    angular
        .module('elogbooks.admin.settings')
        .controller('AdminSettingsAddEditChaseSettingsController', AdminSettingsAddEditChaseSettingsController);

    function AdminSettingsAddEditChaseSettingsController($filter, $state, lodash, messenger, apiClient, chaseSettingsResponse, siteAssociateTypesResponse, chaseSettingResponse, moment, confirmationModal) {
        var vm = this;
        vm.setting = chaseSettingResponse;
        vm.setting.associatetypes = [];
        vm.associateTypes = [];

        // Create associate types option array
        if (siteAssociateTypesResponse.count > 0) {
            vm.associateTypes = siteAssociateTypesResponse.siteAssociateTypes.map(function(item) {
                return {
                    value: item._links.self.href,
                    title: item.name
                }
            })
        }

        // Select associate types that are in relation with existing chase setting
        if (typeof vm.setting.id !== 'undefined') {
            if (typeof vm.setting._links.associatetypes !== 'undefined') {
                vm.setting.associatetypes = vm.setting._links.associatetypes.map(function(item) {
                    return item.href;
                });
            }
        } else {
            vm.setting.targetSite = false;
            vm.setting.targetOperative = false;
            vm.setting.targetApprover = false;
            vm.setting.targetSubmitter = false;
            vm.setting.weekendsPublicHolidays = false;
        }

        // For new chase settings create request structure
        if (typeof vm.setting._links == 'undefined') {
            vm.setting._links = {};
            vm.setting._links.associatetype = [];
        }

        if (!angular.isNumber(chaseSettingResponse.type)) {
            var type = null;

            switch (chaseSettingResponse.type) {
                case 'reactive':
                    type = 0;
                    break;
                case 'reactiveStatutory':
                    type = 1;
                    break;
                case 'planned':
                    type = 2;
                    break;
                case 'plannedStatutory':
                    type = 3;
                    break;
                case 'quote':
                    type = 4;
                    break;
                case 'contractExpired':
                    type = 2; // ContractSetting specific types, hence the duplicates
                    break;
                case 'contractApproval':
                    type = 3; // ContractSetting specific types, hence the duplicates
                    break;
            }

            vm.setting.type = type;
        }

        vm.create = createAction;
        vm.update = updateAction;
        vm.delete = deleteAction;

        function updateAction() {
            vm.setting._links.associatetype = getAssociateTypes();

            apiClient.update(vm.setting.getLink('edit'), angular.copy(vm.setting)).then(function(response) {
                if (response) {
                    messenger.success('CHASE_SETTING_UPDATED');
                    $state.go('^', {}, {reload: $state.get('^.^')});
                } else {
                    messenger.error('REQUEST_ERROR');
                }
            });
        }

        function createAction() {
            vm.setting._links.associatetype = getAssociateTypes();

            apiClient.create(chaseSettingsResponse._links.create.href, vm.setting).then(function(response) {
                if (response) {
                    messenger.success('CHASE_SETTING_UPDATED');
                    $state.go('^', {}, {reload: $state.get('^.^')});
                } else {
                    messenger.error('REQUEST_ERROR');
                }
            });
        }

        function deleteAction() {
            return confirmationModal.open().result.then(
                function () {
                    return apiClient.delete(chaseSettingResponse.getLink('delete')).then(function (response){
                        if (response) {
                            messenger.success('DELETE_SUCCESS');
                            $state.go('^', {}, {reload: $state.current.parent});
                        }
                    });
                }
            );
        }

        function getAssociateTypes() {
            if (vm.setting.associatetypes.length > 0) {
                return vm.setting.associatetypes.map(function(item) {
                    return {href: item};
                });
            }

            return [];
        }
    }
})();
