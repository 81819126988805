(function () {
    'use strict';

    angular
        .module('elogbooks.user.audits')
        .config(registerRoutes);

    function registerRoutes($stateProvider) {
        $stateProvider
            .state('dashboard.user.audits', {
                abstract: true,
                template: '<bookerize></bookerize>',
                ncyBreadcrumb: {
                    skip: true
                }
            })
            .state('dashboard.user.audits.dashboard', {
                url: '/audits/dashboard',
                parent: 'dashboard.user.audits',
                templateUrl: '/modules/common/audit/dashboard/dashboard.html',
                controller: 'AuditDashboardController',
                controllerAs: 'vm',
                resolve: {
                    auditDashboardResponse: function(rootResourceResponse, apiClient) {
                        if (!rootResourceResponse.getLink('audit-dashboard')) {
                            return null;
                        }

                        return apiClient.get(rootResourceResponse.getLink('audit-dashboard'));
                    },
                    actionAuditTypeCollectionResponse: function(apiClient, rootResourceResponse) {
                        // systemType 3 = RESERVED_TYPE_RAISED_FROM_AUDIT_QUESTION
                        return apiClient.get(rootResourceResponse.getLink('actiontypes'), {systemType: 3, limit: 1}).then(function(response) {
                            return response || apiClient.noResourceFound();
                        });
                    },
                },
                ncyBreadcrumb: {
                    label: '{{ ::"AUDITS_DASHBOARD" | translate }}'
                }
            })
            .state('dashboard.user.audits.list', {
                url: '/audits?auditPage&auditLimit&auditOrder&auditId&auditSummary&auditReference&auditType&auditStatus&createdAt&createdAtEnd&generatedForDate&generatedForDateEnd&completedAt&completedAtEnd&completionDueAt&completionDueAtEnd&nextOccurrence&nextOccurrenceEnd&siteAssociateType&userId&serviceProvider&operative',
                resolve: {
                    siteCollectionResponse: function(apiClient, user, paramsService) {
                        var params = {
                            page: 1,
                            limit: 30,
                            order: 'id',
                            datagroup: 'search'
                        };

                        params = angular.extend({}, paramsService, params);

                        return apiClient.get(user.getLink('sites'), params, 'long').then(function(response) {
                            return response || apiClient.noResourceFound();
                        });
                    },
                    auditCollectionResponse: function (apiClient, rootResourceResponse, $stateParams, selectedSiteResponse, globalFilterParamsService) {
                        var params = $stateParams;
                        if (selectedSiteResponse) {
                            params.site = selectedSiteResponse.getData().id;
                        } else {
                            params.site = null;
                        }

                        var isAuditPage =  ($stateParams.auditType === 'audit' && ($stateParams.auditStatus === 'all' || $stateParams.auditStatus[0] === 'all'));

                        params = angular.extend({}, $stateParams, {
                            page: $stateParams.auditPage,
                            limit: $stateParams.auditLimit,
                            order: $stateParams.auditOrder,
                            id: $stateParams.auditId,
                            summary: $stateParams.auditSummary,
                            reference: $stateParams.auditReference,
                            'status[]':isAuditPage ? ['audit_draft', 'audit_created', 'audit_started'] : $stateParams.auditStatus,
                            type: $stateParams.auditType,
                            site: params.site,
                            createdAt: $stateParams.createdAt,
                            createdAtEnd: $stateParams.createdAtEnd,
                            generatedForDate: $stateParams.generatedForDate,
                            generatedForDateEnd: $stateParams.generatedForDateEnd,
                            completedAt: $stateParams.completedAt,
                            completedAtEnd: $stateParams.completedAtEnd,
                            completionDueAt: $stateParams.completionDueAt,
                            completionDueAtEnd: $stateParams.completionDueAtEnd,
                            nextOccurrence: $stateParams.nextOccurrence,
                            nextOccurrenceEnd: $stateParams.nextOccurrenceEnd
                        });

                        params = angular.extend({}, params, globalFilterParamsService.getLocalStorageFilters());

                        if ($stateParams.serviceProvider){
                            params.serviceProvider = JSON.parse($stateParams.serviceProvider).v;
                        }

                        if ($stateParams.operative){
                            params.operative = JSON.parse($stateParams.operative).v;
                        }

                        return apiClient.getPage(rootResourceResponse.getLink('audits'), params);
                    },
                    serviceProviderCollectionResponse: function($stateParams, apiClient, user, userManager, selectedSiteResponse) {
                        if (!user.getLink('serviceproviders')) {
                            return null;
                        }

                        var params = {};
                        if ($stateParams.serviceProviders) {
                            params.id = $stateParams.serviceProviders[0];
                        }

                        if (selectedSiteResponse) {
                            params.siteId = selectedSiteResponse.id;
                        }

                        if (user.getLink('serviceproviders')) {
                            return apiClient.get(user.getLink('serviceproviders'), params, 'medium').then(function(response) {
                                return response || apiClient.noResourceFound();
                            });
                        }
                    },
                    operativeCollectionResponse: function (rootResourceResponse, apiClient) {
                        if (rootResourceResponse.getLink('operatives')) {
                            return apiClient.get(rootResourceResponse.getLink('operatives')).then(function (response) {
                                return response;
                            });
                        } else {
                            return null;
                        }
                    }
                },
                params: {
                    auditPage: '1',
                    auditLimit: '30',
                    options: null,
                    auditId: null,
                    auditSummary: null,
                    auditReference: null,
                    auditStatus: 'all',
                    auditType: 'audit',
                    auditOrder: 'summary',
                    createdAt: null,
                    createdAtEnd: null,
                    generatedForDate: null,
                    generatedForDateEnd: null,
                    completedAt: null,
                    completedAtEnd: null,
                    completionDueAt: null,
                    completionDueAtEnd: null,
                    nextOccurrence: null,
                    nextOccurrenceEnd: null,
                    scheduleName: null,
                    serviceProviders: {array: true},
                    serviceProvider: null,
                    operative: null,
                },
                views: parseBook({
                    'elogbooks-left@dashboard.user.audits': {
                        templateUrl: '/modules/common/audit/list/audits.html',
                        controller: 'AuditListController',
                        controllerAs: 'vm'
                    }
                }),
                ncyBreadcrumb: {
                    label: '{{ ::"AUDITS" | translate }}'
                }
            })
            .state('dashboard.user.audits.list.add', {
                url: '/add/{auditResource}',
                views: parseBook({
                    'elogbooks-right@dashboard.user.audits': {
                        templateUrl: '/modules/common/audit/add-edit/audits-form.html',
                        controller: 'AuditAddEditController',
                        controllerAs: 'vm'
                    }
                }),
                resolve: {
                    serviceProviderResponse: function ($stateParams, apiClient, user) {
                        return apiClient.getPage(user.getLink('serviceproviders'));
                    },
                    siteCollectionResponse: function ($stateParams, apiClient, user) {
                        return apiClient.getPage(user.getLink('sites'), angular.extend({}, $stateParams, {'datagroup': 'search'}));
                    },
                    auditTemplateResponse: function (apiClient, $stateParams) {
                        if ($stateParams.auditResource) {
                            return apiClient.get($stateParams.auditResource.decode(), {status: 'audit_template_active'});
                        }

                        return {};
                    },
                    auditResponse: function () {
                        return {};
                    },
                    siteResponse: function (selectedSiteResponse) {
                        return  selectedSiteResponse ? selectedSiteResponse : null;
                    },
                    templateCollectionResponse: function (rootResourceResponse, apiClient, $stateParams, selectedSiteResponse, userManager) {
                        if (userManager.hasPermission('site_permission_view_audit_templates')) {
                            var params = $stateParams;
                            if (selectedSiteResponse) {
                                params.site = selectedSiteResponse.getData().id;
                            } else {
                                params.site = null;
                            }
                            return apiClient.getPage(rootResourceResponse.getLink('audits'), angular.extend({}, params, {type:'template', limit: 30, scheduleName: null}));
                        } else {
                            return {};
                        }
                    }
                },
                ncyBreadcrumb: {
                    label: '{{ ::"ADD" | translate }}'
                }
            })
            .state('dashboard.user.audits.list.details', {
                url: '/details/{auditResource}',
                parent: 'dashboard.user.audits.list',
                abstract: true,
                views: parseBook({
                    'elogbooks-right@dashboard.user.audits': {
                        templateUrl: '/modules/common/audit/tabs.html',
                        controller: 'AuditTabsController',
                        controllerAs: 'vm'
                    }
                }),
                resolve: {
                    auditResponse: function (apiClient, $stateParams) {
                        return apiClient.get($stateParams.auditResource.decode());
                    },
                    auditTemplateVersionsResponse: function (apiClient, auditResponse) {
                        if (typeof auditResponse._links.versions !== 'undefined') {
                            return apiClient.get(auditResponse.getLink('versions')).then(function (response) {
                                return response;
                            });
                        } else {
                            return {};
                        }
                    },
                    serviceProviderResponse: function ($stateParams, apiClient, user) {
                        return apiClient.getPage(user.getLink('serviceproviders'));
                    },
                    auditTemplateResponse: function () {
                        return {};
                    },
                    sectionsCollectionResponse: function (apiClient, auditResponse) {
                        var link = auditResponse.getLink('template-sections') ? auditResponse.getLink('template-sections') : auditResponse.getLink('sections');

                        return apiClient.get(link).then(function (response) {
                            return response;
                        });
                    },
                    siteResponse: function (auditResponse, siteResponse, apiClient) {
                        if (typeof auditResponse._links.site !== 'undefined') {
                            return apiClient.get(auditResponse.getLink('site')).then(function (response) {
                                return response;
                            });
                        }
                    },
                    templateCollectionResponse: function (rootResourceResponse, apiClient, $stateParams, selectedSiteResponse) {
                        var params = $stateParams;
                        if (selectedSiteResponse) {
                            params.site = selectedSiteResponse.getData().id;
                        } else {
                            params.site = null;
                        }
                        return apiClient.getPage(rootResourceResponse.getLink('audits'), angular.extend({}, params, {type:params.auditType}));
                    }
                },
                ncyBreadcrumb: {
                    label: '{{ ::$resolves().auditResponse.summary }}'
                }
            })
            .state('dashboard.user.audits.list.details.info', {
                url: '/info',
                parent: 'dashboard.user.audits.list.details',
                views: parseBook({
                    'tab-panel@dashboard.user.audits.list.details': {
                        templateUrl: '/modules/common/audit/details/details.html',
                        controller: 'AuditDetailsController',
                        controllerAs: 'vm'
                    }
                }),
                ncyBreadcrumb: {
                    label: '{{ ::"INFO" | translate }}'
                }
            })
            .state('dashboard.user.audits.list.details.info.edit', {
                url: '/edit',
                parent: 'dashboard.user.audits.list.details.info',
                views: {
                   'tab-panel@dashboard.user.audits.list.details': {
                        templateUrl: '/modules/common/audit/add-edit/audits-form.html',
                        controller: 'AuditAddEditController',
                        controllerAs: 'vm'
                    }
                },
                resolve: {
                    siteResponse: function () {
                        return {};
                    }
                },
                ncyBreadcrumb: {
                    label: '{{ ::"EDIT" | translate }}'
                }
            })
            .state('dashboard.user.audits.list.details.sections', {
                url: '/sections',
                parent: 'dashboard.user.audits.list.details',
                views: parseBook({
                    'tab-panel@dashboard.user.audits.list.details': {
                        templateUrl: '/modules/common/audit/sections/list/sections.html',
                        controller: 'AuditSectionsController',
                        controllerAs: 'vm'
                    }
                }),
                 resolve: {
                    sectionResponse: function () {
                        return {};
                    }
                },
                ncyBreadcrumb: {
                    label: '{{ ::"AUDIT_SECTIONS" | translate }}'
                }
            })
            .state('dashboard.user.audits.list.details.sections.add', {
                url: '/add',
                parent: 'dashboard.user.audits.list.details.sections',
                views: parseBook({
                    'tab-panel@dashboard.user.audits.list.details': {
                        templateUrl: '/modules/common/audit/sections/add-edit/section-form.html',
                        controller: 'AuditSectionAddEditController',
                        controllerAs: 'vm'
                    }
                }),
                resolve: {
                    parentSectionResponse: function () {
                        return {};
                    },
                    locationResponse: function (apiClient, auditResponse) {
                        if (typeof auditResponse._links.site != 'undefined') {
                            return apiClient.get(auditResponse._links.site.href+'/locations');
                         }

                         return {};
                    }
                },
                ncyBreadcrumb: {
                    label: '{{ ::"ADD" | translate }}'
                }
            })
            .state('dashboard.user.audits.list.details.sections.details', {
                url: '/details/{sectionResource}',
                parent: 'dashboard.user.audits.list.details.sections',
                views: parseBook({
                    'tab-panel@dashboard.user.audits.list.details': {
                        templateUrl: '/modules/common/audit/sections/details/details.html',
                        controller: 'AuditSectionDetailsController',
                        controllerAs: 'vm'
                    },
                    'sub-tab-panel@dashboard.user.audits.list.details': {
                        templateUrl: '/modules/common/audit/sections/list/sections.html',
                        controller: 'AuditSectionsController',
                        controllerAs: 'vm'
                    },
                    'questions-tab-panel@dashboard.user.audits.list.details': {
                        templateUrl: '/modules/common/audit/questions/list/questions.html',
                        controller: 'AuditQuestionsController',
                        controllerAs: 'vm'
                    }
                }),
                resolve: {
                    sectionResponse: function (apiClient, $stateParams, auditService) {
                        auditService.auditAnswerFlashRaiseJobs = [];
                        return apiClient.get($stateParams.sectionResource.decode(), {datagroup: 'details'});
                    },
                    sectionsCollectionResponse: function (apiClient, sectionResponse) {
                        /* get child sections for this section */
                        return apiClient.get(sectionResponse.getLink('children')).then(function (response) {
                           return response;
                         });
                    },
                    questionsCollectionResponse: function (apiClient, sectionResponse) {
                         /* get questions for this section */
                        return apiClient.get(sectionResponse.getLink('questions')).then(function (response) {
                           return response;
                         });
                    }
                },
                ncyBreadcrumb: {
                    label: '{{ ::$resolves().sectionResponse.name }}'
                }
            })
            .state('dashboard.user.audits.list.details.sections.add.subsection', {
                url: '/details/{parentSectionResponse}/add',
                parent: 'dashboard.user.audits.list.details.sections',
                views: parseBook({
                    'tab-panel@dashboard.user.audits.list.details': {
                        templateUrl: '/modules/common/audit/sections/add-edit/section-form.html',
                        controller: 'AuditSectionAddEditController',
                        controllerAs: 'vm'
                    },
                    'sub-tab-panel@dashboard.user.audits.list.details': {

                    }
                }),
                resolve: {
                    sectionResponse: function () {
                        return {};
                    },
                    parentSectionResponse: function (apiClient, $stateParams) {
                        return apiClient.get($stateParams.parentSectionResponse.decode());
                    },
                    locationResponse: function (apiClient, auditResponse) {
                        if (typeof auditResponse._links.site != 'undefined') {
                            return apiClient.get(auditResponse._links.site.href+'/locations');
                         }

                         return {};
                    }
                },
                ncyBreadcrumb: {
                    label: '{{ ::"ADD" | translate }}'
                }
            })
            .state('dashboard.user.audits.list.details.sections.details.edit', {
                url: '/edit',
                parent: 'dashboard.user.audits.list.details.sections.details',
                views: {
                   'tab-panel@dashboard.user.audits.list.details': {
                        templateUrl: '/modules/common/audit/sections/add-edit/section-form.html',
                        controller: 'AuditSectionAddEditController',
                        controllerAs: 'vm'
                    }
                },
                resolve: {
                    parentSectionResponse: function (apiClient, sectionResponse) {
                        if (typeof sectionResponse._links.parent != 'undefined') {
                            return apiClient.get(sectionResponse.getLink('parent')).then(function (response) {
                                return response;
                            });
                        } else {
                            return {};
                        }
                    },
                    locationResponse: function (apiClient, auditResponse) {
                        if (typeof auditResponse._links.site != 'undefined') {
                            return apiClient.get(auditResponse._links.site.href+'/locations');
                         }

                         return {};
                    }
                },
                ncyBreadcrumb: {
                    label: '{{ ::"EDIT" | translate }}'
                }
            })
            .state('dashboard.user.audits.list.details.sections.details.add', {
                url: '/add-question',
                parent: 'dashboard.user.audits.list.details.sections.details',
                views: parseBook({
                    'tab-panel@dashboard.user.audits.list.details': {
                         templateUrl: '/modules/common/audit/questions/add-edit/question-form.html',
                        controller: 'AuditQuestionAddEditController',
                        controllerAs: 'vm'
                    },
                    'sub-tab-panel@dashboard.user.audits.list.details': {
                    },
                    'questions-tab-panel@dashboard.user.audits.list.details': {

                    }
                }),
                resolve: {
                    questionResponse: function (apiClient, $stateParams) {
                        return {};
                    },
                    answerOptionsResponse: function (apiClient, $stateParams) {
                        return {};
                    },
                    config: function (userManager) {
                        var can = {
                            canCreateAnswerOption: false,
                            canSeeAnswerOption: false
                        };

                        if (userManager.hasPermission('site_permission_create_audit_answer_options')) {
                            can.canCreateAnswerOption = true;
                        } else {
                            can.canCreateAnswerOption = false;
                        }

                        if (userManager.hasPermission('site_permission_view_audit_answer_options')) {
                            can.canSeeAnswerOption = true;
                        } else {
                            can.canSeeAnswerOption = false;
                        }
                        return can;
                    }
                },
                ncyBreadcrumb: {
                    label: '{{ ::"ADD" | translate }}'
                }
            })
            .state('dashboard.user.audits.list.details.sections.details.question', {
                url: '/question/{questionResource}',
                parent: 'dashboard.user.audits.list.details.sections.details',
                views: parseBook({
                    'tab-panel@dashboard.user.audits.list.details': {
                        templateUrl: '/modules/common/audit/questions/details/details.html',
                        controller: 'AuditQuestionDetailsController',
                        controllerAs: 'vm'
                    },
                    'questions-tab-panel@dashboard.user.audits.list.details': {
                        templateUrl: '/modules/common/audit/questions/answer-options/list/answer-options.html',
                        controller: 'AuditAnswerOptionsController',
                        controllerAs: 'vm'
                    }
                }),
                resolve: {
                    questionResponse: function (apiClient, $stateParams) {
                        return apiClient.get($stateParams.questionResource.decode());
                    },
                    questionAnswerResponse: function (apiClient, questionResponse) {
                        if(questionResponse._links.answer) {
                            return apiClient.get(questionResponse.getLink('answer')).then(function (response) {
                               return response;
                             });
                        }
                    },
                    answerOptionsResponse: function (apiClient, questionResponse) {
                         return apiClient.get(questionResponse.getLink('answerOptions')).then(function (response) {
                           return response;
                         });
                    }
                },
                ncyBreadcrumb: {
                    label: '{{ ::$resolves().questionResponse.summary }}'
                }
            })
            .state('dashboard.user.audits.list.details.sections.details.question.edit', {
                url: '/edit',
                parent: 'dashboard.user.audits.list.details.sections.details.question',
                views: {
                    'tab-panel@dashboard.user.audits.list.details': {
                        templateUrl: '/modules/common/audit/questions/add-edit/question-form.html',
                        controller: 'AuditQuestionAddEditController',
                        controllerAs: 'vm'
                    }
                },
                resolve: {
                    config: function (userManager) {
                        if (userManager.hasPermission('site_permission_create_audit_answer_options')) {
                            return {
                                canCreateAnswerOption: true
                            };
                        } else {
                            return {
                                canCreateAnswerOption: false
                            };
                        }
                    }
                },
                ncyBreadcrumb: {
                    label: '{{ ::"EDIT" | translate }}'
                }
            })
           .state('dashboard.user.audits.list.details.sections.details.question.add', {
                url: '/add-answer-option',
                parent: 'dashboard.user.audits.list.details.sections.details.question',
                views: parseBook({
                    'questions-tab-panel@dashboard.user.audits.list.details': {
                        templateUrl: '/modules/common/audit/questions/answer-options/add-edit/answer-option-form.html',
                        controller: 'AuditAnswerOptionAddEditController',
                        controllerAs: 'vm'
                    }
                }),
                resolve: {
                    answerOptionResponse: function (apiClient, $stateParams) {
                        return {};
                    },
                },
                ncyBreadcrumb: {
                    label: '{{ ::"ADD" | translate }}'
                }
            })
           .state('dashboard.user.audits.list.details.sections.details.question.answer-option', {
                url: '/answer-option/{answerOptionResource}/edit',
                parent: 'dashboard.user.audits.list.details.sections.details.question',
                views: {
                    'questions-tab-panel@dashboard.user.audits.list.details': {
                        templateUrl: '/modules/common/audit/questions/answer-options/add-edit/answer-option-form.html',
                        controller: 'AuditAnswerOptionAddEditController',
                        controllerAs: 'vm'
                    }
                },
                resolve: {

                    answerOptionResponse: function (apiClient, $stateParams) {
                        return apiClient.get($stateParams.answerOptionResource.decode());
                    },
                    questionResponse: function(questionResponse) {
                        return questionResponse;
                    }
                },
                ncyBreadcrumb: {
                    label: '{{ ::"EDIT" | translate }}'
                }
            })
            .state('dashboard.user.audits.answer-options-list', {
                url: '/answer-templates?answerTemplatePage&answerTemplateLimit&answerTemplateOrder&answerTemplateName&answerTemplateType&answerTemplateStatus&answerTemplateButton&answerTemplateVisibility',
                resolve: {
                    answerTemplateCollectionResponse: function (apiClient, rootResourceResponse, $stateParams) {

                        return apiClient.getPage(rootResourceResponse.getLink('audit-answer-templates'), angular.extend({}, $stateParams, {
                            page: $stateParams.answerTemplatePage,
                            limit: $stateParams.answerTemplateLimit,
                            order: $stateParams.answerTemplateOrder,
                            name: $stateParams.answerTemplateName,
                            status: $stateParams.answerTemplateStatus,
                            type: $stateParams.answerTemplateType,
                            optionName: $stateParams.answerTemplateOptionName,
                            visibility: $stateParams.answerTemplateVisibility,
                            noEmpty: null
                        }));
                    }
                },
                params: {
                    answerTemplatePage: '1',
                    answerTemplateLimit: '30',
                    answerTemplateName: null,
                    answerTemplateOptionName: null,
                    answerTemplateVisibility: 'all',
                    answerTemplateStatus: 'active',
                    answerTemplateType: 'all',
                    answerTemplateOrder: null,
                },
                views: parseBook({
                    'elogbooks-left@dashboard.user.audits': {
                        templateUrl: '/modules/common/audit/answer-templates/list/list.html',
                        controller: 'AuditAnswerTemplateListController',
                        controllerAs: 'vm'
                    }
                }),
                ncyBreadcrumb: {
                    label: '{{ ::"ANSWER_OPTIONS" | translate }}'
                }
            })
            .state('dashboard.user.audits.answer-options-list.add', {
                url: '/add',
                parent: 'dashboard.user.audits.answer-options-list',
                views: parseBook({
                    'elogbooks-left@dashboard.user.audits': {
                        templateUrl: '/modules/common/audit/answer-templates/add-edit/answer-template-form.html',
                        controller: 'AuditAnswerTemplateAddEditController',
                        controllerAs: 'vm'
                    }
                }),
                resolve: {
                    answerTemplateResponse: function (apiClient, $stateParams) {
                        return null;
                    },
                    answerOptionTemplateCollectionResponse: function (apiClient, answerTemplateResponse) {
                        return null;
                    }
                },
                ncyBreadcrumb: {
                    label: '{{ ::"ADD" | translate }}'
                }
            })
            .state('dashboard.user.audits.answer-options-list.details', {
                url: '/details/{answerTemplateResource}',
                parent: 'dashboard.user.audits.answer-options-list',
                abstract: true,
                views: parseBook({
                    'elogbooks-right@dashboard.user.audits': {
                        templateUrl: '/modules/common/audit/answer-templates/tabs.html',
                        controller: 'AuditAnswerTemplateTabsController',
                        controllerAs: 'vm'
                    }
                }),
                resolve: {
                    answerTemplateResponse: function (apiClient, $stateParams) {
                        return apiClient.get($stateParams.answerTemplateResource.decode());
                    },
                    answerOptionTemplateCollectionResponse: function (apiClient, answerTemplateResponse) {
                        return apiClient.get(answerTemplateResponse.getLink('answer-options')).then(function (response) {
                            return response;
                        });
                    }
                },
                ncyBreadcrumb: {
                    label: '{{ ::$resolves().answerTemplateResponse.name }}'
                }
            })
            .state('dashboard.user.audits.answer-options-list.details.info', {
                url: '/',
                parent: 'dashboard.user.audits.answer-options-list.details',
                views: parseBook({
                    'template-tab-panel@dashboard.user.audits.answer-options-list.details': {
                        templateUrl: '/modules/common/audit/answer-templates/details/answer-template.details.html',
                        controller: 'AuditAnswerTemplateDetailsController',
                        controllerAs: 'vm'
                    },
                    'options-tab-panel@dashboard.user.audits.answer-options-list.details': {
                        templateUrl: '/modules/common/audit/answer-templates/answer-option-templates/details/answer-options-templates.html',
                        controller: 'AuditAnswerOptionTemplateDetailController',
                        controllerAs: 'vm'
                    }
                }),
                resolve: {},
                ncyBreadcrumb: {
                    label: '{{ ::"INFO" | translate }}'
                }
            })
            .state('dashboard.user.audits.answer-options-list.details.info.edit', {
                url: '/edit/',
                parent: 'dashboard.user.audits.answer-options-list.details.info',
                views: parseBook({
                   'template-tab-panel@dashboard.user.audits.answer-options-list.details': {
                        templateUrl: '/modules/common/audit/answer-templates/add-edit/answer-template-form.html',
                        controller: 'AuditAnswerTemplateAddEditController',
                        controllerAs: 'vm'
                    }
                }),
                resolve: {},
                ncyBreadcrumb: {
                    label: '{{ ::"EDIT" | translate }}'
                }
            })
            .state('dashboard.user.audits.answer-options-list.details.info.add', {
                url: '/add/',
                parent: 'dashboard.user.audits.answer-options-list.details.info',
                views: parseBook({
                   'options-tab-panel@dashboard.user.audits.answer-options-list.details': {
                        templateUrl: '/modules/common/audit/questions/answer-options/add-edit/answer-option-form.html',
                        controller: 'AuditAnswerOptionTemplateAddEditController',
                        controllerAs: 'vm'
                    }
                }),
                resolve: {
                    answerOptionTemplateResponse: function (apiClient, $stateParams) {
                        return null;
                    }
                },
                ncyBreadcrumb: {
                    label: '{{ ::"ADD" | translate }}'
                }
            })
            .state('dashboard.user.audits.answer-options-list.details.info.answer-option', {
                url: '/answer-option-template/{answerOptionResource}/edit',
                parent: 'dashboard.user.audits.answer-options-list.details.info',
                views: parseBook({
                   'options-tab-panel@dashboard.user.audits.answer-options-list.details': {
                        templateUrl: '/modules/common/audit/questions/answer-options/add-edit/answer-option-form.html',
                        controller: 'AuditAnswerOptionTemplateAddEditController',
                        controllerAs: 'vm'
                    }
                }),
                resolve: {
                    answerOptionTemplateResponse: function (apiClient, $stateParams) {
                        return apiClient.get($stateParams.answerOptionResource.decode());
                    }
                },
                ncyBreadcrumb: {
                    label: '{{ ::"EDIT" | translate }}'
                }
            });
    }
})();
