(function(){
    'use strict';

    angular
        .module('elogbooks.user', [
            'elogbooks.user.sites',
            'elogbooks.user.jobs',
            'elogbooks.user.audits',
            'elogbooks.user.meters',
            'elogbooks.user.quote-requests',
            'elogbooks.user.contracts',
            'elogbooks.user.surveys',
            'elogbooks.user.tenants',
            'elogbooks.user.service-providers',
            'elogbooks.user.tasks',
            'elogbooks.user.master-tasks',
            'elogbooks.user.patrols',
            'elogbooks.user.actions',
            'elogbooks.user.preferences',
            'elogbooks.user.duty-management',
            'elogbooks.user.files',
            'elogbooks.user.assets',
            'elogbooks.user.escalations',
            'elogbooks.user.buddies',
            'elogbooks.user.reports',
            'elogbooks.user.bulk-actions',
            'elogbooks.user.spillages',
            'elogbooks.user.manage-operatives',
            'elogbooks.user.waste-management',
            'elogbooks.user.sharing-setup',
            'elogbooks.user.finance',
            'elogbooks.user.absence-planners',
            'elogbooks.user.timesheets',
            'elogbooks.user.integrations'
        ]);
})();
