(function () {
    'use strict';

    angular
        .module('elogbooks')
        .controller('PermissionsController', PermissionsController);

    PermissionsController.$inject = [
        'apiClient',
        '$state',
        'permissionService',
        'entityResponse',
        'requestDataFactory',
        'messenger',
        'permissionKey',
        'entityName',
        'userManager'
    ];

    function PermissionsController (
        apiClient,
        $state,
        permissionService,
        entityResponse,
        requestDataFactory,
        messenger,
        permissionKey,
        entityName,
        userManager
    ) {
        var vm = this;
            vm.entity = entityResponse;
            vm.permissions = permissionService.getAllTranslated();
            vm.selectedPermissions = [];
            vm.appliedPermissions = [];
            vm.data = requestDataFactory.createRequest(vm.entity);
            vm.update = updateAction;
            vm.entityName = entityName;
            vm.permissionKey = permissionKey;

        function updateAction () {
            var reloadView = false;

            // if updating the explicit permissions for the current user, then reload page to update menu links
            if (vm.entity.getLink('self') === userManager.user.getLink('self')) {
                reloadView = true;
            }

            // if updating a role, reload the page in case any implicit permsissions affects the user
            if (vm.entity.getLink('self').indexOf('/roles/') !== -1) {
                reloadView = true;
            }

            return apiClient.update(vm.entity.getLink('self'), vm.data).then(function (response) {
                if (response) {
                    if (reloadView) {
                        userManager.clearCache();
                    }

                    $state.go('.', {}, { reload: reloadView }).then(function () {
                        messenger.success(entityName + '_PERMISSIONS_UPDATED');

                        // update original entity
                        vm.entity[vm.permissionKey] = vm.data[vm.permissionKey];
                    });
                } else {
                    $state.reload();
                }
            });
        }
    }
})();
