(function() {
    'use strict';

    angular
        .module('elogbooks.admin.settings')
        .controller('AdminSettingsAddEditReviewExpiryController', AdminSettingsAddEditReviewExpiryController);

    function AdminSettingsAddEditReviewExpiryController($filter, $state, lodash, messenger, apiClient, settingsResponse, siteAssociateTypesResponse, config, moment) {
        var vm = this;
        vm.setting = settingsResponse;
        vm.setting.associatetypes = [];
        vm.entity = config.entity;
        vm.associateTypes = [];

        //Create associate types option array
        if (siteAssociateTypesResponse.count > 0) {
            vm.associateTypes = siteAssociateTypesResponse.siteAssociateTypes.map(function(item) {
                return {
                    value: item._links.self.href,
                    title: item.name
                };
            });
        }

        if (typeof vm.setting.id !== 'undefined') {
            if (typeof vm.setting._links.associatetypes !== 'undefined') {
                vm.setting.associatetypes = vm.setting._links.associatetypes.map(function(item) {
                    return item.href;
                });
            }
        } else {
            vm.setting.targetSite = false;
            vm.setting.targetApprover = false;
            vm.setting.targetSubmitter = false;
            vm.setting.weekendsPublicHolidays = false;
        }

        if (typeof vm.setting._links === 'undefined') {
            vm.setting._links = {};
            vm.setting._links.associatetype = [];
        }

        vm.create = createAction;
        vm.update = updateAction;

        function updateAction() {
            vm.setting._links.associatetype = getAssociateTypes();

            apiClient.update(vm.setting.getLink('edit'), angular.copy(vm.setting)).then(function(response) {
                if (response) {
                    return $state.go('^', {}, {reload: $state.get('^.^')}).then(function () {
                        messenger.success(vm.entity + '_SETTING_UPDATED');
                    });
                } else {
                    messenger.error('REQUEST_ERROR');
                }
            });
        }

        function createAction() {
            vm.setting._links.associatetype = getAssociateTypes();

            if (vm.entity) {
                var type = null;

                switch (vm.entity) {
                    case 'REVIEW':
                        type = 0;
                        break;
                    case 'EXPIRY':
                        type = 1;
                        break;
                }

                vm.setting.type = type;
            }

            apiClient.create(settingsResponse.getLink('create'), vm.setting).then(function(response) {
                if (response) {
                    return $state.go('^', {}, {reload: $state.get('^.^')}).then(function () {
                        messenger.success(vm.entity + '_SETTING_CREATED');
                    });
                } else {
                    messenger.error('REQUEST_ERROR');
                }
            });
        }

        function getAssociateTypes() {
            if (vm.setting.associatetypes.length > 0) {
                return vm.setting.associatetypes.map(function(item) {
                    return {href: item};
                });
            }

            return [];
        }
    }
})();
