(function() {
    'use strict';

    angular
        .module('elogbooks.common.quote-requests', []);
})();

/**
 * Note:
 *
 * This is a global function. It was intended to be a service that would then be injected
 * in to the routing files / functions, but it appears that services cannot be injected in
 * to the routing functions. An unknown provider error occurs.
 */
function elbQuoteRequestRouting() {
    var self = this,
        config = {},
        $stateProvider,
        priorityLinkParameters = {'active': true, 'entityTypes': 'entity_type_all,entity_type_quote'};

    this.addRoutes = addRoutes;

    function getView(view, viewObject) {
        var result = {};
        result[view] = viewObject;

        if (config.isBookable) {
            result = parseBook(result);
        }

        return result;
    }

    function getMultipleViews(views) {
        var result = {};

        angular.forEach(views, function(view, $index) {
            result[view.name] = view.value;
        });

        if (config.isBookable) {
            result = parseBook(result);
        }

        return result;
    }

    function getLabel(translationKey) {
        return {label: '{{ ::"' + translationKey + '" | translate }}'};
    }

    // Check if workflow link exists else error and redirect to parent
    function checkLink($state, $stateParams, messenger, quoteResponse, workflowLink) {
        if (workflowLink && !quoteResponse.getLink(workflowLink)) {
            var toState = $state.next.name.substring(0, $state.next.name.indexOf('.workflow')) + '.workflow';

            $state.go(toState, $stateParams, { reload: toState }).then(function () {
                messenger.error('WORKFLOW_ACTION_NOT_AVAILABLE');
            });

            return false;
        }

        return true;
    }

    /**
     * Usage:
     *     elbQuoteRequestRouting().addRoutes(
     *         $stateProvider,
     *         'dashboard.user.quote-requests',
     *         true || false
     *     )
     *
     * @param $stateProvider $stateProvider
     * @param string baseRoute
     * @param boolean isBookable
     */
    function addRoutes(_$stateProvider, baseRoute, isBookable) {

        config = {
            "isBookable": isBookable,
            "baseRoute": baseRoute,
            "baseView": '@' + baseRoute,
            "baseBookViewLeft": 'elogbooks-left@' + baseRoute,
            "baseBookViewRight": 'elogbooks-right@' + baseRoute
        };

        if (!isBookable) {
            config.baseBookViewLeft = config.baseView;
            config.baseBookViewRight = config.baseView;
        }

        $stateProvider = _$stateProvider;

        registerRoutes();
        registerRoutesDetails();
        registerRoutesDetailsFiles();
        registerRoutesDetailsWorkflow();
        registerRoutesDetailsOptions();
        registerRoutesDetailsOptionsDetails();
        registerRoutesDetailsOptionsDetailsInfo();
        registerRoutesDetailsOptionsDetailsLabour();
        registerRoutesDetailsOptionsDetailsMaterials();
        registerRoutesDetailsOptionsDetailsOther();
        registerRoutesDetailsOptionsDetailsFiles();
        registerRoutesAddSubQuote();
    }

    function registerRoutes() {
        var baseRouteObject = {
            abstract: true,
            templateUrl: '/shared-templates/view.html',
            ncyBreadcrumb: {
                skip: true
            }
        };
        if (config.isBookable) {
            delete baseRouteObject.templateUrl;
            baseRouteObject.template = '<bookerize></bookerize>'
        }
        var quoteParams = {
            quoteRequestsPage: "1",
            quoteRequestsLimit: "30",
            quoteId: null,
            quoteApprover: null,
            quoteSummary: null,
            quoteStatus: {array: true},
            quotePriority: {array: true},
            sites: {array: true},
            siteRegion: {array: true},
            quoteDescription: null,
            createdAtStart: null,
            createdAtEnd: null,
            createdBy: null,
            operative: null,
            approver: {array: true},
            alarmedAtStart: null,
            alarmedAtEnd: null,
            serviceProviders: {array: true},
            overdue: null,
            completionDueStart: null,
            completionDueEnd: null,
            lastSubmittedAtStart: null,
            lastSubmittedAtEnd: null,
            quoteIncludeNeverSubmitted: null,
            quoteCompletionDueAtBetween: null,
            iAmTheCurrentApprover: null,
            reminderAtStart: null,
            reminderAtEnd: null,
            siteGroups: {array: true},
            serviceProviderName: null,
            fromJob: null,
            embedded: 'quotes',
            fileOrder: '-id',
            filePage: '1',
            coreClient: null,
            serviceProviderGroup: null,
            isRemedial: null,
            sourceJob: null,
            fromPerformanceStatistics: null,
            quoteRemedial: null,
            budgetQuote: null,
            sourceQuote: null,
            showBudgetQuoteFilter: null
        };

        $stateProvider
            .state(config.baseRoute, baseRouteObject)
            .state(config.baseRoute + '.list', {
                params: quoteParams,
                url: '/quoterequests?' + Object.keys(quoteParams).join('&'),
                parent: config.baseRoute,
                views: getView(config.baseBookViewLeft, {
                    templateUrl: '/modules/common/quote-requests/quote-requests-list.html',
                    controller: 'CommonQuoteRequestsController',
                    controllerAs: 'vm'
                }),
                resolve: {
                    quoteRequestCollectionResponse: function(paramsService, apiClient, user, $stateParams, selectedSiteResponse, siteResponse, quoteParamsService) {
                        var params = quoteParamsService.getQuoteParams(paramsService, user, selectedSiteResponse);

                        if (typeof siteResponse.id !== 'undefined') {
                            delete params['sites[]'];
                            params.site = siteResponse.id;
                        }

                        if ($stateParams.serviceProviderGroup) {
                            params.serviceProviderGroup = $stateParams.serviceProviderGroup;
                        }

                        if (user.getLink('quoterequests')) {
                            params = angular.extend({}, paramsService, params);
                            delete params.status;

                            return apiClient.getPage(user.getLink('quoterequests'), params).then(function(response) {
                                if (response.budgetQuoteFilter) {
                                    $stateParams.showBudgetQuoteFilter = true;
                                }

                                return response || apiClient.noResourceFound();
                            });
                        }
                    },
                    priorityCollectionResponse: function(apiClient, rootResourceResponse, selectedSiteResponse, $stateParams) {
                        var priorityLinkParameters;

                        if ($stateParams.sites) {
                            priorityLinkParameters = {'active': true, 'entityTypes': 'entity_type_all,entity_type_quote', 'sites[]':$stateParams.sites};
                        } else if(selectedSiteResponse) {
                            priorityLinkParameters = {'active': true, 'entityTypes': 'entity_type_all,entity_type_quote', 'sites[]':selectedSiteResponse.id};
                        } else {
                            priorityLinkParameters = {'active': true, 'entityTypes': 'entity_type_all,entity_type_quote'};
                        }

                        priorityLinkParameters.order ='name';

                        if (rootResourceResponse.getLink('priorities')) {
                            return apiClient.get(rootResourceResponse.getLink('priorities'), priorityLinkParameters, 'long').then(function(response) {
                                return response || apiClient.noResourceFound();
                            });
                        }
                    },
                    serviceProviderCollectionResponse: function($stateParams, apiClient, user, userManager, selectedSiteResponse, siteResponse) {
                        var params = {};
                        if ($stateParams.serviceProviders) {
                            params.id = $stateParams.serviceProviders[0];
                        }

                        if (typeof siteResponse.id !== 'undefined') {
                            params.siteId = siteResponse.id;
                        } else if (selectedSiteResponse) {
                            params.siteId = selectedSiteResponse.id;
                        }

                        return apiClient.get(user.getLink('serviceproviders'), params, 'medium').then(function(response) {
                            return response || apiClient.noResourceFound();
                        });
                    },
                    config: function() {
                        return {};
                    }
                },
                ncyBreadcrumb: getLabel('QUOTE_REQUESTS')
            })
            .state(config.baseRoute + '.list.add', {
                url: '/add/{siteResource}?action&spillage',
                abstract: true,
                parent: config.baseRoute + '.list',
                views: getView(config.baseBookViewRight, {
                    templateUrl: '/modules/common/quote-requests/add/quote-request-form.html',
                    controller: 'CommonQuoteRequestAddController',
                    controllerAs: 'vm'
                }),
                params: {
                    action: null,
                    spillage: null,
                    budgetQuoteConversion: null,
                    budgetQuoteServiceProviderHref: null,
                    budgetQuotelocationHref: null,
                    budgetQuoteHref: null,
                    budgetRequestQuoteHref: null,
                },
                resolve: {
                    quoteRequestResponse: function() {
                        return {};
                    },
                    siteResponse: function(apiClient, selectedSiteResponse, $stateParams, siteResponse) {
                        if (siteResponse && siteResponse.id) {
                            return siteResponse;
                        } else if ($stateParams.siteResource) {
                            return apiClient.get($stateParams.siteResource.decode()).then(function(response) {
                                return response || apiClient.noResourceFound();
                            });
                        } else if (selectedSiteResponse) {
                            return selectedSiteResponse;
                        }

                        return;
                    },
                    actionResponseLink: function(apiClient, $stateParams) {
                        if ($stateParams.action) {
                            return $stateParams.action.decode();
                        }

                        return null;
                    },
                    spillageResponseLink: function($stateParams) {
                        if ($stateParams.spillage) {
                            return $stateParams.spillage.decode();
                        }

                        return null;
                    },
                    config: function($state, selectedSiteResponse, userManager) {
                        return {
                            isHelpdesk: userManager.hasPermission('site_permission_helpdesk'),
                            showSiteNotes: userManager.hasPermission('site_permission_helpdesk'),
                            addReporter: userManager.hasPermission('site_permission_helpdesk'),
                            siteDisabled: $state.current.name.indexOf('dashboard.user.sites') !== -1 || selectedSiteResponse
                        };
                    }
                },
                ncyBreadcrumb: getLabel('QUOTE_REQUEST_ADD'),
                scroll: false
            })
            .state(config.baseRoute + '.list.add.site', {
                url: '/site',
                parent: config.baseRoute + '.list.add',
                views: getView('@' + config.baseRoute + '.list.add', {
                    templateUrl: '/modules/common/quote-requests/add/quote-request-form-site.html',
                    controller: 'CommonQuoteRequestAddSiteController',
                    controllerAs: '_' // Faked controllerAs so we can inherit from parent
                }),
                resolve: {
                    siteCollectionResponse: function(apiClient, user, config) {
                        if (config.siteDisabled) {
                            return;
                        }

                        return apiClient.get(user.getLink('sites'), {
                            permission: ['site_permission_create_quotes'],
                            datagroup: 'search'
                        }).then(function(response) {
                            return response || apiClient.noResourceFound();
                        });
                    },
                    siteRelationshipResponse: function(rootResourceResponse) {
                        return rootResourceResponse.getResource('siterelationships');
                    }
                },
                ncyBreadcrumb: getLabel('SITE')
            })
            .state(config.baseRoute + '.list.add.contact', {
                url: '/contact',
                parent: config.baseRoute + '.list.add.site',
                views: getView('@' + config.baseRoute + '.list.add', {
                    templateUrl: '/modules/common/quote-requests/add/quote-request-form-contact.html',
                    controller: 'CommonQuoteRequestAddContactController',
                    controllerAs: '_' // Faked controllerAs so we can inherit from parent
                }),
                resolve: {
                    userCollectionResponse: function(siteResponse) {
                        return siteResponse.getResource('memberships');
                    }
                },
                ncyBreadcrumb: getLabel('QUOTE_REQUEST_REPORTER')
            })
            .state(config.baseRoute + '.list.add.details', {
                url: '/details',
                parent: config.baseRoute + '.list.add.site',
                views: getView('@' + config.baseRoute + '.list.add', {
                    templateUrl: '/modules/common/quote-requests/add/quote-request-form-details.html',
                    controller: 'CommonQuoteRequestAddDetailsController',
                    controllerAs: '_' // Faked controllerAs so we can inherit from parent
                }),
                resolve: {
                    priorityCollectionResponse: function(apiClient, siteResponse, rootResourceResponse) {
                        var priorityLinkParameters = {'active': true, 'entityTypes': 'entity_type_all,entity_type_quote'};

                        return apiClient.get(siteResponse.getLink('priorities'), priorityLinkParameters).then(function(response) {
                            if (response.priorities && response.priorities.length > 0) {
                                return response;
                            }

                            return apiClient.get(rootResourceResponse.getLink('priorities'), priorityLinkParameters).then(function(response) {
                                return response || apiClient.noResourceFound();
                            });
                        });
                    },
                    userOperativeCollectionResponse: function(apiClient, user, $stateParams) {
                        if ($stateParams.isRemedial) {
                            return apiClient.get(user.getLink('operatives'), {limit: 999}).then(function(response) {
                                return response || apiClient.noResourceFound();
                            });
                        } else {
                            return {}
                        }
                    },
                    actionResponse: function(apiClient, $stateParams) {
                        if ($stateParams.action) {
                            return apiClient.get($stateParams.action.decode(), { datagroup: 'details' }).then(function (response) {
                                return response || apiClient.noResourceFound();
                            });
                        }

                        return null;
                    },
                    actionFilesCollection: function(apiClient, actionResponse) {
                        if (actionResponse) {
                            return apiClient.get(actionResponse.getLink('files')).then(function (response) {
                                return response || apiClient.noResourceFound();
                            });
                        }
                    },
                    partnershipCollectionResponse: function(apiClient, siteResponse, elbSettings) {
                        var params = {active: 1, permission: 'create_quotes', serviceProvidersStatus: 'active'};

                        if (elbSettings.getSetting('prosure_enabled').value) {
                            params = angular.extend({order: 'prosureStatus'}, params);
                        }

                        return apiClient.get(siteResponse.getLink('partnerships'), params).then(function(response) {
                            return response || apiClient.noResourceFound();
                        });
                    },
                    locationCollectionResponse: function (apiClient, siteResponse) {
                        return apiClient.get(siteResponse.getLink('locations')).then(function (response) {
                            return response || apiClient.noResourceFound();
                        });
                    },
                    config: function(userManager) {
                        return {
                            showSiteNotes: userManager.hasPermission('site_permission_helpdesk')
                        };
                    },
                    quoteRequestResponse: function (apiClient, $stateParams) {
                        if ($stateParams.budgetQuoteConversion) {
                            return apiClient.get($stateParams.budgetRequestQuoteHref).then(function (response) {
                                return response || apiClient.noResourceFound();
                            });
                        }

                        return null;
                    }
                },
                ncyBreadcrumb: getLabel('QUOTE_REQUEST_DETAILS')
            });
    }


    function registerRoutesDetails() {
        $stateProvider
            .state(config.baseRoute + '.list.details', {
                url: '/{quoteResource}',
                parent: config.baseRoute + '.list',
                abstract: true,
                views: getMultipleViews([
                    {
                        name: config.baseBookViewRight,
                        value: {
                            templateUrl: '/modules/common/quote-requests/details/quotes/details/quote-details.html',
                            controller: 'CommonQuoteRequestDetailsQuoteDetailsController',
                            controllerAs: 'vm'
                        }
                    },
                    {
                        name: 'feed@' + config.baseRoute + '.list.details',
                        value: {
                            templateUrl: '/modules/common/quote-requests/details/quotes/details/event/feed.html',
                            controller: 'CommonQuoteRequestDetailsQuoteDetailsEventFeedController',
                            controllerAs: 'vm'
                        }
                    }
                ]),
                resolve: {
                    quoteResponse: function (apiClient, $stateParams) {
                        return apiClient.get($stateParams.quoteResource.decode(), {datagroup: 'details'}).then(function (quoteResponse) {
                            return quoteResponse || apiClient.noResourceFound();
                        });
                    },
                    eventCollectionResponse: function(apiClient, quoteResponse) {
                        return apiClient.get(quoteResponse.getLink('events'), { datagroup: 'details' }).then(function(response) {
                            return response || apiClient.noResourceFound();
                        })
                    },
                    messageCollectionResponse: function(apiClient, quoteResponse) {
                        return apiClient.get(quoteResponse.getLink('messages')).then(function(response) {
                            return response || apiClient.noResourceFound();
                        });
                    },
                    quoteRequestResponse: function(apiClient, quoteResponse) {
                        return apiClient.get(quoteResponse.getLink('quoterequest')).then(function(response) {
                            return response || apiClient.noResourceFound();
                        });
                    },
                    siteResponse: function(apiClient, quoteRequestResponse) {
                        return quoteRequestResponse.getResource('site') || apiClient.noResourceFound();
                    },
                    config: function(userManager) {
                        return {
                            isHelpdesk: userManager.hasPermission('site_permission_helpdesk')
                        };
                    },
                    fileCollectionResponse: function(apiClient, quoteResponse, $stateParams) {
                        return apiClient.get(quoteResponse.getLink('files'), {
                            order: $stateParams.fileOrder,
                            page: $stateParams.filePage
                        }).then(function(response) {
                            return response || apiClient.noResourceFound();
                        });
                    },
                    quoteLink: function (apiClient, quoteResponse) {
                        if (quoteResponse.getLink('quote-link')) {
                            return apiClient.get(quoteResponse.getLink('quote-link')).then(function (response) {
                                return response || apiClient.noResourceFound();
                            });
                        }

                        return null;
                    }
                },
                ncyBreadcrumb: {
                    label: '{{ ::$resolves().quoteRequestResponse.summary }}'
                }
            })
            .state(config.baseRoute + '.list.details.quote', {
                parent: config.baseRoute + '.list.details',
                abstract: true,
                ncyBreadcrumb: {
                    label: '{{ ::$resolves().quoteResponse.getLinkAttribute("serviceprovider", "title") }}'
                }
            })
            .state(config.baseRoute + '.list.details.quote.contacts', {
                url: '/contacts',
                parent: config.baseRoute + '.list.details.quote',
                views: getView('info@' + config.baseRoute + '.list.details', {
                    templateUrl: '/modules/common/contacts/contacts.html',
                    controller: 'CommonContactsController',
                    controllerAs: 'vm'
                }),
                resolve: {
                    entityResponse: function (quoteResponse) {
                        return quoteResponse;
                    },
                    contactCollectionResponse: function(apiClient, quoteResponse) {
                        return apiClient.get(quoteResponse.getLink('contacts')).then(function(response) {
                            return response || (quoteResponse.getLink('contacts') && apiClient.noResourceFound());
                        });
                    },
                    config: function(config) {
                        return angular.extend(config, {
                            entityName: 'QUOTE'
                        });
                    },
                },
                ncyBreadcrumb: getLabel('QUOTE_CONTACTS'),
                scroll: false
            })
            .state(config.baseRoute + '.list.details.quote.site-notes', {
                url: '/site-notes',
                parent: config.baseRoute + '.list.details.quote',
                views: getView('info@' + config.baseRoute + '.list.details', {
                    templateUrl: '/modules/common/site-notes/site-notes-list.html',
                    controller: 'CommonSiteNotesController',
                    controllerAs: 'vm'
                }),
                resolve: {
                    siteNoteCollectionResponse: function(apiClient, siteResponse) {
                        return apiClient.get(siteResponse.getLink('notes'), { shared: 'true' }).then(function(response) {
                            return response || apiClient.noResourceFound();
                        })
                    }
                },
                ncyBreadcrumb: getLabel('SITE_NOTES'),
                scroll: false
            });
    }

    function registerRoutesDetailsFiles() {
        $stateProvider
            .state(config.baseRoute + '.list.details.quote.files', {
                url: '/files',
                parent: config.baseRoute + '.list.details.quote',
                views: getView('info@' + config.baseRoute + '.list.details', {
                    templateUrl: '/modules/common/files/files-list.html',
                    controller: 'CommonFilesListController',
                    controllerAs: 'vm'
                }),
                params: {
                    documentType: null
                },
                resolve: {
                    tagCollectionResponse: function(apiClient, rootResourceResponse) {
                        return apiClient.get(rootResourceResponse.getLink('tags'), {limit: 30}).then(function(response) {
                            return response || apiClient.noResourceFound();
                        });
                    },
                    fileCollectionResponse: function($resolve, apiClient, quoteResponse, $state, lodash, $stateParams) {
                        return apiClient.get(quoteResponse.getLink('files'), {
                            order: $stateParams.fileOrder,
                            page: $stateParams.filePage
                        }).then(function(response) {
                            return response || apiClient.noResourceFound();
                        });
                    },
                    integrationDocTypesResponse: function() {
                        return null;
                    },
                    documentTypeCollectionResponse : function (apiClient, rootResourceResponse) {
                        return apiClient.get(rootResourceResponse.getLink('documenttypes'), {status: 'active', order: 'name'})
                            .then(function (response) {
                                return response || apiClient.noResourceFound();
                            });
                    },
                    jobResponse: function () {
                        return null;
                    },
                    config: function(config) {
                        return angular.extend(config, {
                            showDownloadButton: true,
                            showQuoteRequest: true
                        });
                    },
                    serviceProviderResponse: function () {
                        return null;
                    },
                    operativeCollectionResponse: function () {
                        return null;
                    }
                },
                ncyBreadcrumb: getLabel('FILES'),
                scroll: false
            })
            .state(config.baseRoute + '.list.details.quote.files.add', {
                url: '/add',
                parent: config.baseRoute + '.list.details.quote.files',
                views: getView(config.baseBookViewRight, {
                    templateUrl: '/modules/common/files/add-edit/files-add-edit.html',
                    controller: 'CommonFilesAddEditController',
                    controllerAs: 'vm'
                }),
                resolve: {
                    fileResponse: function() {
                        return {};
                    },
                    config: function(fileCollectionResponse, siteResponse, quoteResponse) {
                        return {
                            entitySite: siteResponse,
                            fileCreateLink: fileCollectionResponse.getLink('self'),
                            showVisibility: true
                        };
                    }
                },
                ncyBreadcrumb: getLabel('FILES_ADD'),
                scroll: false
            })
            .state(config.baseRoute + '.list.details.quote.files.details', {
                url: '/{fileResource}',
                parent: config.baseRoute + '.list.details.quote.files',
                views: getView('files@' + config.baseRoute + '.list.details', {
                    templateUrl: '/modules/common/files/details/files-details.html',
                    controller: 'CommonFilesDetailsController',
                    controllerAs: 'vm'
                }),
                resolve: {
                    fileResponse: function(apiClient, $stateParams) {
                        return apiClient.get($stateParams.fileResource.decode(), {datagroup: 'details'}).then(function(response) {
                            return response || apiClient.noResourceFound();
                        });
                    },
                    config: function (config) {
                        return angular.extend(config, {
                            showVisibility: true
                        });
                    }
                },
                ncyBreadcrumb: {
                    label: '{{ ::$resolves().fileResponse.title }}'
                },
                scroll: false
            })
            .state(config.baseRoute + '.list.details.quote.files.details.edit', {
                url: '/edit',
                parent: config.baseRoute + '.list.details.quote.files.details',
                views: getView(config.baseBookViewRight, {
                    templateUrl: '/modules/common/files/add-edit/files-add-edit.html',
                    controller: 'CommonFilesAddEditController',
                    controllerAs: 'vm'
                }),
                resolve: {
                    config: function (config, quoteResponse) {
                        return angular.extend(config, {
                            showVisibility: true,
                            associatedEntity: quoteResponse,
                            type: 'quote',
                        });
                    }
                },
                ncyBreadcrumb: getLabel('FILE_EDIT')
            });
    }

    function registerRoutesDetailsWorkflow() {
        $stateProvider
            .state(config.baseRoute + '.list.details.quote.workflow', {
                url: '/workflow',
                parent: config.baseRoute + '.list.details.quote',
                views: getView('info@' + config.baseRoute + '.list.details', {
                    templateUrl: '/modules/common/quote-requests/details/quotes/details/workflow/workflow.html',
                    controller: 'CommonQuoteRequestDetailsQuoteDetailsWorkflowController',
                    controllerAs: 'vm'
                }),
                resolve: {
                    scopeData: function() { return null; },
                    formData: function() { return null; },
                    hasWorkflowLink: function($state, $stateParams, messenger, quoteResponse) {
                        if ($state.next.name.indexOf('.workflow.') > -1) {
                            var workflowLink = $state.next.name.split('.workflow.')[1];
                            // set workflow link to the actual link defined in response
                            switch (workflowLink) {
                                case 'hold':
                                    workflowLink = 'put-on-hold';
                                    break;
                                }

                            return checkLink($state, $stateParams, messenger, quoteResponse, workflowLink);
                        }

                        return true;
                    },
                },
                ncyBreadcrumb: getLabel('WORKFLOW'),
                scroll: false
            })
            .state(config.baseRoute + '.list.details.quote.workflow.cancel', {
                url: '/cancel',
                parent: config.baseRoute + '.list.details.quote.workflow',
                views: getView(config.baseBookViewRight, {
                    templateUrl: '/modules/common/quote-requests/details/quotes/details/workflow/_wrapper.html',
                    controller: 'QuoteCancelController',
                    controllerAs: 'vm'
                }),
                ncyBreadcrumb: getLabel('QUOTE_WORKFLOW_CANCEL')
            })
            .state(config.baseRoute + '.list.details.quote.workflow.reopen', {
                url: '/reopen',
                parent: config.baseRoute + '.list.details.quote.workflow',
                views: getView(config.baseBookViewRight, {
                    templateUrl: '/modules/common/quote-requests/details/quotes/details/workflow/_wrapper.html',
                    controller: 'QuoteReopenController',
                    controllerAs: 'vm'
                }),
                ncyBreadcrumb: getLabel('QUOTE_WORKFLOW_REOPEN')
            })
            .state(config.baseRoute + '.list.details.quote.workflow.escalate', {
                url: '/escalate',
                parent: config.baseRoute + '.list.details.quote.workflow',
                views: getView(config.baseBookViewRight, {
                    templateUrl: '/modules/common/quote-requests/details/quotes/details/workflow/_wrapper.html',
                    controller: 'QuoteEscalateController',
                    controllerAs: 'vm'
                }),
                ncyBreadcrumb: getLabel('QUOTE_WORKFLOW_ESCALATE')
            })
            .state(config.baseRoute + '.list.details.quote.workflow.submit', {
                url: '/submit',
                parent: config.baseRoute + '.list.details.quote.workflow',
                views: getView(config.baseBookViewRight, {
                    templateUrl: '/modules/common/quote-requests/details/quotes/details/workflow/_wrapper.html',
                    controller: 'QuoteOperationSubmitController',
                    controllerAs: 'vm'
                }),
                ncyBreadcrumb: getLabel('QUOTE_WORKFLOW_SUBMIT')
            })
            .state(config.baseRoute + '.list.details.quote.workflow.submit-shared', {
                url: '/submit-shared',
                parent: config.baseRoute + '.list.details.quote.workflow',
                views: getView(config.baseBookViewRight, {
                    templateUrl: '/modules/common/quote-requests/details/quotes/details/workflow/_wrapper.html',
                    controller: 'QuoteSubmitSharedController',
                    controllerAs: 'vm'
                }),
                resolve: {
                    p2pResponse: function (apiClient, quoteResponse) {
                        if (quoteResponse.getLink('p2p')) {
                            return apiClient.get(quoteResponse.getLink('p2p')).then(function (response) {
                                return response || apiClient.noResourceFound();
                            });
                        }
                        return null;
                    },
                    quoteCollectionResponse: function(apiClient, quoteRequestResponse, quoteResponse, $stateParams) {
                        var params = {
                            page: $stateParams.quoteOptionsPage,
                            limit: $stateParams.quoteOptionsLimit,
                            datagroup: 'options',
                            id: quoteResponse.id
                        };

                        var attributes = quoteResponse.attributes;

                        if (attributes
                            && attributes.up_share_client
                            && !attributes.down_share_client
                            && quoteResponse.isSubQuote !== true
                        ) {
                            params.includeSubQuotes = true;
                        }

                        return apiClient.get(quoteRequestResponse.getLink('quotes'), params).then(function(response) {
                            return response || apiClient.noResourceFound();
                        });
                    }
                },
                ncyBreadcrumb: getLabel('QUOTE_WORKFLOW_SUBMIT')
            })
            .state(config.baseRoute + '.list.details.quote.workflow.abstain', {
                url: '/abstain',
                parent: config.baseRoute + '.list.details.quote.workflow',
                views: getView(config.baseBookViewRight, {
                    templateUrl: '/modules/common/quote-requests/details/quotes/details/workflow/_wrapper.html',
                    controller: 'QuoteOperationAbstainController',
                    controllerAs: 'vm'
                }),
                ncyBreadcrumb: getLabel('QUOTE_WORKFLOW_ABSTAIN')
            })
            .state(config.baseRoute + '.list.details.quote.workflow.request-extension', {
                url: '/request-extension',
                parent: config.baseRoute + '.list.details.quote.workflow',
                views: getView(config.baseBookViewRight, {
                    templateUrl: '/modules/common/quote-requests/details/quotes/details/workflow/_wrapper.html',
                    controller: 'QuoteExtensionRequestController',
                    controllerAs: 'vm'
                }),
                ncyBreadcrumb: getLabel('QUOTE_WORKFLOW_REQUEST_EXTENSION')
            })
            .state(config.baseRoute + '.list.details.quote.workflow.postpone-reminder-at', {
                url: '/postpone-reminder-at',
                parent: config.baseRoute + '.list.details.quote.workflow',
                views: getView(config.baseBookViewRight, {
                    templateUrl: '/modules/common/quote-requests/details/quotes/details/workflow/_wrapper.html',
                    controller: 'QuotePostponeReminderAtController',
                    controllerAs: 'vm'
                }),
                ncyBreadcrumb: getLabel('QUOTE_WORKFLOW_POSTPONE_REMINDER_AT')
            })
            .state(config.baseRoute + '.list.details.quote.workflow.accept-extension', {
                url: '/accept-extension',
                parent: config.baseRoute + '.list.details.quote.workflow',
                views: getView(config.baseBookViewRight, {
                    templateUrl: '/modules/common/quote-requests/details/quotes/details/workflow/_wrapper.html',
                    controller: 'QuoteExtensionAcceptController',
                    controllerAs: 'vm'
                }),
                ncyBreadcrumb: getLabel('QUOTE_WORKFLOW_ACCEPT_EXTENSION')
            })
            .state(config.baseRoute + '.list.details.quote.workflow.reject-extension', {
                url: '/reject-extension',
                parent: config.baseRoute + '.list.details.quote.workflow',
                views: getView(config.baseBookViewRight, {
                    templateUrl: '/modules/common/quote-requests/details/quotes/details/workflow/_wrapper.html',
                    controller: 'QuoteExtensionRejectController',
                    controllerAs: 'vm'
                }),
                ncyBreadcrumb: getLabel('QUOTE_WORKFLOW_REJECT_EXTENSION')
            })
            .state(config.baseRoute + '.list.details.quote.workflow.cancel-extension', {
                url: '/cancel-extension',
                parent: config.baseRoute + '.list.details.quote.workflow',
                views: getView(config.baseBookViewRight, {
                    templateUrl: '/modules/common/quote-requests/details/quotes/details/workflow/_wrapper.html',
                    controller: 'QuoteExtensionCancelController',
                    controllerAs: 'vm'
                }),
                ncyBreadcrumb: getLabel('QUOTE_WORKFLOW_CANCEL_EXTENSION')
            })
            .state(config.baseRoute + '.list.details.quote.workflow.request-more-information', {
                url: '/request-more-information',
                parent: config.baseRoute + '.list.details.quote.workflow',
                views: getView(config.baseBookViewRight, {
                    templateUrl: '/modules/common/quote-requests/details/quotes/details/workflow/_wrapper.html',
                    controller: 'QuoteAcceptanceMoreInformationController',
                    controllerAs: 'vm'
                }),
                ncyBreadcrumb: getLabel('QUOTE_WORKFLOW_REQUEST_MORE_INFORMATION')
            })
            .state(config.baseRoute + '.list.details.quote.workflow.reject', {
                url: '/reject',
                parent: config.baseRoute + '.list.details.quote.workflow',
                views: getView(config.baseBookViewRight, {
                    templateUrl: '/modules/common/quote-requests/details/quotes/details/workflow/_wrapper.html',
                    controller: 'QuoteAcceptanceRejectController',
                    controllerAs: 'vm'
                }),
                ncyBreadcrumb: getLabel('QUOTE_WORKFLOW_REJECT')
            })
            .state(config.baseRoute + '.list.details.quote.workflow.reject-values', {
                url: '/reject-values',
                parent: config.baseRoute + '.list.details.quote.workflow',
                views: getView(config.baseBookViewRight, {
                    templateUrl: '/modules/common/quote-requests/details/quotes/details/workflow/_wrapper.html',
                    controller: 'QuoteAcceptanceRejectValuesController',
                    controllerAs: 'vm'
                }),
                ncyBreadcrumb: getLabel('QUOTE_WORKFLOW_REJECT_VALUES')
            })
            .state(config.baseRoute + '.list.details.quote.workflow.hold', {
                url: '/hold',
                parent: config.baseRoute + '.list.details.quote.workflow',
                views: getView(config.baseBookViewRight, {
                    templateUrl: '/modules/common/quote-requests/details/quotes/details/workflow/_wrapper.html',
                    controller: 'QuoteAcceptanceHoldController',
                    controllerAs: 'vm'
                }),
                ncyBreadcrumb: getLabel('QUOTE_WORKFLOW_HOLD')
            })
            .state(config.baseRoute + '.list.details.quote.workflow.alarm', {
                url: '/alarm',
                parent: config.baseRoute + '.list.details.quote.workflow',
                views: getView(config.baseBookViewRight, {
                    templateUrl: '/modules/common/quote-requests/details/quotes/details/workflow/_wrapper.html',
                    controller: 'QuoteAlarmController',
                    controllerAs: 'vm'
                }),
                ncyBreadcrumb: {
                    label: "{{ ::($resolves().quoteResponse.alarmAt ? 'QUOTE_WORKFLOW_ALARM' : 'QUOTE_WORKFLOW_ALARM_SETNEW') | translate }}"
                }
            })
            .state(config.baseRoute + '.list.details.quote.workflow.accept', {
                url: '/accept',
                params: {
                    quoteOptionsPage: '1',
                    quoteOptionsLimit: '30'
                },
                parent: config.baseRoute + '.list.details.quote.workflow',
                views: getView(config.baseBookViewRight, {
                    templateUrl: '/modules/common/quote-requests/details/quotes/details/workflow/accept.html',
                    controller: 'QuoteAcceptanceAcceptController',
                    controllerAs: 'vm'
                }),
                resolve: {
                    p2pResponse: function (apiClient, $stateParams, modulesService, quoteResponse) {
                        if (quoteResponse.getLink('p2p')) {
                            return apiClient.get(quoteResponse.getLink('p2p')).then(function (response) {
                                return response || apiClient.noResourceFound();
                            });
                        }
                        return null;
                    },
                    accountingElementsResponse: function(apiClient, quoteResponse) {
                        if (quoteResponse.getLink('p2p-accounting-elements')) {
                            return apiClient.get(quoteResponse.getLink('p2p-accounting-elements'));
                        }
                        return null;
                    },
                    quoteOptionCollectionResponse: function(apiClient, quoteResponse, $stateParams) {
                        var params = {
                            page: $stateParams.quoteOptionsPage,
                            limit: $stateParams.quoteOptionsLimit,
                            acceptQuote: true
                        };

                        return apiClient.get(quoteResponse.getLink('options'), params).then(function(response) {
                            return response || apiClient.noResourceFound();
                        });
                    },
                    jobGroupRequired: function(apiClient, rootResourceResponse) {
                        return apiClient.get(rootResourceResponse.getLink('jobsettings')).then(function(response) {
                            return response ? response.requireJobGroup : apiClient.noResourceFound();
                        });
                    },
                    jobGroupCollectionResponse: function(apiClient, rootResourceResponse) {
                        return apiClient.get(rootResourceResponse.getLink('jobgroups'), {limit: 999999, fetchHierarchy: false}).then(function(response) {
                            return response || apiClient.noResourceFound();
                        });
                    },
                    statutoryTypeCollectionResponse: function(apiClient, rootResourceResponse) {
                        return apiClient.get(rootResourceResponse.getLink('statutorytypes')).then(function(response) {
                            return response || apiClient.noResourceFound();
                        });
                    },
                    serviceProviderResponse: function(apiClient, quoteResponse) {
                        return apiClient.get(quoteResponse.getLink('serviceprovider'), {datagroup: 'details'}).then(function(response) {
                            return response || apiClient.noResourceFound();
                        });
                    },
                    operativeResponse: function(apiClient, serviceProviderResponse) {
                        if (serviceProviderResponse.getLink('operatives')) {
                            return apiClient.get(serviceProviderResponse.getLink('operatives'), { permission: 'operative_permission_receive_jobs', includeServiceTypes: true }).then(function (response) {
                                return response || apiClient.noResourceFound();
                            });
                        }

                        return [];
                    },
                    partnershipResponse: function(apiClient, serviceProviderResponse, siteResponse) {
                        return apiClient.get(siteResponse.getLink('partnerships'), {serviceProviderId: serviceProviderResponse.id}).then(function(response) {
                            if (!response.partnerships[0]) {
                                return apiClient.noResourceFound();
                            }

                            return response.partnerships[0];
                        });
                    },
                    priorityCollectionResponse: function(apiClient, rootResourceResponse, siteResponse, serviceProviderResponse, partnershipResponse) {
                        var priorities;

                        // accepting a quote creates a job, so job priorities are required at this stage
                        priorityLinkParameters = {'active': true, 'entityTypes': 'entity_type_all,entity_type_job'};

                        return apiClient.get(partnershipResponse.getLink('priorities'), priorityLinkParameters).then(function(response) {
                            if (response.count > 0) {
                                return response;
                            }

                            return apiClient.get(serviceProviderResponse.getLink('priorities'), priorityLinkParameters).then(function(response) {
                                if (response.count > 0) {
                                    return response;
                                }

                                return apiClient.get(siteResponse.getLink('priorities'), priorityLinkParameters).then(function(response) {
                                    if (response.count > 0) {
                                        return response;
                                    }

                                    return apiClient.get(rootResourceResponse.getLink('priorities'), priorityLinkParameters).then(function(response) {
                                        if (response.count > 0) {
                                            return response;
                                        }

                                        return apiClient.noResourceFound();
                                    });
                                });
                            });
                        });
                    },
                    settingCollectionResponse: function(apiClient, rootResourceResponse) {
                        return apiClient.get(rootResourceResponse.getLink('jobsettings')).then(function(response) {
                            return response || apiClient.noResourceFound();
                        });
                    },
                    jobRequisiteCollectionResponse: function(siteResponse, serviceProviderResponse, user, userManager, apiClient) {
                        return apiClient.get(user.getLink('job-requisites'), {
                            active: 1,
                            optIn: true,
                            optOut: true,
                            'option[]': ['quotedWorksOption'],
                            site: siteResponse.id,
                            serviceProvider: serviceProviderResponse.id
                        });
                    },
                    siteAssociateTypesCollectionResponse: function(rootResourceResponse, siteResponse, apiClient, quoteResponse, elbSettings) {
                        var valueApprovalSiteAssociateType = elbSettings.getSetting('quote_value_approval_site_associate_type');

                        if (quoteResponse.getLink('request-value-approval') && valueApprovalSiteAssociateType && valueApprovalSiteAssociateType.value) {
                            return apiClient.get(rootResourceResponse.getLink('siteassociatetypes'), {
                                site: siteResponse.id,
                                id: valueApprovalSiteAssociateType.value
                            });
                        } else {
                            return null;
                        }
                    },
                    documentTypesCollectionResponse: function (apiClient, rootResourceResponse) {
                        if (rootResourceResponse.getLink('documenttypes')) {
                            return apiClient.get(rootResourceResponse.getLink('documenttypes'), {status: 'active', limit:999}).then(function (response) {
                                return response;
                            });
                        }
                    },
                    locationCollectionResponse: function (apiClient, siteResponse) {
                        if (siteResponse.getLink('locations')) {
                            return apiClient.get(siteResponse.getLink('locations')).then(function (response) {
                                return response || apiClient.noResourceFound();
                            });
                        }
                    },
                    quoteRequestResponse: function ($resolves, apiClient, quoteResponse) {
                        return $resolves().quoteRequestResponse || apiClient.get(quoteResponse.getLink('quoterequest')).then(function(response) {
                            return response || apiClient.noResourceFound();
                        });
                    }
                },
                ncyBreadcrumb: getLabel('QUOTE_WORKFLOW_ACCEPT')
            })
            .state(config.baseRoute + '.list.details.quote.workflow.accept-values', {
                url: '/accept-values',
                params: {
                    quoteOptionsPage: '1',
                    quoteOptionsLimit: '30'
                },
                resolve: {
                    quoteOptionCollectionResponse: function(apiClient, quoteResponse, $stateParams) {
                        var params = {
                            page: $stateParams.quoteOptionsPage,
                            limit: $stateParams.quoteOptionsLimit,
                            valueApproval: true
                        };

                        return apiClient.get(quoteResponse.getLink('options'), params).then(function(response) {
                            return response || apiClient.noResourceFound();
                        });
                    },
                },
                parent: config.baseRoute + '.list.details.quote.workflow',
                views: getView(config.baseBookViewRight, {
                    templateUrl: '/modules/common/quote-requests/details/quotes/details/workflow/accept-values.html',
                    controller: 'QuoteAcceptanceAcceptValuesController',
                    controllerAs: 'vm'
                }),
                ncyBreadcrumb: getLabel('QUOTE_WORKFLOW_ACCEPT_VALUES')
            })
            .state(config.baseRoute + '.list.details.quote.workflow.escalate-approval', {
                url: '/approval/escalate',
                parent: config.baseRoute + '.list.details.quote.workflow',
                views: getView(config.baseBookViewRight, {
                    templateUrl: '/modules/common/quote-requests/details/quotes/details/workflow/_wrapper.html',
                    controller: 'QuoteApprovalEscalateController',
                    controllerAs: 'vm'
                }),
                ncyBreadcrumb: getLabel('QUOTE_WORKFLOW_ESCALATE_APPROVAL')
            })
            .state(config.baseRoute + '.list.details.quote.workflow.reassign-operative', {
                url: '/reassign',
                parent: config.baseRoute + '.list.details.quote.workflow',
                views: getView(config.baseBookViewRight, {
                    templateUrl: '/modules/common/quote-requests/details/quotes/details/workflow/_wrapper.html',
                    controller: 'QuoteReassignOperativeController',
                    controllerAs: 'vm'
                }),
                resolve: {
                    serviceProviderResponse: function(apiClient, quoteResponse) {
                        return quoteResponse.getResource('serviceprovider') || apiClient.noResourceFound();
                    },
                    operativeCollectionResponse: function(apiClient, serviceProviderResponse, siteResponse) {
                        var params = {
                            permission: 'operative_permission_receive_quotes',
                            includeServiceTypes: true
                        };

                        if (siteResponse) {
                            params.siteId = siteResponse.id;
                        }

                        if (serviceProviderResponse.getLink('operatives')) {
                            return apiClient.get(serviceProviderResponse.getLink('operatives'), params).then(function (response) {
                                return response || apiClient.noResourceFound();
                            });
                        }

                        return [];
                    },
                    formData: function(quoteResponse) {
                        return {
                            note: null,
                            currentOperativeLink: quoteResponse.getLink('operative'),
                            currentOperative: quoteResponse.getLinkAttribute('operative', 'title'),
                            _links: {}
                        };
                    },
                    scopeData: function(siteResponse, operativeCollectionResponse) {
                        var returnObject = {
                            action: 'reassign-operative',
                            translation: 'QUOTE_WORKFLOW_REASSIGN_OPERATIVE',
                            noteRequired: false,
                            partial: 'reassign-operative',
                            siteResponse: siteResponse,
                            operativeSelectModel: {
                                link: operativeCollectionResponse.getLink('self'),
                                response: operativeCollectionResponse,
                                responseKeyPath: 'operatives',
                                required: true,
                                itemHrefPath: '_links.self.href',
                                itemValuePath: '_links.user.title'
                            }
                        };

                        return returnObject;
                    }
                },
                ncyBreadcrumb: getLabel('QUOTE_WORKFLOW_REASSIGN_ASSIGNMENT')
            })
    }

    function registerRoutesDetailsOptions() {
        $stateProvider
            .state(config.baseRoute + '.list.details.quote.options', {
                url: '/options',
                params: {
                    quoteOptionsPage: '1',
                    quoteOptionsLimit: '30'
                },
                parent: config.baseRoute + '.list.details.quote',
                views: getView('info@' + config.baseRoute + '.list.details', {
                    templateUrl: '/modules/common/quote-requests/details/quotes/details/options/options.html',
                    controller: 'CommonQuoteRequestDetailsQuoteDetailsOptionsController',
                    controllerAs: 'vm'
                }),
                resolve: {
                    quoteOptionCollectionResponse: function(apiClient, quoteRequestResponse, quoteResponse, $stateParams) {
                        var params = {
                            page: $stateParams.quoteOptionsPage,
                            limit: $stateParams.quoteOptionsLimit,
                            datagroup: 'options',
                            id: quoteResponse.id,
                        };

                        var attributes = quoteResponse.attributes;

                        if (
                            attributes
                            && attributes.up_share_client
                            && !attributes.down_share_client
                            && quoteResponse.isSubQuote !== true
                        ) {
                            params.includeSubQuotes = true;
                        }

                        return apiClient.get(quoteRequestResponse.getLink('quotes'), params).then(function(response) {
                            return response || apiClient.noResourceFound();
                        });
                    },
                    jobResponse : function () {
                        return null;
                    }
                },
                ncyBreadcrumb: getLabel('QUOTE_OPTIONS'),
                scroll: false
            })
            .state(config.baseRoute + '.list.details.quote.options.add', {
                url: '/add',
                parent: config.baseRoute + '.list.details.quote.options',
                views: getView(config.baseBookViewRight, {
                    templateUrl: '/modules/common/quote-requests/details/quotes/details/options/add-edit/options-form.html',
                    controller: 'CommonQuoteRequestDetailsQuoteDetailsOptionsAddEditController',
                    controllerAs: 'vm'
                }),
                resolve: {
                    quoteOptionResponse: function() {
                        return {};
                    }
                },
                ncyBreadcrumb: getLabel('QUOTE_OPTION_ADD')
            });
    }

    function registerRoutesDetailsOptionsDetails() {
        $stateProvider
            .state(config.baseRoute + '.list.details.quote.options.details', {
                url: '/{quoteOptionResource}',
                parent: config.baseRoute + '.list.details.quote.options',
                abstract: true,
                views: getView('options@' + config.baseRoute + '.list.details', {
                    templateUrl: '/modules/common/quote-requests/details/quotes/details/options/details/option-details.html',
                    controller: 'CommonQuoteRequestDetailsQuoteDetailsOptionDetailsController',
                    controllerAs: 'vm'
                }),
                resolve: {
                    quoteOptionResponse: function(apiClient, $stateParams) {
                        return apiClient.get($stateParams.quoteOptionResource.decode(), {datagroup: 'details'}).then(function(response) {
                            return response || apiClient.noResourceFound();
                        })
                    },
                    quoteOptionFileCollectionResponse: function() {
                        return null;
                    }
                },
                ncyBreadcrumb: {
                    label: '{{ ::$resolves().quoteOptionResponse.summary }}'
                },
                scroll: false
            });
    }

    function registerRoutesDetailsOptionsDetailsInfo() {
        $stateProvider
            .state(config.baseRoute + '.list.details.quote.options.details.info', {
                url: '/info',
                parent: config.baseRoute + '.list.details.quote.options.details',
                views: getView('info@' + config.baseRoute + '.list.details.quote.options.details', {
                    templateUrl: '/modules/common/quote-requests/details/quotes/details/options/details/info/info.html',
                    controller: 'CommonQuoteRequestDetailsQuoteDetialsOptionDetailsInfoController',
                    controllerAs: 'vm'
                }),
                resolve: {
                    quoteResponse: function (quoteResponse) {
                        return quoteResponse;
                    }
                },
                ncyBreadcrumb: getLabel('INFO'),
                scroll: false
            })
            .state(config.baseRoute + '.list.details.quote.options.details.info.edit', {
                url: '/edit',
                parent: config.baseRoute + '.list.details.quote.options.details.info',
                views: getView('info@' + config.baseRoute + '.list.details.quote.options.details', {
                    templateUrl: '/modules/common/quote-requests/details/quotes/details/options/add-edit/options-form.html',
                    controller: 'CommonQuoteRequestDetailsQuoteDetailsOptionsAddEditController',
                    controllerAs: 'vm'
                }),
                ncyBreadcrumb: getLabel('QUOTE_OPTION_EDIT')
            });
    }

    function registerRoutesDetailsOptionsDetailsLabour() {
        $stateProvider
            .state(config.baseRoute + '.list.details.quote.options.details.labour', {
                url: '/labour',
                parent: config.baseRoute + '.list.details.quote.options.details',
                views: getView('info@' + config.baseRoute + '.list.details.quote.options.details', {
                    templateUrl: '/modules/common/quote-requests/details/quotes/details/options/details/labours/labours.html',
                    controller: 'CommonQuoteRequestDetailsQuoteDetailsOptionDetailsLabourController',
                    controllerAs: 'vm'
                }),
                resolve: {
                    quoteOptionLabourCollectionResponse: function(apiClient, quoteOptionResponse) {
                        return apiClient.get(quoteOptionResponse.getLink('labours'), {datagroup: 'list'}).then(function(response) {
                            return response || apiClient.noResourceFound();
                        });
                    },
                    labourTypeCollectionResponse: function(apiClient, rootResourceResponse, siteResponse) {
                        if (rootResourceResponse.getLink('labourtypes')) {
                            var params = {
                                active: true,
                                pricingSchedule: siteResponse.pricingScheduleId || null
                            };

                            return apiClient.get(rootResourceResponse.getLink('labourtypes'), params).then( function(response) {
                                return response || null;
                            });
                        }
                    },
                    labourRatesCollectionResponse:  function(apiClient, siteResponse) {
                        if (siteResponse.getLink('labourrates')) {
                            return apiClient.get(siteResponse.getLink('labourrates')).then( function(response) {
                                return response || null;
                            });
                        }
                    }
                },
                ncyBreadcrumb: getLabel('QUOTE_OPTION_LABOURS'),
                scroll: false
            })
            .state(config.baseRoute + '.list.details.quote.options.details.labour.add', {
                url: '/add',
                parent: config.baseRoute + '.list.details.quote.options.details.labour',
                views: getView('info@' + config.baseRoute + '.list.details.quote.options.details', {
                    templateUrl: '/modules/common/quote-requests/details/quotes/details/options/details/labours/add-edit/labour-form.html',
                    controller: 'CommonQuoteRequestDetailsQuoteDetailsOptionDetailsLabourAddEditController',
                    controllerAs: 'vm'
                }),
                resolve: {
                    quoteOptionLabourResponse: function() {
                        return {};
                    }
                },
                ncyBreadcrumb: getLabel('QUOTE_OPTION_LABOUR_ADD')
            })
            .state(config.baseRoute + '.list.details.quote.options.details.labour.edit', {
                url: '/{quoteOptionLabourResource}',
                parent: config.baseRoute + '.list.details.quote.options.details.labour',
                views: getView('info@' + config.baseRoute + '.list.details.quote.options.details', {
                    templateUrl: '/modules/common/quote-requests/details/quotes/details/options/details/labours/add-edit/labour-form.html',
                    controller: 'CommonQuoteRequestDetailsQuoteDetailsOptionDetailsLabourAddEditController',
                    controllerAs: 'vm'
                }),
                resolve: {
                    quoteOptionLabourResponse: function(apiClient, $stateParams) {
                        return apiClient.get($stateParams.quoteOptionLabourResource.decode(), {datagroup: 'details'}).then(function(response) {
                            return response || apiClient.noResourceFound();
                        });
                    }
                },
                ncyBreadcrumb: getLabel('QUOTE_OPTION_LABOUR_EDIT')
            });
    }

    function registerRoutesDetailsOptionsDetailsMaterials() {
        $stateProvider
            .state(config.baseRoute + '.list.details.quote.options.details.materials', {
                url: '/materials',
                parent: config.baseRoute + '.list.details.quote.options.details',
                views: getView('info@' + config.baseRoute + '.list.details.quote.options.details', {
                    templateUrl: '/modules/common/quote-requests/details/quotes/details/options/details/materials/materials.html',
                    controller: 'CommonQuoteRequestDetailsQuoteDetailsOptionDetailsMaterialController',
                    controllerAs: 'vm'
                }),
                resolve: {
                    quoteOptionMaterialCollectionResponse: function(apiClient, quoteOptionResponse) {
                        return apiClient.get(quoteOptionResponse.getLink('materials'), {datagroup: 'details'}).then(function(response) {
                            return response || apiClient.noResourceFound();
                        });
                    }
                },
                ncyBreadcrumb: getLabel('QUOTE_OPTION_MATERIALS'),
                scroll: false
            })
            .state(config.baseRoute + '.list.details.quote.options.details.materials.add', {
                url: '/add',
                parent: config.baseRoute + '.list.details.quote.options.details.materials',
                views: getView('info@' + config.baseRoute + '.list.details.quote.options.details', {
                    templateUrl: '/modules/common/quote-requests/details/quotes/details/options/details/materials/add-edit/material-form.html',
                    controller: 'CommonQuoteRequestDetailsQuoteDetailsOptionDetailsMaterialAddEditController',
                    controllerAs: 'vm'
                }),
                resolve: {
                    quoteOptionMaterialResponse: function() {
                        return {};
                    }
                },
                ncyBreadcrumb: getLabel('QUOTE_OPTION_MATERIAL_ADD')
            })
            .state(config.baseRoute + '.list.details.quote.options.details.materials.edit', {
                url: '/{quoteOptionMaterialResource}',
                parent: config.baseRoute + '.list.details.quote.options.details.materials',
                views: getView('info@' + config.baseRoute + '.list.details.quote.options.details', {
                    templateUrl: '/modules/common/quote-requests/details/quotes/details/options/details/materials/add-edit/material-form.html',
                    controller: 'CommonQuoteRequestDetailsQuoteDetailsOptionDetailsMaterialAddEditController',
                    controllerAs: 'vm'
                }),
                resolve: {
                    quoteOptionMaterialResponse: function(apiClient, $stateParams) {
                        return apiClient.get($stateParams.quoteOptionMaterialResource.decode(), {datagroup: 'details'}).then(function(response) {
                            return response || apiClient.noResourceFound();
                        });
                    }
                },
                ncyBreadcrumb: getLabel('QUOTE_OPTION_MATERIAL_EDIT')
            });
    }

    function registerRoutesDetailsOptionsDetailsOther() {
        $stateProvider
            .state(config.baseRoute + '.list.details.quote.options.details.other', {
                url: '/other',
                parent: config.baseRoute + '.list.details.quote.options.details',
                views: getView('info@' + config.baseRoute + '.list.details.quote.options.details', {
                    templateUrl: '/modules/common/quote-requests/details/quotes/details/options/details/others/others.html',
                    controller: 'CommonQuoteRequestDetailsQuoteDetailsOptionDetailsOtherController',
                    controllerAs: 'vm'
                }),
                resolve: {
                    quoteOptionOtherCollectionResponse: function(apiClient, quoteOptionResponse) {
                        return apiClient.get(quoteOptionResponse.getLink('others'), {datagroup: 'details'}).then(function(response) {
                            return response || apiClient.noResourceFound();
                        });
                    }
                },
                ncyBreadcrumb: getLabel('QUOTE_OPTION_OTHERS'),
                scroll: false
            })
            .state(config.baseRoute + '.list.details.quote.options.details.other.add', {
                url: '/add',
                parent: config.baseRoute + '.list.details.quote.options.details.other',
                views: getView('info@' + config.baseRoute + '.list.details.quote.options.details', {
                    templateUrl: '/modules/common/quote-requests/details/quotes/details/options/details/others/add-edit/other-form.html',
                    controller: 'CommonQuoteRequestDetailsQuoteDetailsOptionDetailsOtherAddEditController',
                    controllerAs: 'vm'
                }),
                resolve: {
                    quoteOptionOtherResponse: function() {
                        return {};
                    }
                },
                ncyBreadcrumb: getLabel('QUOTE_OPTION_OTHER_ADD')
            })
            .state(config.baseRoute + '.list.details.quote.options.details.other.edit', {
                url: '/{quoteOptionOtherResource}',
                parent: config.baseRoute + '.list.details.quote.options.details.other',
                views: getView('info@' + config.baseRoute + '.list.details.quote.options.details', {
                    templateUrl: '/modules/common/quote-requests/details/quotes/details/options/details/others/add-edit/other-form.html',
                    controller: 'CommonQuoteRequestDetailsQuoteDetailsOptionDetailsOtherAddEditController',
                    controllerAs: 'vm'
                }),
                resolve: {
                    quoteOptionOtherResponse: function(apiClient, $stateParams) {
                        return apiClient.get($stateParams.quoteOptionOtherResource.decode(), {datagroup: 'details'}).then(function(response) {
                            return response || apiClient.noResourceFound();
                        });
                    }
                },
                ncyBreadcrumb: getLabel('QUOTE_OPTION_OTHER_EDIT')
            });
    }

    function registerRoutesDetailsOptionsDetailsFiles() {
        $stateProvider
            .state(config.baseRoute + '.list.details.quote.options.details.files', {
                url: '/files',
                parent: config.baseRoute + '.list.details.quote.options.details',
                views: getView('info@' + config.baseRoute + '.list.details.quote.options.details', {
                    templateUrl: '/modules/common/files/files-list.html',
                    controller: 'CommonFilesListController',
                    controllerAs: 'vm'
                }),
                params: {
                    documentType: null
                },
                resolve: {
                    tagCollectionResponse: function(apiClient, rootResourceResponse) {
                        return apiClient.get(rootResourceResponse.getLink('tags'), {limit: 30}).then(function(response) {
                            return response || apiClient.noResourceFound();
                        });
                    },
                    fileCollectionResponse: function($resolve, apiClient, quoteOptionResponse, $state, lodash, $stateParams) {
                        return apiClient.get(quoteOptionResponse.getLink('files'), {
                            order: $stateParams.fileOrder,
                            page: $stateParams.filePage
                        }).then(function(response) {
                            return response || apiClient.noResourceFound();
                        });
                    },
                    integrationDocTypesResponse: function() {
                        return null;
                    },
                    documentTypeCollectionResponse : function (apiClient, rootResourceResponse) {
                        return apiClient.get(rootResourceResponse.getLink('documenttypes'), {status: 'active', order: 'name'})
                            .then(function (response) {
                                return response || apiClient.noResourceFound();
                            });
                    },
                    config: function (config) {
                        return angular.extend(config, {
                            showDownloadButton: true,
                            showQuoteRequest: true
                        });
                    },
                    serviceProviderResponse: function () {
                        return null;
                    },
                    operativeCollectionResponse: function () {
                        return null;
                    }
                },
                ncyBreadcrumb: getLabel('FILES'),
                scroll: false
            })
            .state(config.baseRoute + '.list.details.quote.options.details.files.add', {
                url: '/add',
                parent: config.baseRoute + '.list.details.quote.options.details.files',
                views: getView('files@' + config.baseRoute + '.list.details', {
                    templateUrl: '/modules/common/files/add-edit/files-add-edit.html',
                    controller: 'CommonFilesAddEditController',
                    controllerAs: 'vm'
                }),
                resolve: {
                    fileResponse: function() {
                        return {};
                    },
                    config: function(fileCollectionResponse, siteResponse) {
                        return {
                            entitySite: siteResponse,
                            fileCreateLink: fileCollectionResponse.getLink('self'),
                            showVisibility: true,
                            reloadRoute: '^.^.^.^.^'
                        };
                    }
                },
                ncyBreadcrumb: getLabel('FILES_ADD'),
                scroll: false
            })
            .state(config.baseRoute + '.list.details.quote.options.details.files.details', {
                url: '/{fileResource}',
                parent: config.baseRoute + '.list.details.quote.options.details.files',
                views: getView('files@' + config.baseRoute + '.list.details', {
                    templateUrl: '/modules/common/files/details/files-details.html',
                    controller: 'CommonFilesDetailsController',
                    controllerAs: 'vm'
                }),
                resolve: {
                    fileResponse: function(apiClient, $stateParams) {
                        return apiClient.get($stateParams.fileResource.decode(), {datagroup: 'details'}).then(function(response) {
                            return response || apiClient.noResourceFound();
                        });
                    },
                    config: function (config) {
                        return angular.extend(config, {
                            showVisibility: true
                        });
                    }
                },
                ncyBreadcrumb: {
                    label: '{{ ::$resolves().fileResponse.title }}'
                },
                scroll: false
            })
            .state(config.baseRoute + '.list.details.quote.options.details.files.details.edit', {
                url: '/edit',
                parent: config.baseRoute + '.list.details.quote.options.details.files.details',
                views: getView('files@' + config.baseRoute + '.list.details', {
                    templateUrl: '/modules/common/files/add-edit/files-add-edit.html',
                    controller: 'CommonFilesAddEditController',
                    controllerAs: 'vm'
                }),
                resolve: {
                    config: function (config, quoteOptionResponse) {
                        return angular.extend(config, {
                            showVisibility: true,
                            associatedEntity: quoteOptionResponse,
                            type: 'quote-option',
                            reloadRoute: '^.^.^.^.^.^'
                        });
                    }
                },
                ncyBreadcrumb: getLabel('FILE_EDIT')
            });
    }

    function registerRoutesAddSubQuote() {
        $stateProvider
            .state(config.baseRoute + '.list.details.add-sub-quotes', {
                url: '/add-sub-quotes/{quoteRequestResource}',
                parent: config.baseRoute + '.list',
                views: getView(config.baseBookViewRight, {
                    templateUrl: '/modules/common/quote-requests/add-sub-quotes/add-sub-quotes.html',
                    controller: 'CommonQuoteRequestAddSubQuotesController',
                    controllerAs: 'vm'
                }),
                ncyBreadcrumb: getLabel('ADD_SUB_QUOTES'),
                resolve: {
                    quoteRequestResponse: function($stateParams, apiClient) {
                        return apiClient.get($stateParams.quoteRequestResource.decode()).then(function(quoteRequestResponse) {
                            return quoteRequestResponse || apiClient.noResourceFound();
                        });
                    },
                    siteResponse: function(quoteRequestResponse, apiClient) {
                        return apiClient.get(quoteRequestResponse.getLink('site')).then(function(siteResponse) {
                            return siteResponse || apiClient.noResourceFound();
                        });
                    },
                    partnershipCollectionResponse: function(apiClient, siteResponse, quoteRequestResponse, elbSettings) {
                        var params = {serviceProvidersStatus: 'active', permission: 'create_quotes', excludeServiceProvidersFromQuoteRequest: quoteRequestResponse.id};

                        if (elbSettings.getSetting('prosure_enabled').value) {
                            params = angular.extend({order: 'prosureStatus'}, params);
                        }

                        return apiClient.get(siteResponse.getLink('partnerships'), params).then(function(partnershipCollectionResponse) {
                            return partnershipCollectionResponse || apiClient.noResourceFound();
                        });
                    },
                }
            });
    }

    return this;
}
