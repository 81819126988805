(function () {
    'use strict';

    angular
        .module('elogbooksDirectives')
        .directive('elbPermissions', ['$translate', 'lodash', ElogbooksPermissions]);

    function ElogbooksPermissions ($translate, lodash) {
        return {
            restrict: 'AE',
            scope: {
                permissions: '=',
                title: '@',
                titleIcon: '@',
                appliedPermissions: '=',
                entityPermissions: '=',
                updateAction: '=',
                selectedPermissions: '='
            },
            templateUrl: '/modules/directives/elb-permissions/permissions.html',
            link: function ($scope, element, attr, ctrl) {
                $scope.addPermission = addPermission;
                $scope.addPermissions = addPermissions;
                $scope.removePermission = removePermission;
                $scope.selectedPermissions = [];
                $scope.entityPermissions = $scope.entityPermissions || [];

                angular.forEach($scope.entityPermissions, function (permission, index) {
                    if(lodash.find($scope.permissions, { label: permission })) {
                        addPermission(permission);
                    }
                });

                function addPermission (permission) {
                    addPermissions([{ label: permission }], true, false);
                }

                function addPermissions (permissions, force, sendUpdate) {
                    angular.forEach(permissions, function (permission, index) {
                        var index = $scope.entityPermissions.indexOf(permission.label);

                        if (index === -1 || force) {

                            var newPermission = {
                                label: permission.label,
                                translation: $translate.instant(permission.label.toUpperCase()),
                                description: $translate.instant(permission.label.toUpperCase() + '_DETAIL')
                            };

                            if (index === -1) {
                                $scope.entityPermissions.push(newPermission.label);
                            }

                            $scope.appliedPermissions.push(newPermission);

                            angular.forEach($scope.permissions, function (permission, index) {
                                if (permission.label === newPermission.label) {
                                    $scope.permissions.splice(index, 1);
                                }
                            });
                        }
                    });

                    $scope.selectedPermissions = [];

                    // run page controller updateAction after initial permission load
                    if(sendUpdate && permissions.length) {
                        $scope.updateAction();
                    }
                }

                function removePermission (permission) {
                    var index = $scope.entityPermissions.indexOf(permission),
                        appliedIndex = $scope.appliedPermissions.indexOf(permission);

                    if (index > -1) {
                        $scope.entityPermissions.splice(index, 1);

                        angular.forEach($scope.appliedPermissions, function (appliedPermission, index) {
                            if (appliedPermission.label === permission) {
                                $scope.appliedPermissions.splice(index, 1);
                            }
                        });

                        var oldPermission = {
                            label: permission,
                            name: $translate.instant(permission.toUpperCase()),
                            translation: $translate.instant(permission.toUpperCase()),
                            description: $translate.instant(permission.toUpperCase() + '_DETAIL')
                        };

                        $scope.permissions.push(oldPermission);

                        // trigger updateAction
                        $scope.updateAction();
                    }
                }
            }
        }
    }
})();
