(function () {
    'use strict';

    angular
        .module('elogbooks.admin.sites')
        .controller('PartnershipInfoController', PartnershipInfoController);

    PartnershipInfoController.$inject = ['partnershipResponse', 'serviceProviderResponse', 'userManager'];

    function PartnershipInfoController (partnershipResponse, serviceProviderResponse, userManager) {
        var vm = this;
        vm.partnership = partnershipResponse;
        vm.serviceProvider = serviceProviderResponse;
        vm.isClientAdmin = userManager.hasPermission('user_permission_client_admin_edit_partnerships');
    }
})();
