(function () {
    'use strict';

    angular
        .module('elogbooks.admin.users')
        .controller('UserDetailsController', UserDetailsController);

    UserDetailsController.$inject = ['$uibModal', 'userResponse', 'userManager'];

    function UserDetailsController ($uibModal, userResponse, userManager) {
        var vm = this;
            vm.enablePasswordResetModel = enablePasswordResetModel;
            vm.user = userResponse;
            vm.clientAdminAccess = userManager.hasPermission('user_permission_client_admin_manage_users') && !userManager.hasPermission('user_permission_manage_users');

        function enablePasswordResetModel() {
            var modalInstance = $uibModal.open({
                templateUrl: '/modules/admin/users/details/user-password-reset-modal.html',
                controller: 'UserPasswordResetModalController',
                controllerAs: 'vm',
                resolve: {
                    userResource: function () {
                        return userResponse;
                    }
                }
            });
        }
    }

})();
