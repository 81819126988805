(function() {
    'use strict';

    angular
        .module('elogbooks.admin.client-billing')
        .controller('ClientBillingAddEditController', ClientBillingAddEditController);

    ClientBillingAddEditController.$inject = [
        'clientBillingResponse',
        'billingTypesCollectionResponse',
        '$translate',
        'apiClient',
        'messenger',
        '$state',
        'clientBillingService'
    ];

    function ClientBillingAddEditController(
        clientBillingResponse,
        billingTypesCollectionResponse,
        $translate,
        apiClient,
        messenger,
        $state,
        clientBillingService
    ) {
        var vm = this;

        vm.clientBilling = clientBillingResponse;
        vm.data = {};
        vm.textButton = vm.clientBilling.invoiceMode ? 'UPDATE' : 'ADD';
        vm.data.clientBillingCharges = [];
        vm.showNewRowButton = null;

        vm.monthOptions = [
            { value: 1, title: $translate.instant("JANUARY"), days: 31 },
            { value: 2, title: $translate.instant("FEBRUARY"), days: 28 },
            { value: 3, title: $translate.instant("MARCH"), days: 31 },
            { value: 4, title: $translate.instant("APRIL"), days: 30 },
            { value: 5, title: $translate.instant("MAY"), days: 31 },
            { value: 6, title: $translate.instant("JUNE"), days: 30 },
            { value: 7, title: $translate.instant("JULY"), days: 31 },
            { value: 8, title: $translate.instant("AUGUST"), days: 31 },
            { value: 9, title: $translate.instant("SEPTEMBER"), days: 30 },
            { value: 10, title: $translate.instant("OCTOBER"), days: 31 },
            { value: 11, title: $translate.instant("NOVEMBER"), days: 30 },
            { value: 12, title: $translate.instant("DECEMBER"), days: 31 }
        ];

        vm.data.cis = vm.clientBilling.cis ? vm.clientBilling.cis : null;
        vm.data.serviceType = vm.clientBilling.serviceType === 'NONE_SELECTED' ? null : vm.clientBilling.serviceType;
        vm.data.invoiceFrequency = vm.clientBilling.invoiceFrequency === 'NONE_SELECTED' ? null : vm.clientBilling.invoiceFrequency;
        vm.data.invoiceMode = vm.clientBilling.invoiceMode === 'NONE_SELECTED' ? null : vm.clientBilling.invoiceMode;
        vm.data.startingMonth = vm.clientBilling.startingMonth ? vm.clientBilling.startingMonth : null;
        vm.data.anniversaryDateMonth = vm.clientBilling.anniversaryDateMonth ? vm.clientBilling.anniversaryDateMonth.toString() : null;
        vm.data.anniversaryDateDay =  vm.clientBilling.anniversaryDateDay ? vm.clientBilling.anniversaryDateDay.toString() : null;
        vm.data.contractStartedAt = vm.clientBilling.contractStartedAt === 'NONE_SELECTED' || vm.clientBilling.contractStartedAt === undefined ? null : new Date(vm.clientBilling.contractStartedAt.split('/').reverse().join('-'));

        vm.selectedAnniversaryMonth =  vm.data.anniversaryDateMonth ? vm.monthOptions.find(option => option.value === parseInt(vm.data.anniversaryDateMonth)) : null;

        if (vm.clientBilling.clientBillingCharges) {
            angular.forEach(vm.clientBilling.clientBillingCharges.clientbillingcharges, function(clientBillingCharge){
                clientBillingCharge.value = (clientBillingCharge.cost * clientBillingCharge.quantity).toFixed(2);
                vm.data.clientBillingCharges.push(clientBillingCharge);
            });
        }

        if (vm.data.clientBillingCharges.length >= 10) {
            vm.showNewRowButton = false;
        } else {
            vm.showNewRowButton = true;
        }

        vm.invoiceFrequencyOptions = vm.clientBilling.invoiceFrequency ? [] : [{ value: null, title: $translate.instant(vm.clientBilling.invoiceFrequency), selected: true }];
        Object.keys(vm.clientBilling.invoiceFrequencyTypes).forEach(key => {
            vm.invoiceFrequencyOptions.push({ value: key, title: $translate.instant(vm.clientBilling.invoiceFrequencyTypes[key]) });
        });

        vm.invoiceModeOptions = vm.clientBilling.invoiceFrequency ? [] : [{ value: null, title: $translate.instant(vm.clientBilling.invoiceFrequency) }];
        Object.keys(vm.clientBilling.invoiceModeTypes).forEach(key => {
            vm.invoiceModeOptions.push({ value: key, title: $translate.instant(vm.clientBilling.invoiceModeTypes[key]) });
        });

        vm.serviceTypeOptions = [];
        Object.keys(vm.clientBilling.serviceTypeOptions).forEach(key => {
            vm.serviceTypeOptions.push({ value: vm.clientBilling.serviceTypeOptions[key], title: vm.clientBilling.serviceTypeOptions[key] });
        });

        vm.create = createAction;
        vm.newRow = newRow;
        vm.deleteRow = deleteRow;
        vm.updateValue = updateValue;
        vm.cancel = cancel;
        vm.updateAnniversaryMonth = updateAnniversaryMonth;

        const [ invoiceFrequencyOccurrence ] = clientBillingService.getInvoiceFrequencyOccurrence(vm.clientBilling.invoiceFrequency)
        const billingTypes = clientBillingService.parseBillingTypes(billingTypesCollectionResponse.billingTypes, invoiceFrequencyOccurrence);
        const totalYearlyCost = parseFloat(clientBillingService.sum(billingTypes, 'yearlyCost')).toFixed(2);
        const totalAdditionnalChargesPerYear = (parseFloat(clientBillingService.totalRowsValue(vm.data.clientBillingCharges, 'cost', 'quantity'))).toFixed(2);
        const totalContractValuePerYear = (parseFloat(totalYearlyCost) + parseFloat(totalAdditionnalChargesPerYear)).toFixed(2);

        if (vm.clientBilling.clientBillingCharges) {
            const [ data ] = clientBillingService.calculateSurchargeMinimumContract(
                vm.clientBilling.clientBillingCharges,
                vm.clientBilling.minimumContractValue,
                totalContractValuePerYear
            );
            vm.data.clientBillingCharges = data.clientbillingcharges;
        }

        function createAction() {
            let payload = angular.copy(vm.data);
            if (payload.invoiceFrequency === "Monthly") {
                payload.startingMonth = 0;
            }

            if (!payload.cis) {
                payload.cis = null;
            }

            if (payload.clientBillingCharges.length > 0) {
                payload.clientBillingCharges = payload.clientBillingCharges.filter(charge => !charge.surcharge);
            }

            apiClient.create(vm.clientBilling.getLink('add-edit'), payload).then(function (response) {
                if (response) {
                    $state.go('^', {}, { reload: $state.get('^.^') }).then(function () {
                        messenger.success('UPDATE_SUCCESS');
                    });
                } else {
                    messenger.error('REQUEST_ERROR');
                }
            });
        }

        function newRow() {
            const numberRows = vm.data.clientBillingCharges.length;

            if (numberRows < 10) {
                const newRow = {
                    id: 0,
                    name: null,
                    cost: null,
                    quantity: null,
                    value: null
                };

                if (vm.data.clientBillingCharges.some(charge => charge.surcharge)) {
                    vm.data.clientBillingCharges.splice(numberRows - 1, 0, newRow);
                } else {
                    vm.data.clientBillingCharges.push(newRow);
                }
            }

            if (numberRows >= 10) {
                vm.showNewRowButton = false;
            }
        }

        function deleteRow(key) {
            if (vm.data.clientBillingCharges[key].surcharge) {
                // Should not get here as the delete button is disabled for this charge. More of an extra safe guard.
                return;
            }

            if (vm.data.clientBillingCharges[key].id === 0) {
                vm.data.clientBillingCharges.splice(key, 1);
            } else {
                vm.data.clientBillingCharges[key].remove = true;
            }

            if (vm.data.clientBillingCharges.filter((item) => {return !item.remove}).length < 10) {
                vm.showNewRowButton = true;
            }
        }

        function updateValue(key) {
            if (vm.data.clientBillingCharges[key].cost && vm.data.clientBillingCharges[key].quantity) {
                vm.data.clientBillingCharges[key].value = (vm.data.clientBillingCharges[key].cost * vm.data.clientBillingCharges[key].quantity).toFixed(2);
                return;
            }
            vm.data.clientBillingCharges[key].value = null;
            return;
        }

        function cancel() {
            $state.go('^', {}, { reload: $state.get('^.^') });
        }

        function updateAnniversaryMonth() {
            vm.selectedAnniversaryMonth =  vm.data.anniversaryDateMonth ? vm.monthOptions.find(option => option.value === parseInt(vm.data.anniversaryDateMonth)) : null;

            vm.data.anniversaryDateDay = null;
        }
    }
})();
