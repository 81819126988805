(function() {
    'use strict';

    angular
        .module('elogbooks')
        .config(Translations);

    function Translations($translateProvider) {
        $translateProvider.translations('en-gb', {
            BILLING_TYPES: 'Billing Types',
            BILLING_TYPE: 'Billing Type',
            BILLING_TYPES_LIST: 'Billing Types',
            BILLING_TYPES_ADD: 'Add New Billing Type',
            BILLING_TYPES_EDIT: 'Edit Billing Type',

            BILLING_TYPE_UPDATED: 'Billing Type Updated',
            BILLING_TYPE_CREATED: 'Billing Type Created',
            BILLING_TYPE_DELETED: 'Billing Type Deleted',

            BILLING_TYPE_INFORMATION: 'Billing Type Information',
            BILLING_TYPE_CREATED_BY: '<b>{{ ::name }}</b> created the Billing Type',
            BILLING_TYPE_UPDATED_BY: '<b>{{ ::name }}</b> updated the Billing Type',
            BILLING_TYPE_COST_UPLIFTED_BY: '<b>{{ ::name }}</b> applied {{ ::upliftPercentage }}% Uplift',

            BILLING_COST: 'Billing Cost',
            COST: 'Cost',
            DEFAULT_BILLING_TYPE :'Default',
            BILLING_TYPE_DELETE_FORBIDDEN: 'Default Billing Type can\'t be deleted',
            BILLING_TYPE_IS_REQUIRED: 'Billing Type is required',

            CLIENT_BILLING: 'Client Billing',
            CLIENT_BILLING_HISTORICAL_REPORTS: 'Historical Reports',
            CLIENT_BILLING_CONFIG: 'Config',
            CLIENT_BILLING_REMINDER_VIEW: 'Review Reminders',
            CLIENT_BILLING_REMINDER_ADD: 'Configuration',
            CLIENT_BILLING_CONFIG_UPDATED: '<b>{{ ::name }}</b> updated the Client Billing configuration',
            CLIENT_BILLING_TREND_GRAPH: 'Trend Graph',
            CLIENT_BILLING_PORTFOLIO_VARIATION: 'Portfolio Variation',
            CLIENT_BILLING_PORTFOLIO_VARIATIONS: 'Portfolio Variations',
            MONTH_1: 'Month 1',
            MONTH_2: 'Month 2',
            SITE_VARIATION: 'Site Variation',
            VALUE_VARIATION: 'Value Variation',
            PORTFOLIO_VARIATION_FORM_ERROR_MSG: 'Please select a value for month 1 inferior to month 2',

            VALUE_PER_ANNUM: 'Value (Per Annum)',
            VALUE_PER_INVOICING_FREQUENCY: 'Value (Per Invoicing Frequency)',
            CLIENT_INVOICING_OVERVIEW: 'Client Invoicing Overview',
            SITE_INVOICING_OVERVIEW: 'Site Invoicing Overview',
            INVOICE_FREQUENCY: 'Invoice Frequency',
            INVOICE_FREQUENCY_NOT_SET: 'Invoice Frequency Not Set',
            INVOICING_MONTHS: 'Invoicing Months (1st of each)',
            INVOICE_MODE: 'Invoice Mode',
            TOTAL_CONTRACT_VALUE: 'Total Contract Value',
            SERVICE_TYPE: 'Service Type',

            QUARTERLY: 'Quarterly',
            ANNUALLY: 'Annually',
            IN_ARREARS: 'In Arrears',
            IN_ADVANCE: 'In Advance',
            STARTING_MONTH: 'Starting Month',
            ADDITIONAL_CHARGES: 'Additional Charges',
            YOU_HAVE_REACHED_A_MAXIMUM_OF_10_ADDITIONAL_CHARGES: 'You have reached a maximum of 10 additional charges.',
            CLIENT_INVOICING_ADD: 'Client Invoicing',
            EVERY_MONTH: 'Every Month',
            FIRST_REMINDER: 'First Reminder',
            SECOND_REMINDER: 'Second Reminder',
            FIRST_REMINDER_DAYS: 'First Reminder (Days)',
            SECOND_REMINDER_DAYS: 'Second Reminder (Days)',
            CLIENT_BILLING_CONTRACT_MANAGER: '(Contract Manager)',
            DEFAULT_7_DAYS: '7 Days',
            DEFAULT_1_DAY: '1 Day',
            CLIENT_BILLING_SETTINGS_UPDATED: 'Client Billing Settings Updated',
            PERCENTAGE_UPLIFT: 'Percentage Uplift',
            APPLY_PERCENTAGE_UPLIFT: 'Apply Percentage Uplift',
            UPLIFT_PERCENTAGE: 'Uplift Percentage',
            APPLY_AS_OF: 'Apply As Of',
            UPLIFTED_COST: 'Uplifted Cost',
            CLIENT_BILLING_CONFIRMATION_MODAL_MESSAGE: 'This will apply the selected percentage uplift to all existing billing values and additional charges.',
            APPLY_AS_OF_MUST_BE_HIGHER_THAN_TODAY_DATE: 'Apply As Of Must Be Higher Than Today Date',
            UPLIFT_PERCENTAGE_MUST_BE_BETWEEN_0_AND_100: 'Uplift Percentage Must Be Between 0 And 100',
            CONTRACT_UPLIFT: 'Contract Uplift',
            PERCENTAGE_INCREASE: 'Percentage Increase',
            UPLIFT_REMINDER_DAYS: 'Uplift Reminder (Days)',
            UPLIFT_REMINDER: 'Uplift Reminder',
            APPLY_MINIMUM_CONTRACT_VALUE: 'Apply Minimum Contract Value',
            MINIMUM_CONTRACT_VALUE: 'Minimum Contract Value',
            SURCHARGE_MINIMUM_CONTRACT_VALUE: 'Surcharge to Minimum Contract Value',

            CONTRACT_ANNIVERSARY_DATE: 'Anniversary Date'
        });
    }
})();
