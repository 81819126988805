(function () {
    'use strict';

    angular
        .module('elogbooksServices')
        .service('clientBillingService', clientBillingService);

        clientBillingService.$inject = ['moment', '$translate'];

    function clientBillingService(moment, $translate) {
        this.upliftedCost = upliftedCost;
        this.calculateSurchargeMinimumContract = calculateSurchargeMinimumContract;
        this.sum = sum;
        this.totalRowsValue = totalRowsValue;
        this.getInvoiceFrequencyOccurrence = getInvoiceFrequencyOccurrence;
        this.parseBillingTypes = parseBillingTypes;

        const calculateQuarterlyPeriod = (months, month) => {
            if (month === null ) {
                return  '';
            }

            let period = month;
            let result = [months[month - 1]];

            while (result.length < 4) {
                period += 3;
                if (period > 12) {
                    period -= 12;
                }
                result.push(months[period - 1]);
            }

            return (result.filter((month) => month)).join(', ');
        };

        this.getOrdinalNumber = (day) => {
            if (day > 3 && day < 21) return "th";

            switch (day % 10) {
                case 1:
                    return "st";
                case 2:
                    return "nd";
                case 3:
                    return "rd";
                default:
                    return "th";
            }
        };

        function upliftedCost(upliftPercentage, cost) {
            if (!upliftPercentage) {
                return cost;
            }

            const uplifted = ((upliftPercentage * cost) / 100) + Number(cost);
            return uplifted.toFixed(2);
        };

        function sum (items, prop) {
            return items.reduce(function(a, b) {
                return parseFloat(a) + parseFloat(b[prop]);
            }, 0);
        };

        function parseBillingTypes(billingTypes, invoiceFrequencyOccurrence) {
            return billingTypes.map(billingType => {
                let newType = billingType;

                newType.cost = parseFloat(billingType.cost).toFixed(2);
                newType.yearlyCost = parseFloat(billingType.cost * billingType.sitesCount).toFixed(2);
                newType.costPerFrequency = invoiceFrequencyOccurrence ?
                        parseFloat(newType.yearlyCost / invoiceFrequencyOccurrence).toFixed(2) :
                        'INVOICE_FREQUENCY_NOT_SET';

                return newType;
            });
        }

        function getInvoiceFrequencyOccurrence(invoiceFrequency, startingMonth = null) {
            const months = moment.months();
            let invoicingMonths = '';
            let invoiceFrequencyOccurrence = null;

            switch (invoiceFrequency) {
                case 'Monthly':
                    invoicingMonths = months.join(', ');
                    invoiceFrequencyOccurrence = 12;
                    break;
                case 'Quarterly':
                    invoicingMonths = startingMonth ? calculateQuarterlyPeriod(months, startingMonth) : null;
                    invoiceFrequencyOccurrence = 4;
                    break;
                case 'Annually':
                    invoicingMonths = startingMonth ? months[startingMonth - 1] : null;
                    invoiceFrequencyOccurrence = 1;
                    break;
                default:
                    invoicingMonths = 'INVOICE_FREQUENCY_NOT_SET';
                    invoiceFrequencyOccurrence = null;
            }

            return [invoiceFrequencyOccurrence, invoicingMonths];
        }

        function totalRowsValue(items, prop, multiplier) {
            return items.reduce(function (a, b) {
                return parseFloat(a) + parseFloat(b[prop] * b[multiplier]);
            }, 0);
        }

        function calculateSurchargeMinimumContract(data, minimumContractValue, totalContractValuePerYear) {
            let surchargeMinimumContract = null;
            if (minimumContractValue && parseFloat(totalContractValuePerYear) < minimumContractValue) {
                if (data.clientbillingcharges.length > 0) {
                    const index = data.clientbillingcharges.map(charge => charge.surcharge).indexOf(true);
                    if (index !== -1) {
                        data.clientbillingcharges[index].cost = minimumContractValue - parseFloat(totalContractValuePerYear);
                        return data;
                    }
                }

                const surchargeCost = Math.round((minimumContractValue - parseFloat(totalContractValuePerYear)) *100) / 100;
                surchargeMinimumContract = {
                    name: $translate.instant('SURCHARGE_MINIMUM_CONTRACT_VALUE'),
                    cost: surchargeCost,
                    quantity: 1,
                    value: (surchargeCost).toFixed(2),
                    disabled: true,
                    surcharge: true
                };

                data.clientbillingcharges.push(surchargeMinimumContract);
                data.count += 1;
            }
            return [data, surchargeMinimumContract];
        }
    };
})();
