(function () {
    'use strict';

    angular
        .module('elogbooks.admin.settings')
        .controller('AdminEditJobExtraSettingsController', AdminEditJobExtraSettingsController);

    function AdminEditJobExtraSettingsController($scope, apiClient, messenger, jobExtraSettings, $translate, lodash, siteAssociateTypesResponse) {
        var vm = this;
            vm.settings = jobExtraSettings;
            vm.settings.id = 1;
            vm.update = updateAction;
            vm.siteAssociateTypesResponse = siteAssociateTypesResponse;

            vm.extensionOptions = [
                {
                    value: 'autoApprove',
                    title: $translate.instant('AUTO_APPROVE')
                },
                {
                    value: 'off',
                    title: $translate.instant('OFF')
                },
                {
                    value: 'on',
                    title: $translate.instant('ON' )
                }
            ];

            if (vm.settings.timeDelay === undefined ) {
                 vm.settings.timeDelay = 2;
            }

            vm.jobNotifySiteAssociateTypeModel = {
                response: siteAssociateTypesResponse,
                link: siteAssociateTypesResponse ? siteAssociateTypesResponse.getLink('self') : null,
                required: false,
                responseKeyPath: 'siteAssociateTypes',
                itemHrefPath: '_links.self.href',
                selected : lodash.get(vm.settings,'_links.jobNotifySiteAssociateType',null),
                onSelect: changJobNotifySiteAssociateType,
                onRemove: changJobNotifySiteAssociateType
            };
        
        function changJobNotifySiteAssociateType(jobNotifySiteAssociateTypeModel) {
            if (jobNotifySiteAssociateTypeModel.selected ) {
                vm.settings._links.jobNotifySiteAssociateType = { href: jobNotifySiteAssociateTypeModel.selected.href };
            } else {
                delete vm.settings._links.jobNotifySiteAssociateType;
            }
        }

        function updateAction() {
            if (vm.settings.requireJobGroup === false) {
                vm.settings.jobGroupChildSelectMandatory = false;
            }
            // Copy the data before adjusting so UI is not affected
            vm.data = lodash.clone(vm.settings);

            switch (vm.data.jobCompletionDueSlaExtension){
                case 'off':
                    vm.data.jobCompletionDueSlaExtension = 0;
                break;
                case 'on':
                    vm.data.jobCompletionDueSlaExtension = -1;
                break;
                default:
                    vm.data.jobCompletionDueSlaExtension = vm.settings.timeDelay;
            }

            apiClient.update(jobExtraSettings.getLink('edit'), vm.data).then(function (response) {
                if (response) {
                    $scope.$state.go('^', {}, { reload: true }).then(function () {
                        messenger.success('SETTINGS_UPDATED');
                    });
                } else {
                    messenger.error('REQUEST_ERROR');
                }
            });
        }
    }
})();

