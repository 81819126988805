(function () {
    'use strict';

    angular
        .module('elogbooks')
        .controller('LoginController', LoginController);

    LoginController.$inject = ['$state', '$rootScope', '$location', 'userManager', 'apiClient', 'rootResource', 'vcRecaptchaService', '$window', 'ssoService', 'stonlyService'];

    function LoginController($state, $rootScope, $location, userManager, apiClient, rootResource, vcRecaptchaService, $window, ssoService, stonlyService) {

        $rootScope.skipDirtyCheck = true;

        var vm = this;
        vm.helpdeskPhoneNumber = rootResource ? rootResource.helpdeskPhoneNumber : null;
        vm.loginFailMsg = false;
        vm.recaptchaCheck = false;
        vm.recaptchaResponse = null;
        vm.recaptchaKey = '6LfhEEIUAAAAAFvkgLosgt7xogSYfelgjvECRgAZ';
        vm.recaptchaSetResponse = recaptchaSetResponse;
        vm.recaptchaSetWidgetId = recaptchaSetWidgetId;
        vm.recaptchaSetExpiration = recaptchaSetExpiration;
        vm.credentials = {
            username: null,
            password: null,
            source: 'web'
        };
        vm.email = null;
        vm.loading = false;
        vm.usernameError = false;
        vm.ssoLoginEnabled = false;
        vm.login = login;
        vm.loginWithSso = loginWithSso;
        vm.forgottenPassword = forgottenPassword;
        vm.forgottenUsername = forgottenUsername;
        vm.cancelForgotUsername = cancelForgotUsername;
        vm.checkEmail = checkEmail;

        vm.themePrefix = rootResource ? rootResource.theme : 'elb';
        vm.themeLogo = `/${vm.themePrefix}-logo.svg`;

        /** Christmas logo disabled
        vm.christmas = new Date().getMonth() + 1 == 12
        if (vm.christmas) {
            vm.themeLogo = '/elb-christmas-logo.png';
        }
        */

        function loginWithSso(){
            vm.loginFailMsg = false;
            stonlyService.init()
            ssoService.login();
        }

        if ($window.localStorage.getItem('recaptchaRequired')) {
            vm.recaptchaCheck = true;
        }

        if (rootResource &&  undefined !== rootResource.ssoParams) {
            vm.ssoLoginEnabled = rootResource.ssoParams.hasOwnProperty('type');
        }

        if (vm.ssoLoginEnabled) {
            vm.ssoType = rootResource.ssoParams.type.toUpperCase();
        }

        if (userManager.getToken()) {
            if ((userManager.hasPermission("site_permission_helpdesk")) && (!userManager.hasPermission("user_permission_manage_users"))) {
                $state.go('dashboard.helpdesk.dashboard.quotes.chase');
            } else {
                $state.go('dashboard.user.dashboard');
            }
        }

        function login() {
            vm.loading = true;
            stonlyService.init()

            if (vm.recaptchaCheck) {
                vm.credentials.recaptchaResponse = vm.recaptchaResponse;
            }

            userManager.login(vm.credentials).then(function(result){
                if (result !== true) {
                    vm.loginFailMsg = result;
                }
                if (result === 'USER_AUTH_RECAPTCHA_REQUIRED') {
                    vm.loading = false;
                    vm.recaptchaCheck = true;
                    $window.localStorage.setItem('recaptchaRequired', true);
                }
                else if (result === 'USER_AUTH_RECAPTCHA_INVALID') {
                    vm.recaptchaCheck = true;
                    vm.recaptchaSetExpiration();
                    vm.loading = false;
                }
                else if (result === 'USER_AUTH_TOO_MANY_ATTEMPTS' || result === 'USER_AUTH_BAD_CREDENTIALS') {
                    vm.credentials.password = null;
                    if (vm.recaptchaCheck) {
                        vm.recaptchaSetExpiration();
                    }
                    vm.loading = false;
                }
                else if (result) {
                    $window.localStorage.removeItem('recaptchaRequired');
                    // get user resource to enable checking preferences
                    userManager.getUser().then(function (user) {
                        if ($state.params.destination) {
                            $rootScope.skipDirtyCheck = false;
                            $location.url($state.params.destination.decode());
                        } else if ((userManager.hasPermission("site_permission_helpdesk")) && (!userManager.hasPermission("user_permission_manage_users"))) {
                            $state.go('dashboard.helpdesk.dashboard.quotes.chase').then(function () {
                                $rootScope.skipDirtyCheck = false;
                            });
                        } else {
                            $state.go('dashboard.user.dashboard').then(function() {
                                $rootScope.skipDirtyCheck = false;
                            });
                        }
                    });
                } else {
                    vm.loading = false;
                    vm.loginFailMsg = 'USER_AUTH_FAIL';
                }
            });
        }

        function forgottenPassword() {
            vm.loading = true;

            apiClient.create(rootResource.getLink('passwordchangerequests'), {
                username: vm.credentials.username
            }).then(function (response) {
                if (response) {
                    vm.loading = false;
                    vm.passwordRequestSubmitted = true;
                } else {
                    vm.loading = false;
                }
            });
        }

        function forgottenUsername() {
            vm.loading = true;
            vm.usernameError = false;

            apiClient.create(rootResource.getLink('forgot-username-request'), {
                email: vm.email
            }).then(function (response) {
                if (response) {
                    vm.loading = false;
                    vm.usernameError = false;
                    vm.usernameRequestSubmitted = true;
                } else {
                    vm.loading = false;
                    vm.usernameError = true;
                }
            });
        }

        function recaptchaSetResponse(response) {
            vm.recaptchaResponse = response;
        }

        function recaptchaSetWidgetId(widgetId) {
            vm.widgetId = widgetId;
        };

        function recaptchaSetExpiration() {
            vcRecaptchaService.reload(vm.widgetId);
        };

        function cancelForgotUsername(form) {
            vm.forgotUsername = !vm.forgotUsername;
            vm.email = null;
            vm.loginFailMsg = false;
            vm.usernameError = null;
            form.$setPristine();
        }

        function checkEmail(form) {
            if (form.email.$invalid && form.$submitted) {
                return true;
            }

            return false;
        }
    }
})();
