angular.module('elogbooksServices').service('siteAssociatesService', function (apiClient, $q, userManager) {
    var self = this;
    self.getSiteAssociates = getSiteAssociates;

    function getSiteAssociates(siteResponse, rootResourceResponse) {
        if (userManager.hasPermission('site_permission_view_associates') && siteResponse.getLink('associates')) {
            let associatesResponse = apiClient.get(siteResponse.getLink('associates')).then(function(response) {
                return response || apiClient.noResourceFound();
            });
            let associateTypesResponse = rootResourceResponse.getResource('siteassociatetypes');
            let associatesCollection = [];

            $q.all([associatesResponse, associateTypesResponse]).then(function(response) {
                let associates = response[0].associates;
                let associateTypes = response[1].siteAssociateTypes;
                let siteassociatetype = null;
                let siteMembership = null;
                angular.forEach(associateTypes, function(associateType, key) {
                    siteassociatetype = {'title' : associateType.name, 'href' : associateType._links.self.href, 'members' : []};

                    angular.forEach(associates, function(associate, key) {

                        if(associate._links.type.href === associateType._links.self.href) {
                            siteMembership = {
                                'title' : associate._links.sitemembership.title,
                                'href' : associate._links.sitemembership.href,
                                'userHref' : associate._links.user.href
                            };
                            siteassociatetype.members.push(siteMembership);
                        }
                    });
                    associatesCollection.push(siteassociatetype);
                });
            });
            return associatesCollection;
        }
        return {};
    }
});
