(function() {
    'use strict';

    angular
        .module('elogbooks')
        .config(Translations);

    function Translations($translateProvider) {
        $translateProvider.translations('en-gb', {
            JOB_WORKFLOW_ESCALATE: 'Escalate',
            JOB_WORKFLOW_ESCALATE_OPERATION: 'Escalate',
            JOB_WORKFLOW_ESCALATE_OPERATION_SUBMIT: 'Escalate',

            JOB_WORKFLOW_COMMENCE: 'Commence Job',

            JOB_WORKFLOW_COMPLETE: 'Complete Job',
            JOB_WORKFLOW_COMPLETE_OPERATION: 'Complete Job',
            JOB_WORKFLOW_COMPLETE_OPERATION_SUBMIT: 'Complete Job',
            JOB_WORKFLOW_COMPLETE_OPERATION_SUCCESS: 'Job Completed',

            JOB_WORKFLOW_REQUEST_EXTENSION: 'Request Extension',
            JOB_WORKFLOW_REQUEST_EXTENSION_SUBMIT: 'Request',
            JOB_WORKFLOW_REQUEST_EXTENSION_SUCCESS: 'Job Extension Requested',

            JOB_WORKFLOW_ACCEPT_EXTENSION: 'Accept Extension',
            JOB_WORKFLOW_ACCEPT_EXTENSION_SUBMIT: 'Accept',
            JOB_WORKFLOW_ACCEPT_EXTENSION_SUCCESS: 'Job Extension Accepted',

            JOB_WORKFLOW_REJECT_EXTENSION: 'Reject Extension',
            JOB_WORKFLOW_REJECT_EXTENSION_SUBMIT: 'Reject',
            JOB_WORKFLOW_REJECT_EXTENSION_SUCCESS: 'Job Extension Rejected',

            JOB_WORKFLOW_CANCEL_EXTENSION: 'Cancel Extension',
            JOB_WORKFLOW_CANCEL_EXTENSION_SUBMIT: 'Cancel Extension',
            JOB_WORKFLOW_CANCEL_EXTENSION_SUCCESS: 'Job Extension Cancelled',

            JOB_WORKFLOW_CANCEL_OPERATION: 'Cancel Job',
            JOB_WORKFLOW_CANCEL_OPERATION_SUBMIT: 'Submit',
            JOB_WORKFLOW_CANCEL_OPERATION_SUCCESS: 'Job Cancelled',
            JOB_WORKFLOW_CANCEL_ASSIGNMENT: 'Cancel Assignment',

            JOB_WORKFLOW_SKIP: 'Skip',
            JOB_WORKFLOW_SKIP_OPERATION: 'Skip',
            JOB_WORKFLOW_SKIP_OPERATION_SUBMIT: 'Submit',

            JOB_WORKFLOW_ACCEPT_ASSIGNMENT: 'Acknowledge',
            JOB_WORKFLOW_REQUEST_PROPOSAL: 'Request New SLA',
            JOB_WORKFLOW_CANCEL_PROPOSAL: 'Cancel New SLA',
            JOB_WORKFLOW_INSIST_ASSIGNMENT: 'Insist',

            JOB_WORKFLOW_ACCEPT_APPROVAL: 'Approve',
            JOB_WORKFLOW_ACCEPT_APPROVAL_SUBMIT: 'Approve',

            JOB_WORKFLOW_REOPEN: 'Reopen',
            JOB_WORKFLOW_REOPEN_OPERATION: 'Reopen',
            JOB_WORKFLOW_REOPEN_OPERATION_SUBMIT: 'Reopen',
            JOB_WORKFLOW_REOPEN_OPERATION_SUCCESS: 'Job Reopened',

            JOB_WORKFLOW_ESCALATE_APPROVAL: 'Escalate Approval',

            JOB_WORKFLOW_REASSIGN_ASSIGNMENT: 'Reassign Assignment',

            JOB_WORKFLOW_ADD_OPERATIVE: 'Add Operative',

            JOB_WORKFLOW_CHANGE_REQUIRED_BY_DATE_OPERATION: 'Postpone Action',
            JOB_WORKFLOW_CHANGE_REQUIRED_BY_DATE_OPERATION_SUBMIT: 'Postpone Action',
            JOB_WORKFLOW_CHANGE_REQUIRED_BY_DATE_OPERATION_SUCCESS: 'Action Postponed',

            JOB_WORKFLOW_EDIT_JOB: 'Edit Job',
            JOB_WORKFLOW_EDIT_JOB_SUBMIT: 'Save',
            JOB_WORKFLOW_EDIT_JOB_SUCCESS: 'Job Edited',
            JOB_WORKFLOW_EDIT_JOB_DOCS_CONFIRMATION: 'You have changed one or more Document Types that may require approval.<br><br>This could result in the job status changing, or raise/cancel any actions associated to this record. Do you wish to proceed?',

            JOB_WORKFLOW_P2P: 'P2P',

            NEEDS_ONE_STATUTORY_FILE: 'At least one file should be statutory type',
            NO_FUTURE_JOBS: 'No future jobs',
            INVALID_OPERATIVE_SELECTED: 'Selected operative is invalid',
            SELECTED_OPERATIVE_DOESNT_EXIST: 'Selected operative doesnt exist',
            OPERATIVE_REQUIRED_NO_PRIMARY_SET: 'Operative required as no primary operative is set',
            ONE_HOUR: 'You cannot request a date later than 1 hour from now',
            SERVICE_PROVIDER_NOT_CONTRACTOR_APPROVED: 'Service Provider not contractor approved',
            COMPLETION_DATE_CANNOT_BE_BEFORE_DATE_ATTENDED: 'Completion date cannot be before date attended',

            JOB_APPROVAL_REQUESTED: 'Job approval requested',
            JOB_ASSIGNMENT_ACCEPTED: 'Job assignment accepted',
            JOB_ASSIGNMENT_REJECTED: 'Job assignment rejected',
            JOB_EXTENSION_CANCELLED: 'Job extension cancelled',
            JOB_EXTENSION_REQUESTED: 'Job extension requested',
            JOB_OPERATION_ACKNOWLEDGED: 'Job acknowledged',
            JOB_OPERATION_COMMENCED: 'Job commenced',
            JOB_OPERATION_COMPLETED: 'Job completed',
            JOB_OPERATION_COMPLETED_PAPERWORK: 'Job paperwork completed',
            JOB_OPERATION_MISSED: 'Job skipped',
            JOB_OPERATION_REASSIGNED: 'Job reassigned',
            JOB_PROPOSAL_CANCELLED: 'New job SLA proposal cancelled',
            JOB_PROPOSAL_REQUESTED: 'New job SLA proposed',
            JOB_MESSAGE: 'Job updates added'
        });
    }
})();
