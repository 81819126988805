(function () {
    'use strict';

    angular
        .module('elogbooks.user.sites')
        .config(registerRoutes)
        .config(registerRoutesList)
        .config(registerRoutesDetails)
        .config(registerRoutesSurveys)
        .config(registerRoutesPatrols)
        .config(registerRoutesMeters)
        .config(registerRoutesContacts)
        .config(registerRouteWebhooks)
        .config(registerRoutesLocations)
        .config(registerRoutesAssociates)
        .config(registerRoutesTasks)
        .config(registerRoutesApprovers)
        .config(registerRoutesWasteManagement);

    function registerRoutes($stateProvider) {
        $stateProvider
            .state('dashboard.user.sites', {
                url: '/sites',
                abstract: true,
                template: '<bookerize></bookerize>',
                ncyBreadcrumb: {
                    skip: true
                }
            });
    }

    function registerRoutesList($stateProvider) {
        var siteParams = {
                page: '1',
                limit: '30',
                siteName: null,
                reference: null,
                status: 'active',
                id: null,
                activeAt: null,
                inactiveAt: null,
                address: null,
                siteGroup: null,
                siteGroups: {array: true},
                siteRegions: {array: true},
                sectors: {array: true},
                reporter: null,
                externalReference: null,
                associateType: null,
                associate: null,
                reportSites: null,
                locationActive: 'yes',
                childLocationActive: 'yes',
                documentType: {array: true}
        };
        $stateProvider
            .state('dashboard.user.sites.list', {
                url: '/list?' + Object.keys(siteParams).join('&'),
                parent: 'dashboard.user.sites',
                params: siteParams,
                views: parseBook({
                    'elogbooks-left@dashboard.user.sites': {
                        templateUrl: '/modules/user/sites/sites-list.html',
                        controller: 'UserSiteListController',
                        controllerAs: 'vm'
                    }
                }),
                resolve: {
                    siteCollectionResponse: function ($stateParams, apiClient, user, selectedSiteResponse, globalFilterParamsService) {
                        var params = angular.extend({}, $stateParams,
                            {
                                page: $stateParams.page,
                                limit: $stateParams.limit,
                                siteName: $stateParams.siteName,
                                reference: $stateParams.reference,
                                status: $stateParams.status,
                                'siteRegions[]': $stateParams.siteRegions,
                                id: $stateParams.id,
                                activeAt: $stateParams.activeAt,
                                inactiveAt: $stateParams.inactiveAt,
                                address: $stateParams.address,
                                externalReference: $stateParams.externalReference,
                                associate: $stateParams.associate,
                                associateType: $stateParams.associateType,
                                'siteGroups[]': $stateParams.siteGroups,
                                siteGroup: $stateParams.siteGroup ? JSON.parse($stateParams.siteGroup).v : null,
                                'siteRegion[]': $stateParams.siteRegion,
                                'sectors[]': $stateParams.sectors
                            }
                        );

                        params = angular.extend({}, params, globalFilterParamsService.getLocalStorageFilters());

                        if (selectedSiteResponse) {
                            params.id = selectedSiteResponse.id;
                        }

                        return apiClient.get(user.getLink('sites'), params, 'long').then(function(response) {
                            return response || apiClient.noResourceFound();
                        });
                    },
                    siteGroupCollectionResponse: function($stateParams, apiClient, rootResourceResponse) {
                        if (rootResourceResponse.getLink('sitegroups')) {
                            return apiClient.get(rootResourceResponse.getLink('sitegroups'), { active: 'yes' }, 'long').then(function (response) {
                                return response || apiClient.noResourceFound();
                            });
                        }
                    },
                    regionCollectionResponse: function($stateParams, apiClient, rootResourceResponse) {
                        if (rootResourceResponse.getLink('regions')) {
                            return apiClient.get(rootResourceResponse.getLink('regions'), null, 'long').then(function (response) {
                                return response || apiClient.noResourceFound();
                            });
                        }
                    },
                    siteAssociateCollectionResponse: function($stateParams, apiClient, user) {
                        if ($stateParams.associateType) {
                            return apiClient.get(user.getLink('users'), {associateType: $stateParams.associateType}, null, 'long').then(function(response) {
                                return response || apiClient.noResourceFound();
                            });
                        }
                    },
                    associateTypeCollectionResponse: function($stateParams, apiClient, rootResourceResponse) {
                        if (rootResourceResponse.getLink('siteassociatetypes')) {
                            return apiClient.get(rootResourceResponse.getLink('siteassociatetypes'), null, 'long').then(function(response) {
                                return response || apiClient.noResourceFound();
                            });
                        }
                    }
                },
                ncyBreadcrumb: {
                    label: '{{ ::"SITES" | translate }}'
                }
            });
    }

    function registerRoutesDetails($stateProvider) {
        $stateProvider
            .state('dashboard.user.sites.list.details', {
                url: '/details/{resource}',
                abstract: true,
                parent: 'dashboard.user.sites.list',
                views: parseBook({
                    'elogbooks-right@dashboard.user.sites': {
                        templateUrl: '/modules/user/sites/details/sites-details.html',
                        controller: 'UserSiteDetailsController',
                        controllerAs: 'vm'
                    }
                }),
                resolve: {
                    userManager: function(userManager) {
                        return userManager;
                    },
                    siteResponse: function (apiClient, $stateParams, $rootScope, $state) {
                        return apiClient.get($stateParams.resource.decode(), { datagroup: 'details' }).then(function (response) {
                            return response || apiClient.noResourceFound('dashboard.user.sites.list');
                        });
                    },
                    approversResponse: function(siteResponse) {
                        return siteResponse.getResource('approvers');
                    },
                    associatesResponse: function(apiClient, siteResponse) {
                        if (siteResponse.getLink('associates')) {
                            return apiClient.get(siteResponse.getLink('associates'));
                        }
                    },
                    quoteThresholdsCollectionResponse: function () {
                        // to please shared controller
                    }
                },
                ncyBreadcrumb: {
                    label: '{{ ::$resolves().siteResponse.name }}'
                }
            })
            .state('dashboard.user.sites.list.details.info', {
                url: '/info',
                parent: 'dashboard.user.sites.list.details',
                views: parseBook({
                    'elogbooks-right@dashboard.user.sites.list.details': {
                        templateUrl: '/modules/user/sites/details/info/site-info.html',
                        controller: 'UserSitesInfoController',
                        controllerAs: 'vm'
                    }
                }),
                resolve: {
                    siteFilesCollectionResponse: function (apiClient, siteResponse) {
                        return apiClient.get(siteResponse.getLink('images'),{isImage:1}).then(function (response) {
                            return response || apiClient.noResourceFound();
                        });
                    }
                },
                ncyBreadcrumb: {
                    label: '{{ ::"INFO" | translate }}'
                }
            })
            .state('dashboard.user.sites.list.details.notes', {
                url: '/notes',
                parent: 'dashboard.user.sites.list.details',
                views: parseBook({
                    'elogbooks-right@dashboard.user.sites.list.details': {
                        templateUrl: '/modules/user/sites/details/notes/notes-list.html',
                        controller: 'UserNotesController',
                        controllerAs: 'vm'
                    },
                    'feed@dashboard.user.sites.list.details.notes': {
                        templateUrl: '/modules/common/event/event-feed/feed.html',
                        controller: 'FeedController',
                        controllerAs: 'vm'
                    }
                }),
                resolve: {
                    noteCollectionResponse: function (apiClient, siteResponse) {
                        return apiClient.get(siteResponse.getLink('notes')).then(function (response) {
                            return response || apiClient.noResourceFound();
                        });
                    },
                    siteNoteTypeCollectionResponse: function (apiClient, siteResponse) {
                        return apiClient.get(siteResponse.getLink('notetypes')).then(function (response) {
                            return response || apiClient.noResourceFound();
                        });
                    },
                    eventsCollection: function (apiClient, siteResponse) {
                        return apiClient.get(siteResponse.getLink('note-events')).then(function(response) {
                            return response || apiClient.noResourceFound();
                        });
                    },
                    entityResponse: function (siteResponse) {
                      return siteResponse;
                    }
                },
                ncyBreadcrumb: {
                    label: '{{ ::"NOTES" | translate }}'
                }
            })
            .state('dashboard.user.sites.list.details.notes.edit', {
                url: '/edit',
                parent: 'dashboard.user.sites.list.details.notes',
                views: {
                    '@dashboard.user.sites.list.details': {
                        templateUrl: '/modules/common/site/notes-form.html',
                        controller: 'CommonSiteNotesEditController',
                        controllerAs: 'vm'
                    }
                },
                ncyBreadcrumb: {
                    label: '{{ ::"EDIT" | translate }}'
                }
            });
    }

    function registerRoutesPatrols ($stateProvider) {
        $stateProvider
            .state('dashboard.user.sites.list.details.patrols', {
                url: '/patrols?patrolPage&patrolLimit',
                parent: 'dashboard.user.sites.list.details',
                views: parseBook({
                    'elogbooks-right@dashboard.user.sites.list.details': {
                        templateUrl: '/modules/user/sites/details/patrols/patrols-list.html',
                        controller: 'UserSitePatrolsController',
                        controllerAs: 'vm'
                    }
                }),
                resolve: {
                    sitePatrolsCollection: function (apiClient, siteResponse, $stateParams) {
                        return apiClient.get(siteResponse.getLink('patrols'), angular.extend({}, $stateParams, {
                            page: $stateParams.patrolPage,
                            limit: $stateParams.patrolLimit
                        }));
                    }
                },
                params: {
                    patrolPage: '1',
                    patrolLimit: '15',
                },
                ncyBreadcrumb: {
                    label: '{{ ::"PATROLS" | translate }}'
                }
            })
            .state('dashboard.user.sites.list.details.patrols.edit', {
                url: '/patrols',
                parent: 'dashboard.user.sites.list.details.patrols',
                views: parseBook({
                    'dashboard.user.sites.list.details.patrols': {
                        templateUrl: '/modules/user/sites/details/patrols/modal/details.html',
                        controller: 'UserSitePatrolsController',
                        controllerAs: 'vm'
                    }
                }),
                resolve: {
                    sitePatrolsCollection: function (apiClient, siteResponse) {
                        return apiClient.get(siteResponse.getLink('patrols')).then(function (response) {
                            return response;
                        });
                    }
                },
                ncyBreadcrumb: {
                    label: '{{ ::"EDIT" | translate }}'
                }
            });
    }

    function registerRoutesSurveys($stateProvider) {
        $stateProvider
            .state('dashboard.user.sites.list.details.surveys', {
                url: '/surveys',
                abstract: true,
                parent: 'dashboard.user.sites.list.details',
                views: parseBook({
                    'elogbooks-right@dashboard.user.sites.list.details': {
                        templateUrl: '/modules/user/sites/details/surveys/survey-tabs.html',
                    }
                }),
                 ncyBreadcrumb: {
                    label: '{{ ::"SURVEYS" | translate }}'
                }

            })
            .state('dashboard.user.sites.list.details.surveys.schedules', {
                url: '/schedules?schedulePage&lscheduleLimit&scheduleOrder&coreClients',
                parent: 'dashboard.user.sites.list.details.surveys',
                views: parseBook({
                    'tab-panel@dashboard.user.sites.list.details.surveys': {
                        templateUrl: '/modules/user/sites/details/surveys/schedules/list.html',
                        controller: 'UserSiteSurveyScheduleListController',
                        controllerAs: 'vm'
                    }
                }),
                resolve: {
                    surveySchedulesCollectionResponse: function (apiClient, siteResponse, $stateParams) {
                        var params = angular.extend({}, $stateParams, {
                            page: $stateParams.schedulePage,
                            limit: $stateParams.scheduleLimit,
                            order: $stateParams.scheduleOrder,
                            site: siteResponse.id,
                            'coreCLients[]': $stateParams.coreClients
                        });
                        return apiClient.get(siteResponse.getLink('surveyschedules'), params).then(function (response) {
                            return response;
                        });
                    }
                },
                params: {
                    schedulePage: '1',
                    scheduleLimit: '30',
                    scheduleOrder: 'id',
                    coreClients: {array: true}
                },
                ncyBreadcrumb: {
                    label: '{{ ::"SURVEY_SCHEDULES" | translate }}'
                }
            })
            .state('dashboard.user.sites.list.details.surveys.schedules.details', {
                url: '/details/{scheduleResource}',
                parent: 'dashboard.user.sites.list.details.surveys.schedules',
                views: parseBook({
                    'tab-panel@dashboard.user.sites.list.details.surveys': {
                        templateUrl: '/modules/user/sites/details/surveys/schedules/details/details.html',
                        controller: 'SurveyScheduleDetailsController',
                        controllerAs: 'vm'
                    }
                }),
                resolve: {
                    scheduleResponse: function (apiClient, $stateParams) {
                        return apiClient.get($stateParams.scheduleResource.decode());
                    }
                },
                ncyBreadcrumb: {
                    label: '{{ ::"SURVEY_SCHEDULE" | translate }}'
                }
            })
            .state('dashboard.user.sites.list.details.surveys.schedules.details.edit', {
                url: '/edit',
                parent: 'dashboard.user.sites.list.details.surveys.schedules.details',
                views: parseBook({
                    'tab-panel@dashboard.user.sites.list.details.surveys': {
                        templateUrl: '/modules/user/sites/details/surveys/schedules/add-edit/add-edit.html',
                        controller: 'AddEditSurveyScheduleController',
                        controllerAs: 'vm'
                    }
                }),
                resolve: {
                    partnershipsCollection: function (apiClient, siteResponse, $stateParams) {
                        return apiClient.get(siteResponse.getLink('partnerships'), angular.extend($stateParams, {active: 1}));
                    },
                    surveysCollection : function (apiClient, scheduleResponse, siteResponse, rootResourceResponse) {
                        var param = {
                            status: 'active',
                            site: siteResponse.id,
                            serviceProvider: scheduleResponse._links.serviceprovider.id
                        };
                        return apiClient.get(rootResourceResponse.getLink('surveys'), param);
                    },
                },
                ncyBreadcrumb: {
                    label: '{{ ::"EDIT" | translate }}'
                }
            })
            .state('dashboard.user.sites.list.details.surveys.schedules.add', {
                url: '/add',
                parent: 'dashboard.user.sites.list.details.surveys.schedules',
                views: parseBook({
                    'tab-panel@dashboard.user.sites.list.details.surveys': {
                        templateUrl: '/modules/user/sites/details/surveys/schedules/add-edit/add-edit.html',
                        controller: 'AddEditSurveyScheduleController',
                        controllerAs: 'vm'
                    }
                }),
                resolve: {
                    partnershipsCollection: function (apiClient, siteResponse, $stateParams) {
                        return apiClient.get(siteResponse.getLink('partnerships'), { active: 1 });
                    },
                    surveysCollection : function (rootResourceResponse) {
                        return rootResourceResponse.getResource('surveys');
                    },
                    scheduleResponse : function () {
                    }
                },
                ncyBreadcrumb: {
                    label: '{{ ::"ADD" | translate }}'
                }
            })
            .state('dashboard.user.sites.list.details.surveys.active', {
                url: '/active?surveyStatus&{serviceProviderName:any}&surveyName&reportingMonth&reportingYear&scoreLessThan&coreClients&surveyPage&surveyLimit&surveyOrder&reportingPeriod',
                parent: 'dashboard.user.sites.list.details.surveys',
                views: parseBook({
                    'tab-panel@dashboard.user.sites.list.details.surveys': {
                        templateUrl: '/modules/common/survey/survey-requests/list/survey-requests.html',
                        controller: 'SurveyRequestsListController',
                        controllerAs: 'vm'
                    }
                }),
                resolve: {
                    surveyRequestCollectionResponse: function ($stateParams, apiClient, user, siteResponse) {
                        var params = {
                            page: $stateParams.surveyPage,
                            limit: $stateParams.surveyLimit,
                            order: $stateParams.surveyOrder,
                            reportingPeriod: $stateParams.reportingPeriod ? moment(new Date($stateParams.reportingPeriod)).format('MM/YYYY') : null,
                            scoreLessThan: $stateParams.scoreLessThan,
                            site: siteResponse.id,
                            'status[]': $stateParams.surveyStatus,
                            'siteRegions[]': $stateParams.siteRegions,
                            name: $stateParams.surveyName,
                            'coreClients[]': $stateParams.coreClients
                        };
                        return apiClient.getPage(siteResponse.getLink('surveyrequests'), angular.extend({}, $stateParams, params));
                    },
                    coreClientCollectionResponse: function(apiClient, rootResourceResponse) {
                        return apiClient.get(rootResourceResponse.getLink('coreclients')).then(function (response) {
                            return response || apiClient.noResourceFound();
                        });
                    },
                    serviceProviderGroupCollectionResponse: function($stateParams, apiClient, rootResourceResponse) {
                        return apiClient.get(rootResourceResponse.getLink('serviceprovidergroups')).then(function(response) {
                            return response || apiClient.noResourceFound();
                        });
                    },
                    siteGroupCollectionResponse: function(apiClient, rootResourceResponse) {
                        if (rootResourceResponse.getLink('sitegroups')) {
                            return apiClient.get(rootResourceResponse.getLink('sitegroups')).then(function (response) {
                                return response || apiClient.noResourceFound();
                            });
                        }
                    },
                    regionCollectionResponse: function(apiClient, rootResourceResponse) {
                        return apiClient.get(rootResourceResponse.getLink('regions')).then(function (response) {
                            return response || apiClient.noResourceFound();
                        });
                    },
                },
                params: {
                    surveyPage: "1",
                    surveyLimit: "30",
                    surveyOrder: "id",
                    surveyStatus: {array: true},
                    serviceProviderName: null,
                    scoreLessThan: null,
                    surveyName: null,
                    coreClient: null,
                    siteGroup: null,
                    siteRegions: {array: true},
                    serviceProviderGroup: null,
                    reportingPeriod: null,
                    coreClients: {array: true}
                },
                 ncyBreadcrumb: {
                    label: '{{ ::"SURVEY_REQUESTS" | translate }}'
                }
            })
            .state('dashboard.user.sites.list.details.surveys.active.add', {
                url: '/add',
                parent: 'dashboard.user.sites.list.details.surveys.active',
                views: parseBook({
                    'tab-panel@dashboard.user.sites.list.details.surveys': {
                        templateUrl: '/modules/common/survey/survey-requests/add-edit/survey-request-form.html',
                        controller: 'SurveyRequestAddController',
                        controllerAs: 'vm'
                    }
                }),
                resolve: {
                    surveyResponse: function () {
                        return {};
                    },
                    partnershipsResponse: function(apiClient, siteResponse) {
                        if (siteResponse) {
                            return apiClient.get(siteResponse.getLink('partnerships'), { serviceProvidersStatus: 'active' });
                        } else {
                            return null;
                        }
                    }
                },
                ncyBreadcrumb: {
                    label: '{{ ::"ADD" | translate }}'
                }
            })
            .state('dashboard.user.sites.list.details.surveys.active.details', {
                url: '/details/{surveyResource}',
                parent: 'dashboard.user.sites.list.details.surveys.active',
                views: parseBook({
                    'tab-panel@dashboard.user.sites.list.details.surveys': {
                        templateUrl: '/modules/common/survey/survey-requests/details/survey-request.details.html',
                        controller: 'SurveyRequestDetailsController',
                        controllerAs: 'vm'
                    }
                }),
                resolve: {
                    surveyRequestResponse: function ($stateParams, apiClient, base64) {
                        return apiClient.get(base64.decode($stateParams.surveyResource));
                    },
                    surveyResponse: function (surveyRequestResponse) {
                        return surveyRequestResponse.getResource('survey');
                    },
                    surveyRequestLinesResponse: function (surveyRequestResponse) {
                        return surveyRequestResponse.getResource('lines');
                    },
                    config: function(){
                        return {
                            site: true
                        }
                    }
                },
                ncyBreadcrumb: {
                    label: '{{ ::$resolves().surveyResponse.name }}'
                }
            })
            .state('dashboard.user.sites.list.details.surveys.active.details.edit', {
                url: '/edit',
                parent: 'dashboard.user.sites.list.details.surveys.active.details',
                views: parseBook({
                    'tab-panel@dashboard.user.sites.list.details.surveys': {
                        templateUrl: '/modules/common/survey/survey-requests/add-edit/survey-request-edit-form.html',
                        controller: 'SurveyRequestEditController',
                        controllerAs: 'vm'
                    }
                }),
                ncyBreadcrumb: {
                    label: '{{ ::"EDIT" | translate }}'
                }
            });
    }

    function registerRoutesMeters($stateProvider) {
        elbMetersRouting().addRoutes($stateProvider, 'dashboard.user.sites.list.details.meters', true, false);
    }

    function registerRoutesAssociates($stateProvider) {
        $stateProvider
            .state('dashboard.user.sites.list.details.associates', {
                url: '/associates',
                parent: 'dashboard.user.sites.list.details',
                views: parseBook({
                    'elogbooks-right@dashboard.user.sites.list.details': {
                        templateUrl: '/modules/user/sites/details/associates/associates-list.html',
                        controller: 'UserSitesAssociatesController',
                        controllerAs: 'vm'
                    }
                }),
                resolve: {
                     quoteThresholdsCollectionResponse: function (apiClient, siteResponse, lodash) {
                        if (siteResponse.getLink('thresholds')) {
                            return apiClient.get(siteResponse.getLink('thresholds')).then(function (response) {
                                if( response !== 'undefined') {
                                    var unlimitedThreshold = lodash.remove(response.quoteThresholds, function(threshold) { return typeof threshold.value === 'undefined' });
                                    response.quoteThresholds.unshift.apply(response.quoteThresholds, unlimitedThreshold);

                                    return response;
                                } else {
                                    apiClient.noResourceFound();
                                }
                            });
                        }
                    }
                },
                ncyBreadcrumb: {
                    label: '{{ ::"ASSOCIATES" | translate }}'
                }
            })
            .state('dashboard.user.sites.list.details.associates.edit', {
                url: '/edit',
                parent: 'dashboard.user.sites.list.details.associates',
                views: parseBook({
                    'elogbooks-right@dashboard.user.sites.list.details': {
                        templateUrl: '/modules/common/associates/associates-form.html',
                        controller: 'AssociatesEditController',
                        controllerAs: 'vm'
                    }
                }),
                resolve: {
                    membershipsResponse: function (siteResponse, apiClient) {
                        return apiClient.get(siteResponse.getLink('memberships'), {
                            limit: 30,
                            permission: ['site_permission_can_be_associate'],
                            datagroup: 'list',
                            userActive: true
                        });
                    }
                },
                scroll: false,
                ncyBreadcrumb: {
                    label: '{{ ::"ASSOCIATES" | translate }}'
                }
            });
    }

    function registerRoutesApprovers($stateProvider) {
        $stateProvider
            .state('dashboard.user.sites.list.details.associates.approvers', {
                url: '/approvers',
                parent: 'dashboard.user.sites.list.details.associates',
                abstract: true,
                ncyBreadcrumb: {
                    skip: true
                }
            })
            .state('dashboard.user.sites.list.details.associates.approvers.edit', {
                url: '/edit',
                parent: 'dashboard.user.sites.list.details.associates.approvers',
                views: {
                    '@dashboard.user.sites.list.details': {
                        templateUrl: '/modules/common/approvers/approvers-form.html',
                        controller: 'ApproversEditController',
                        controllerAs: 'vm'
                    }
                },
                resolve: {
                    membershipsResponse: function (siteResponse, apiClient) {
                        return apiClient.get(siteResponse.getLink('memberships'), {
                            limit: 30,
                            permission: ['site_permission_can_be_approver'],
                            datagroup: 'list',
                            userActive: null
                        });
                    }
                },
                ncyBreadcrumb: {
                    label: '{{ ::"APPROVERS" | translate }}'
                }
            });
    }

    function registerRoutesContacts($stateProvider) {
        $stateProvider
            .state('dashboard.user.sites.list.details.contacts', {
                url: '/contacts?contactName&contactEmail&contactRole',
                parent: 'dashboard.user.sites.list.details',
                views: parseBook({
                    'elogbooks-right@dashboard.user.sites.list.details': {
                        templateUrl: '/modules/user/sites/details/contacts/contacts-list.html',
                        controller: 'UserSitesContactsController',
                        controllerAs: 'vm',
                    },
                    'contacts@dashboard.user.sites.list.details.contacts': {
                        templateUrl: '/modules/common/site-contacts/contacts-list.html',
                        controller: 'CommonSiteContactsController',
                        controllerAs: 'vm',
                    }
                }),
                params: {
                    locationOrder: 'id',
                    contactName: null,
                    contactEmail: null,
                    contactCompany: null,
                    contactRole: null,
                    contactPage: '1',
                    contactLimit: '30',
                    contactOrder: 'name',
                },
                resolve: {
                    contactsResponse: function (apiClient, siteResponse, lodash, $stateParams) {
                        if (!lodash.has(siteResponse, '_links.contacts')) {
                            return {};
                        }

                        var params = angular.extend({}, $stateParams, {
                            name: $stateParams.contactName,
                            email: $stateParams.contactEmail,
                            company: $stateParams.contactCompany,
                            role: $stateParams.contactRole,
                            page: $stateParams.contactPage,
                            limit: $stateParams.contactLimit,
                            order: $stateParams.contactOrder,
                        });


                        return apiClient.get(siteResponse.getLink('contacts'), params).then(function (response) {
                            return response || apiClient.noResourceFound();
                        });
                    },
                    contactInformationResponse: function (siteResponse) {
                        return siteResponse.getResource('contactinformation');
                    },
                    config: function() {
                        return {
                            contactType: 'contact',
                            detailRoute: '.details',
                            label: 'SITE_CONTACT',
                            tabTitle: 'CONTACTS',
                            noBorder: false
                        };
                    },
                    regionCollectionResponse: function() {
                        return null;
                    }
                },
                ncyBreadcrumb: {
                    label: '{{ ::"CONTACTS" | translate }}'
                }
            })
            .state('dashboard.user.sites.list.details.contacts.add', {
                url: '/add',
                parent: 'dashboard.user.sites.list.details.contacts',
                views: parseBook({
                    'elogbooks-right@dashboard.user.sites.list.details': {
                        templateUrl: '/modules/common/site-contacts/add-edit/contacts-addedit.html',
                        controller: 'CommonSiteContactsAddEditController',
                        controllerAs: 'vm'
                    }
                }),
                resolve: {
                    contactResponse: function () {
                        return {};
                    },
                    userCollectionResponse: function (apiClient, user) {
                        return apiClient.get(user.getLink('users'), { status: 'active' }).then(function (response) {
                            return response || apiClient.noResourceFound();
                        });
                    }
                },
                ncyBreadcrumb: {
                    label: '{{ ::"ADD" | translate }}'
                }
            })
           .state('dashboard.user.sites.list.details.contacts.details', {
                url: '/details/{contactResource}',
                parent: 'dashboard.user.sites.list.details.contacts',
                views: parseBook({
                    'elogbooks-right@dashboard.user.sites.list.details': {
                        templateUrl: '/modules/common/site-contacts/details/contacts-details.html',
                        controller: 'CommonSiteContactsDetailsController',
                        controllerAs: 'vm'
                    }
                }),
                resolve: {
                    contactResponse: function (apiClient, $stateParams) {
                        return apiClient.get($stateParams.contactResource.decode()).then(function (response) {
                            return response || apiClient.noResourceFound();
                        });
                    },
                    contactInformationResponse: function (apiClient, contactResponse) {
                        var contactInformationLink = null;

                        if (contactResponse.user) {
                            contactInformationLink = contactResponse.user.getLink('contactinformation');
                        } else {
                            contactInformationLink = contactResponse.getLink('contactinformation');
                        }

                        if (contactInformationLink === null) {
                            return apiClient.noResourceFound();
                        }

                        return apiClient.get(contactInformationLink).then(function (response) {
                            return response || apiClient.noResourceFound();
                        });
                    }
                },
                ncyBreadcrumb: {
                    label: '{{ ::"CONTACT_INFORMATION_ADDRESS" | translate }}'
                }
            })
            .state('dashboard.user.sites.list.details.contacts.details.edit', {
                url: '/edit',
                parent: 'dashboard.user.sites.list.details.contacts.details',
                views: parseBook({
                    'elogbooks-right@dashboard.user.sites.list.details': {
                        templateUrl: '/modules/common/site-contacts/add-edit/contacts-addedit.html',
                        controller: 'CommonSiteContactsAddEditController',
                        controllerAs: 'vm'
                    }
                }),
                resolve: {
                    config: function() {
                        return {
                            contactType: 'contact',
                            detailRoute: '.details',
                            label: 'SITE_CONTACT',
                            mode: 'edit'
                        };
                    }
                },
                ncyBreadcrumb: {
                    label: '{{ ::"EDIT" | translate }}'
                }
            });
    }

    function registerRouteWebhooks($stateProvider) {
        $stateProvider
            .state('dashboard.user.sites.list.details.webhooks', {
                url: '/webhooks?webhookPage&webhookLimit&webhookOrder&webhookName',
                parent: 'dashboard.user.sites.list.details',
                views: {
                    '@dashboard.user.sites.list.details': {
                        templateUrl: '/modules/common/webhooks/list/webhooks-list.html',
                        controller: 'WebhooksListController',
                        controllerAs: 'vm'
                    }
                },
                resolve: {
                    webhooksCollection: function(apiClient, siteResponse, $stateParams) {
                        return apiClient.get(siteResponse.getLink('webhooks'), angular.extend({}, $stateParams, {
                            page: $stateParams.webhookPage,
                            limit: $stateParams.webhookLimit,
                            order: $stateParams.webhookOrder,
                            name: $stateParams.webhookName
                        }));
                    },
                    webhookCreateType: function() {
                        return 'create-site-webhook';
                    }
                },
                ncyBreadcrumb: {
                    label: '{{ ::"WEBHOOKS" | translate }}'
                },
                params: {
                    webhookPage: '1',
                    webhookLimit: '30',
                    webhookOrder: '-id',
                    webhookName: null
                }
            })
            .state('dashboard.user.sites.list.details.webhooks.add', {
                url: '/add',
                parent: 'dashboard.user.sites.list.details.webhooks',
                views: {
                    '@dashboard.user.sites.list.details': {
                        templateUrl: '/modules/common/webhooks/addedit/webhooks-addedit.html',
                        controller: 'WebhooksAddEditController',
                        controllerAs: 'vm'
                    }
                },
                resolve: {
                    webhookResponse: function() {
                        return {};
                    },
                    webhookCreateLink: function(webhooksCollection) {
                        return webhooksCollection.getLink('create-site-webhook');
                    },
                    config: function() {
                        return {
                            noBorder: false
                        }
                    }
                },
                ncyBreadcrumb: {
                    label: '{{ ::"ADD" | translate }}'
                }
            })
            .state('dashboard.user.sites.list.details.webhooks.details', {
                url: '/details/{webhookResource}',
                parent: 'dashboard.user.sites.list.details.webhooks',
                abstract: true,
                resolve: {
                    webhookResponse: function(apiClient, $stateParams) {
                        return apiClient.get($stateParams.webhookResource.decode());
                    }
                },
                ncyBreadcrumb: {
                    label: '{{ ::$resolves().webhookResponse.url }} '
                }
            })
            .state('dashboard.user.sites.list.details.webhooks.details.webhooksinfo', {
                url: '/info',
                parent: 'dashboard.user.sites.list.details.webhooks.details',
                views: {
                    '@dashboard.user.sites.list.details': {
                        templateUrl: '/modules/common/webhooks/details/info/webhooks-info.html',
                        controller: 'WebhooksDetailsInfoController',
                        controllerAs: 'vm'
                    }
                },
                resolve: {
                    config: function () {
                        return {
                            noBorder: false
                        };
                    },
                    serviceProviderResponse: function() {
                        return {};
                    },
                },
                ncyBreadcrumb: {
                    label: '{{ ::"INFO" | translate }}'
                }
            })
            .state('dashboard.user.sites.list.details.webhooks.details.webhooksinfo.edit', {
                url: '/edit',
                parent: 'dashboard.user.sites.list.details.webhooks.details.webhooksinfo',
                views: {
                    '@dashboard.user.sites.list.details': {
                        templateUrl: '/modules/common/webhooks/addedit/webhooks-addedit.html',
                        controller: 'WebhooksAddEditController',
                        controllerAs: 'vm'
                    }
                },
                resolve: {
                    webhookCreateLink: function(webhooksCollection) {
                        return null;
                    },
                    config: function() {
                        return {
                            noBorder: false
                        };
                    }
                },
                ncyBreadcrumb: {
                    label: '{{ ::"EDIT" | translate }}'
                }
            });
    }

    function registerRoutesLocations($stateProvider) {
        $stateProvider
            .state('dashboard.user.sites.list.details.locations', {
                url: '/locations?locationPage&locationLimit&locationOrder&locationName',
                parent: 'dashboard.user.sites.list.details',
                views: {
                    '@dashboard.user.sites.list.details': {
                        templateUrl: '/modules/common/site/details/locations/locations-list.html',
                        controller: 'CommonLocationsController',
                        controllerAs: 'vm'
                    }
                },
                params: {
                    locationPage: '1',
                    locationLimit: '30',
                    locationOrder: 'name',
                    locationActive: false,
                    locationName: null
                },
                resolve: {
                    locationsCollectionResponse: function(apiClient, siteResponse, $stateParams, $state) {
                        return apiClient.get(siteResponse.getLink('locations'), angular.extend({}, $stateParams, {
                            page: $stateParams.locationPage,
                            limit: $stateParams.locationLimit,
                            order: $stateParams.locationOrder,
                            active: $stateParams.locationActive,
                            name: $stateParams.locationName
                        })).then(function(response) {
                            if (response && $stateParams.locationPage != 1 && $stateParams.locationPage > response.pages) {
                                $stateParams.locationPage = 1;
                                return $state.go(
                                    'dashboard.user.sites.list.details.locations',
                                    $stateParams,
                                    {reload: 'dashboard.user.sites.list.details.locations.details'}
                                );
                            }

                            return response || apiClient.noResourceFound();
                        });
                    },
                    billingTypeCollectionResponse: function (apiClient, rootResourceResponse) {
                        if (rootResourceResponse.getLink('billingtypes')) {
                            return apiClient.get(rootResourceResponse.getLink('billingtypes'), {order: 'default'}).then(function(response) {
                                return response || apiClient.noResourceFound();
                            });
                        }
                    },
                    config: function(userManager) {
                        return {
                            readOnly: !userManager.hasPermission('site_permission_edit_locations')
                        };
                    }
                },
                ncyBreadcrumb: {
                    label: '{{ ::"LOCATIONS" | translate }}'
                }
            })
            .state('dashboard.user.sites.list.details.locations.details', {
                url: '/details/{locationResource}',
                parent: 'dashboard.user.sites.list.details.locations',
                abstract: true,
                views: {
                    '@dashboard.user.sites.list.details': {
                        templateUrl: '/modules/common/site/details/locations/tabs.html',
                        controller: 'LocationTabsController',
                        controllerAs: 'vm'
                    }
                },
                params: {
                    childLocationPage: '1',
                    childLocationLimit: '30',
                    childLocationOrder: 'name'
                },
                resolve: {
                    locationResponse: function(apiClient, $stateParams, $state) {
                        return apiClient.get($stateParams.locationResource.decode()).then(function(response) {
                            return response || apiClient.noResourceFound();
                        });
                    },
                    config: function(userManager) {
                        return {
                            readOnly: !userManager.hasPermission('site_permission_edit_locations')
                        };
                    }
                },
                ncyBreadcrumb: {
                    label: '{{ ::$resolves().locationResponse.name }} '
                }
            })
            .state('dashboard.user.sites.list.details.locations.details.info', {
                url: '/info',
                parent: 'dashboard.user.sites.list.details.locations.details',
                views: {
                    'tab-panel@dashboard.user.sites.list.details.locations.details': {
                        templateUrl: '/modules/common/site/details/locations/details/location-details.html',
                        controller: 'CommonLocationDetailsController',
                        controllerAs: 'vm'
                    }
                },
                resolve: {
                    locationsCollectionResponse: function(apiClient, locationResponse, $stateParams, $state) {
                        return apiClient.get(locationResponse.getLink('children'), angular.extend({}, $stateParams, {
                            page: $stateParams.childLocationPage,
                            limit: $stateParams.childLocationLimit,
                            order: $stateParams.locationOrder,
                            active: $stateParams.childLocationActive
                        })).then(function(response) {
                            return response || apiClient.noResourceFound();
                        });
                    }
                },
                ncyBreadcrumb: {
                    label: '{{ ::"INFO" | translate }}'
                }
            })
            .state('dashboard.user.sites.list.details.locations.details.notes', {
                url: '/notes',
                parent: 'dashboard.user.sites.list.details.locations.details',
                views: {
                    'tab-panel@dashboard.user.sites.list.details.locations.details': {
                        templateUrl: '/modules/user/sites/details/notes/notes-list.html',
                        controller: 'UserNotesController',
                        controllerAs: 'vm'
                    }
                },
                resolve: {
                    noteCollectionResponse: function(apiClient, locationResponse) {
                        return apiClient.get(locationResponse.getLink('notes'), {active: 'all'}).then(function(response) {
                            return response || apiClient.noResourceFound();
                        });
                    },
                    siteNoteTypeCollectionResponse: function (apiClient, siteResponse) {
                        return siteResponse.getResource('notetypes');
                    }
                },
                ncyBreadcrumb: {
                    label: '{{ ::"LOCATION_NOTES" | translate }}'
                }
            })
            .state('dashboard.user.sites.list.details.locations.details.add', {
                url: '/details/{parentLocationResponse}/add',
                parent: 'dashboard.user.sites.list.details.locations',
                views: {
                    '@dashboard.user.sites.list.details': {
                        templateUrl: '/modules/admin/sites/details/locations/add-edit/location-form.html',
                        controller: 'LocationAddEditController',
                        controllerAs: 'vm'
                    }
                },
                resolve: {
                    locationResponse: function() {
                        return {};
                    },
                    parentLocationResponse: function(apiClient, $stateParams) {
                        if ($stateParams.parentLocationResponse !== '') {
                            return apiClient.get($stateParams.parentLocationResponse.decode()).then(function (response) {
                                return response || apiClient.noResourceFound();
                            });
                        } else {
                            return {};
                        }
                    }
                },
                ncyBreadcrumb: {
                    label: '{{ ::"ADD" | translate }}'
                }
            })
            .state('dashboard.user.sites.list.details.locations.details.edit', {
                url: '/details/edit',
                parent: 'dashboard.user.sites.list.details.locations.details',
                views: {
                    '@dashboard.user.sites.list.details': {
                        templateUrl: '/modules/admin/sites/details/locations/add-edit/location-form.html',
                        controller: 'LocationAddEditController',
                        controllerAs: 'vm'
                    }
                },
                resolve: {
                    parentLocationResponse: function () {
                        return {};
                    }
                },
                ncyBreadcrumb: {
                    label: '{{ ::"EDIT" | translate }}'
                }
            })
            .state('dashboard.user.sites.list.details.locations.details.notes.edit', {
                url: '/edit',
                parent: 'dashboard.user.sites.list.details.locations.details.notes',
                views: {
                    '@dashboard.user.sites.list.details': {
                        templateUrl: '/modules/admin/sites/details/notes/edit/notes-form.html',
                        controller: 'NotesEditController',
                        controllerAs: 'vm'
                    }
                },
                ncyBreadcrumb: {
                    label: '{{ ::"EDIT" | translate }}'
                }
            });
    }

    function registerRoutesWasteManagement($stateProvider) {
        var params = {
            wasteManagementPage: '1',
            wasteManagementLimit: '30',
            id: null,
            site: null,
            serviceProvider: null,
            wasteType: null,
            unit: null,
            dateStart: null,
            dateEnd: null,
            order: null
        };
        $stateProvider
            .state('dashboard.user.sites.list.details.waste-management-entries', {
                url: '/entries',
                abstract: true,
                parent: 'dashboard.user.sites.list.details',
                views: parseBook({
                    "@dashboard.user.sites.list.details": {
                        templateUrl: '/modules/common/waste-management/waste-management.html',
                        controller: 'UserWasteManagementController',
                        controllerAs: 'vm'
                    }
                }),
                ncyBreadcrumb: {
                    label: '{{ ::"WASTE_MANAGEMENT" | translate }}'
                }
            })
            .state('dashboard.user.sites.list.details.waste-management-entries.list', {
                url: '/list',
                parent: 'dashboard.user.sites.list.details',
                params: params,
                views: parseBook({
                    '@dashboard.user.sites.list.details': {
                        templateUrl: '/modules/common/waste-management/waste-entries-list.html',
                        controller: 'WasteEntriesController',
                        controllerAs: 'vm'
                    }
                }),
                resolve: {
                    wasteEntryCollectionResponse: function ($stateParams, apiClient, user, siteResponse) {
                        var params = $stateParams;

                        params = angular.extend({}, params, {
                            serviceProvider: $stateParams.serviceProvider,
                            wasteType: $stateParams.wasteType,
                            unit: $stateParams.unit,
                            site: siteResponse.id,
                            page: $stateParams.wasteManagementPage,
                            limit: $stateParams.wasteManagementLimit,
                            dateStart: $stateParams.dateStart ? new Date($stateParams.dateStart.toISOString()) : null,
                            dateEnd: $stateParams.dateEnd ? new Date($stateParams.dateEnd.toISOString()) : null
                        });

                        if ($stateParams.serviceProvider) {
                            params.serviceProvider = JSON.parse($stateParams.serviceProvider).v;
                        }

                        if ($stateParams.wasteType) {
                            params.wasteType = JSON.parse($stateParams.wasteType).v;
                        }

                        if ($stateParams.unit) {
                            params.unit = JSON.parse($stateParams.unit).v;
                        }

                        return apiClient.get(user.getLink('waste-entries'), params).then(function(response) {
                            return response;
                        });
                    },
                    wasteTypeCollectionResponse : function (apiClient, $stateParams, rootResourceResponse) {
                        return apiClient.get(rootResourceResponse.getLink('wastetypes'), $stateParams).then(function (response) {
                            return response;
                        });
                    },
                    serviceProviderCollectionResponse: function ($stateParams, apiClient, siteResponse) {
                        if (siteResponse.getLink('serviceproviders')) {
                            return apiClient.get(siteResponse.getLink('serviceproviders'), params).then(function (response) {
                                return response;
                            });
                        }
                    },
                    treatmentTypeCollectionResponse : function (apiClient, $stateParams, rootResourceResponse) {
                        return apiClient.get(rootResourceResponse.getLink('treatmenttypes'), $stateParams).then(function (response) {
                            return response;
                        });
                    },
                    unitCollectionResponse: function (rootResourceResponse, apiClient) {
                        return apiClient.get(rootResourceResponse.getLink('units'));
                    },
                    config: function () {
                        return {
                            site_level: true
                        };
                    }
                },
                ncyBreadcrumb: {
                    label: '{{ ::"WASTE_ENTRIES" | translate }}'
                }
            })
            .state('dashboard.user.sites.list.details.waste-management-entries.list.add', {
                url: '/addWaste',
                parent: 'dashboard.user.sites.list.details.waste-management-entries.list',
                views: parseBook({
                    '@dashboard.user.sites.list.details': {
                        templateUrl: '/modules/common/waste-management/add-edit/waste-entries-form.html',
                        controller: 'WasteEntryAddEditController',
                        controllerAs: 'vm'
                    }
                }),
                resolve: {
                    siteCollectionResponse: function (user, apiClient) {
                        return apiClient.get(user.getLink('sites'));
                    },
                    serviceProviderCollectionResponse: function (user, apiClient) {
                        return apiClient.get(user.getLink('serviceproviders'));
                    },
                    unitCollectionResponse: function (rootResourceResponse, apiClient) {
                        return apiClient.get(rootResourceResponse.getLink('units'), {status: true});
                    },
                    wasteEntryResponse: function () {
                        return {};
                    },
                    treatmentTypeCollectionResponse : function (apiClient, $stateParams, rootResourceResponse) {
                        return apiClient.get(rootResourceResponse.getLink('treatmenttypes'), {status: true}).then(function (response) {
                            return response;
                        });
                    },
                    wasteTypeCollectionResponse : function (apiClient, $stateParams, rootResourceResponse) {
                        return apiClient.get(rootResourceResponse.getLink('wastetypes'), {status: true}).then(function (response) {
                            return response;
                        });
                    },
                    config: function () {
                        return {
                            site_level: true,
                            add: true
                        };
                    }
                },
                ncyBreadcrumb: {
                    label: '{{ ::"ADD" | translate }}'
                }
            })
            .state('dashboard.user.sites.list.details.waste-management-entries.list.details', {
                url: '/details/{entryResource}',
                abstract: true,
                parent: 'dashboard.user.sites.list.details.waste-management-entries.list',
                views: parseBook({
                    '@dashboard.user.sites.list.details': {
                        templateUrl: '/modules/user/sites/details/waste-entry/tabs.html',
                        controller: 'WasteEntryDetailsController',
                        controllerAs: 'vm'
                    }
                }),
                resolve: {
                    wasteEntryResponse: function (apiClient, $stateParams) {
                        return apiClient.get($stateParams.entryResource.decode()).then(function (response) {
                                return response;
                        });
                    },
                    sectionResource: function () {
                        return {
                            infoSref: 'dashboard.user.sites.list.details.waste-management-entries.list.details.info',
                            fileSref: 'dashboard.user.sites.list.details.waste-management-entries.list.details.files'
                        };
                    }
                },
                ncyBreadcrumb: {
                    label: '{{ ::$resolves().wasteEntryResponse.id }}'
                }
            })
            .state('dashboard.user.sites.list.details.waste-management-entries.list.details.info', {
                url: '/info',
                parent: 'dashboard.user.sites.list.details.waste-management-entries.list.details',
                views: parseBook({
                    'tab-panel@dashboard.user.sites.list.details.waste-management-entries.list.details': {
                        templateUrl: '/modules/common/waste-management/details/info/waste-entry-info.html',
                        controller: 'WasteEntryInfoController',
                        controllerAs: 'vm'
                    }
                }),
                ncyBreadcrumb: {
                    label: '{{ ::"INFO" | translate }}'
                }
            })
            .state('dashboard.user.sites.list.details.waste-management-entries.list.details.files', {
                url: '/files',
                parent: 'dashboard.user.sites.list.details.waste-management-entries.list.details',
                views: parseBook({
                    'tab-panel@dashboard.user.sites.list.details.waste-management-entries.list.details': {
                        templateUrl: '/modules/common/files/files-list.html',
                        controller: 'CommonFilesListController',
                        controllerAs: 'vm'
                    }
                }),
                params: {
                    filePage: '1',
                    fileLimit: '30',
                    fileOrder: 'id',
                    fileSelectedSiteResource: null,
                    fileTitle: null,
                    fileUploadedBy: null,
                    fileUploadedDateStart: null,
                    fileUploadedDateEnd: null,
                    fileExpiresDateStart: null,
                    fileExpiresDateEnd: null,
                    fileTags: null,
                    fileIsStatutory: null,
                    documentType: null
                },
                resolve: {
                    fileCollectionResponse: function (apiClient, siteResponse, wasteEntryResponse, $stateParams) {
                        var params = angular.extend({}, $stateParams,
                            {
                                page: $stateParams.filePage,
                                limit: $stateParams.fileLimit,
                                order: $stateParams.fileOrder,
                                title: $stateParams.fileTitle,
                                uploadedBy: $stateParams.fileUploadedBy,
                                uploadedDateStart: $stateParams.fileUploadedDateStart ? new Date($stateParams.fileUploadedDateStart).toISOString() : null,
                                uploadedDateEnd: $stateParams.fileUploadedDateEnd ? new Date($stateParams.fileUploadedDateEnd).toISOString() : null,
                                expiresDateStart: $stateParams.fileExpiresDateStart ? new Date($stateParams.fileExpiresDateStart).toISOString() : null,
                                expiresDateEnd: $stateParams.fileExpiresDateEnd ? new Date($stateParams.fileExpiresDateEnd).toISOString() : null,
                                tags: $stateParams.fileTags,
                                isStatutory: $stateParams.fileIsStatutory,
                                documentType: $stateParams.documentType ? JSON.parse($stateParams.documentType).v : null
                            }
                        );

                        if (siteResponse) {
                            params.site = siteResponse.id;
                        }

                        return apiClient.get(wasteEntryResponse.getLink('files'), params).then(function (response) {
                            return response || apiClient.noResourceFound();
                        });
                    },
                    tagCollectionResponse: function (apiClient, rootResourceResponse) {
                        return apiClient.get(rootResourceResponse.getLink('tags'), {limit: 30}).then(function (response) {
                            return response || apiClient.noResourceFound();
                        });
                    },
                    config: function (fileCollectionResponse) {
                        return {
                            jobId: false,
                            showStatutoryType: false,
                            hideAddButton: !fileCollectionResponse.canCreate,
                            overrideRoute: 'dashboard.user.sites.list.details.waste-management-entries.list.details.files.details',
                            widerNameColumn: true
                        };
                    },
                    integrationDocTypesResponse: function () {
                        return null;
                    },
                    documentTypeCollectionResponse : function (apiClient, rootResourceResponse) {
                        return apiClient.get(rootResourceResponse.getLink('documenttypes'), {status: 'active', order: 'name'})
                            .then(function (response) {
                                return response || apiClient.noResourceFound();
                            });
                    },
                    serviceProviderResponse: function () {
                        return null;
                    },
                    operativeCollectionResponse: function () {
                        return null;
                    }
                },
                ncyBreadcrumb: {
                    label: '{{ ::"FILES" | translate }}'
                }
            })
            .state('dashboard.user.sites.list.details.waste-management-entries.list.details.files.details', {
                url: '/{fileResource}',
                parent: 'dashboard.user.sites.list.details.waste-management-entries.list.details.files',
                views: parseBook({
                    'tab-panel@dashboard.user.sites.list.details.waste-management-entries.list.details': {
                        templateUrl: '/modules/common/files/details/files-details.html',
                        controller: 'CommonFilesDetailsController',
                        controllerAs: 'vm'
                    }
                }),
                resolve: {
                    fileResponse: function(apiClient, $stateParams) {
                        return apiClient.get($stateParams.fileResource.decode(), {datagroup: 'details'}).then(function(response) {
                            return response || apiClient.noResourceFound();
                        });
                    },
                    config: function (config) {
                        return angular.extend(config, {
                            showVisibility: true,
                            noBorder: true
                        });
                    }
                },
                ncyBreadcrumb: {
                    label: '{{ ::$resolves().fileResponse.title }}'
                }
            })
            .state('dashboard.user.sites.list.details.waste-management-entries.list.details.files.details.edit', {
                url: '/edit',
                parent: 'dashboard.user.sites.list.details.waste-management-entries.list.details.files.details',
                views: parseBook({
                    'tab-panel@dashboard.user.sites.list.details.waste-management-entries.list.details': {
                        templateUrl: '/modules/common/files/add-edit/files-add-edit.html',
                        controller: 'CommonFilesAddEditController',
                        controllerAs: 'vm'
                    }
                }),
                resolve: {
                    config: function (config) {
                        return angular.extend(config, {
                            showVisibility: true,
                            noBorder: true
                        });
                    }
                },
                ncyBreadcrumb: {
                    label: '{{ ::"FILE_EDIT" | translate }}'
                }
            })
            .state('dashboard.user.sites.list.details.waste-management-entries.list.details.files.add', {
                url: '/add',
                parent: 'dashboard.user.sites.list.details.waste-management-entries.list.details.files',
                views: parseBook({
                    'tab-panel@dashboard.user.sites.list.details.waste-management-entries.list.details': {
                        templateUrl: '/modules/common/waste-management/details/files/add/files-add.html',
                        controller: 'CommonWasteEntryFileAddController',
                        controllerAs: 'vm'
                    }
                }),
                ncyBreadcrumb: {
                    label: '{{ ::"ADD" | translate }}'
                }
            })
            .state('dashboard.user.sites.list.details.waste-management-entries.list.details.info.edit', {
                url: '/edit',
                parent: 'dashboard.user.sites.list.details.waste-management-entries.list.details.info',
                views: parseBook({
                    '@dashboard.user.sites.list.details': {
                        templateUrl: '/modules/common/waste-management/add-edit/waste-entries-form.html',
                        controller: 'WasteEntryAddEditController',
                        controllerAs: 'vm'
                    }
                }),
                resolve: {
                    unitCollectionResponse: function (rootResourceResponse, apiClient) {
                        return apiClient.get(rootResourceResponse.getLink('units'), {status: true});
                    },
                    wasteTypeCollectionResponse : function (apiClient, $stateParams, rootResourceResponse) {
                        return apiClient.get(rootResourceResponse.getLink('wastetypes'), {status: true}).then(function (response) {
                            return response;
                        });
                    },
                    treatmentTypeCollectionResponse : function (apiClient, $stateParams, rootResourceResponse) {
                        return apiClient.get(rootResourceResponse.getLink('treatmenttypes'), {status: true}).then(function (response) {
                            return response;
                        });
                    },
                    config: function () {
                        return {
                            site_level: true,
                            add: false
                        };
                    }
                },
                ncyBreadcrumb: {
                    label: '{{ ::"EDIT" | translate }}'
                }
            });
    }

    function registerRoutesTasks($stateProvider){
        $stateProvider
            .state('dashboard.user.sites.list.details.tasks',{
                url: '/manage-tasks',
                abstract: true,
                parent: 'dashboard.user.sites.list.details',
                views: parseBook({
                    'elogbooks-right@dashboard.user.sites.list.details': {
                        templateUrl: '/modules/user/sites/details/tasks/tasks-tabs.html'
                    }
                }),
                ncyBreadcrumb: {
                    label: '{{ ::"TASKS" | translate }}'
                }
            })
            .state('dashboard.user.sites.list.details.tasks.planner-view', {
                url: '/planner-view?taskPage&taskLimit&taskYear&taskName&{taskServiceProviderName:any}&taskStatus&taskMasterTask&siteIds&taskServiceType&taskStatutoryType&taskOperativeName&regionId&taskAssetId&taskFrequency&taskApprovalStatus&taskOrder&taskApproved',
                parent: 'dashboard.user.sites.list.details.tasks',
                views: parseBook({
                    "tab-panel@dashboard.user.sites.list.details.tasks": {
                        templateUrl: '/modules/common/tasks/view-planner/task-planner.html',
                        controller: 'UserTasksPlannerController',
                        controllerAs: 'vm'
                    }
                }),
                params: {
                    taskYear: (new Date()).getFullYear().toString(),
                    taskServiceType: null,
                    taskStatus: 'active',
                    regionId: null,
                    taskOperativeName: null,
                    taskAssetId: null,
                    taskServiceProviderName: null,
                    taskFrequency: null,
                    taskApprovalStatus: null,
                    taskMasterTask: null,
                    siteIds: {array: true},
                    taskStatutoryType: {array: true},
                    taskOrder: null,
                    taskApproved: 'true',
                    taskName: null,
                    taskPage: '1',
                    taskLimit:'30',
                    documentTypes: {array: true},
                },
                resolve: {
                    siteResource: function (siteResponse) {
                        return siteResponse;
                    },
                    serviceProviderCollection: function ($stateParams, apiClient, user, siteResponse) {
                        var params = {};
                        if (siteResponse) {
                            params.siteId = siteResponse.id;
                        }

                        return apiClient.get(user.getLink('serviceproviders'), params);
                    },
                    tasksCollectionResource: function ($stateParams, apiClient, user, siteResponse, base64) {
                        var params = {};
                        params.datagroup = 'planner';

                        $stateParams.siteIds = [siteResponse.id];
                        $stateParams.siteResource = base64.encode(siteResponse.getLink('self'));

                        if (typeof $stateParams.taskStatutoryType === 'undefined') {
                            $stateParams.taskStatutoryType = [];
                        }

                        params = angular.extend({}, params, {
                            'siteIds[]': $stateParams.siteIds,
                            'statutoryType[]': $stateParams.taskStatutoryType,
                            'regionId': $stateParams.regionId ? JSON.parse($stateParams.regionId).v : null,
                            'assetId': $stateParams.taskAssetId ? JSON.parse($stateParams.taskAssetId).v : null,
                            'year': $stateParams.taskYear,
                            'serviceType': $stateParams.taskServiceType,
                            'status': $stateParams.taskStatus,
                            'operativeName': $stateParams.taskOperativeName,
                            'serviceProviderName': $stateParams.taskServiceProviderName,
                            'frequency': $stateParams.taskFrequency,
                            'approvalStatus': $stateParams.taskApprovalStatus,
                            'order': $stateParams.taskOrder,
                            'approved': $stateParams.taskApproved,
                            'name': $stateParams.taskName,
                            'page': $stateParams.taskPage,
                            'limit': $stateParams.taskLimit,
                            'documentTypes[]':$stateParams.documentTypes
                        });

                        return apiClient.get(user.getLink('tasks'), params);
                    },
                    taskGenerateRequestsCollectionResource: function ($stateParams, apiClient, user) {
                        return [];
                    },
                    documentTypesCollectionResponse: function (apiClient, rootResourceResponse) {
                        if (rootResourceResponse.getLink('documenttypes')) {
                            return apiClient.get(rootResourceResponse.getLink('documenttypes'), null, 'long').then(function (response) {
                                return response || apiClient.noResourceFound();
                            });
                        }
                    },
                    statutoryTypeCollectionResource: function (apiClient, rootResourceResponse) {
                        if (rootResourceResponse.getLink('statutorytypes')) {
                            return apiClient.get(rootResourceResponse.getLink('statutorytypes')).then(function (response) {
                                return response || apiClient.noResourceFound();
                            });
                        }
                    },
                    selectedSiteFilterResponse: function($stateParams, siteCollectionResponse) {
                        return siteCollectionResponse.sites.find(({id}) => id === Number($stateParams.siteIds[0])) || null
                    },
                    config: function () {
                        return {
                            siteLevel: true,
                            noBorder: true
                        };
                    }
                },
                ncyBreadcrumb: {
                    label: '{{ ::"TASK_PLANNER" | translate }}'
                }
            });
    }
})();
