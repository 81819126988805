(function () {
    'use strict';

    angular
        .module('elogbooks.admin.users')
        .controller('UserOperativeController', UserOperativeController);

    UserOperativeController.$inject = ['apiClient', 'lodash', 'confirmationModal', 'operativesResponse', 'userResponse', 'operativePermissions', 'rootResourceResponse', 'messenger', 'filterPermissions'];

    function UserOperativeController(apiClient, lodash, confirmationModal, operativesResponse, userResponse, operativePermissions, rootResourceResponse, messenger, filterPermissions) {

        var vm = this;
        vm.loadOperatives = loadOperatives;
        vm.addOperative = addOperative;
        vm.deleteOne = deleteOne;
        vm.toggleAll = toggleAll;
        vm.toggleOne = toggleOne;
        vm.removeSinglePermission = removeSinglePermission;
        vm.addPermissions = addPermissions;
        vm.operatives = operativesResponse.getData().operatives;
        vm.pages = operativesResponse.getData().pages;
        vm.operativesResponse = operativesResponse;
        vm.rootResourceResponse = rootResourceResponse;
        vm.removePermissions = removePermissions;
        vm.permissions = operativePermissions.getAllTranslated();
        vm.selectedPermissions = [];
        vm.selectedOperatives = [];
        vm.allSelected;

        angular.forEach(vm.operatives, function (value, index) {
            vm.operatives[index].permissions = filterPermissions.filterAllowedPermissions(vm.operatives[index].permissions, operativePermissions.getAll());
            vm.operatives[index].checked = false;
        });

        vm.serviceProviderSelect = {
            link : rootResourceResponse ? rootResourceResponse.getLink('serviceproviders') : '',
            responseKeyPath: 'serviceproviders',
            searchKey: 'name',
            required: true
        };

        function loadOperatives() {
            apiClient.getPage(operativesResponse.getLink('self')).then(function (response) {
                vm.operatives = response.getData().operatives;
                vm.pages = response.getData().pages;
                vm.allSelected = false;
            });
        }

        function addOperative() {
            var requestData = {
                _links: {
                    serviceProvider: {
                        href: vm.serviceProviderSelect.selected.object.getLink('self')
                    }
                }
            };

            apiClient.create(userResponse.getLink('operatives'), requestData).then(function (response) {
                if (response) {
                    vm.loadOperatives();
                    messenger.success('SERVICE_PROVIDERS_UPDATED');
                }
            });
        }

        function deleteOne(operative) {
            return confirmationModal.open().result.then(
                function() {
                    // YES was clicked
                    apiClient.delete(operative._links.delete.href).then(function (response) {
                        if (response) {
                            vm.loadOperatives();
                             messenger.success('SERVICE_PROVIDERS_UPDATED');
                        }
                    });
                },
                function() {
                    // NO was clicked
                }
            );
        }

        function toggleAll(allSelected) {
            vm.selectedOperatives = [];
            if (allSelected) {
                lodash.each(vm.operatives, function (operative) {
                    operative.checked = true;
                    vm.selectedOperatives.push(operative);
                });
            } else {
                lodash.each(vm.operatives, function (operative) {
                    operative.checked = false;
                });
            }
        }

        function toggleOne(operative) {
            if (operative.checked) {
                vm.selectedOperatives.push(operative);
            } else {
                vm.selectedOperatives = lodash.without(vm.selectedOperatives, operative);
            }
        }

        function removeSinglePermission(operative, permission) {
            operative.permissions = lodash.without(operative.permissions, permission);

            apiClient.update(operative._links.self.href, { permissions: operative.permissions }).then(function (response) {
                if (!response) {
                    operative.permissions.push(permission);
                }
                else {
                    messenger.success('SERVICE_PROVIDER_PERMISSIONS_UPDATED');
                }
            });
        }

        function addPermissions() {
            var requestData = {
                _links: {
                    operative: []
                },
                additions: []
            }, permissions = [];

            lodash.each(vm.selectedPermissions, function (permission) {
                permissions.push(permission.label);
            });

            requestData.additions = permissions;
            lodash.each(vm.selectedOperatives, function (operative) {
                requestData._links.operative.push(operative._links.self);
                operative.permissions = lodash.union(operative.permissions, permissions);
            });

            // TODO use advertised link when EWV-425 is complete
            apiClient.create(userResponse.getLink('operatives') + '/permission-changeset', requestData).then(function (response) {
                messenger.success('SERVICE_PROVIDER_PERMISSIONS_UPDATED');
            });
        };

        function removePermissions() {
            var requestData = {
                _links: {
                    operative: []
                },
                subtractions: []
            }, permissions = [];
            lodash.each(vm.selectedPermissions, function (permission) {
                permissions.push(permission.label);
            });
            requestData.subtractions = permissions;
            lodash.each(vm.selectedOperatives, function (operative) {
                requestData._links.operative.push(operative._links.self);
                operative.permissions = lodash.difference(operative.permissions, permissions);
            });
            // TODO use advertised link when EWV-425 is complete
            apiClient.create(userResponse.getLink('operatives') + '/permission-changeset', requestData).then(function (response) {
                messenger.success('SERVICE_PROVIDER_PERMISSIONS_UPDATED');
            });
        }
    }
})();
