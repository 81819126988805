app.config(function ($translateProvider) {
    $translateProvider.translations('en-gb', {
        TYPE: 'Type',
        MIN_VALUE: 'Min Value',
        MAX_VALUE: 'Max Value',
        MIN_LENGTH: 'Min Length',
        MAX_LENGTH: 'Max Length',
        CHOICES: 'Choices',
        BE_APPROVER: 'Needs to be approver',
        REQUIRED: 'Required',
        NUMBER_LEGEND: 'Number Legend',

        //Statuses
        DRAFT: 'Draft',
        TRAINING: 'Training',
        ACTIVE: 'Active',
        INACTIVE: 'Inactive',
        YES: 'Yes',
        NO: 'No',

        // general
        IMPORTS_ID: 'Id',
        IMPORTS_FULLNAME: 'Full name',
        IMPORTS_NAME: 'Name',
        IMPORTS_ADDRESS1: 'Address line 1',
        IMPORTS_ADDRESS2: 'Address line 2',
        IMPORTS_ADDRESS3: 'Address line 3',
        IMPORTS_TOWN: 'Address town',
        IMPORTS_REGION: 'Address region',
        IMPORTS_POSTCODE: 'Address postcode',
        IMPORTS_COUNTRY: 'Address country',
        IMPORTS_STATUS: 'Current status',
        IMPORTS_TELEPHONE: 'Primary telephone number',
        IMPORTS_SECONDARY_TELEPHONE: 'Secondary telephone numbers',
        IMPORTS_WEBSITE: 'Website',
        IMPORTS_CITY: 'City',

        IMPORTS_TELEPHONE_NOTES: 'Primary telephone number notes',
        IMPORTS_SECONDARY_TELEPHONE_NOTES: 'Secondary telephone number notes',

        IMPORTS_SERVICE_PROVIDERS: 'Service provider names',
        IMPORTS_SERVICE_PROVIDER_IDS: 'Service provider Ids',
        IMPORTS_SERVICE_PROVIDER_ID: 'Service provider Id',
        IMPORTS_SERVICE_PROVIDER: 'Service provider (Id or Name)',
        IMPORTS_SERVICE_PROVIDER_NAME: 'Service provider name',
        IMPORTS_SERVICE_ROUTINE_IDS: 'Service routine Ids',
        IMPORTS_SERVICE_ROUTINE_ID: 'Service routine Id',
        IMPORTS_SERVICE_TYPES: 'Service type names',
        IMPORTS_SERVICE_TYPES_IDS: 'Service type Ids',
        IMPORTS_STATUTORY_ID: 'Statutory Id',
        IMPORTS_LOCATION: 'Location Name, ID Or New Location Name',
        IMPORTS_ROLES: 'Roles',
        IMPORTS_ROLE_IDS: 'Role Ids',
        IMPORTS_DESCRIPTION: 'Description',
        IMPORTS_UTILITY_CONSULTANT: 'Utility Consultant',
        IMPORTS_SITE_IDS: 'Site ids',
        IMPORTS_SITE_ID: 'Site id',
        IMPORTS_SITE: 'Site (Id or Name)',
        IMPORTS_JOB_GROUP_ID: 'Job group id',
        IMPORTS_ACTIVE: 'Active',
        IMPORTS_REFERENCE: 'Reference',
        IMPORTS_EXTERNAL_REFERENCE: 'External Reference',
        IMPORTS_TRAINED_AT: 'Trained at',
        IMPORTS_ACTIVE_AT: 'Active at',
        IMPORTS_INACTIVE_AT: 'Inactive at',
        IMPORTS_GROUP_ID: 'Group id',
        IMPORTS_SERVICE_TYPE_ID: 'Service Type id',
        IMPORTS_BILLING_TYPE: 'Billing Type name',
        IMPORTS_BILLING_TYPE_COST: 'Billing cost',
        IMPORTS_BILLING_TYPE_ID: 'Billing Type id',

        //User legend translations
        IMPORTS_NEW_CUSTOMER_ACCESS: 'New customer access',
        IMPORTS_USERNAME: 'Username',
        IMPORTS_EMAIL: 'Valid email address',
        IMPORTS_POSITION: 'Position in the company',
        IMPORTS_NOTES: 'Notes',
        IMPORTS_DEPARTMENT: 'Department Id',
        IMPORTS_USER_CATEGORY: 'User Category',

        //assets
        IMPORTS_MAKE: 'Make',
        IMPORTS_QUANTITY: 'Quantity',
        IMPORTS_MODEL: 'Model',
        IMPORTS_BARCODE: 'Barcode',
        IMPORTS_END_OF_LIFE: 'End of life',
        IMPORTS_CHARGEABLE: 'Chargable',
        IMPORTS_BUSINESS_CONTINUITY_RISK: 'Business continuity risk',
        IMPORTS_REPLACEMENT_COST_LABOUR: 'Replacement cost labour',
        IMPORTS_REPLACEMENT_COST_MATERIALS: 'Replacement cost materials',
        IMPORTS_ASSET_TYPE_ID: 'Asset type id',
        IMPORTS_SERIAL_NUMBER: 'Serial number',
        IMPORTS_ASSET_NFC_ID: 'NFC tag id',
        IMPORTS_LOCATION_ID: 'Location Id',
        IMPORTS_ASSET_SCORE_TYPE_IDS: 'Asset Score Type Ids',

        //service provider
        IMPORTS_PRIMARY_JOB_OPERATIVE_ID: 'Primary Job Operative Id',
        IMPORTS_PRIMARY_QUOTE_OPERATIVE_ID: 'Primary Quote Operative Id',
        IMPORTS_PRIMARY_QUOTE_REVIEWER_OPERATIVE_ID: 'Primary Quote Reviewer Operative Id',
        IMPORTS_PRIMARY_OPERATIVE_ID: 'Primary Operative Id',
        IMPORTS_PRIMARY_ESCALATION_OPERATIVE_ID: 'Primary Escalation Operative Id',
        IMPORTS_PRIMARY_HEALTH_SAFETY_OPERATIVE_ID: 'Primary Health & Safety Operative Id',
        IMPORTS_OPERATIVE_IDS: 'Operative Ids',
        IMPORTS_SERVICE_PROVIDER_CONTRACTOR_APPROVED: 'Contractor Approved',
        IMPORTS_SERVICE_PROVIDER_REQUIRE_SIGNATURE: 'Require Signature',
        IMPORTS_GROUP_IDS: 'Groups Id\'s',
        IMPORTS_SERVICE_PROVIDER_PPM_ALERT_OPTION: 'Upcoming PPM Email',
        IMPORTS_SERVICE_PROVIDER_PPM_NOTIFICATIONPERIOD: 'PPM reminder notification period',
        IMPORTS_SERVICE_PROVIDER_SAFECONTRACTOR_ID: 'SafeContractor Id',

        PPM_REMINER_DISABLED: 'Disabled',
        PPM_REMINDER_STATUTORY_ONLY: 'Statutory only',
        PPM_REMINDER_ALL: 'Remind PPM',

        //site
        IMPORTS_AREA: 'Area',
        IMPORTS_AREA_UNITS: 'Area units',
        IMPORTS_SECTOR_ID: 'Sector id',
        IMPORTS_REGION_ID: 'Region id',
        IMPORTS_EXCLUDE_SITE_FROM_P2P: 'Exclude site from p2p',
        IMPORTS_P2P_STARTED_AT_DATE: 'P2P Started At Date',
        IMPORTS_JOB_APPROVAL_REQUIRED: 'Job approval required',
        IMPORTS_JOB_APPROVERS_ID: 'Job approvers Id',
        IMPORTS_JOB_EXTENSIONS_APPROVAL_REQUIRED: 'Job extensions approval required',
        IMPORTS_JOB_EXTENSIONS_APPROVERS_ID: 'Job extensions approvers Id',
        IMPORTS_QUOTE_APPROVERS_ID: 'Quote approvers Id',
        IMPORTS_PPM_APPROVAL_REQUIRED: 'PPM approval required',
        IMPORTS_PPM_APPROVERS_ID: 'PPM Approvers Id',
        IMPORTS_PPM_EXTENSIONS_APPROVAL_REQUIRED: 'PPM extensions approval required',
        IMPORTS_PPM_EXTENSIONS_APPROVERS_ID: 'PPM extensions Approvers Id',
        IMPORTS_CONTACT_APPROVAL_REQUIRED: 'Contact approval required',
        IMPORTS_CONTACT_APPROVERS_ID: 'Contact approvers Id',
        IMPORTS_SURVEY_APPROVERS_ID: 'Survey approvers Id',
        IMPORTS_SURVEY_APPROVAL_REQUIRED: 'Survey approval required',
        IMPORTS_SURVEY_APPROVAL_APPROVERS_ID: 'Survey approvers Id',
        IMPORTS_SERVICE_PROVIDERS_ID: 'Service providers Id',
        IMPORTS_REFERENCE_PREFIX: 'Reference Prefix',

        IMPORTS_SITE_ASSOCIATE_ID1: 'Import Site Associate id1',
        IMPORTS_SITE_ASSOCIATE_USERS_ID1: 'Import Site Associate Users id1',
        IMPORTS_SITE_ASSOCIATE_ID2: 'Import Site Associate id2',
        IMPORTS_SITE_ASSOCIATE_USERS_ID2: 'Import Site Associate Users id2',
        IMPORTS_SITE_ASSOCIATE_ID3: 'Import Site Associate id3',
        IMPORTS_SITE_ASSOCIATE_USERS_ID3: 'Import Site Associate Users id3',
        IMPORTS_Q_COMPLIANCE_SYSTEM_TYPE: 'Q Compliance System Type',
        IMPORTS_SITE_OCCUPANCY_HEADCOUNT: 'Import site occupancy headcount',
        IMPORTS_RISKWISE_SYSTEM_TYPE: 'Import Riskwise System Type',

        //site tenant
        IMPORTS_COMPANY: 'Company',
        IMPORTS_ROLE: 'Role',

        //meter
        IMPORTS_SUPPLY_TYPE_ID: 'Supply type id',
        IMPORTS_REFERENCE_TYPE: 'Reference type',
        IMPORTS_ACCOUNT_NUMBER: 'Account number',
        IMPORTS_MULTIPLIER: 'Multiplier',
        IMPORTS_MAX_VALUE: 'Max value',
        IMPORTS_FREQUENCY: 'Frequency',
        IMPORTS_FREQUENCY_INTERVAL: 'Frequency (e.g: 1Y, 2M, 3W)',
        IMPORTS_INTERVAL: 'Interval',
        IMPORTS_IS_DUAL_RATED: 'Is dual rated',
        IMPORTS_IS_AUTOMATICALLY_READ: 'Automcatically read',
        IMPORTS_FILE_REQUIRED_ON_READING: 'File required on reading',
        IMPORTS_TYPE_ID: 'Type id',
        IMPORTS_MANUFACTURE_ID: 'Manufacture id',
        IMPORTS_UTILITY_PROVIDER_ID: 'Utility provider id',
        IMPORTS_OCCUPANCY_ID: 'Occupancy id',
        IMPORTS_READ_AT: 'Read at',
        IMPORTS_LAST_READING: 'Last reading',
        IMPORTS_UNIT: 'Meter unit',
        IMPORTS_TUR: 'Tenant Utility Re-charge (TUR)',

        //task
        IMPORTS_TYPE: 'Type',
        IMPORTS_SUMMARY: 'Summary',
        IMPORTS_OPERATIVE_ID: 'Operative Id',
        IMPORTS_ASSET_ID: 'Asset id',

        //contract
        IMPORTS_CONTRACT_TYPE: 'Contract Type',
        IMPORTS_START_AT: 'Start At',
        IMPORTS_REVIEW_AT: 'Review At',
        IMPORTS_REVIEW_REMINDER: 'Review Reminder',
        IMPORTS_EXPIRY_DATE: 'Expiry Date',
        IMPORTS_EXPIRES_REMINDER: 'Expires Reminder',
        IMPORTS_VALUE: 'Value',
        IMPORTS_BILLING_INTERVAL: 'Billing Interval',
        IMPORTS_BILLING_FREQUENCY: 'Billing Frequency',
        IMPORTS_LAST_TENDERED: 'Last Tendered',
        IMPORTS_NOTICE_PERIOD_INTERVAL: 'Notice Period Interval',
        IMPORTS_NOTICE_PERIOD_FREQUENCY: 'Notice Period Frequency',
        //waste entries
        IMPORTS_WASTE_UNIT: 'Unit (Id or name)',
        IMPORTS_WASTE_TYPE: 'Waste type (Id, name)',
        IMPORTS_WASTE_COLLECTION_DATE: 'Collection date and time',
        IMPORTS_WASTE_VALUE: 'Waste Value',
        IMPORTS_WASTE_TREATMENT_TYPE: 'Treatment type (Id or name)',
        IMPORTS_WASTE_TREATMENT_TYPE_VALUE: 'Treatment type value',

        // Meter Reading
        IMPORTS_METER_ID: 'Meter id',
        IMPORTS_READING: 'Meter reading',
        IMPORTS_READING_TYPE: 'Reading type',
        IMPORTS_ROLLED_OVER: 'Rolled over',

        //asset/task
        IMPORTS_TASK: 'Task',
        IMPORTS_TASK_NAME: 'Task Name',
        IMPORTS_ASSET: 'Asset',
        IMPORTS_ASSET_NAME: 'Asset Name',
        IMPORTS_ASSET_REFERENCE: 'Asset Reference',
        IMPORTS_ASSET_MAKE: 'Asset Make',
        IMPORTS_ASSET_QUANTITY: 'Asset Quantity',
        IMPORTS_ASSET_SERIAL_NUMBER: 'Asset Serial Number',
        IMPORTS_ASSET_REPLACEMENT_COST_LABOUR: 'Asset Labour Replacement Cost',
        IMPORTS_ASSET_REPLACEMENT_COST_MATERIALS: 'Asset Material Replacement Cost',
        IMPORTS_ASSET_TYPE: 'Asset Type',
        IMPORTS_SERVICE_ROUTINE: 'Asset Service Routine',
        IMPORTS_FREQUENCY_ID: 'Frequency ID',
        IMPORTS_FREQUENCY_STICK_TO_START_DATE: 'Frequency Stick to Start Date',
        IMPORTS_FREQUENCY_STATUTORY_TYPE: 'Frequency Statutory Type',
        IMPORTS_FREQUENCY_DOCUMENT_TYPE: 'Frequency Document Type (Id or Name)',
        IMPORTS_FREQUENCY_DOCUMENT_TYPE_NAME: 'Frequency Document Type (Name)',
        IMPORTS_FREQUENCY_DESCRIPTION: 'Frequency Description',
        IMPORTS_FREQUENCY_START_DATE: 'Frequency Start date (cannot be before 1st Jan last year)',
        IMPORTS_FREQUENCY_POINTS: 'Frequency Points',
        IMPORTS_FREQUENCY_HOURS: 'Frequency Hours',
        IMPORTS_FREQUENCY_MINUTES: 'Frequency Minutes',

        //Site Notes
        IMPORTS_SITE_NOTE_TYPE_1_NAME: 'Site Note Title 1',
        IMPORTS_SITE_NOTE_TYPE_1_BODY: 'Site Note Body 1',
        IMPORTS_SITE_NOTE_TYPE_2_NAME: 'Site Note Title 2',
        IMPORTS_SITE_NOTE_TYPE_2_BODY: 'Site Note Body 2',
        IMPORTS_SITE_NOTE_TYPE_3_NAME: 'Site Note Title 3',
        IMPORTS_SITE_NOTE_TYPE_3_BODY: 'Site Note Body 3',
        IMPORTS_SITE_NOTE_TYPE_4_NAME: 'Site Note Title 4',
        IMPORTS_SITE_NOTE_TYPE_4_BODY: 'Site Note Body 4',
        IMPORTS_SITE_NOTE_TYPE_5_NAME: 'Site Note Title 5',
        IMPORTS_SITE_NOTE_TYPE_5_BODY: 'Site Note Body 5',
        IMPORTS_SITE_NOTE_TYPE_6_NAME: 'Site Note Title 6',
        IMPORTS_SITE_NOTE_TYPE_6_BODY: 'Site Note Body 6',
        IMPORTS_SITE_NOTE_TYPE_7_NAME: 'Site Note Title 7',
        IMPORTS_SITE_NOTE_TYPE_7_BODY: 'Site Note Body 7',
        IMPORTS_SITE_NOTE_TYPE_8_NAME: 'Site Note Title 8',
        IMPORTS_SITE_NOTE_TYPE_8_BODY: 'Site Note Body 8',
        IMPORTS_SITE_NOTE_TYPE_9_NAME: 'Site Note Title 9',
        IMPORTS_SITE_NOTE_TYPE_9_BODY: 'Site Note Body 9',
        IMPORTS_SITE_NOTE_TYPE_10_NAME: 'Site Note Title 10',
        IMPORTS_SITE_NOTE_TYPE_10_BODY: 'Site Note Body 10',
        IMPORTS_SITE_NAME: 'Site Name',
        IMPORTS_ACTION_SITE: 'Site Name',
        IMPORTS_ACTION_SITE_REFERENCE: 'Site Reference',
        IMPORTS_ACTION_ACTION_TYPE: 'Action Type Name',
        IMPORTS_ACTION_PRIORITY: 'Priority Name',
        IMPORTS_ACTION_ASSET: 'Asset Name',
        IMPORTS_ACTION_LOCATION: 'Location Name',
        IMPORTS_ACTION_COMPLETION_DUE: 'Completion Due',
        IMPORTS_ACTION_STATUTORY_TYPE: 'Statutory Type Name'
    });
});
