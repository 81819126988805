(function() {
    'use strict';

    angular
        .module('elogbooks.user.jobs')
        .config(registerRoutes)
        .config(registerRoutesAdd)
        .config(registerRoutesDetails)
        .config(registerRoutesDetailsWorkflow);

    var jobParams = {
        jobsPage: '1',
        jobsLimit: '30',
        jobsOrder: '-id',
        reporter: null,
        fileOrder: '-id',
        jobId: null,
        jobsType: null,
        statType: {array: true},
        jobSummary: null,
        jobPriority: {array: true},
        assetTypes: {array: true},
        jobSiteRegion: {array: true},
        serviceProviders: {array: true},
        createdAtStart: null,
        createdAtEnd: null,
        createdBy: null,
        jobGroups: {array: true},
        chargeType: null,
        operative: null,
        alarmedAtStart: null,
        alarmedAtEnd: null,
        description: null,
        siteIds: {array: true},
        sectors: {array: true},
        approver: {array: true},
        jobStatus: {array: true},
        p2p: null,
        p2pAwaitingValue: null,
        p2pAwaitingPoNumber: null,
        jobValues: null,
        listJobValue: null,
        jobValuesAwaitingValue: null,
        jobValuesAwaitingApproval: null,
        jobsOverdueAttendance: null,
        jobsOverdueCompletion: null,
        jobsStatutory: null,
        jobsServiceProvider: null,
        jobsReminderAtStart: null,
        jobsReminderAtEnd: null,
        jobsAttendanceDueStart: null,
        jobsReminderAtBetween: null,
        jobsAttendanceDueEnd: null,
        completionDueStart: null,
        completionDueEnd: null,
        iAmTheCurrentApprover: null,
        iAmTheExtensionApprover: null,
        jobsOverdueAttendancePerformance: null,
        jobSiteGroups: {array: true},
        datagroup: null,
        asset: null,
        subAsset: null,
        serviceProvider: null,
        fromJob:null,
        jobRequisitePendingApproval: null,
        requisite: null,
        bulkAction: null,
        jobsDueBy: null,
        completedAtStart: null,
        completedAtEnd: null,
        coreClient: null,
        serviceProviderGroup: null,
        location: null,
        isRemedial: null,
        jobsRemedial: null,
        sourceJob: null,
        'excludedStatuses[]': {array: true},
        jobSpecificSpPriority: null,
        sourceJobAsset: null,
        sourceJobSubasset: null,
        documentTypes: {array: true},
        jobsHasDocumentType: null,
        prosureStatusAtCompletion: {array: true},
        prosureStatusAtApproval: {array: true}
    };

    function registerRoutes($stateProvider) {
        $stateProvider
            .state('dashboard.user.jobs', {
                url: '?' + Object.keys(jobParams).join('&'),
                abstract: true,
                params: jobParams,
                template: '<bookerize></bookerize>',
                resolve: {
                    config: function(user, userManager) {
                        /**
                         * While unused, user is required as a dependency here to prevent race conditions against userManager.
                         * Do not remove it!
                         */
                        return {
                            route: '.details.status',
                            resourceName: 'resource',
                            isHelpdesk: userManager.hasPermission('site_permission_helpdesk'),
                            isTenantPlus: userManager.hasPermission('site_permission_tenant_plus')
                                          && !userManager.hasPermission('site_permission_helpdesk')
                                          && !userManager.hasPermission('site_permission_create_jobs'),
                            isTenant: userManager.hasPermission('site_permission_tenant')
                                      && !userManager.hasPermission('site_permission_tenant_plus')
                                      && !userManager.hasPermission('site_permission_helpdesk')
                                      && !userManager.hasPermission('site_permission_create_jobs'),
                            ignoredFilters: [
                                'jobsPage',
                                'jobsLimit',
                                'jobsOrder',
                                'fileOrder',
                                'limit',
                                'childLocationActive',
                                'page',
                                'locationActive',
                                'resource',
                                'status'
                            ]
                        };
                    },
                },
                ncyBreadcrumb: {
                    skip: true
                }
            })
            .state('dashboard.user.jobs.list', {
                url: '/jobs',
                parent: 'dashboard.user.jobs',
                views: parseBook({
                    'elogbooks-left@dashboard.user.jobs': {
                        templateUrl: '/modules/common/jobs/jobs-list.html',
                        controller: 'CommonJobsController',
                        controllerAs: 'vm'
                    }
                }),
                resolve: {
                    statutoryTypeCollectionResponse: function (apiClient, rootResourceResponse) {
                        if (rootResourceResponse.getLink('statutorytypes')) {
                            return apiClient.get(rootResourceResponse.getLink('statutorytypes'), null, 'long').then(function (response) {
                                return response || apiClient.noResourceFound();
                            });
                        }
                    },
                    documentTypesCollectionResponse: function (apiClient, rootResourceResponse) {
                        if (rootResourceResponse.getLink('documenttypes')) {
                            return apiClient.get(rootResourceResponse.getLink('documenttypes'), null, 'long').then(function (response) {
                                return response || apiClient.noResourceFound();
                            });
                        }
                    },
                    priorityCollectionResponse: function(apiClient, rootResourceResponse, selectedSiteResponse, $stateParams) {
                        var priorityLinkParameters;

                        if ($stateParams.siteIds) {
                            priorityLinkParameters = {'active': true, 'entityTypes': 'entity_type_all,entity_type_job', 'sites[]':$stateParams.siteIds};
                        } else if(selectedSiteResponse) {
                            priorityLinkParameters = {'active': true, 'entityTypes': 'entity_type_all,entity_type_job', 'sites[]':selectedSiteResponse.id};
                        } else {
                            priorityLinkParameters = {'active': true, 'entityTypes': 'entity_type_all,entity_type_job'};
                        }

                        priorityLinkParameters.order ='name';

                        if (rootResourceResponse.getLink('priorities')) {
                            return apiClient.get(rootResourceResponse.getLink('priorities'), priorityLinkParameters, 'long').then(function(response) {
                                return response || apiClient.noResourceFound();
                            });
                        }
                    },
                    jobCollectionResponse: function($stateParams, $rootScope, jobStatuses, userManager, elbSettings) {

                        if (userManager.hasPermission('site_permission_helpdesk')) {
                            $rootScope.jobStatusQuickFilter = 'all';
                        } else {
                            if (Array.isArray($stateParams.jobStatus)) {
                                $rootScope.jobStatusQuickFilter = $stateParams.jobStatus.length === 0 ? 'all' : 'filtered';
                            }

                            if ($stateParams.jobStatus === undefined && $rootScope.jobStatusQuickFilter != 'all') {
                                $stateParams.jobStatus = jobStatuses.getAll('nonfinal');
                            }
                        }

                        if (!elbSettings.getSetting('prosure_enabled').value) {
                            delete $stateParams.prosureStatusAtCompletion;
                            delete $stateParams.prosureStatusAtApproval;
                        }

                        return {
                            jobs: null,
                            pages: 0,
                            count: 0
                        }
                    },
                    chargeTypesCollectionResponse: function(apiClient, rootResourceResponse) {
                        if (rootResourceResponse.getLink('chargetypes')) {
                            return apiClient.get(rootResourceResponse.getLink('chargetypes'), null, 'long').then(function(response) {
                                return response || apiClient.noResourceFound();
                            });
                        }
                    },
                    canBulkReassign: function(user, userManager) {
                        return userManager.hasPermission('site_permission_bulk_reassign_jobs');
                    },
                    canBulkReassignOperative: function(apiClient, user, userManager) {
                        if (user.getLink('operatives')) {
                            return apiClient.get(user.getLink('operatives'), {permission: 'operative_permission_bulk_reassign_operative_jobs'}, 'long').then(function(response) {
                                var canReassign = false;
                                if (response) {
                                    canReassign = response.count;
                                }
                                return canReassign || userManager.hasPermission('site_permission_bulk_reassign_operative_jobs');
                            });
                        }
                    },
                    canBulkCancel: function(apiClient, user, userManager) {
                        if (user.getLink('operatives')) {
                            return apiClient.get(user.getLink('operatives'), {permission: 'operative_permission_bulk_cancel_jobs'}, 'long').then(function(response) {
                                var canCancel = false;
                                if (response) {
                                    canCancel = response.count;
                                }
                                return canCancel || userManager.hasPermission('site_permission_bulk_cancel_jobs');
                            });
                        }
                    },
                    serviceProviderCollectionResponse: function($stateParams, apiClient, user, userManager, selectedSiteResponse) {
                        if (!user.getLink('serviceproviders')) {
                            return null;
                        }

                        var params = {};
                        if ($stateParams.serviceProviders) {
                            params.id = $stateParams.serviceProviders[0];
                        }

                        if (selectedSiteResponse) {
                            params.siteId = selectedSiteResponse.id;
                        }

                        if (user.getLink('serviceproviders')) {
                            return apiClient.get(user.getLink('serviceproviders'), params, 'medium').then(function(response) {
                                return response || apiClient.noResourceFound();
                            });
                        }
                    }
            },
                ncyBreadcrumb: {
                    label: '{{ ::"JOBS" | translate }}'
                }
            });
    }

    function registerRoutesAdd($stateProvider) {
        $stateProvider
            .state('dashboard.user.jobs.list.add', {
                url: '/add/{siteResource}',
                abstract: true,
                views: parseBook({
                    'elogbooks-right@dashboard.user.jobs': {
                        templateUrl: '/modules/common/jobs/add/job-form.html',
                        controller: 'CommonJobAddController',
                        controllerAs: 'vm'
                    }
                }),
                resolve: {
                    actionResponse: function() {
                        return null;
                    },
                    spillageResponse: function() {
                        return null;
                    },
                    config: function($stateParams, selectedSiteResponse, config) {
                        return angular.extend(config, {
                            isHelpdesk: config.isHelpdesk,
                            isTenant: config.isTenant,
                            showSiteNotes: config.isHelpdesk,
                            addReporter: config.isHelpdesk,
                            link: null,
                            siteDisabled: (selectedSiteResponse)
                        });
                    },
                    siteResourceResponse: function (apiClient, $stateParams, selectedSiteResponse) {
                        if ($stateParams.siteResource) {
                            return apiClient.get($stateParams.siteResource.decode()).then(function(response) {
                                return response || apiClient.noResourceFound();
                            });
                        } else if (selectedSiteResponse) {
                            return selectedSiteResponse;
                        }
                    },
                    chargeTypesCollectionResponse: function(apiClient, rootResourceResponse) {
                        return apiClient.get(rootResourceResponse.getLink('chargetypes')).then( function(response) {
                            return response || apiClient.noResourceFound();
                        });
                    },
                    notesCollectionResponse: function($stateParams, apiClient, siteResourceResponse, config) {
                        if (config.isHelpdesk && siteResourceResponse) {
                            return apiClient.get(siteResourceResponse.getLink('notes')).then(function (response) {
                                return response || apiClient.noResourceFound();
                            });
                        }
                    }
                },
                ncyBreadcrumb: {
                    label: '{{ ::"JOB_ADD" | translate }}'
                }
            })
            .state('dashboard.user.jobs.list.add.notes', {
                url: '/notes',
                parent: 'dashboard.user.jobs.list.add',
                views: {
                    'info@dashboard.user.jobs.list.add': {
                        templateUrl: '/modules/common/jobs/add/job-notes.html',
                        controller: 'CommonJobAddNotesController',
                        controllerAs: 'vm'
                    }
                },
                ncyBreadcrumb: {
                    label: '{{ ::"NOTES" | translate }}'
                }
            })
            .state('dashboard.user.jobs.list.add.site', {
                url: '/site',
                parent: 'dashboard.user.jobs.list.add',
                views: parseBook({
                    'elogbooks-right@dashboard.user.jobs.list.add': {
                        templateUrl: '/modules/common/jobs/add/job-form-site.html',
                        controller: 'CommonJobAddSiteController',
                        controllerAs: '_' // Faked controllerAs so we can inherit from parent
                    }
                }),
                resolve: {
                    siteCollectionResponse: function (apiClient, user, userManager) {
                        var params = {
                            'can-raise-jobs': true,
                            'datagroup': 'search'
                        };

                        if (userManager.hasPermission('site_permission_tenant')) {
                            delete params['can-raise-jobs'];
                            params['can-raise-tenant-jobs'] = true;
                        }

                        return apiClient.get(user.getLink('sites'), params).then(function (response) {
                            return response || apiClient.noResourceFound();
                        });
                    },
                    siteRelationshipResponse: function(rootResourceResponse) {
                        return rootResourceResponse.getResource('siterelationships');
                    },
                    siteResourceResponse: function(siteResourceResponse) {
                        return siteResourceResponse;
                    }
                },
                ncyBreadcrumb: {
                    label: '{{ ::"SITE" | translate }}'
                }
            })
            .state('dashboard.user.jobs.list.add.reporter', {
                url: '/reporter',
                parent: 'dashboard.user.jobs.list.add.site',
                views: parseBook({
                    'elogbooks-right@dashboard.user.jobs.list.add': {
                        templateUrl: '/modules/common/jobs/add/job-form-reporter.html',
                        controller: 'CommonJobAddReporterController',
                        controllerAs: '_' // Faked controllerAs so we can inherit from parent
                    }
                }),
                resolve: {
                    userCollectionResponse: function(apiClient, user, siteResourceResponse) {
                        return null;
                    }
                },
                ncyBreadcrumb: {
                    label: '{{ ::"JOB_REPORTER" | translate }}'
                }
            })
            .state('dashboard.user.jobs.list.add.contact', {
                url: '/contact',
                parent: 'dashboard.user.jobs.list.add.site',
                views: parseBook({
                    'elogbooks-right@dashboard.user.jobs.list.add': {
                        templateUrl: '/modules/common/jobs/add/job-form-contact.html',
                        controller: 'CommonJobAddContactController',
                        controllerAs: '_' // Faked controllerAs so we can inherit from parent
                    }
                }),
                resolve: {
                    userCollectionResponse: function (siteResourceResponse) {
                        return null; //siteResourceResponse.getResource('memberships');
                    },
                    actionFilesCollection: function () {
                        return null;
                    }
                },
                ncyBreadcrumb: {
                    label: '{{ ::"JOB_CONTACT" | translate }}'
                }
            })
            .state('dashboard.user.jobs.list.add.details', {
                url: '/details',
                parent: 'dashboard.user.jobs.list.add.contact',
                views: parseBook({
                    'elogbooks-right@dashboard.user.jobs.list.add': {
                        templateUrl: '/modules/common/jobs/add/job-form-details.html',
                        controller: 'CommonJobAddDetailsController',
                        controllerAs: '_' // Faked controllerAs so we can inherit from parent
                    }
                }),
                resolve: {
                    jobGroupCollectionResponse: function (apiClient, rootResourceResponse) {
                        return apiClient.get(rootResourceResponse.getLink('jobgroups'), { order: name, fetchHierarchy: false }).then(function (response) {
                            return response || apiClient.noResourceFound();
                        });
                    },
                    partnershipCollectionResponse: function (apiClient, siteResourceResponse, user, elbSettings) {
                        var params = {active: 1, includeCanCreateJobsOtherServiceProviders: 'true'};

                        if (elbSettings.getSetting('prosure_enabled').value) {
                            params = angular.extend({order: 'prosureStatus'}, params);
                        }

                        if (siteResourceResponse.getLink('partnerships')) {
                            return apiClient.get(siteResourceResponse.getLink('partnerships'), params).then(function (response) {
                                return response || apiClient.noResourceFound();
                            });
                        } else if (user.getLink('partnerships')) {
                            params = angular.extend({siteId: siteResourceResponse.id}, params);

                            return apiClient.get(user.getLink('partnerships'), params).then(function (response) {
                                return response || apiClient.noResourceFound();
                            });
                        }
                    },
                    settingCollectionResponse: function (apiClient, rootResourceResponse) {
                        return apiClient.get(rootResourceResponse.getLink('jobsettings')).then(function (response) {
                            return response || apiClient.noResourceFound();
                        });
                    },
                    statutoryTypeCollectionResponse: function (apiClient, rootResourceResponse) {
                        return apiClient.get(rootResourceResponse.getLink('statutorytypes')).then(function (response) {
                            return response || apiClient.noResourceFound();
                        })
                    },
                    locationCollectionResponse: function (apiClient, siteResourceResponse) {
                        return apiClient.get(siteResourceResponse.getLink('locations')).then(function (response) {
                            return response || apiClient.noResourceFound();
                        })
                    },
                    assetsCollectionResponse: function (apiClient, siteResourceResponse) {
                        if (siteResourceResponse.getLink('assets')) {
                            return apiClient.get(siteResourceResponse.getLink('assets'), {active:1}).then(function (response) {
                                return response;
                            });
                        }
                    },
                    userIsReactiveJobApprover: function(apiClient, siteResourceResponse, settingCollectionResponse) {
                        if (settingCollectionResponse.reactiveSelfApproval) {
                            return apiClient.get(siteResourceResponse.getLink('isreactivejobapprover')).then(function (response) {
                                var data = response.getData();
                                return data.isReactiveJobApprover || false;
                            });
                        } else {
                            return null;
                        }
                    },
                    config: function($state, selectedSiteResponse, userManager) {
                        return {
                            showSiteNotes: userManager.hasPermission('site_permission_helpdesk')
                        };
                    },
                    documentTypesCollectionResponse: function (apiClient, rootResourceResponse) {
                        if (rootResourceResponse.getLink('documenttypes')) {
                            return apiClient.get(rootResourceResponse.getLink('documenttypes'), {status: 'active', limit: 999}).then(function (response) {
                                return response;
                            });
                        }
                    }
                },
                ncyBreadcrumb: {
                    label: '{{ ::"JOB_DETAILS" | translate }}'
                }
            });
    }

    function registerRoutesDetails($stateProvider) {
        elbJobDetailsRouting().addRoutes($stateProvider, 'dashboard.user.jobs', false);
        elbJobDetailsRouting().addRoutes($stateProvider, 'dashboard.user.jobs.list', true);
    }

    function registerRoutesDetailsWorkflow($stateProvider) {
        elbJobWorkflowRouting().addRoutes($stateProvider, 'dashboard.user.jobs.details.status', true);
        elbJobWorkflowRouting().addRoutes($stateProvider, 'dashboard.user.jobs.list.details.status', true);
    }
})();
