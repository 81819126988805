angular.module('elogbooksDirectives').directive('elogbooksLocationPath', [function () {
    return {
        restrict: 'AE',
        require: ['ngModel'],
        scope: {
            model: '=ngModel',
            route: '@',
            routeParams: '@',
            linkLast: '@'
        },
        templateUrl: '/modules/directives/location-path/location-path.html',
        controller: function ($scope) {

            if ($scope.model) {
                if (typeof $scope.model.fullPathString === 'string') {
                    $scope.model.fullPathString = JSON.parse($scope.model.fullPathString);
                } else {
                    $scope.model.fullPathString = [
                        {
                            name: $scope.model.name,
                            _links: {self: {href: $scope.model.href}}
                        }
                    ];
                }
            }

            $scope.getParams = getParams;

            function getParams(location) {
                return angular.extend({}, angular.fromJson($scope.routeParams), {locationResource: location._links.self.href.encode()});
            }
        }
    };
}]);
