(function() {
    'use strict';

    angular
        .module('elogbooks.admin.service-providers')
        .controller('ServiceProviderPartnershipsListController', ServiceProviderPartnershipsListController);

    ServiceProviderPartnershipsListController.$inject = ['serviceProviderResponse', '$stateParams', '$state', 'lodash', 'confirmationModal', 'messenger', 'apiClient', 'partnershipsResponse', '$filter'];

    function ServiceProviderPartnershipsListController(serviceProviderResponse, $stateParams, $state, lodash, confirmationModal, messenger, apiClient, partnershipsResponse, $filter) {
        var vm = this;
        vm.partnerships = partnershipsResponse.partnerships;
        vm.partnershipsResponse = partnershipsResponse;
        vm.partnerships._links = partnershipsResponse._links;
        vm.entityCount = partnershipsResponse.count;
        vm.partnerships.canCreate = lodash.has(partnershipsResponse, '_links.createServiceProviderPartnership');

        var statusOptions = [
            {title: $filter('translate')('ALL'), value: null},
            {title: $filter('translate')('STATUS_ACTIVE'), value: '1'},
            {title: $filter('translate')('STATUS_INACTIVE'), value: '0'}
        ];

        vm.criteria = {
            site: {type: 'text', title: 'SITE_NAME', value: $stateParams.site},
            partnershipActive: {
                type: 'options',
                title: 'PARTNERSHIP_STATUS',
                value: $stateParams.partnershipActive,
                options: statusOptions,
                clearValue: null
            }
        };

        vm.search = searchAction;
        vm.order = orderAction;

        function searchAction(params) {
            var override = {
                partnershipPage: 1
            };

            $state.go('.', angular.extend({}, params, override), { reload: $state.current });
        }

        function orderAction(key) {

            $stateParams.partnershipOrder = key;

            var override = {
                partnershipPage: 1
            };

            $state.go('.', angular.extend({}, $stateParams, override), { reload: $state.current });
        }
    }
})();
