(function () {
    'use strict';

    angular
        .module('elogbooksDirectives')
        .controller('loadFilterCriteriaController', loadFilterCriteriaController);

        loadFilterCriteriaController.$inject = ['$scope', 'apiClient', '$q', '$state', 'messenger', 'filterCriteriaService', 'confirmationModal'];

    function loadFilterCriteriaController($scope, apiClient, $q, $state, messenger, filterCriteriaService, confirmationModal) {
        var requests = [],
            selectedFilter = filterCriteriaService.getSelectedFilter();
        var removingInProgress = false;

        $scope.loading = true;
        $scope.loadingMore = false;
        $scope.applyFilters = applyFilters;
        $scope.deleteFilters = deleteFilters;
        $scope.loadMore = loadMore;
        $scope.selectedFilter = selectedFilter;

        if ($scope.filterUser.getLink('filter-criteria')) {
            var params = {
                route: $scope.$root.currentState
            };
            requests.push(apiClient.get($scope.filterUser.getLink('filter-criteria'), params));
        }

        $q.all(requests).then(function (response) {
            $scope.filtersResponse = response[0];
            $scope.loading = false;
        });

        function applyFilters (filter) {
            $scope.close();
            var filters = JSON.parse(filter.query);
            // reset all $state.params to avoid keeping filters when selecting another save filter
            var params = filterCriteriaService.setAll($state.params, null);
            params = Object.assign({}, params, filters);
            
            if (params.jobStatus === null) {
                params.jobStatus = [];
                filters.jobStatus = [];
            }
            
            $state.go(filter.route, params, {reload: filter.route});
            filterCriteriaService.setSelectedFilter(filter);
        }

        function deleteFilters (filter) {
            if (removingInProgress) {
                return;
            }

            filter.isRemoving = true;

            if (filter.getLink('delete')) {
                var modal = confirmationModal.open();

                modal.closed.then(function () {
                    if (!removingInProgress) {
                        filter.isRemoving = false;
                    }
                });

                return modal.result.then(function () {
                    removingInProgress = true;
                    return apiClient.delete(filter.getLink('delete')).then(function (response) {
                        removingInProgress = false;
                        filter.isRemoving = false;

                        if (response) {
                            messenger.success('FILTER_DELETED');
                            filter.removed = true;

                            // remove highlights if deleted filter is applied
                            if (selectedFilter && selectedFilter.id === filter.id) {
                                filterCriteriaService.setSelectedFilter(null);
                                $state.go(filter.route, {}, {reload: filter.route});
                            }

                        } else {
                            messenger.error('REQUEST_ERROR');
                        }
                    });
                });
            }
        }

        function loadMore() {
            if ($scope.loadingMore) {
                return;
            }

            $scope.filtersResponse.noMore = false;

            if ($scope.filtersResponse.page === $scope.filtersResponse.pages) {
                messenger.error('NO_MORE_RESULTS');
            }

            if ($scope.filtersResponse.page < $scope.filtersResponse.pages) {
                $scope.loadingMore = true;
                var params = {
                    route: $scope.$root.currentState
                };

                apiClient.get($scope.filtersResponse.getLink('next'), params).then(function (response) {
                    $scope.filtersResponse.filterCriteria = $scope.filtersResponse.filterCriteria.concat(response.filterCriteria);
                    $scope.filtersResponse.page = response.page;
                    $scope.filtersResponse.pages = response.pages;
                    $scope.filtersResponse._links = response._links;
                    $scope.filtersResponse.noMore = $scope.filtersResponse.page === $scope.filtersResponse.pages;
                    $scope.loadingMore = false;
                });
            }
        }
    }
})();
