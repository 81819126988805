(function () {
    'use strict';

    angular
        .module('elogbooks.common.quote-requests')
        .controller('CommonQuoteRequestsController', CommonQuoteRequestsController);

    CommonQuoteRequestsController.$inject = [
        '$state',
        '$stateParams',
        'config',
        'quoteRequestCollectionResponse',
        'priorityCollectionResponse',
        'quoteRequestService',
        '$scope',
        'rootResourceResponse',
        'selectedSiteResponse',
        'user',
        'paramConverterService',
        'serviceProviderCollectionResponse',
        '$translate',
        'elbSettings',
        'analyticsService'
    ];

    function CommonQuoteRequestsController(
        $state,
        $stateParams,
        config,
        quoteRequestCollectionResponse,
        priorityCollectionResponse,
        quoteRequestService,
        $scope,
        rootResourceResponse,
        selectedSiteResponse,
        user,
        paramConverterService,
        serviceProviderCollectionResponse,
        $translate,
        elbSettings,
        analyticsService
    ) {
        var vm = this;
        vm.quoteRequestCollectionResponse = quoteRequestCollectionResponse;
        vm.quoteRequestService = quoteRequestService;
        vm.config = config;
        vm.budgetQuotesSetting = elbSettings.getSetting('budget_quotes').value;

        vm.getViewRoute = getViewRoute;

        vm.search = searchAction;
        vm.order = orderAction;
        vm.getPriorities = getPriorities;

        vm.loadedCollections = [priorityCollectionResponse];

        vm.stateParams = $stateParams;
        vm.resources = {
            'rootResource': rootResourceResponse,
            'selectedSite': selectedSiteResponse,
            'stateParams': $stateParams,
            'root': rootResourceResponse,
            'user': user,
            'sites': $scope.vm.siteSelect
        };

        var deregisterWatch = $scope.$watch('vm.quoteRequestCollectionResponse', function(value) {
            if (value.count) {
                analyticsService.pageLoaded();
                deregisterWatch();
            }
        });

        function getPriorities() {
            if (priorityCollectionResponse) {
                return paramConverterService.formatResponse(priorityCollectionResponse.priorities);
            }
        }

        vm.criteria = {
            quoteSummary: {
                type: 'text',
                title: 'SUMMARY',
                value: $stateParams.quoteSummary
            },
            quoteStatus: {
                type: 'options',
                title: 'STATUS',
                multiple: true,
                value: $stateParams.quoteStatus,
                options: quoteRequestService.getQuoteStatusesAsObject(vm.quoteRequestCollectionResponse.budgetQuoteFilter)
            },
            quotePriority: {
                type: 'options',
                title: 'PRIORITY',
                multiple: true,
                value: paramConverterService.checkStateParams($stateParams.quotePriority),
                options: getPriorities()
            }
        };

        if (serviceProviderCollectionResponse) {
            vm.serviceProviderSelect = {
                response : serviceProviderCollectionResponse,
                link : serviceProviderCollectionResponse ? serviceProviderCollectionResponse.getLink('self') : null,
                responseKeyPath: 'serviceproviders'
            };

            vm.criteria.serviceProviderName = { type: 'selectwidget', title: 'SERVICE_PROVIDER', value: $stateParams.serviceProviderName, options: vm.serviceProviderSelect };
        }

        if (vm.quoteRequestCollectionResponse.budgetQuoteFilter) {
            vm.criteria.budgetQuote = {
                type: 'options',
                title: 'BUDGET_QUOTE',
                value: $stateParams.budgetQuote ? $stateParams.budgetQuote.toString() : null,
                options: [
                    {
                        title: $translate.instant('NONE_SELECTED'),
                        value: null
                    },
                    {
                        title: $translate.instant('YES'),
                        value: '1'
                    },
                    {
                        title: $translate.instant('NO'),
                        value: '0'
                    },
                    {
                        title: $translate.instant('Converted'),
                        value: '-1'
                    }
                ]
            };
        }

        function searchAction(params) {
            var override = {
                quoteRequestsPage: 1
            };

            $state.go('.', angular.extend({}, params, override), { reload: $state.current });
        }

        function orderAction(key) {
            $stateParams.quoteRequestsOrder = key;

            $state.go('.', angular.extend({}, $stateParams), { reload: $state.current });
        }

        function getViewRoute(quoteRequest, quote, route, parameters) {
            route = vm.config.route || route;

            if (!parameters) {
                parameters = vm.config.parameters || {};
            }

            parameters.quoteRequestResource = quoteRequest.getLink('self').encode();
            parameters.quoteResource = quote.getLink('self').encode();
            parameters.resource = quoteRequest.getLink('site').encode();

            return route + '(' + JSON.stringify(parameters) + ')';
        }
    }
})();
